import ReviewService from '@/services/ReviewService'
import { isEqual } from 'lodash-es'
import moment from 'moment-timezone'

const CLICKOUT_BLACKLIST = ['grubhub', 'doordash']

export default {
  namespaced: true,
  state: {
    reviews: [],
    filters: {},
    limit: 30,
    skip: 0,
    refreshKey: 0,
    reviewListLoading: false,
    allReviewsLoaded: false,
    clickoutBlacklist: CLICKOUT_BLACKLIST,
    oldestReviewTimestamp: '',
  },
  getters: {
    selectReviews: (state) => state.reviews,
    selectFilters: (state) => state.filters,
    selectLimit: (state) => state.limit,
    selectSkip: (state) => state.skip,
    selectRefreshKey: (state) => state.refreshKey,
    selectReviewListLoading: (state) => state.reviewListLoading,
    selectAllReviewsLoaded: (state) => state.allReviewsLoaded,
    selectIsClickoutBlacklisted: (state) => (network) => state.clickoutBlacklist?.includes(network),
    selectOldestReviewTimestamp: (state) => state.oldestReviewTimestamp,
  },
  mutations: {
    SET_REVIEWS(state, reviews) {
      state.reviews = [...reviews]
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_SKIP(state, skip) {
      state.skip = skip
    },
    INCREMENT_REFRESH_KEY(state) {
      state.refreshKey += 1
    },
    SET_REVIEW_LIST_LOADING(state, status) {
      state.reviewListLoading = status
    },
    SET_ALL_REVIEWS_LOADED(state, status) {
      state.allReviewsLoaded = status
    },
    SET_OLDEST_REVIEW_TIMESTAMP(state, timestamp) {
      state.oldestReviewTimestamp = moment(timestamp).startOf('day').format()
    },
  },
  actions: {
    async updateFilters({ commit, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return
      commit('SET_FILTERS', filters)
      commit('SET_SKIP', 0)
      commit('SET_ALL_REVIEWS_LOADED', false)
      commit('INCREMENT_REFRESH_KEY')
    },

    async refreshList({ commit }) {
      commit('INCREMENT_REFRESH_KEY')
    },

    async formatPayloadFilters({ getters, rootGetters }) {
      const { dateRange, locations, network } = getters.selectFilters
      let payload = {}

      payload.timestampRange = dateRange
      payload.locationIds = locations?.length
        ? locations
        : rootGetters['location/selectActiveLocations'].map((l) => l._id)
      if (network) {
        const reviewSubscriptionIds = rootGetters[
          'location/selectLocationReviewSubscriptionsByNetwork'
        ](network, payload.locationIds)
        if (reviewSubscriptionIds?.length) {
          payload.reviewSubscriptionIds = reviewSubscriptionIds
        }
      }
      payload.limit = getters.selectLimit
      payload.skip = getters.selectSkip

      return payload
    },

    async fetchReviewList({ getters, commit, dispatch }) {
      if (getters.selectReviewListLoading) return

      if (!getters.selectSkip) {
        commit('SET_REVIEW_LIST_LOADING', true)
      }

      const response = await ReviewService.search({
        companyIds: getters.selectFilters.companies,
        filters: await dispatch('formatPayloadFilters'),
      })

      if (response.body.data.length < getters.selectLimit) {
        commit('SET_ALL_REVIEWS_LOADED', true)
      }

      if (!getters.selectSkip) {
        commit('SET_REVIEWS', response.body.data)
        commit('SET_REVIEW_LIST_LOADING', false)
      } else {
        commit('SET_REVIEWS', [...getters.selectReviews, ...response.body.data])
      }

      commit('SET_SKIP', getters.selectSkip + getters.selectLimit)
    },

    async fetchOldestTimestamp({ commit, rootGetters }) {
      const activeCompany = rootGetters['company/activeCompany']
      if (!activeCompany || activeCompany._id === 'ALL_COMPANIES') return
      const response = await ReviewService.fetchOldestTimestamp({ companyIds: [activeCompany._id] })
      commit('SET_OLDEST_REVIEW_TIMESTAMP', response.body.data.oldestTimestamp)
    },

    // deprecated
    async getReviewList({ state, rootState, commit }, options) {
      const response = await ReviewService.list({
        filters: options,
        companyIds: [rootState.company.activeCompany._id],
      })

      if (options.skip) {
        return commit('SET_REVIEWS', [...state.reviews, ...response.body.data])
      }
      commit('SET_REVIEWS', response.body.data)
    },
  },
}
