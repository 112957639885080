var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('label',{attrs:{"for":"promoExpirationPicker"}},[_vm._v("Automatically expire")]),_c('small',{staticClass:"form-text text-muted mb-3"},[_vm._v("Optionally, you can have your offer expire automatically.")]),_c('b-dropdown',{staticClass:"hide-caret",attrs:{"variant":"white"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"fe fe-clock ml-1 text-muted",staticStyle:{"position":"relative","top":"1px"}}),_vm._v(" "+_vm._s(_vm.selectedExpirationType)+" ")]),_vm._l((_vm.expirationTypes),function(expirationType){return _c('b-dropdown-item',{key:expirationType,staticClass:"dropdown-item",on:{"click":function($event){return _vm.setExpirationType(expirationType)}}},[_vm._v(_vm._s(expirationType))])})],2)],1),_c('div',{staticClass:"col-12 col-lg-6 mt-4 mt-lg-0"},[_c('div',{staticClass:"form-group"},[(_vm.showExpirationDaysField)?_c('div',[_c('label',{attrs:{"for":"promoExpiresIn"}},[_vm._v("Duration")]),_c('small',{staticClass:"form-text text-muted mb-3"},[_vm._v("Setup how long a customer has to redeem the offer.")]),_c('div',{staticClass:"input-group"},[_vm._m(0),_c('input',{ref:"expiresIn",staticClass:"form-control form-control-prepended",attrs:{"type":"text","id":"promoExpiresIn"},domProps:{"value":_vm.expiresIn},on:{"input":_vm.onExpiresInChange}}),_vm._m(1)])]):_vm._e(),(_vm.showExpirationDateField)?_c('div',[_c('label',{attrs:{"for":"promoExpiresAt"}},[_vm._v("Expiration date")]),_c('small',{staticClass:"form-text text-muted mb-3"},[_vm._v("Offer will be valid until the end of the selected date.")]),_c('flat-pickr',{ref:"expiresAt",staticClass:"form-control",attrs:{"id":"promoExpiresAt","value":_vm.expiresAt,"config":{
            mode: 'single',
            dateFormat: 'M j, Y',
            enableTime: false,
            altInput: true,
            altFormat: 'M j, Y',
            wrap: true,
            key: 'expiresAt',
          },"placeholder":"Choose a date","name":"expiresAt"},on:{"on-change":_vm.onDateChange}})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v("Expire after")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_vm._v("days")])])
}]

export { render, staticRenderFns }