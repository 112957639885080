<template>
  <multiselect
    placeholder="Type to search"
    style="min-width: 100px; text-transform: capitalize"
    selectLabel
    v-model="rating"
    @input="updateRating"
    :options="surveyRatings"
    :custom-label="ratingLabel"
    track-by="score"
  >
    <template slot="placeholder">
      <span class="text-secondary"> <i class="far fa-smile"></i> {{ placeholder }} </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span class="text-capitalize">{{ props.option.emoti }} {{ props.option.title }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import { surveyRatings } from '@/lib/rating'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SingleRatingFilter',
  components: {
    Multiselect,
  },
  props: {
    placeholder: {
      type: String,
      default: 'All ratings',
    },
  },
  data: () => ({
    surveyRatings: surveyRatings,
    rating: null,
  }),
  methods: {
    updateRating(rating) {
      this.$emit('updateRating', rating)
    },
    ratingLabel({ emoti, title }) {
      return `${emoti} ${title}`
    },
  },
}
</script>
