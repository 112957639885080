<template>
  <div class="message">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-2">
        <a
          href="javascript:;"
          class="btn btn-sm btn-rounded-circle mt-1"
          style="background-color: #39afd1"
        >
          <PhoneIcon size="1.15x" class="mt--1 text-white"></PhoneIcon>
        </a>
      </div>
      <div class="col">
        <div class="comment-body">
          <p class="comment-text">
            Called
            <span v-if="call.actions && call.actions.length">
              and selected
              <span style="font-weight: bold">
                #{{ call.actions[0].digit }} - {{ call.actions[0].name }}
              </span>
            </span>
          </p>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">
                {{ call.startTime | moment('LT') }}
                {{ timezone }}
                <span v-if="location">
                  &#8226;
                  {{ location.friendlyName || location.city || location.name }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
import { PhoneIcon } from 'vue-feather-icons'

export default {
  name: 'CallEvent',
  props: ['timezone', 'call', 'location'],
  components: {
    Avatar,
    PhoneIcon,
  },
}
</script>
