var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentPrefix)?_c('popper',{ref:"popperRef",staticClass:"date-popper",attrs:{"trigger":"click","options":{
      placement: 'bottom',
      modifiers: {
        offset: { offset: '0,6px' },
        flip: { enabled: false },
        gpuAcceleration: true,
      },
    }},on:{"show":function($event){return _vm.initialize()}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"row mt-0"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle mb--4 mt-3 text-left"},[_vm._v("Select a date range")])]),_c('div',{staticClass:"col text-right"},[_c('button',{staticClass:"btn btn-outline-secondary btn-sm mb--2",on:{"click":function($event){return _vm.reset()}}},[_vm._v("Reset")])])]),_c('hr'),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-white dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.currentPrefix.name)+" ")]),_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;","data-toggle":"true"},on:{"click":function($event){return _vm.reset()}}},[_vm._v("All time")]),_vm._l((_vm.prefixes),function(prefix){return _c('a',{key:prefix.type,staticClass:"dropdown-item",attrs:{"data-toggle":"true","href":"javascript:;"},on:{"click":function($event){return _vm.setPrefix(prefix)}}},[_vm._v(_vm._s(prefix.name))])})],2)]),(_vm.currentPrefix.type === 'range')?_c('flat-pickr',{ref:"rangeRef",staticClass:"form-control",attrs:{"config":{
            mode: 'range',
            dateFormat: 'M j, Y',
            altFormat: 'M j, Y',
            altInput: true,
            inline: true,
            wrap: false,
          },"placeholder":"Choose a range","name":"date"},on:{"on-close":_vm.applyFilters},model:{value:(_vm.currentPrefix.date),callback:function ($$v) {_vm.$set(_vm.currentPrefix, "date", $$v)},expression:"currentPrefix.date"}}):_vm._e(),(_vm.currentPrefix.type === 'ago')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPrefix.date),expression:"currentPrefix.date"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"90"},domProps:{"value":(_vm.currentPrefix.date)},on:{"keyup":_vm.applyFilters,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.currentPrefix, "date", $event.target.value)}}}):_vm._e(),(_vm.currentPrefix.type === 'ago')?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-outline-secondary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.currentPrefix.unit)+" ")]),_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.setUnit('days')}}},[_vm._v("days")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.setUnit('weeks')}}},[_vm._v("weeks")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.setUnit('months')}}},[_vm._v("months")])])]):_vm._e(),(_vm.currentPrefix.type === 'since' || _vm.currentPrefix.type === 'on')?_c('flat-pickr',{ref:"sinceRef",staticClass:"form-control",attrs:{"config":{
            mode: 'single',
            dateFormat: 'M j, Y',
            altInput: true,
            inline: true,
            wrap: false,
          },"placeholder":"Choose a date","name":"date"},on:{"on-close":_vm.applyFilters},model:{value:(_vm.currentPrefix.date),callback:function ($$v) {_vm.$set(_vm.currentPrefix, "date", $$v)},expression:"currentPrefix.date"}}):_vm._e()],1),(_vm.currentPrefix.type != 'ago')?_c('button',{staticClass:"btn btn-primary mt-3 w-100",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.applyFilters()}}},[_vm._v(" Apply ")]):_vm._e()]),_c('button',{staticClass:"btn btn-outline-secondary",staticStyle:{"font-size":"14px"},attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"fe fe-clock"}),_vm._v(" "+_vm._s(_vm.currentPrefix.date ? `${_vm.currentPrefix.name} ${_vm.currentPrefix.date}${ _vm.currentPrefix.unit ? ' ' + _vm.currentPrefix.unit : '' }` : 'All time')+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }