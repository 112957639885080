<template>
  <div>
    <!-- Add Template Modal -->
    <CreateTemplateModal
      @added="handleInput"
      ref="createTemplateModal"
      :parentComponent="'responses'"
    />

    <Multiselect
      :show-labels="false"
      :close-on-select="false"
      multiple
      :value="selectedTemplates"
      @select="handleInput"
      @remove="handleRemove"
      @close="save"
      :options="templateOptions"
      :disabled="disabled"
      label="title"
      track-by="_id"
    >
      <template slot="option" slot-scope="{ option }">
        <div :class="{ delineated: option._id === creationOption._id }" style="color: #6e84a3">
          {{ option.title }}
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import CreateTemplateModal from '@/components/Modals/CreateTemplateModal'
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const TemplateModule = createNamespacedHelpers('template')

export default {
  name: 'AutoTemplatePicker',
  props: {
    rating: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    init: {
      type: Array,
    },
  },
  components: {
    CreateTemplateModal,
    Multiselect,
  },
  data: () => ({
    selectedTemplates: [],
    creationOption: {
      _id: 'NEW_TEMPLATE',
      title: 'Create New Template',
    },
  }),
  mounted() {
    if (this.init?.length) {
      this.selectedTemplates = this.selectAutoTemplates.filter((t) => this.init.includes(t._id))
    }
  },
  computed: {
    ...TemplateModule.mapGetters(['selectAutoTemplates']),
    templateOptions() {
      return [...this.selectAutoTemplates, this.creationOption]
    },
  },
  methods: {
    handleInput(value) {
      if (value._id === this.creationOption._id) {
        this.$refs['createTemplateModal'].open()
      } else {
        this.selectedTemplates.push(value)
      }
    },
    handleRemove(option) {
      const index = this.selectedTemplates.findIndex((t) => t.id === option.id)
      if (index !== -1) {
        this.selectedTemplates.splice(index, 1)
        this.save()
      }
    },
    save() {
      this.$emit(
        'change',
        this.selectedTemplates.map((t) => t._id)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.delineated {
  border-top: 1px solid #d2ddec;
  margin: -12px;
  padding: 12px;
}
</style>
