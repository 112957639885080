<template>
  <div>
    <div class="form-group">
      <h6 class="header-pretitle mt-3">SOURCE</h6>
      <div class="custom-multiselect" style="width: 22%">
        <MultiSelect
          taggable
          tag-placeholder="Press Enter to create"
          placeholder="Type to search or create new"
          @tag="setNewSource"
          selectLabel
          :close-on-select="true"
          v-model="sourceValue"
          :searchable="true"
          :show-labels="true"
          tagPosition="bottom"
          :limit="2"
          :options="getSourceList()"
        >
          <span slot="noResult">No results.</span>
          <template slot="placeholder">
            <span class="text-secondary">All Sources </span>
          </template>
          <template #option="{ option }">
            <div class="d-flex flex-column">
              <small v-if="option" class="small text-truncate">{{ option | truncate(20) }}</small>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
    <div class="card">
      <table class="table table-sm table-wrap card-table">
        <thead>
          <tr>
            <th scope="col" class="location-heading">Location</th>
            <th scope="col" class="link-heading">Link</th>
            <th scope="col" style="width: fit-content">
              <span v-if="sourceValue" class="text-right">
                <button class="btn btn-outline-primary btn-sm" @click="downloadCSV()">
                  Download CSV
                </button>
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="!sourceValue" class="list">
          <tr class="align-middle text-capitalize text-left w-100" style="height: 120px">
            <td colspan="2" class="flex-row align-middle">
              Please select a survey source to display the survey links.
            </td>
          </tr>
        </tbody>
        <tbody v-else class="list">
          <tr>
            <td style="font-weight: 500" class="align-middle text-capitalize text-left">
              All Locations
            </td>
            <td
              style="padding: 0.9375rem !important; font-weight: 500"
              class="text-left d-flex w-100"
            >
              <span style="width: 88%">
                {{ getSurveyLinkByEnvironment(allLocationSurveyLink.replace('https://', '')) }}
              </span>
              <span
                class="fe fe-copy px-2 cursor-pointer"
                v-clipboard:copy="getSurveyLinkByEnvironment(allLocationSurveyLink)"
                v-clipboard:success="onCopyToClipboard"
              ></span>
            </td>
          </tr>
          <tr
            ref="survey-table"
            v-for="location of LocationSurveyLinks(selectActiveLocations)"
            :key="location._id"
          >
            <td class="align-middle text-capitalize text-left">
              {{ location.name }}
            </td>
            <td class="d-flex w-100">
              <span class="width-88-percent">
                {{ getSurveyLinkByEnvironment(location.surveyLink.replace('https://', '')) }}
              </span>

              <span
                class="fe fe-copy px-2 cursor-pointer"
                v-clipboard:copy="getSurveyLinkByEnvironment(location.surveyLink)"
                v-clipboard:success="onCopyToClipboard"
              ></span>
            </td>
            <td class="text-left"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import SurveyService from '../../../../services/SurveyService'
import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex'
import MultiSelect from '../../../Common/MultiSelect'
const UserModule = createNamespacedHelpers('user')
const SurveyModule = createNamespacedHelpers('survey')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanySettingsSurveyLinks',
  components: { MultiSelect },
  data() {
    return {
      sourceValue: '',
    }
  },
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...SurveyModule.mapState(['sources']),
    ...CompanyModule.mapState(['activeCompany']),
    ...mapGetters('location', ['selectActiveLocations']),
    allLocationSurveyLink() {
      return `https://survey.ovationup.com/${this.activeCompany._id}/leave-survey?source=${this.sourceValue}`
    },
  },
  async mounted() {
    await this.getSources()
    await this.getUserLocationsByActiveCompany()
  },
  methods: {
    ...mapActions('location', ['getUserLocationsByActiveCompany']),
    ...SurveyModule.mapActions(['getSources']),
    onCopyToClipboard() {
      this.$notify({
        title: 'Copied to clipboard!',
      })
    },
    getSourceList() {
      return this.sources
        ?.filter((item) => {
          return item !== ''
        })
        ?.map((item) => item?.trim()?.replace(/\s/g, '-'))
    },
    LocationSurveyLinks(locations) {
      const locationInfo = []
      locations.map((item) => {
        let locationName = item.friendlyName ? item.friendlyName : item.city
        locationInfo.push({
          name: locationName,
          surveyLink: `https://survey.ovationup.com/leave-survey/${item._id}?source=${this.sourceValue}`,
        })
      })
      return locationInfo
    },
    async downloadCSV() {
      let mainLink = ''
      let allLocation = {
        name: 'All Locations',
        surveyLink: this.allLocationSurveyLink,
      }
      this.LocationSurveyLinks.splice(0, 0, allLocation)
      this.LocationSurveyLinks.forEach((item, index) => {
        mainLink += item.name + ',' + item.surveyLink + ',\n'
      })
      let fileURL = window.URL.createObjectURL(new Blob([`NAME,LINK\n${mainLink}`]))
      let fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `survey_links_details.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async setNewSource(newSource) {
      this.sourceValue = newSource
      if (this.sources.indexOf(this.sourceValue) !== -1) return
      await SurveyService.create({ companyId: this.activeCompany._id, source: this.sourceValue })
      await this.getSources()
    },
    getSurveyLinkByEnvironment(link) {
      const env = process.env.NODE_ENV
      const isProduction = env === 'production'
      if (isProduction) {
        return link
      }
      return link?.replace('.', '-test.')
    },
  },
}
</script>
<style>
.custom-dropdown > .btn {
  display: flex !important;
  align-items: center;
  padding: 0 12px;
}
.custom-dropdown > .btn-white:hover {
  background: #fff !important;
}
.custom-dropdown > .btn-white:focus {
  background: #fff !important;
}
.custom-dropdown > .btn-white:active {
  background: #fff !important;
}
.custom-dropdown > .dropdown-menu {
  width: 100%;
}
.custom-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #e6e6e6 !important;
}
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  background: #fff !important;
}
.source-input {
  background: #fff !important;
  border: none !important;
  padding: 0 !important;
}
.new-source-parent {
  border: 1px solid #dce4ec;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}
.new-source-input {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0.5rem 0.75rem !important;
  outline: none !important;
  width: 100% !important;
}
.create-source-text {
  border-top: 1px solid #dce4ec;
  padding: 0.5rem 0.75rem;
  font-weight: bold;
}
.location-heading {
  width: 30%;
  vertical-align: middle !important;
}
.link-heading {
  width: 70%;
  vertical-align: middle !important;
}
.width-88-percent {
  width: 88% !important;
}
.all-location-link {
  padding: 0.9375rem !important;
  font-weight: 500;
}
.multiselect .multiselect__option--highlight::after {
  width: 100%;
}
</style>
