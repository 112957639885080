<template>
  <div class="card mr-3 p-4 text-center">
    <h3 class="text-muted mb-4" style="font-size: 14px">Incidents</h3>
    <apexchart
      :height="heatmapHeight + 'px'"
      width="95%"
      type="heatmap"
      :series="selectHeatmapSeries"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'Heatmap',
  computed: {
    ...InsightsModule.mapGetters([
      'selectCategoryName',
      'selectHeatmapCategories',
      'selectHeatmapSeries',
      'selectFeedbackCount',
    ]),
    ...LocationModule.mapGetters(['selectLocationName']),
    heatmapHeight() {
      const cellHeight = 40
      const xAxisHeight = 68
      const projectedHeight = cellHeight * this.selectHeatmapSeries.length + xAxisHeight
      return projectedHeight
    },
    chartOptions() {
      return {
        chart: {
          type: 'heatmap',
          toolbar: { show: false },
          zoom: { enabled: false },
          events: {
            // this 'click' will be used if we support navigating on clicking lcoation name
            // click: (event, chartContext, config) => {
            //   if (event.path[3].className.baseVal === 'apexcharts-yaxis') {
            //     console.log(event.path[0].innerHTML)
            //   }
            // },
            dataPointSelection: (event, chartContext, config) => {
              // don't do anything if they click on the 'Feedback' column cell
              if (config.dataPointIndex !== 0) {
                this.$router.push({
                  path: '/insights/details',
                  query: {
                    dateRange: this.$route.query.dateRange,
                    sentiment: 'NEGATIVE',
                    category: this.selectHeatmapCategories[config.dataPointIndex],
                    locs: !['All Locations', 'Company-wide', 'Selected Locations'].includes(
                      this.selectHeatmapSeries[config.seriesIndex].name
                    )
                      ? this.selectHeatmapSeries[config.seriesIndex].name
                      : undefined,
                    c: this.$route.query.c,
                    sources: this.$route.query.sources,
                    keywords: this.$route.query.keywords,
                  },
                })
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#12263F'],
          },
          formatter: (val, { seriesIndex }) => {
            return val === -1
              ? this.selectHeatmapSeries[seriesIndex].totalCount
              : val
              ? val + '%'
              : '0%'
          },
        },
        xaxis: {
          categories: this.selectHeatmapCategories,
          tooltip: { enabled: false },
          position: 'top',
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: {
            formatter: (val, opts) => {
              return this.selectCategoryName(val)
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (val, opts) => {
              let label = ['All Locations', 'Selected Locations', 'Company-wide'].includes(val)
                ? val
                : this.selectLocationName(val)
              return label
            },
            minWidth: 80,
            maxWidth: 100,
          },
        },
        legend: { show: false },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const incidentCount = this.selectHeatmapSeries[seriesIndex].counts[dataPointIndex]
            const totalCount = this.selectHeatmapSeries[seriesIndex].totalCount
            return dataPointIndex !== 0
              ? `
              ${incidentCount} ${incidentCount === 1 ? 'incident' : 'incidents'}
              out of ${totalCount} ${totalCount === 1 ? 'piece' : 'pieces'} of feedback
            `
              : `${totalCount} ${totalCount === 1 ? 'piece' : 'pieces'} of feedback`
          },
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: false,
            colorScale: {
              ranges: [
                {
                  from: -1,
                  to: 0,
                  color: '#FFFFFF',
                },
                {
                  from: 1,
                  to: 5,
                  color: '#FED2DA',
                },
                {
                  from: 6,
                  to: 10,
                  color: '#FDA5B5',
                },
                {
                  from: 11,
                  to: 100,
                  color: '#FB4B6B',
                },
              ],
            },
          },
        },
      }
    },
  },
}
</script>

<style scoped>
#line {
  color: white;
}
.bg-light-green {
  background: #ccf7e5;
}
.bg-light-red {
  background: #ffcccb;
}
</style>
