import Vue from 'vue'

export default {
  list({ companyId }) {
    return Vue.http.get('keyword', {
      params: {
        companyId,
      },
    })
  },
  create({ companyId, fields }) {
    return Vue.http.post('keyword', { companyId, ...fields })
  },
  update({ keywordId, fields }) {
    return Vue.http.patch(`keyword/${keywordId}`, { ...fields })
  },
  archive(keywordId) {
    return Vue.http.delete(`keyword/${keywordId}`)
  },
  generateLinks({ keywordId, fields }) {
    return Vue.http.post(`keyword/${keywordId}/magic-links`, { ...fields })
  },
}
