<template>
  <div class="row align-items-center bg-white" v-if="initialized">
    <div class="col-auto pr-0">
      <span class="fe fe-search text-muted"></span>
    </div>
    <div class="col">
      <input
        type="search"
        class="form-control form-control-flush search"
        placeholder="Search"
        v-model="searchTerm"
        @input="debounceEmit"
      />
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import { debounce } from 'lodash-es'

export default {
  name: 'ArrayFuzzySearch',
  props: {
    // can be array of strings or objects
    arrayData: {
      type: Array,
      required: true,
    },
    // keys are required if the arrayData is an array of objects
    keys: {
      type: Array,
    },
  },
  data: () => ({
    searchTerm: '',
    fuse: null,
    initialized: false,
    debounceEmit: null,
  }),
  mounted() {
    this.fuse = new Fuse(this.arrayData, this.options)
    this.debounceEmit = debounce((v) => {
      this.updateEmit(v)
    }, 500)
    this.initialized = true
  },
  computed: {
    options() {
      return this.keys.length ? { keys: this.keys } : {}
    },
  },
  methods: {
    async updateEmit() {
      this.$emit(
        'filteredResults',
        this.searchTerm ? this.fuse.search(this.searchTerm).map((res) => res.item) : this.arrayData
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
