<template>
  <div class="card p-4 text-center h-100">
    <div class="muted-text">{{ title }}</div>
    <div class="main-text h-100 d-flex align-items-center justify-content-center">{{ metric }}</div>
  </div>
</template>

<script>
export default {
  name: 'MetricCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    valueType: {
      type: String,
      validator(v) {
        return ['money', 'number'].includes(v)
      },
      required: true,
    },
  },
  computed: {
    metric() {
      if (this.valueType === 'money') return `$${this.value.toFixed(2)}`
      else if (this.valueType === 'number') return this.value
      return ''
    },
  },
}
</script>

<style scoped>
.muted-text {
  color: #95aac9;
}
.main-text {
  font-size: 26px;
  font-weight: bold;
}
</style>
