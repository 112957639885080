import Vue from 'vue'

export default {
  enable(reviewSubscriptionId, locationId) {
    return Vue.http.put(
      `app-services/v2/review-subscription/${reviewSubscriptionId}/enable/${locationId}`
    )
  },
  disable(reviewSubscriptionId, locationId) {
    return Vue.http.delete(
      `app-services/v2/review-subscription/${reviewSubscriptionId}/location/${locationId}`
    )
  },
  update(reviewSubscriptionId, locationId, modifications) {
    return Vue.http.put(
      `app-services/v2/review-subscription/${reviewSubscriptionId}/location/${locationId}`,
      modifications
    )
  },
}
