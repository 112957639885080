<template>
  <div>
    <div id="dashboard" ref="dashboard" v-if="selectDashboards.length">
      <div id="errorModal" class="modal" v-show="showErrorModal">
        <p class="error-message">
          Sorry, there was an issue loading the dashboard. Please refresh the page and if issue
          persists contact customer support.
          <br />
          {{ errorMessage }}
        </p>
      </div>
    </div>
    <div v-else>
      <ReportEmptyState />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { BoldBI } from '@boldbi/boldbi-embedded-sdk'
import ReportEmptyState from '../ReportEmptyState'
import { createNamespacedHelpers } from 'vuex'
window.jQuery = $

const ReportModule = createNamespacedHelpers('report')
let boldbiDashboard = null

export default {
  name: 'DashboardView',
  components: {
    ReportEmptyState,
  },
  data: () => ({
    errorMessage: '',
    showErrorModal: false,
  }),
  async mounted() {
    await this.fetchReportDashboards()
    await this.loadDashboard()
  },
  async beforeUnmount() {
    if (boldbiBuilder) {
      await this.destroyBI()
    }
  },
  computed: {
    ...ReportModule.mapGetters([
      'selectActiveDashboard',
      'selectDashboards',
      'selectBoldBiUrl',
      'selectOBSUrl',
    ]),
  },
  methods: {
    ...ReportModule.mapActions(['fetchReportDashboards', 'fetchReportDashboardDrafts']),
    async destroyBI() {
      boldbiBuilder.destroyStyles()
      boldbiBuilder.destroy()
    },
    async loadDashboard() {
      try {
        const data = {
          DashboardId: this.selectActiveDashboard.Id,
          EmbedType: 'component',
          Environment: 'onpremise',
          ExpirationTime: '10000',
          ServerUrl: this.selectBoldBiUrl,
          SiteIdentifier: 'site/site1',
        }
        const authUrl = `${this.selectOBSUrl}/app-services/v2/boldbi/embed-detail`
        renderDashboard(data, authUrl)
      } catch (error) {
        this.errorMessage =
          'To compile and run the project, an embed config file needs to be required.'
        this.showErrorModal = true
      }

      function renderDashboard(data, authUrl) {
        if (boldbiDashboard) {
          boldbiDashboard.destroyStyles()
          boldbiDashboard.destroy()
        }
        boldbiDashboard = BoldBI.create({
          serverUrl: data.ServerUrl + '/' + data.SiteIdentifier,
          dashboardId: data.DashboardId,
          embedContainerId: 'dashboard',
          embedType: BoldBI.EmbedType.Component,
          environment: data.Environment,
          width: '100%',
          height: window.innerHeight - $('#report-header')[0].offsetHeight + 'px',
          expirationTime: 100000,
          authorizationServer: {
            headers: {
              Authorization: localStorage.getItem('jwt'),
            },
            url: authUrl,
          },
          dashboardSettings: {
            themeSettings: {
              isLocalTheme: true,
            },
          },
        })
        boldbiDashboard.loadDashboard()
        boldbiDashboard?.addStyles()
      }
    },
  },
}
</script>

<style scoped>
.error-message {
  color: red;
  text-align: center;
  font-size: 20px;
  margin-top: 300px;
}
</style>
