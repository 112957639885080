import OrganizationService from '@/services/OrganizationService'

export default {
  namespaced: true,
  state: {
    organizations: [],
    organization: null,
    organizationCompanies: [],
  },
  getters: {
    selectOrganizations: (state) => state.organizations,
    selectOrganization: (state) => state.organization,
    selectOrganizationCompanies: (state) => state.organizationCompanies
  },
  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
    SET_ORGANIZATION(state, organization) {
      state.organization = organization
    },
    SET_ORGANIZATION_COMPANIES(state, organizationCompanies) {
      state.organizationCompanies = organizationCompanies
    },
    RESET_ORGANIZATION(state) {
      state.organization = null
      state.organizationCompanies = []
    },
  },
  actions: {
    async setOrganizations({ commit, dispatch }, organizations) {
      // organizations are populated when setting the onlineUser
      commit('SET_ORGANIZATIONS', organizations)
      // set the first organization as the selected organization
      if (organizations?.length) {
        await dispatch('setOrganization', organizations[0])
      }
    },

    async setOrganization({ commit, dispatch }, organization) {
      commit('SET_ORGANIZATION', organization)
      await dispatch('fetchOrganizationCompanies')
    },

    async fetchOrganizationCompanies({ commit, getters }) {
      const response = await OrganizationService.fetchOrganizationCompanies({
        organizationId: getters.selectOrganization._id,
      })
      commit('SET_ORGANIZATION_COMPANIES', response.body.data.companies)
    },
  },
}
