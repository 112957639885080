export default {
  namespaced: true,
  state: {
    templateTags: [
      {
        name: 'Customer First Name',
        value: '<Customer First Name>',
      },
      {
        name: 'Author First Name',
        value: '<Author First Name>',
      },
      {
        name: 'Company Name',
        value: '<Company Name>',
      },
      {
        name: 'Review Link',
        value: '<Review Link>',
      },
      {
        name: 'Survey Link',
        value: '<Survey Link>',
      },
      {
        name: 'Order Link',
        value: '<Order Link>',
      },
      {
        name: 'Location Name',
        value: '<Location Name>',
      },
      {
        name: 'Location City',
        value: '<Location City>',
      },
    ],
  },
}
