import Vue from 'vue'

const SettingsService = {
  getSettings,
}

export function getSettings() {
  return Vue.http.get('app-services/v2/settings')
}

export default SettingsService
