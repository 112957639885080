<template>
  <div class="card" :class="{ 'disabled-card': location.disabled }">
    <div class="card-body">
      <div class="text-center">
        <button class="btn btn-link text-dark" @click="viewSettings" :disabled="location.disabled">
          <span class="card-avatar avatar avatar-lg mx-auto">
            <img
              :src="location.primaryPhoto || '/img/avatars/profiles/store.svg'"
              alt=""
              class="avatar-img rounded"
            />
          </span>
        </button>
      </div>

      <h2 class="card-title text-center mb-2">
        <button class="btn btn-link text-dark" @click="viewSettings" :disabled="location.disabled">
          <span class="h2">
            {{ location.friendlyName || location.city }}
          </span>
        </button>
      </h2>

      <p class="card-text text-center text-muted mb-1">
        {{ location.addressDetails ? location.addressDetails.streetNumber : '' }}
        {{ location.addressDetails ? location.addressDetails.streetName : '' }}
      </p>

      <CustomerProductBadges :products="location.products" v-if="location.products" />
      <hr />

      <div class="row align-items-center">
        <div class="col">
          <p
            v-if="onlineUser.role === 'admin' || onlineUser.role === 'location-manager'"
            class="card-text small text-muted"
          >
            <button
              @click="viewSettings"
              class="btn btn-sm btn-white d-md-inline-block"
              :disabled="location.disabled"
            >
              <i class="fe fe-survey"></i> Settings
            </button>
          </p>
        </div>
        <div class="col-auto">
          <div class="avatar-group">
            <span
              v-for="platform in location.subscriptions"
              :key="platform._id"
              class="avatar avatar-xs"
            >
              <img
                :src="`/theme/img/platforms/${platform.network}.svg`"
                class="avatar-img rounded-circle"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerProductBadges from '@/components/Modules/Customer/CustomerProducts/CustomerProductBadges'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')

export default {
  name: 'CompanySettingsLocationCard',
  props: ['location'],
  components: {
    CustomerProductBadges,
  },
  computed: {
    ...mapState(['onlineUser']),
  },
  methods: {
    viewSettings() {
      this.$router.push({
        name: 'location-settings-platforms',
        params: { locationId: this.location._id },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.disabled-card {
  background-color: rgb(232, 233, 238);
  opacity: 0.4;
}
</style>
