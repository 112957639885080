import SubscriptionService from '@/services/SubscriptionService'

/**
 * This is for billing subscriptions, not related to review subscriptions
 */

export default {
  namespaced: true,
  state: {
    subscriptions: [],
  },
  getters: {
    selectSubscriptions: (state) => state.subscriptions,
  },
  mutations: {
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions
    },
  },
  actions: {
    async fetchSubscriptions({ commit, rootGetters }) {
      const filters = {
        companyIds: [rootGetters['company/selectActiveCompany']._id],
        locationIds: rootGetters['location/userActiveCompanyLocations'].map((l) => l._id),
      }
      const user = rootGetters['user/onlineUser']

      const response = await SubscriptionService.fetchSubscriptions({ filters })
      const subscriptions = response.body.data.subscriptions
      const filteredSubscriptions = subscriptions.filter((s) => {
        // TODO: delete commented code after 2023 if no longer needed
        // if (!s.companies?.length) return false
        if (user.role !== 'admin') {
          if (
            s.type === 'company' ||
            user.role !== 'location-manager' ||
            !s.locations?.every((l) => filters.locationIds.includes(l))
          ) {
            return false
          }
        }
        return true
      })

      commit('SET_SUBSCRIPTIONS', filteredSubscriptions)
    },

    async createSession(_, { subscriptionId, returnUrl }) {
      const response = await SubscriptionService.createSession({ subscriptionId, returnUrl })
      return response.body.data.sessionUrl
    },
  },
}
