<template>
  <multiselect
    :style="{
      width: selectedLocations.length || wideMode ? 'auto' : '140px',
    }"
    placeholder="Type to search"
    selectLabel
    :close-on-select="false"
    v-model="selectedLocations"
    :multiple="true"
    @close="emitFilter(null)"
    @remove="emitFilter($event)"
    :searchable="true"
    :showLabels="false"
    :limitText="(count) => `+${count} more`"
    :limit="2"
    :options="locationsToShow"
    :custom-label="locationName"
    track-by="_id"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary"> <span class="fe fe-map-pin"></span> Select locations </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span>{{ locationName(props.option) }}</span>
        <Br />
        <small class="text-muted small text-truncate" v-if="props.option.addressDetails">{{
          `${props.option.addressDetails.streetNumber} ${props.option.addressDetails.streetName}`
            | truncate(20)
        }}</small>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'LocationFilter',
  props: [
    'disabledOptions',
    'selectedCompanies',
    'wideMode',
    'locations',
    'initialLocations',
    'resetOnUnselect',
  ],
  components: {
    Multiselect,
  },
  data: () => ({
    selectedLocations: [],
  }),
  mounted() {
    if (this.initialLocations) {
      this.selectedLocations = this.locations.filter((loc) =>
        this.initialLocations.includes(loc._id)
      )
    }
  },
  watch: {
    selectedCompanies(val) {
      if (!this.resetOnUnselect) {
        this.selectedLocations = []
        this.emitFilter(null)
      } else {
        this.selectedLocations = [...this.selectedLocations].filter((l) => val.includes(l.company))
      }
    },
  },
  computed: {
    locationsToShow() {
      let self = this
      return (
        [...this.locations]
          .filter(
            (location) =>
              !self.selectedCompanies ||
              !self.selectedCompanies.length ||
              self.selectedCompanies.includes(location.company)
          )
          .sort((a, b) => {
            return self.locationName(a) > self.locationName(b) ? 1 : -1
          })
          // disabled options
          .map((location) =>
            !self?.disabledOptions?.length
              ? location
              : {
                  ...location,
                  $isDisabled: self.disabledOptions.includes(location._id),
                }
          )
      )
    },
  },
  methods: {
    emitFilter(event) {
      this.$emit('update', [
        ...this.selectedLocations
          .map((location) => location._id)
          .filter((locationId) => (!event || locationId != event._id ? true : false)),
      ])
    },
    locationName({ friendlyName, city }) {
      return friendlyName || city
    },
  },
}
</script>
