<template>
  <div class="row justify-content-center">
    <div class="col-12 p-0" style="max-width: 900px">
      <div v-for="(question, index) of questions" :key="index">
        <div
          class="card shadow px-4 pt-4 w-100 clickable"
          :class="{ selectedCard: selectedCardIndex === index }"
          @click="setSelectedCardIndex(index)"
        >
          <div class="row">
            <!-- question section -->
            <div class="col-7 pr-4">
              <div class="mb-3 h4">
                {{ question.question }}
              </div>
              <div class="mb-4">
                <ShortAnswerType v-if="question.type === 'SHORT_ANSWER'" />
                <RatingScaleType v-if="question.type === 'RATING_SCALE'" />
                <SingleSelectionType
                  v-if="question.type === 'SINGLE_SELECTION'"
                  :options="question.questionData.options"
                />
                <MultipleSelectionType
                  v-if="question.type === 'MULTIPLE_SELECTION'"
                  :options="question.questionData.options"
                />
              </div>
            </div>

            <!-- info section -->
            <div class="col-5 border-left mb-3 pl-4 pb-3" v-if="selectedCardIndex === index">
              <div class="mb-3 h4">Target Audience:</div>
              <!-- rating -->
              <div class="mb-1">
                <span class="font-weight-bold">- Ratings:</span>
                <span v-if="question.filters.ratingsFilter.length">
                  <img
                    v-for="(rating, index) of question.filters.ratingsFilter"
                    :key="index"
                    class="ml-2 mt--2"
                    :src="`/img/ratings/${rating}.png`"
                    :alt="`rating ${rating} emoji`"
                    contain
                    height="18px"
                  />
                </span>
                <span v-if="!question.filters.ratingsFilter.length">&nbsp;All</span>
              </div>

              <!-- responded -->
              <div class="mb-1">
                <span class="font-weight-bold">- Responded:</span>
                <span class="text-capitalize">
                  {{ question.filters.respondedFilter.toLowerCase() }}
                </span>
              </div>

              <!-- source -->
              <div>
                <span class="font-weight-bold">- Sources:</span>
                {{ question.filters.sourcesFilter.join(', ') || 'All' }}
              </div>

              <!-- locations -->
              <div>
                <span class="font-weight-bold">- Locations:</span>
                {{ listLocationNames(question.filters.locationsFilter) }}
              </div>

              <!-- limits -->
              <div class="mb-3 mt-2 h4 border-top pt-2">Limits:</div>
              <div v-if="question.limits">
                <!-- ask once -->
                <div class="mb-1">
                  <span class="font-weight-bold">- Ask only once:</span>
                  <span class="text-capitalize">
                    {{ question.limits.askOnce }}
                  </span>
                </div>
              </div>
              <div v-else>
                <span class="font-weight-bold">- None</span>
              </div>
            </div>

            <!-- footer control section -->
            <div class="col-12" v-if="selectedCardIndex === index">
              <div class="card-footer py-2 px-0 text-right" style="height: 55px">
                <span
                  class="badge text-capitalize float-left mt-3"
                  style="font-size: 12px"
                  :class="statusClass(question.status)"
                >
                  {{ question.status.toLowerCase() }}
                </span>
                <button
                  type="button"
                  v-for="(button, index) of statusButtons[question.status]"
                  :key="index"
                  class="btn"
                  :title="button.title"
                >
                  <i
                    class="text-muted mt-1"
                    style="font-size: 17px"
                    :class="button.icon"
                    @click="
                      updateStatus(button.confirmation, {
                        questionId: question._id,
                        status: button.status,
                      })
                    "
                  ></i>
                </button>
                <CategoryUpdateDropdown :question="question" />
              </div>
            </div>
          </div>
        </div>

        <!-- follow up question cards -->
        <div
          v-if="selectedCardIndex === index && question.children.length"
          class="d-flex align-items-end flex-column"
        >
          <div
            v-for="(child, index) of question.children"
            :key="index"
            class="card shadow px-4 pt-4"
            style="width: 90%"
          >
            <!-- child question section -->
            <div class="row">
              <div class="col-7 pr-4">
                <div class="mb-3 h4">
                  {{ child.question.question }}
                </div>
                <div class="mb-4">
                  <ShortAnswerType v-if="child.question.type === 'SHORT_ANSWER'" />
                  <RatingScaleType v-if="child.question.type === 'RATING_SCALE'" />
                  <SingleSelectionType
                    v-if="child.question.type === 'SINGLE_SELECTION'"
                    :options="child.question.questionData.options"
                  />
                  <MultipleSelectionType
                    v-if="child.question.type === 'MULTIPLE_SELECTION'"
                    :options="child.question.questionData.options"
                  />
                </div>
              </div>

              <!-- child info section -->
              <div class="col-5 border-left mb-3 pl-4 pb-3">
                <div class="mb-3 h4 font-weight-bold">Ask if response is:</div>
                <div v-if="question.type === 'RATING_SCALE'" class="pl-3">
                  <img
                    :src="`/img/ratings/${question.questionData.options[child.optionIndex]}.png`"
                    :alt="`rating ${question.questionData.options[child.optionIndex]} emoji`"
                    contain
                    height="30px"
                  />
                </div>
                <div v-else class="pl-3">
                  {{ question.questionData.options[child.optionIndex] }}
                </div>
              </div>
            </div>

            <!-- child footer control section -->
            <div class="col-12 p-0">
              <div class="card-footer py-2 px-0 text-right" style="height: 55px">
                <span
                  class="badge text-capitalize float-left mt-3"
                  style="font-size: 12px"
                  :class="statusClass(child.question.status)"
                >
                  {{ child.question.status.toLowerCase() }}
                </span>
                <button
                  type="button"
                  v-for="(button, index) of childStatusButtons[child.question.status]"
                  :key="index"
                  class="btn"
                  :title="button.title"
                >
                  <i
                    class="text-muted mt-1"
                    style="font-size: 17px"
                    :class="button.icon"
                    @click="
                      updateStatus(button.confirmation, {
                        questionId: child.question._id,
                        status: button.status,
                      })
                    "
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShortAnswerType from './QuestionCreation/PreviewTypes/ShortAnswerType'
import RatingScaleType from './QuestionCreation/PreviewTypes/RatingScaleType'
import SingleSelectionType from './QuestionCreation/PreviewTypes/SingleSelectionType'
import MultipleSelectionType from './QuestionCreation/PreviewTypes/MultipleSelectionType'
import CategoryUpdateDropdown from './QuestionCreation/Filters/CategoryUpdateDropdown'
import { createNamespacedHelpers } from 'vuex'

const questionModule = createNamespacedHelpers('customQuestion')
const locationModule = createNamespacedHelpers('location')

export default {
  name: 'QuestionCardList',
  components: {
    ShortAnswerType,
    RatingScaleType,
    MultipleSelectionType,
    SingleSelectionType,
    CategoryUpdateDropdown,
  },
  data: () => ({
    statusButtons: {
      ACTIVE: [
        { title: 'Inactivate', icon: 'fas fa-pause-circle', status: 'INACTIVE' },
        { title: 'Archive', icon: 'fas fa-archive', status: 'ARCHIVED' },
      ],
      INACTIVE: [
        { title: 'Activate', icon: 'fas fa-file-medical-alt', status: 'ACTIVE' },
        { title: 'Archive', icon: 'fas fa-archive', status: 'ARCHIVED' },
      ],
      ARCHIVED: [
        { title: 'Activate', icon: 'fas fa-file-medical-alt', status: 'ACTIVE' },
        { title: 'Move to inactive', icon: 'fas fa-pause-circle', status: 'INACTIVE' },
        { title: 'Delete', icon: 'fas fa-trash-alt', status: 'DELETED', confirmation: 'deletion' },
      ],
    },
    childStatusButtons: {
      ACTIVE: [{ title: 'Inactivate', icon: 'fas fa-pause-circle', status: 'INACTIVE' }],
      INACTIVE: [{ title: 'Activate', icon: 'fas fa-file-medical-alt', status: 'ACTIVE' }],
    },
  }),
  computed: {
    ...questionModule.mapGetters({
      questions: 'getQuestions',
      selectedCardIndex: 'getSelectedCardIndex',
    }),
    ...locationModule.mapGetters(['userActiveLocationNames']),
  },
  methods: {
    ...questionModule.mapActions(['updateCustomQuestion', 'setSelectedCardIndex']),
    listLocationNames(idList) {
      let locationNames = []
      idList?.forEach((id) => {
        const locationData = this.userActiveLocationNames.find((el) => el._id === id)
        locationNames.push(locationData.friendlyName || locationData.city)
      })
      return locationNames.join(', ') || 'All'
    },
    statusClass(status) {
      if (status === 'ACTIVE') {
        return 'badge-soft-success'
      } else if (status === 'INACTIVE') {
        return 'badge-soft-secondary'
      } else if (status === 'ARCHIVED') {
        return 'badge-soft-warning'
      } else {
        return 'badge-soft-success'
      }
    },
    async updateStatus(confirmationType, { questionId, status }) {
      try {
        if (confirmationType === 'deletion') {
          const confirmation = await this.confirmDeletion()
          if (!confirmation) return
        }

        await this.updateCustomQuestion({ questionId, fields: { status } })
        this.$notify({ text: 'Successfully updated question status' })
      } catch (error) {
        this.$notify({ text: 'Failed to update question status!', type: 'error' })
      }
    },
    async confirmDeletion() {
      const message = `
        Are you sure you want to delete this question? 
        You will no longer be able to view reporting after deletion.`
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Deletion' } }),
      ])
      let exportConfirmed = await this.$bvModal.msgBoxConfirm(message, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Delete',
        okVariant: 'danger',
        cancelTitle: 'Cancel',
        centered: true,
      })
      if (exportConfirmed) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>
.selectedCard {
  border-left: 5px solid #2c7be5;
  transform: scale(1.02);
  position: relative;
  z-index: 1000;
}
.card.clickable:hover {
  transform: scale(1.02);
  transition-duration: 0.3s;
}
button {
  border-radius: 50%;
  border: none;
  background-color: white;
  vertical-align: middle;
  text-align: center;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
button:hover {
  background-color: #f5f5f5;
}
</style>
