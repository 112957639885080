<template>
  <b-modal ref="modal" id="inviteEmployeeModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title" id="exampleModalCenterTitle">Invite Teammate</h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label> First name </label>
              <input
                v-model="employee.firstName"
                type="text"
                class="form-control"
                placeholder="Jane"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label> Last name </label>
              <input
                v-model="employee.lastName"
                type="text"
                class="form-control"
                placeholder="Doe"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label> Email address </label>
              <input
                v-model="employee.email"
                type="email"
                class="form-control"
                placeholder="name@address.com"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label> Phone (Login) </label>
              <input
                v-model="employee.phone"
                type="text"
                class="form-control"
                placeholder="(000) 000-0000"
                v-mask="'(###) ###-####'"
              />
            </div>
          </div>
          <div class="col-12 mb-4">
            <RolePicker
              ref="rolePicker"
              v-model="employee.role"
              :show-admin="onlineUser.role === 'admin'"
              @input="roleChanged"
            />
          </div>
          <div class="col-12 mb-4">
            <label> Company </label>
            <CompanyFilter
              ref="companyFilter"
              :wideMode="true"
              :companies="userCompanies"
              @update="handleCompanyUpdate"
              :placeholder="'Select companies'"
            />
          </div>
          <div v-if="employee.role !== 'admin'" class="col-12 mb-4">
            <LocationFilter
              ref="locationAccessPicker"
              :wideMode="true"
              :locations="filteredLocations"
              v-on:update="updateLocations"
              :selectedCompanies="selectedCompanies"
              :resetOnUnselect="true"
            />
          </div>
          <div class="col-12">
            <button
              @click="sendInvitation"
              :disabled="!formValid || isLoading"
              class="btn btn-primary col-12"
              :class="{ 'is-loading': isLoading }"
            >
              Send Invitation
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import RolePicker from '@/components/Modules/User/RolePicker'
import LocationFilter from '@/components/Modules/Filters/LocationFilter.vue'
import CompanyFilter from '@/components/Modules/Filters/CompanyFilter.vue'
import CompanyService from '@/services/CompanyService'
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')

const defaultEmployee = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  locations: [],
}

export default {
  name: 'InviteEmployeeModal',
  components: {
    RolePicker,
    CompanyFilter,
    LocationFilter,
  },
  data: () => ({
    employee: {
      ...defaultEmployee,
    },
    isLoading: false,
    companyIds: [],
    userLocations: [],
    filteredLocations: [],
    selectedCompanies: [],
  }),
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
    ...CompanyModule.mapGetters(['selectCompanies']),

    formValid() {
      return (
        this.employee.firstName &&
        this.employee.lastName &&
        this.employee.email &&
        this.employee.phone &&
        this.employee.role &&
        this.companyIds.length &&
        (this.employee.role === 'admin' || this.employee.locations.length)
      )
    },
    userCompanies() {
      return this.selectCompanies || []
    },
  },
  watch: {
    companyIds(ids) {
      if (!ids?.length) {
        this.filteredLocations = []
        this.selectedCompanies = []
      }
      this.selectedCompanies = [...ids]
      this.filteredLocations = this.allLocations.filter((l) => ids.includes(l.company))
    },
  },
  methods: {
    ...UserModule.mapActions(['fetchUserCompaniesAndLocations']),

    updateLocations(locations) {
      this.employee.locations = locations
    },
    async sendInvitation() {
      this.isLoading = true

      await CompanyService.inviteEmployee({
        inviterId: this.onlineUser._id,
        companyIds: this.companyIds,
        ...this.employee,
      })

      this.close()
      this.$emit('invited')

      setTimeout(() => {
        this.employee = { ...defaultEmployee, locations: [] }
        this.isLoading = false
        this.$refs.rolePicker.reset()
        this.$refs.locationAccessPicker.reset()
        this.$refs.companyFilter.reset()
      }, 400)
    },
    roleChanged() {
      if (this.employee.role === 'admin') {
        this.employee.locations = []
      }
    },
    close() {
      this.$refs.modal.hide()
    },
    handleCompanyUpdate(companyIds) {
      this.companyIds = [...companyIds]
    },
    async fetchMe() {
      const user = await this.fetchUserCompaniesAndLocations()
      const { locations } = user
      if (locations) this.allLocations = [...locations]
    },
  },
  mounted() {
    this.fetchMe()
  },
}
</script>
