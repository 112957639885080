<template>
  <multiselect
    :showLabels="false"
    style="min-width: 100px"
    v-model="status"
    :allowEmpty="false"
    @input="updateFilters"
    :options="options"
    track-by="value"
    label="title"
  >
    <template slot="option" slot-scope="props">
      <span>{{ props.option.title }}</span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'StatusFilter',
  components: {
    Multiselect,
  },
  data: () => ({
    status: { title: 'Active', value: 'ACTIVE' },
    options: [
      { title: 'Active', value: 'ACTIVE' },
      { title: 'Inactive', value: 'INACTIVE' },
      { title: 'Archived', value: 'ARCHIVED' },
    ],
  }),
  methods: {
    updateFilters(selection) {
      this.$emit('updateStatus', selection)
    },
  },
}
</script>
