<template>
  <div>
    <div class="p-3" style="min-height: 500px">
      <div class="px-3 pt-3">
        <h2>
          Options
          <span class="h5">(optional)</span>
        </h2>

        <!-- filters -->
        <div class="px-3 pt-3">
          <!-- ask limit -->
          <div class="mb-5">
            <div>
              <label class="font-weight-bold" for="limitOnce">
                Ask Only Once
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="askOnceInfo"
                ></i>
              </label>
              <div class="custom-control custom-checkbox-toggle">
                <input
                  id="limitOnce"
                  class="custom-control-input"
                  type="checkbox"
                  v-model="form.limits.askOnce"
                />
                <!-- this label isn't used but the toggle switch won't show without it -->
                <label class="custom-control-label" for="limitOnce"></label>
              </div>
            </div>
          </div>

          <!-- Follow up questions -->
          <div class="mb-5" v-if="!['SHORT_ANSWER', 'MULTIPLE_SELECTION'].includes(form.type)">
            <div>
              <label class="font-weight-bold" for="followUp">
                Add Follow Up Questions
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="followUpInfo"
                ></i>
              </label>
              <div class="custom-control custom-checkbox-toggle">
                <input
                  id="followUp"
                  class="custom-control-input"
                  type="checkbox"
                  v-model="addFollowUp"
                  @change="verifyFollowUpRemoval"
                />
                <!-- this label isn't used but the toggle switch won't show without it -->
                <label class="custom-control-label" for="followUp"></label>
              </div>
            </div>
          </div>

          <!-- Follow up assignment -->
          <div class="pl-4" v-if="addFollowUp">
            <div class="mb-3">If a customer responds with...</div>
            <div v-for="(option, index) of form.questionData.options" :key="index">
              <div
                class="ml-3 py-2 border-bottom w-75 d-inline-block"
                v-if="form.type === 'RATING_SCALE'"
              >
                <img
                  :src="`/img/ratings/${option}.png`"
                  :alt="`rating ${option} emoji`"
                  contain
                  height="30px"
                />
              </div>
              <div class="ml-3 py-3 border-bottom w-75 d-inline-block" v-else>
                {{ option.toString() | truncate(70) }}
              </div>

              <!-- dropdown -->
              <div class="pl-3 d-inline-block">
                <b-dropdown size="sm" variant="white">
                  <template slot="button-content">
                    {{
                      !form.children[index]
                        ? 'Do Nothing'
                        : `Ask "${form.children[index].question}"` | truncate(10)
                    }}
                  </template>
                  <b-dropdown-item
                    @click="initFollowUpCreation(index)"
                    v-if="!form.children[index]"
                  >
                    Create Follow-up Question
                  </b-dropdown-item>
                  <b-dropdown-item @click="editFollowUpQuestion(index)" v-else>
                    Edit Follow-up Question
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="resetAction(index)">Do Nothing</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreationFooter :showSave="true" :allowNext="true" @save="save" />
    <QuestionFollowUpModal ref="questionFollowUpModal" @setFollowUp="setFollowUp" />
  </div>
</template>

<script>
import CreationFooter from './CreationFooter'
import QuestionFollowUpModal from '@/components/Modals/QuestionFollowUpModal'

export default {
  name: 'QuestionAudienceStep',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  components: {
    CreationFooter,
    QuestionFollowUpModal,
  },
  data: () => ({
    addFollowUp: false,
    askOnceInfo: `If turned on, the question will not be asked again to the same customer after they answer once.`,
    followUpInfo: `You can create questions and assign them to follow up after specific responses.`,
    followUpIndex: null,
  }),
  methods: {
    setFollowUp(followUpQuestion) {
      let newArray = [...this.form.children]
      newArray[this.followUpIndex] = followUpQuestion
      this.form.children = newArray
    },
    verifyFollowUpRemoval() {
      if (!this.addFollowUp) {
        this.form.children = []
      }
    },
    resetAction(index) {
      let newArray = [...this.form.children]
      newArray[index] = null
      this.form.children = newArray
    },
    initFollowUpCreation(index) {
      this.followUpIndex = index
      this.$refs.questionFollowUpModal.init()
      this.$root.$emit('bv::show::modal', 'questionFollowUpModal')
    },
    editFollowUpQuestion(index) {
      this.followUpIndex = index
      this.$refs.questionFollowUpModal.init(this.form.children[index])
      this.$root.$emit('bv::show::modal', 'questionFollowUpModal')
    },
    save() {
      this.$emit('save')
    },
  },
}
</script>
