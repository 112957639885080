<template>
  <div class="row">
    <div class="col-12">
      <form class="mb-4">
        <div class="form-group">
          <label class="mb-1"> Survey Question </label>
          <small class="form-text text-muted">
            This is the question used to solicit feedback.
          </small>
          <select v-model="activeLocation.settings.surveyQuestion" class="form-control">
            <option
              v-for="(surveyQuestion, idx) in surveyQuestions"
              :key="idx"
              :value="surveyQuestion"
            >
              {{ surveyQuestion }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label class="mb-1"> Survey Delay </label>
          <small class="form-text text-muted">
            How long after the customer provides their contact info should we solicit feedback?
          </small>
          <select v-model="activeLocation.settings.surveyDelay" class="form-control">
            <option v-for="(surveyDelay, idx) in surveyDelays" :key="idx" :value="surveyDelay">
              {{ surveyDelay }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label class="mb-1"> Incentive Reward </label>
          <small class="form-text text-muted"> For your chance to win... </small>
          <input
            v-model="activeLocation.settings.incentive"
            type="text"
            class="form-control"
            placeholder="an iPad Mini"
          />
        </div>

        <div class="form-group">
          <button @click="update" type="button" class="btn btn-primary">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LocationSettingsBase from './LocationSettingsBase'
import LocationService from '@/services/LocationService'
import { surveyQuestions, surveyDelays } from '@/lib/modules/location'

export default {
  name: 'LocationSettingsSurvey',
  extends: LocationSettingsBase,
  data: () => ({
    surveyQuestions: [...surveyQuestions],
    surveyDelays: [...surveyDelays],
  }),
  methods: {
    async update() {
      const response = await LocationService.update({
        locationId: this.activeLocation._id,
        fields: {
          'settings.surveyQuestion': this.activeLocation.settings.surveyQuestion,
          'settings.surveyDelay': this.activeLocation.settings.surveyDelay,
          'settings.incentive': this.activeLocation.settings.incentive,
        },
      })

      const { location } = response.body
      this.setActiveLocation(location)

      this.$notify({
        title: 'Location updated',
        text: 'Your settings were saved.',
      })
    },
  },
}
</script>
