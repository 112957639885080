<template>
  <b-modal ref="modal" id="questionListPreviewModal" hide-header hide-footer centered size="lg">
    <div class="card-header h2">QuestionList Preview</div>

    <div class="card-body">
      <div class="p mb-4">{{ explaination }}</div>
      <div
        v-for="(phone, index) in phoneNumbers"
        :key="index"
        class="d-flex justify-content-center mb-3"
      >
        <input
          type="text"
          class="form-control w-50"
          placeholder="Enter Phone Number"
          v-model="phone.number"
          @keyup.enter="addPhone()"
          @blur="unFocusSingle(index)"
          @click="phone.edit = true"
          v-focus="phone.edit"
        />
        <button class="btn ml-2" @click="remove(index)">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-primary btn-sm" @click="addPhone()">Add Phone</button>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex">
        <div class="mr-auto mt-3 text-danger">{{ inputRequirements }}</div>
        <button class="btn mr-2" @click="close()">Cancel</button>
        <button class="btn btn-primary" :disabled="!validPhones" @click="send()">Send</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'QuestionListPreviewModal',
  data: () => ({
    explaination: `
      Based on the filters you have determined on this page, a set of custom questions will be sent
      to the phone numbers you identify below:
    `,
    validationInfo: `Phone numbers must be 10 digits and numbers only`,
    phoneNumbers: [],
  }),
  computed: {
    ...UserModule.mapGetters(['phone']),
    validPhones() {
      let valid = true
      this.phoneNumbers.forEach((phone) => {
        if (isNaN(phone.number) || phone.number.length !== 10) {
          valid = false
        }
      })
      return valid
    },
    inputRequirements() {
      if (!this.validPhones) {
        return this.validationInfo
      }
      return ''
    },
  },
  methods: {
    ...QuestionModule.mapActions(['sendCustomQuestionListPreview']),
    initialize() {
      this.reset()
      this.addPhone(this.phone)
    },
    addPhone(phone) {
      this.unFocusAll()
      this.phoneNumbers.push({ number: phone || '', edit: true })
    },
    remove(index) {
      this.phoneNumbers.splice(index, 1)
    },
    reset() {
      this.phoneNumbers = []
    },
    unFocusAll() {
      this.phoneNumbers.forEach((phone) => {
        phone.edit = false
      })
    },
    unFocusSingle(index) {
      this.phoneNumbers[index].edit = false
    },
    unFocusExcept(exemptIndex) {
      this.unFocusAll()
      this.phoneNumbers[exemptIndex].edit = true
    },
    async send() {
      let phones = []
      this.phoneNumbers.forEach((phone) => {
        phones.push(phone.number)
      })
      try {
        await this.sendCustomQuestionListPreview({ phoneNumbers: phones })
        this.$notify({ text: `Successfully sent preview texts!` })
      } catch (error) {
        this.$notify({ type: 'error', text: 'Error: Could not send preview texts!' })
        console.log(error)
      }
      this.close()
    },
    close() {
      this.$refs.modal.hide()
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
        el.select()
      },
    },
  },
}
</script>

<style scoped>
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
</style>
