<template>
  <div>
    <div class="alert alert-white text-muted" role="alert">
      <i class="fe fe-move"></i> Drag and drop platforms to customize the order in which they'll be
      shown.
    </div>
    <!-- <div class="row"> -->
    <draggable v-model="platforms">
      <transition-group class="row" name="platforms">
        <div class="col-12 platform-item" v-for="platform in platforms" :key="platform._id">
          <LocationSettingsPlatformCard :platform="platform" />
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import LocationSettingsBase from '../LocationSettingsBase'
import LocationSettingsPlatformCard from './LocationSettingsPlatformCard'
import { createNamespacedHelpers } from 'vuex'

const reviewSubscriptionModule = createNamespacedHelpers('reviewSubscription')

export default {
  name: 'LocationSettingsPlatforms',
  extends: LocationSettingsBase,
  components: {
    LocationSettingsPlatformCard,
    draggable,
  },
  computed: {
    platforms: {
      get() {
        if (this.activeLocation?.subscriptions) {
          const items = [...this.activeLocation.subscriptions].sort(
            (a, b) => a.displayOrder - b.displayOrder
          )
          return items
        }

        return []
      },
      set(platforms) {
        this.updatePlatforms(platforms)
        this.setLocationReviewSubscriptionsOrder(platforms)
      },
    },
  },
  methods: {
    ...reviewSubscriptionModule.mapActions(['updateReviewSubscription']),
    updatePlatforms(platforms) {
      let promises = platforms.map((platform, index) => {
        if (platform.displayOrder === index) return Promise.resolve()
        const modifications = { displayOrder: index }

        return this.updateReviewSubscription({
          reviewSubscriptionId: platform.reviewSubscription._id,
          locationId: this.activeLocation._id,
          modifications,
        })
      })

      return Promise.all(promises)
    },
  },
}
</script>

<style scoped lang="scss">
.platforms-move {
  transition: transform 1s;
}
</style>
