<template>
  <div class="m-2">
    <ReportsOffState v-if="!queryPending && !selectQuestionsExist" />
    <div v-else>
      <ReportsFilters :key="filterRefreshKey" />
      <ReportCardList v-if="!queryPending" />
      <div v-if="queryPending">
        <div class="mb-4 mx-auto" style="max-width: 900px" v-for="index in 4" :key="index">
          <b-skeleton height="150px" />
        </div>
      </div>
      <div class="text-center" v-if="!reports.length">No reports match the selected category.</div>
    </div>
  </div>
</template>

<script>
import ReportsOffState from './OffStates/ReportsOffState'
import ReportsFilters from './ReportsFilters'
import ReportCardList from './ReportCardList'
import { createNamespacedHelpers } from 'vuex'

const CustomQuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'ReportsView',
  components: {
    ReportsOffState,
    ReportsFilters,
    ReportCardList,
  },
  async created() {
    this.setQueryPending(true)
    await this.getReports()
    this.setQueryPending(false)
  },
  computed: {
    ...CustomQuestionModule.mapState([
      'refreshKey',
      'questions',
      'filterRefreshKey',
      'queryPending',
      'reports',
    ]),
    ...CustomQuestionModule.mapGetters(['selectQuestionsExist']),
  },
  methods: {
    ...CustomQuestionModule.mapMutations(['setQueryPending']),
    ...CustomQuestionModule.mapActions(['getReports']),
  },
}
</script>
