<template>
  <div id="grouping-filter-select" :style="`min-width: ${width}px`">
    <treeselect
      v-model="selectedOptions"
      :multiple="true"
      :options="options"
      :normalizer="normalizer"
      :placeholder="placeholderText"
      value-format="object"
      :clear-on-select="true"
      @close="emitSelectedOptions"
      :limit="2"
      :beforeClearAll="clearAll"
      :disabled="isLoading"
    >
      <div slot="value-label" slot-scope="{ node }">{{ getNodeLabel(node) }}</div>
    </treeselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { createNamespacedHelpers } from 'vuex'
import _ from 'lodash-es'

const GroupModule = createNamespacedHelpers('group')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'GroupingFilter',
  props: {
    init: {
      type: Object,
      default: () => ({
        nonGroupMemberIds: null,
        groupIds: null,
        disabledNonGroupMemberIds: null,
      }),
    },
    memberType: { type: String, required: true }, // 'location', 'companies', etc
    nonGroupMembers: { type: Array },
    disabledNonGroupMembers: { type: Array },
    nonGroupMemberLabel: { type: String },
    placeholder: { type: String },
    width: { type: [String, Number], default: '215' },
    locationsOnly: { type: Boolean },
    companies: { type: Array },
    isLoading: { type: Boolean, default: false },
  },
  components: {
    Multiselect,
    Treeselect,
  },
  computed: {
    ...GroupModule.mapGetters(['selectGroups']),
    ...UserModule.mapGetters(['onlineUser']),
    placeholderText() {
      return this.isLoading ? 'Loading Options...' : this.placeholder || 'All Locations'
    },
  },
  data: () => ({
    selectedOptions: [],
    options: [],
  }),
  mounted() {
    this.initFilterOptions()
  },
  methods: {
    clearAll() {
      this.selectedOptions = []
      this.emitSelectedOptions()
    },
    emitSelectedOptions() {
      const groupingData = {
        nonGroupMemberIds: [...this.setSelectedNonGroupMemberIds(new Set(), this.selectedOptions)],
        disabledNonGroupMemberIds: [
          ...this.setSelectedDisabledNonGroupMemberIds(new Set(), this.selectedOptions),
        ],
        groupIds: [...this.setSelectedGroups(new Set(), this.selectedOptions)],
        memberIds: [...this.setSelectedGroupMemberIds(new Set(), this.selectedOptions)],
        memberType: this.memberType,
      }

      this.$emit(
        'update',
        groupingData.nonGroupMemberIds.length ||
          groupingData.groupIds.length ||
          groupingData.disabledNonGroupMemberIds.length
          ? groupingData
          : null
      )
    },
    setSelectedGroups(groupIds, options) {
      if (!options || !options.length) return groupIds

      options.forEach((option) => {
        if (
          option.id !== 'nonGroupMembers' &&
          option.id !== 'disabledNonGroupMembers' &&
          ((option.children && option.children.length) || (option.members && option.members.length))
        ) {
          if (option.id === 'groups') {
            option.children.forEach((child) => {
              groupIds.add(child._id)
            })
          } else {
            groupIds.add(option._id)
          }
        }
      })

      return groupIds
    },
    setSelectedGroupMemberIds(memberIds, options) {
      if (!options || !options.length) return memberIds

      options.forEach((option) => {
        if (option.children && option.children.length) {
          if (option.id === 'nonGroupMembers') return
          this.setSelectedGroupMemberIds(memberIds, option.children)
        } else if (option.members && option.members.length) {
          option.members.forEach((member) => {
            memberIds.add(member)
          })
        }
      })

      return memberIds
    },
    setSelectedDisabledNonGroupMemberIds(memberIds, options) {
      if (!options || !options.length) return memberIds

      options.forEach((option) => {
        if (option.id === 'disabledNonGroupMembers') {
          option.children.forEach((child) => {
            memberIds.add(child._id)
          })
        } else if (!option.children && !option.members && option.disabled) {
          memberIds.add(option._id)
        }
      })

      return memberIds
    },
    setSelectedNonGroupMemberIds(memberIds, options) {
      if (!options || !options.length) return memberIds

      options.forEach((option) => {
        if (option.id === 'nonGroupMembers') {
          option.children.forEach((child) => {
            memberIds.add(child._id)
          })
        } else if (!option.children && !option.members && !option.disabled) {
          memberIds.add(option._id)
        }
      })

      return memberIds
    },
    initFilterOptions() {
      this.options = [
        ...(this.selectGroups.length &&
        this.onlineUser?.role === 'admin' &&
        this.nonGroupMemberLabel !== 'Companies'
          ? [
              {
                id: 'groups',
                label: 'Groups',
                isDefaultExpanded: !!this.init.groupIds?.length,
                children: this.companies?.length
                  ? this.selectGroups.filter((g) => this.companies.includes(g.company))
                  : this.selectGroups,
              },
            ]
          : []),
      ]

      const nonGroupMembersExist = this.nonGroupMembers && this.nonGroupMembers.length
      let nonGroupMemberOption
      if (nonGroupMembersExist) {
        nonGroupMemberOption = {
          id: 'nonGroupMembers',
          label: this.nonGroupMemberLabel,
          children: this.nonGroupMembers.sort((a, b) => {
            return this.relevantName(a) > this.relevantName(b) ? 1 : -1
          }),
          isDefaultExpanded: !!this.init.nonGroupMemberIds?.length || this.locationsOnly,
        }
        this.options.push(nonGroupMemberOption)
      }

      const disabledNonGroupMembersExist =
        this.disabledNonGroupMembers && this.disabledNonGroupMembers.length
      let disabledNonGroupMemberOption
      if (disabledNonGroupMembersExist) {
        disabledNonGroupMemberOption = {
          id: 'disabledNonGroupMembers',
          label: `Disabled ${this.nonGroupMemberLabel}`,
          children: this.disabledNonGroupMembers,
          isDefaultExpanded: !!this.init.disabledNonGroupMemberIds?.length,
        }
        this.options.push(disabledNonGroupMemberOption)
      }

      if (this.init) {
        if (this.init.nonGroupMemberIds) {
          if (
            nonGroupMembersExist &&
            _.isEqual(
              this.init.nonGroupMemberIds.sort(),
              this.nonGroupMembers.map((ngm) => ngm._id.toString()).sort()
            )
          ) {
            this.selectedOptions.push(nonGroupMemberOption)
          } else {
            this.init.nonGroupMemberIds.forEach((nonGroupMemberId) => {
              const nonGroupMemberOptions = this.options.find(
                (option) => option.id === 'nonGroupMembers'
              )

              if (nonGroupMemberOptions) {
                const selectedOption = nonGroupMemberOptions.children.find(
                  (child) => child._id === nonGroupMemberId
                )
                if (selectedOption) {
                  this.selectedOptions.push(selectedOption)
                }
              }
            })
          }
        }

        if (this.init.disabledNonGroupMemberIds) {
          if (
            disabledNonGroupMembersExist &&
            _.isEqual(
              this.init.disabledNonGroupMemberIds.sort(),
              this.disabledNonGroupMembers.map((dngm) => dngm._id.toString()).sort()
            )
          ) {
            this.selectedOptions.push(disabledNonGroupMemberOption)
          } else {
            this.init.disabledNonGroupMemberIds.forEach((disabledNonGroupMemberId) => {
              const disabledNonGroupMemberOptions = this.options.find(
                (option) => option.id === 'disabledNonGroupMembers'
              )

              if (disabledNonGroupMemberOptions) {
                const selectedOption = disabledNonGroupMemberOptions.children.find(
                  (child) => child._id === disabledNonGroupMemberId
                )
                if (selectedOption) {
                  this.selectedOptions.push(selectedOption)
                }
              }
            })
          }
        }

        if (this.init.groupIds) {
          const selectedGroupOptions = this.findSelectedGroupOptions(
            new Set(),
            this.init.groupIds,
            this.selectGroups
          )
          if (selectedGroupOptions) {
            this.selectedOptions = [...this.selectedOptions, ...selectedGroupOptions]
          }
        }
      }
    },
    findSelectedGroupOptions(selectedGroups, groupIds, groups) {
      groups.forEach((group) => {
        if (group.children && group.children.length) {
          this.findSelectedGroupOptions(selectedGroups, groupIds, group.children)
        }

        if (groupIds.includes(group._id)) {
          selectedGroups.add(group)
        }
      })

      return selectedGroups
    },
    normalizer(node) {
      if (['groups', 'nonGroupMembers', 'disabledNonGroupMembers'].includes(node.id)) return node

      return {
        id: node._id,
        label:
          this.nonGroupMemberLabel === 'Locations'
            ? node.friendlyName || node.city || node.name
            : this.nonGroupMemberLabel === 'Companies'
            ? node.friendlyName || node.name
            : node.name,
        children: node.children,
      }
    },
    getNodeLabel(node) {
      if (['groups', 'nonGroupMembers', , 'disabledNonGroupMembers'].includes(node.id)) {
        return `All ${node.label}`
      } else {
        return node.label
      }
    },
    relevantName({ friendlyName, city, name }) {
      if (this.nonGroupMemberLabel === 'Locations') {
        return friendlyName || city
      } else if (this.nonGroupMemberLabel === 'Companies') {
        return friendlyName || name
      } else {
        return name
      }
    },
  },
}
</script>
<style lang="scss">
#grouping-filter-select {
  [data-id='groups'] {
    .vue-treeselect__label-container {
      pointer-events: none;
    }

    .vue-treeselect__checkbox {
      display: none;
    }
  }

  .vue-treeselect__control {
    height: 40.5px;
    border-color: #d2ddec;
    width: unset;
  }

  .vue-treeselect__placeholder {
    margin-top: 2px;
  }

  .vue-treeselect__option,
  .vue-treeselect__option-arrow,
  .vue-treeselect__placeholder {
    color: #6e84a3;
  }

  .vue-treeselect__control-arrow {
    display: none;
  }

  .vue-treeselect__checkbox {
    border-color: #d2ddec;
    background-color: #d2ddec;
  }

  .vue-treeselect__checkbox--checked,
  .vue-treeselect__checkbox--indeterminate {
    border-color: #2c7be5;
    background-color: #2c7be5;
  }

  .vue-treeselect__multi-value-label,
  .vue-treeselect__value-remove {
    color: #12263f;
  }

  .vue-treeselect__multi-value-item {
    background-color: #edf2f9;
  }
}
</style>
