<template>
  <div>
    <!-- question creation preview and editor -->
    <div v-if="!options">
      <div
        v-for="(selection, index) of selections"
        :key="index"
        class="d-flex justify-centent-between align-items-center ml-2 mb-2 mh-35"
      >
        <i class="far fa-square fa-sm text-muted"></i>
        <input
          type="text"
          v-if="selection.edit"
          class="w-100 ml-2"
          v-model="selection.title"
          placeholder="Enter option title..."
          @keyup.enter="addOption"
          @blur="unFocusSingle(index)"
          @keyup="save"
          v-focus="selection.edit"
        />
        <div v-else class="d-inline-block w-100 ml-2" @click="unFocusExcept(index)">
          {{ selection.title }}
        </div>
        <i class="fas fa-times text-muted ml-2" @click="remove(index)"></i>
      </div>
      <div class="ml-3">
        <a class="text-primary" @click="addOption">+ add option</a>
      </div>
    </div>

    <!-- question list preview -->
    <div v-else>
      <div
        v-for="(option, index) of options"
        :key="index"
        class="row align-items-center ml-2 mb-2 h-35"
      >
        <i class="far fa-square fa-sm text-muted float-left"></i>
        <div class="d-inline-block ml-2" style="width: 90%">
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// @group CustomQuestions
// A preview component for Multiple Selection (checkboxes) on the custom question page.
export default {
  name: 'MultipleSelectionType',
  props: {
    existingSelections: {
      type: Array,
      default() {
        return []
      },
    },
    options: {
      type: Array,
    },
    inputTitle: {
      type: String,
    },
  },
  data: () => ({
    selections: [],
  }),
  mounted() {
    if (this.existingSelections.length && this.existingSelections[0]) {
      this.selections = this.formatExistingSelections()
    } else {
      this.addOption()
    }
  },
  methods: {
    formatExistingSelections() {
      return this.existingSelections.map((selection) => {
        return { title: selection, edit: false }
      })
    },
    save() {
      this.$emit(
        'updateOptions',
        this.selections.map((selection) => selection.title)
      )
    },
    unFocusAll() {
      this.selections.forEach((selection) => {
        selection.edit = false
        if (!selection.title) {
          selection.title = this.inputTitle
        }
      })
    },
    unFocusSingle(index) {
      this.selections[index].edit = false
      if (!this.selections[index].title) {
        this.selections[index].title = this.inputTitle
      }
    },
    unFocusExcept(exemptIndex) {
      this.unFocusAll()
      this.selections[exemptIndex].edit = true
    },
    addOption() {
      this.unFocusAll()
      this.selections.push({ title: '', edit: true })
      this.save()
    },
    remove(index) {
      this.selections.splice(index, 1)
      this.save()
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
        el.select()
      },
    },
  },
}
</script>

<style scoped>
.mh-35 {
  min-height: 35px;
}
</style>
