import FeatureFlagService from '@/services/FeatureFlagService'

export default {
  namespaced: true,
  state: {
    flags: {},
    companyIds: [],
  },
  getters: {
    isEnabled: (state) => (id) => {
      if (!Object.keys(state.flags).length) return

      return state.flags[id] && Array.isArray(state.flags[id])
        ? state.companyIds.some((companyId) => state.flags[id]?.includes(companyId))
        : state.flags[id]?.companies?.length
        ? state.companyIds.some((companyId) => state.flags[id]?.companies?.includes(companyId))
        : state.flags[id]?.enabled
    },
    isEnabledV2: (state, _, rootState) => (id) => {
      if (!Object.keys(state.flags).length) return false
      const flag = state.flags[id]
      if (!flag) return false

      // check if company is enabled
      if (Array.isArray(flag)) {
        return state.companyIds.some((companyId) => flag.includes(companyId))
      }

      // check if enabled for all
      if (flag.enabled === true) return true

      // check if user enabled
      const user = rootState.user.onlineUser
      if (flag.users?.length && flag.users.includes(user._id)) return true

      return false
    },
  },
  mutations: {
    setCompanies(state, companyIds) {
      state.companyIds = [...companyIds]
    },
    setFeatureFlags(state, data) {
      state.flags = { ...data }
    },
  },
  actions: {
    setFeatureFlagCompanies({ commit }, companyIds) {
      commit('setCompanies', companyIds)
    },
    async getFeatureFlags({ commit, rootGetters }) {
      const companyIds = rootGetters['company/companies'].map((company) => company._id)
      const response = await FeatureFlagService.list(companyIds)
      commit('setFeatureFlags', response.data)
    },
  },
}
