<template>
  <div class="message" v-if="giftCard">
    <div class="row mt-1 mb-1 message internal">
      <div class="col mr--2">
        <div class="comment-body">
          <p class="comment-text d-inline">
            A gift card ending in {{ giftCard.last4Digits }} for ${{ giftCard.amount / 100 }} was
            sent.
          </p>
          <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Gift Card</span>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">{{ created | moment('LT') }} {{ timezone }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-2">
        <a href="javascript:;" class="btn btn-sm btn-rounded-circle btn-primary">
          <CreditCardIcon size="1.26x" style="color: white" class="mt--1"></CreditCardIcon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { CreditCardIcon } from 'vue-feather-icons'

export default {
  name: 'GiftCardSentEvent',
  props: ['created', 'giftCard', 'timezone'],
  components: {
    CreditCardIcon,
  },
}
</script>
