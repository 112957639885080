<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-0">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div
                class="card"
                :class="{ active: program === 'spend' }"
                @click="setProgram('spend')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <a href="#!" class="avatar avatar-lg pt-2">
                        <span class="avatar-title rounded-circle">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </a>
                    </div>
                    <div class="col">
                      <!-- Title -->
                      <h4 class="card-title mb-1">
                        <a href="#!">Spend</a>
                      </h4>

                      <!-- Status -->
                      <p class="card-text text-muted small">Customers earn per dollar spent.</p>
                    </div>

                    <div class="col-auto" v-if="program === 'spend'">
                      <a href="#!" class="btn btn-sm btn-success btn-rounded-circle">
                        <span class="fe fe-check"></span>
                      </a>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <!-- / .card-body -->
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div
                class="card"
                :class="{ active: program === 'frequency' }"
                @click="setProgram('frequency')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <a href="#!" class="avatar avatar-lg pt-2">
                        <span class="avatar-title rounded-circle text">
                          <i class="fas fa-store"></i>
                        </span>
                      </a>
                    </div>
                    <div class="col">
                      <!-- Title -->
                      <h4 class="card-title mb-1">
                        <a href="#!">Visit Frequency</a>
                      </h4>

                      <!-- Status -->
                      <p class="card-text text-muted small">Customers earn per order made.</p>
                    </div>

                    <div class="col-auto">
                      <a
                        href="#!"
                        class="btn btn-sm btn-success btn-rounded-circle"
                        v-if="program === 'frequency'"
                      >
                        <span class="fe fe-check"></span>
                      </a>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <!-- / .card-body -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanySettingsLoyaltyProgram',
  props: ['program'],
  methods: {
    setProgram(program) {
      this.$emit('programSelected', program)
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
}
</style>
