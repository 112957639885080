const products = () => ({
  tent: {
    type: 'tent',
    title: 'Table Tents',
    img: '/img/illustrations/qr-tent.svg',
    subTitle: 'Standard tent with a QR code and offer.',
    badge: {
      text: 'Lasts ~3 months',
      type: 'secondary',
      inline: true,
    },
    price: {
      amount: 0.5,
      description: '(15 for free)',
    },
    counter: {
      default: 15,
      count: 15,
    },
  },
  acrylic: {
    type: 'acrylic',
    added: false,
    title: 'Acrylic Upgrade',
    img: '/img/illustrations/qr-tent-protector.svg',
    subTitle: 'Upgrade your tents to acrylic for a premium look.',
    badge: {
      text: 'Durable',
      type: 'primary',
      inline: true,
    },
    price: {
      amount: 2,
      description: '(each)',
    },
    button: {
      text: '+ Add',
      type: 'primary',
    },
  },
  kiosk: {
    type: 'kiosk',
    added: false,
    title: 'iPad Kiosk',
    img: '/img/illustrations/ipad-kiosk.svg',
    subTitle: 'Interactive survey app.',
    badge: {
      text: 'Recommended',
      type: 'success',
      inline: true,
    },
    price: {
      amount: 249,
      description: '(activation fee)',
    },
    button: {
      text: '+ Add',
      type: 'primary',
    },
  },
  beacon: {
    type: 'beacon',
    title: 'Retention Beacon',
    img: '/img/illustrations/beacon.svg',
    subTitle: 'In-store mobile device marketing campaigns.',
    badge: {
      text: 'Coming soon',
      type: 'primary',
    },
    button: {
      text: 'Join Waitlist',
      type: 'white',
    },
  },
})

export default {
  namespaced: true,
  state: {
    products: products(),
  },
  mutations: {
    incrementProductCounter(state, product) {
      state.products[product].counter.count++
    },
    decrementProductCounter(state, product) {
      if (state.products[product].counter.count > state.products[product].counter.default) {
        state.products[product].counter.count--
      }
    },
    selectProduct(state, product) {
      state.products[product].added = true
    },
    removeProduct(state, product) {
      state.products[product].added = false
    },
    resetCheckout(state) {
      state.products = products()
    },
  },
  getters: {
    checkoutAmount(state) {
      const { tent, acrylic, kiosk } = state.products

      const totalTents = tent.counter.count
      const extraTents = totalTents - tent.counter.default
      const tentPrice = tent.price.amount

      const upgradeTents = acrylic.added
      const upgradeTentPrice = acrylic.price.amount

      const includeKiosk = kiosk.added
      const kioskPrice = kiosk.price.amount

      return (
        extraTents * tentPrice +
        (upgradeTents ? totalTents * upgradeTentPrice : 0) +
        (includeKiosk ? kioskPrice : 0)
      )
    },
    checkoutItems(state) {
      const { tent, acrylic, kiosk } = state.products

      const items = []

      items.push({
        type: tent.type,
        amount: tent.counter.count,
      })

      if (acrylic.added) {
        items.push({
          type: acrylic.type,
        })
      }

      if (kiosk.added) {
        items.push({
          type: kiosk.type,
        })
      }

      return items
    },
    checkoutDescription(state) {
      const { kiosk } = state.products

      let description = 'Table Tents'

      if (kiosk.added) {
        description += ' & iPad'
      }

      return description
    },
  },
  actions: {},
}
