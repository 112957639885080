<template>
  <div class="mt-3 mb-2">
    <b-skeleton v-if="isSearching" width="90px" height="22px" />
    <template v-else>
      <animated-number
        :value="filteredCount || 0"
        :formatValue="parseInt"
        :duration="500"
        class="font-weight-bold survey-count"
      />
      <span class="survey-text">
        <span v-if="filteredCount !== activeCount"> of {{ activeCount }}</span>
        active questions
      </span>
    </template>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import { createNamespacedHelpers } from 'vuex'
const CustomQuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'QuestionCount',
  props: {
    isSearching: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AnimatedNumber,
  },
  computed: {
    ...CustomQuestionModule.mapState(['activeCount', 'filteredCount']),
  },
}
</script>

<style scoped>
.survey-count {
  color: #6e84a3;
}
.survey-text {
  color: #95aac9;
}
</style>
