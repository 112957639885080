import Vue from 'vue'

export default {
  interpolateTemplate({ templateId, customerId, locationId }) {
    return Vue.http.post(`app-services/v2/template/${templateId}/interpolate`, {
      customerId,
      locationId,
    })
  },
  fetchTemplates({ companyId }) {
    return Vue.http.post(`app-services/v2/template/list`, { companyId })
  },
  listTemplates(payload) {
    return Vue.http.post(`app-services/v2/templates/list`, payload)
  },
  updateTemplate({ templateId, payload }) {
    return Vue.http.put(`app-services/v2/templates/${templateId}`, payload)
  },
  createTemplate(payload) {
    return Vue.http.post(`app-services/v2/templates`, payload)
  },
  deleteTemplate(templateId) {
    return Vue.http.delete(`app-services/v2/templates/${templateId}`)
  },

  /* deprecated */
  create(payload) {
    return Vue.http.post('template', payload)
  },
  list(companyId, userId) {
    return Vue.http.post('template/list', { companyId, userId })
  },
  toggle(templateId) {
    return Vue.http.post('template/toggle', { templateId })
  },
  remove(templateId) {
    return Vue.http.post('template/remove', { templateId })
  },
  interpolate({ input, userId, customerId, locationId }) {
    return Vue.http.post('template/interpolate', { input, userId, customerId, locationId })
  },
}
