import Vue from 'vue'

export default {
  inviteEmployee(payload) {
    return Vue.http.post('app-services/v2/users/invite', payload)
  },
  updateCompanyLegal({ companyId, fields }) {
    return Vue.http.put(`app-services/v2/company/${companyId}/legal`, fields)
  },
  updateCompany({ companyId, fields }) {
    return Vue.http.put(`app-services/v2/company/${companyId}`, fields)
  },
  addOauth(payload) {
    return Vue.http.post(`app-services/v2/company/add-oauth`, payload)
  },

  // deprecated nexus endpoints
  create(payload) {
    return Vue.http.post('company', payload)
  },
  list(userId) {
    return Vue.http.get(`company/list/${userId}`)
  },
  update(payload) {
    return Vue.http.post('company/update', payload)
  },
  getTeam(payload) {
    return Vue.http.post('company/get-team', payload)
  },
  deleteInvite(inviteId) {
    return Vue.http.delete(`company/invite/${inviteId}`)
  },
  resendInvite(payload) {
    return Vue.http.post('company/resend-invite', payload)
  },
  getInvite(token) {
    return Vue.http.get(`company/invite/${token}`)
  },
  saveCard(payload) {
    return Vue.http.post(`company/save-card`, payload)
  },
  // deprecated: use the request in campaignSetups service
  textUsage(companyId) {
    return Vue.http.get(`company/${companyId}/text-usage`)
  },
}
