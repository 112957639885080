<template>
  <div title="Loyalty">
    <b-dropdown
      ref="loyaltyDropdown"
      variant="link"
      size="sm"
      class="hide-caret"
      id="loyalty-dropdown"
    >
      <template slot="button-content">
        <span class="text-capitalize d-flex align-items-center">
          <HeartIcon size="1.56x" class="action-icon" :style="{ color: '#95aac9' }"></HeartIcon>
        </span>
      </template>
      <b-dropdown-item
        v-for="point in points"
        :key="point"
        class="dropdown-item"
        @click="sendPoints(point)"
      >
        Give {{ point }} point{{ point === 1 ? '' : 's' }}
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-form style="width: 225px" class="px-3">
        <b-input-group>
          <b-form-input
            v-model="customPoints"
            type="number"
            placeholder="Specify points"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="sendPoints(customPoints)" variant="primary">
              <i class="far fa-paper-plane" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { HeartIcon } from 'vue-feather-icons'

export default {
  name: 'LoyaltyDropdown',
  props: ['customerId', 'companyId', 'locationId'],
  components: {
    HeartIcon,
  },
  data: () => ({
    customPoints: undefined,
    points: [1, 2, 5, 10],
  }),
  methods: {
    ...mapActions('loyalty', ['sendLoyaltyPoints']),
    async sendPoints(points) {
      await this.sendLoyaltyPoints({
        customerId: this.customerId,
        companyId: this.companyId,
        locationId: this.locationId,
        points,
      })
      this.$notify({
        title: 'On the way!',
        text: `${points} point${points > 1 ? 's' : ''} were sent.`,
      })
    },
  },
  async mounted() {},
}
</script>

<style scoped lang="scss">
a {
  text-transform: capitalize;
}
.dropdown-item {
  font-size: 14px;
}
.action-icon {
  color: #95aac9;
}
.action-icon:hover {
  color: #12263F !important;
}
::v-deep #loyalty-dropdown .btn:focus {
  box-shadow: none !important;
}
::v-deep #loyalty-dropdown .btn-primary:focus {
  box-shadow: none !important;
}
::v-deep #loyalty-dropdown .btn-sm {
  padding: 0 0 0 0 !important;
}
</style>
