<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto">
          <Avatar
            :img="employee.avatar"
            :first-name="employee.firstName"
            :last-name="employee.lastName"
            size="lg"
          />
        </div>
        <div class="col ml--2">
          <h4 class="card-title mb-1">
            <span>{{ employeeName }}</span>
          </h4>
          <p class="card-text small mb-1">{{ employeeRole }}</p>
          <p v-if="locationNames" class="card-text text-muted small">
            {{ locationNames }}
          </p>
        </div>
        <div class="col-auto">
          <button @click="resend" class="btn btn-sm btn-primary">Resend</button>
        </div>
        <div class="col-auto">
          <div class="dropdown">
            <a
              href="#!"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
            >
              <i class="fe fe-more-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a @click="deleteInvite" href="javascript:;" class="dropdown-item"> Delete </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeammateBase from './TeammateBase'
import CompanyService from '@/services/CompanyService'

export default {
  name: 'TeammateCard',
  extends: TeammateBase,
  props: ['invite'],
  methods: {
    async resend() {
      await CompanyService.resendInvite({ inviteId: this.invite._id })
      this.$notify({
        title: 'There it goes!',
        text: `Your invitation to ${this.employee.firstName} has been resent.`,
      })
    },
    async deleteInvite() {
      await CompanyService.deleteInvite(this.invite._id)
      this.$emit('deleted')
    },
  },
}
</script>
