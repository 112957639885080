import TemplateService from '@/services/TemplateService'

const defaultTemplate = () => ({
  _id: undefined,
  title: '',
  body: '',
  locations: [],
})

const defaultAutoTemplate = () => ({
  _id: undefined,
  title: '',
  body: '',
})

export default {
  namespaced: true,
  state: {
    templates: [],
    template: defaultTemplate(),
    locations: [],
    autoTemplates: [],
    autoTemplate: defaultAutoTemplate(),
  },
  getters: {
    templates: (state) => state.templates,
    template: (state) => state.template,
    locations: (state) => state.locations,
    templatesByLocation: (state) => (locationId) => {
      return state.templates.filter(
        (t) => t.locations?.includes(locationId) || t.locations?.length === 0
      )
    },
    selectAutoTemplates: (state) => state.autoTemplates,
    selectAutoTemplate: (state) => state.autoTemplate,
  },
  mutations: {
    setTemplates(state, templates) {
      state.templates = templates
    },
    setTitle(state, title) {
      state.template.title = title
    },
    setBody(state, body) {
      state.template.body = body
    },
    setLocations(state, locations) {
      state.template.locations = locations
    },
    resetTemplate(state) {
      state.template = { ...defaultTemplate() }
    },
    setTemplate(state, template) {
      state.template = { ...template }
    },
    SET_TEMPLATE_PROMO(state, promo) {
      state.template = {
        ...state.template,
        ...promo,
      }
    },
    SET_AUTO_TEMPLATES(state, autoTemplates) {
      state.autoTemplates = autoTemplates
    },
    SET_AUTO_TEMPLATE(state, autoTemplate) {
      state.autoTemplate = autoTemplate
    },
    RESET_AUTO_TEMPLATE(state) {
      state.autoTemplate = { ...defaultAutoTemplate() }
    },
  },
  actions: {
    setPromo({ commit }, promo) {
      commit('SET_TEMPLATE_PROMO', promo)
    },

    setAutoTemplate({ commit }, autoTemplate) {
      commit('SET_AUTO_TEMPLATE', autoTemplate)
    },

    resetAutoTemplate({ commit }) {
      commit('RESET_AUTO_TEMPLATE')
    },

    async queryTemplates({ rootGetters, commit }) {
      const companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]
      const result = await TemplateService.listTemplates({
        filters: {
          companyIds: companies.map((c) => c._id),
        },
      })
      const allTemplates = result.data.data.templates
      const nonAutoTemplates = allTemplates.filter((t) => t.type !== 'auto')
      const autoTemplates = allTemplates.filter((t) => t.type === 'auto')
      commit('setTemplates', nonAutoTemplates)
      commit('SET_AUTO_TEMPLATES', autoTemplates)
    },

    async fetchTemplates({ commit, getters }, companyId) {
      const response = await TemplateService.fetchTemplates({ companyId })
      const templates = response.body.data.templates
      commit('setTemplates', templates)
    },

    async createTemplate({ commit, getters }, template) {
      const response = await TemplateService.createTemplate(template)
      commit('SET_AUTO_TEMPLATES', [...getters.selectAutoTemplates, response.body.template])
      return response.body.template
    },

    async deleteAutoTemplate({ commit, getters, dispatch }, templateId) {
      await TemplateService.deleteTemplate(templateId)
      commit(
        'SET_AUTO_TEMPLATES',
        getters.selectAutoTemplates.filter((t) => t._id !== templateId)
      )
      // remove the template from the autoResponsesConfig
      dispatch('company/removeTemplateFromResponsesConfig', templateId, { root: true })
    },

    async updateTemplate({}, template) {
      const response = await TemplateService.updateTemplate({
        templateId: template._id,
        payload: template,
      })
      return response.body.template
    },
  },
}
