import Chart from 'chart.js'
import { globalOptions, doughnutOptions } from './options'
import Colors from './colors'

export function initCharts() {
  // Global options
  parseOptions(Chart.defaults.global, globalOptions)

  // Doughnut options
  parseOptions(Chart.defaults.doughnut, doughnutOptions)

  // yAxes
  Chart.scaleService.updateScaleDefaults('linear', {
    gridLines: {
      borderDash: [2],
      borderDashOffset: [2],
      color: Colors.gray[300],
      drawBorder: false,
      drawTicks: false,
      lineWidth: 1,
      zeroLineWidth: 0,
      zeroLineColor: Colors.gray[300],
      zeroLineBorderDash: [2],
      zeroLineBorderDashOffset: [2],
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      // callback(value) {
      //   if (!(value % 10)) {
      //     return value
      //   }
      // },
    },
  })

  // xAxes
  Chart.scaleService.updateScaleDefaults('category', {
    gridLines: {
      drawBorder: true,
      drawOnChartArea: false,
      drawTicks: false,
    },
    ticks: {
      padding: 20,
      autoSkip: true,
      maxTicksLimit: 15,
      maxRotation: 0,
      minRotation: 0,
    },
    // maxBarThickness: 10,
  })
}

function parseOptions(parent, options) {
  for (var item in options) {
    if (typeof options[item] !== 'object') {
      parent[item] = options[item]
    } else {
      parseOptions(parent[item], options[item])
    }
  }
}
