<template>
  <div v-if="message">
    <Message
      :content="content"
      :user="validatedUser"
      :timestamp="created"
      :media="message.media"
      :highlights="highlights"
      :type="message.type"
      :timezone="timezone"
      :customer="customer"
      :automatedResponse="message.automatedResponse"
    ></Message>
  </div>
</template>

<script>
import Message from '@/components/Modules/Message/MessageV2'
export default {
  name: 'MessageEvent',
  props: ['created', 'message', 'user', 'conversationMatchedMessage', 'timezone', 'customer'],
  components: {
    Message,
  },
  computed: {
    validatedUser() {
      return this.user && typeof this.user === 'object' ? this.user : {}
    },
    highlights() {
      return (
        this.message &&
        this.conversationMatchedMessage &&
        this.conversationMatchedMessage.score &&
        this.message._id == this.conversationMatchedMessage._id
      )
    },
    content() {
      if (this.highlights) {
        let body = this.conversationMatchedMessage.body
        let highlights = this.conversationMatchedMessage.highlights
        if (highlights && highlights.length) {
          highlights.forEach((highlight) => {
            let replacements = highlight.texts
              .map((text) => {
                if (text.type === 'hit') {
                  return '<mark>' + text.value + '</mark>'
                } else {
                  return text.value
                }
              })
              .join('')

            let originals = highlight.texts
              .map((text) => {
                return text.value
              })
              .join('')

            body = body.replace(originals, replacements)
          })
        }
        return body
      }

      return this.message.body
    },
  },
}
</script>
