<template>
  <div class="mx-5">
    <div v-if="initialized">
      <div class="mb-4">
        <h2 class="mb-2">Automated Responses</h2>
        <small class="text-muted">Maximize your customer engagement with automated responses</small>
      </div>

      <div class="form-group mb-3">
        <div class="card p-4 mb-0">
          <div class="mb-4">
            <!-- delay -->
            <label class="mb-1"> Delay<span class="text-danger">*</span> </label>
            <small class="form-text text-muted">
              You can choose for Automated Responses to be delayed by up to 48 hours. We will not
              message customers you have already responded to.
            </small>
            <div class="d-flex align-items-center">
              <input
                v-model="autoResponsesConfig.delay"
                class="form-control w-25"
                type="number"
                :min="minimumMinutes"
                :max="maximumMinutes"
                placeholder="15"
                step="5"
                @change="checkDelay"
              />
              <div class="ml-2">minutes</div>
            </div>
            <div class="text-danger" v-if="!isValidDelay">
              Delay must be between {{ minimumMinutes }} and {{ maximumMinutes }} minutes (1 minute
              to 48 hours).
            </div>
          </div>

          <div>
            <!-- author -->
            <label class="mb-1">Author Signature</label>
            <small class="form-text text-muted">
              How would you like to sign off your automated responses? A signature is required to
              enable AI responses and may appear in a response similar to this example:
            </small>
            <small class="form-text text-muted">
              Hi [guest name], this is [Author Signature] from [Company Name]. Thank you for sharing
              your feedback. etc...
            </small>
            <div class="d-flex align-items-center">
              <input
                :value="autoResponsesConfig.author"
                class="form-control w-50"
                type="text"
                placeholder="The Ovation Customer Care Team"
                @change="checkAuthor"
                :key="authorKey"
              />
              <div class="ml-2"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 1-4 ratings -->
      <div v-for="x in 4" :key="'rating' + x">
        <CompanySettingsResponsesRating
          :rating="x"
          @update="updateRatingSettings"
          :init="autoResponsesConfig[`rating${x}`]"
          :hasAuthor="hasAuthor"
          :hasDelay="hasDelay"
          :key="authorKey + x"
        />
      </div>
    </div>

    <div v-else>
      <b-skeleton class="mb-4" width="50%" height="30px" />
      <b-skeleton class="mb-4" v-for="x in 4" width="100%" height="30px" :key="x" />
    </div>
  </div>
</template>

<script>
import CompanySettingsResponsesRating from './CompanySettingsResponsesRating'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const TemplateModule = createNamespacedHelpers('template')

export default {
  name: 'CompanySettingsReponses',
  components: { CompanySettingsResponsesRating },
  data: () => ({
    autoResponsesConfig: {
      delay: null,
      rating1: null,
      rating2: null,
      rating3: null,
      rating4: null,
      author: null,
    },
    minimumMinutes: 1,
    maximumMinutes: 48 * 60,
    initialized: false,
    authorKey: 0,
  }),
  async beforeMount() {
    await this.queryTemplates()
    if (this.selectActiveCompany?.settings.autoResponsesConfig) {
      Object.assign(this.autoResponsesConfig, this.selectActiveCompany.settings.autoResponsesConfig)
    }
    this.initialized = true
  },
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    isValidDelay() {
      const delay = this.autoResponsesConfig.delay
      return delay === null || (delay >= this.minimumMinutes && delay <= this.maximumMinutes)
    },
    hasDelay() {
      return !!this.autoResponsesConfig.delay
    },
    hasAuthor() {
      return !!this.autoResponsesConfig.author
    },
  },
  methods: {
    ...TemplateModule.mapActions(['queryTemplates']),
    ...CompanyModule.mapActions(['updateAutoResponsesConfig']),
    checkDelay(event) {
      const delay = event.target.value
      if (!delay) {
        this.autoResponsesConfig.delay = null
      }
      this.saveAutoResponsesConfig()
    },
    updateRatingSettings(rating, value) {
      this.autoResponsesConfig['rating' + rating] = value
      this.saveAutoResponsesConfig()
    },
    checkAuthor(event) {
      const author = event.target.value
      if (this.autoResponsesConfig.author && !author) {
        this.$bvModal
          .msgBoxConfirm(
            `Removing the author signature will deactivate any ratings setup with AI responses. Do you wish to proceed?`,
            {
              title: 'Are you sure?',
              okVariant: 'danger',
              okTitle: 'Delete',
              titleClass: 'h2 m-0',
              footerClass: 'p-4',
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              await this.resetAllAIToggles()
              this.autoResponsesConfig.author = null
              this.saveAutoResponsesConfig()
            }
            this.authorKey++
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        if (this.validateAuthorSignature(author)) {
          this.autoResponsesConfig.author = author
        } else {
          this.autoResponsesConfig.author = author.replace(/[-]/g, '')
        }
        this.saveAutoResponsesConfig()
      }
    },
    validateAuthorSignature(author) {
      const regex = new RegExp(/[-]/g)
      return !regex.test(author)
    },
    async resetAllAIToggles() {
      for (let x = 1; x <= 4; x++) {
        let ratingConfig = this.autoResponsesConfig[`rating${x}`]
        if (ratingConfig) {
          this.autoResponsesConfig[`rating${x}`].aiResponse = false
        }
      }
    },
    async saveAutoResponsesConfig() {
      try {
        await this.updateAutoResponsesConfig(this.autoResponsesConfig)
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Failed to update auto response settings',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
  },
}
</script>
