<template>
  <multiselect
    placeholder="Type to search"
    selectLabel
    :hideSelected="false"
    :close-on-select="false"
    v-model="ratings"
    :multiple="true"
    @input="updateRatings"
    :searchable="false"
    :showLabels="false"
    :limitText="(count) => `+${count} more`"
    :limit="limit"
    :options="surveyRatings"
    :custom-label="ratingLabel"
    track-by="score"
  >
    <template slot="placeholder">
      <span class="text-secondary"> <i class="far fa-smile"></i> {{ placeholder }} </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span class="text-capitalize">{{ props.option.emoti }} {{ props.option.title }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import { surveyRatings } from '@/lib/rating'
import Multiselect from 'vue-multiselect'

export default {
  name: 'RatingFilter',
  props: {
    selections: {
      type: Array,
    },
    limit: {
      type: Number,
      default: 1,
    },
    placeholder: {
      type: String,
      default: 'All ratings',
    },
  },
  components: {
    Multiselect,
  },
  data: () => ({
    surveyRatings: surveyRatings,
    ratings: [],
  }),
  mounted() {
    if (this.selections && this.selections.length) {
      this.ratings = this.surveyRatings.filter((rating) =>
        this.selections.find((s) => s === rating.score)
      )
    }
  },
  methods: {
    updateRatings(ratings) {
      this.$emit('updateRatings', ratings)
    },
    ratingLabel({ emoti, title }) {
      return `${emoti} ${title}`
    },
  },
}
</script>

<style lang="scss">
.multiselect__tag span {
  text-transform: capitalize;
}
</style>
