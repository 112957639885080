import Vue from 'vue'

export default {
  list({ limit, skip, filters }) {
    return Vue.http.post('app-services/v2/call-events/list', { limit, skip, filters })
  },
  summary({ filters }) {
    return Vue.http.post('app-services/v2/call-events/summary', { filters })
  },
}
