<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card" v-for="(reward, index) in rewards" :key="reward.title">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <button class="btn btn-rounded-circle btn-white text-primary">
                  <i class="fe fe-award"></i>
                </button>
              </div>
              <div class="col ml-n2">
                <h4 class="card-title mb-1 name">
                  <a href="#!">{{ reward.title }}</a>
                </h4>
                <p class="card-text small mb-1 text-capitalize">
                  {{ reward.cost }} {{ pluralize(pointLabel) }}
                </p>
              </div>
              <div class="col-auto">
                <a
                  href="javascript:;"
                  @click="removeRewardOption(index)"
                  class="btn btn-sm btn-white d-none d-md-inline-block"
                  >Remove</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="rewards.length < 4">
      <div class="card-header">Add Reward</div>
      <div class="card-body">
        <div class="row">
          <div class="form-group col-12 col-md-5">
            <label>Title</label>
            <input
              v-model="newReward.title"
              placeholder="Free meal on us"
              class="form-control form-control-prepended"
              type="text"
            />
          </div>
          <div class="form-group col-12 col-md-4 text-capitalize">
            <label>{{ pluralize(pointLabel) }} required</label>
            <input
              v-model="newReward.cost"
              placeholder="100"
              class="form-control form-control-prepended"
              type="number"
            />
          </div>
          <div class="form-group col-12 col-md-3">
            <label class="clearfix">&nbsp;</label>
            <button
              class="btn btn-primary btn-secondary col-12"
              type="button"
              @click="addRewardOption"
            >
              <i class="fe fe-plus"></i> Add Reward
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-light" role="alert">
        You can offer up to four rewards. To add a different reward, please remove one.
      </div>
    </div>
  </div>
</template>

<script>
const pluralize = require('pluralize')
export default {
  name: 'CompanySettingsLoyaltyRewards',
  props: ['rewards', 'pointLabel'],
  data: () => ({
    newReward: {
      title: '',
      cost: undefined,
    },
  }),
  methods: {
    pluralize: pluralize,
    addRewardOption() {
      if (!this.newReward.title || !this.newReward.cost)
        return this.$notify({
          title: 'Missing reward fields',
          text: `Double check your reward info.`,
          type: 'error',
        })
      this.$emit('addRewardOption', this.newReward)
      this.newReward = { title: '', cost: undefined }
    },
    removeRewardOption(index) {
      this.$emit('removeRewardOption', index)
    },
  },
}
</script>

<style scoped lang="scss"></style>
