<template>
  <div class="card p-4">
    <div class="row">
      <!-- logo -->
      <div class="col-1 text-center">
        <img
          :alt="source"
          class="avatar-img avatar-sm rounded-circle"
          :src="`/theme/img/platforms/${source}.svg`"
        />
      </div>

      <!-- name, stars, location -->
      <div class="col">
        <div>
          <span class="mr-2">{{ authorName }} </span>
          <StarRating :rating="rating" v-if="rating" />
          <img
            :src="`/img/ratings/${populatedFeedback.survey.rating}.png`"
            :alt="`rating ${populatedFeedback.survey.rating} emoji`"
            contain
            height="18"
            class="mt--2"
            v-else-if="populatedFeedback.survey"
          />
        </div>
        <div class="text-muted mt-1">
          <span>
            <i class="fas fa-map-marker-alt"></i>
            {{ selectLocationName(location) }}
          </span>
          <span class="ml-3" v-if="populatedFeedback.survey">
            <i class="fas fa-tags fa-sm"></i>
            {{ populatedFeedback.survey.source }}
          </span>
        </div>
      </div>

      <!-- datetime stamp -->
      <div class="col-3 text-muted text-right mr-2">
        <i class="fas fa-clock"></i>
        {{ timestamp }}
      </div>
    </div>

    <div class="row">
      <div class="col-1"></div>

      <div class="col">
        <!-- feedback -->
        <div class="my-3">
          <text-highlight :queries="highlightQuery" :highlightStyle="highlightStyles">
            {{ feedback }}
          </text-highlight>
        </div>

        <!-- badges -->
        <span v-for="(badge, index) in badgeList" :key="index" class="h2 mr-2">
          <div
            class="badge mb-1"
            :class="badgeColor(badge.sentiment)"
            :style="{ color: badge.sentiment === 'POSITIVE' ? 'green' : 'red' }"
          >
            <span v-if="badge.category.path">{{ selectCategoryName(badge.category.path) }}: </span>
            {{ badge.category.name }}
          </div>
        </span>
      </div>

      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
import StarRating from '@/components/Modules/Review/StarRating'
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')
const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'DetailsListCard',
  props: {
    populatedFeedback: {
      type: Object,
      required: true,
    },
  },
  components: {
    StarRating,
  },
  data: () => ({}),
  computed: {
    ...InsightsModule.mapGetters(['selectCategory', 'selectCategoryName']),
    ...InsightsDetailsModule.mapGetters([
      'selectClassificationLocation',
      'selectShowHighlight',
      'selectDetailFilters',
    ]),
    ...LocationModule.mapGetters(['selectLocationName']),
    badgeList() {
      return this.populatedFeedback.classifications.map((c) => ({
        _id: c._id,
        category: this.selectCategory(c.category),
        sentiment: c.sentiment,
      }))
    },
    source() {
      const source = this.populatedFeedback.externalReview
        ? this.populatedFeedback.externalReview.network
        : this.populatedFeedback.survey.source
      return ['yelp', 'google', 'facebook', 'opentable', 'doordash'].includes(source)
        ? source
        : 'ovation-logo'
    },
    authorName() {
      return this.populatedFeedback.externalReview
        ? this.populatedFeedback.externalReview.author.name
        : this.populatedFeedback.customer
    },
    rating() {
      return this.populatedFeedback.externalReview?.rating
    },
    location() {
      const location = this.populatedFeedback.externalReview
        ? this.populatedFeedback.externalReview.location ||
          this.selectClassificationLocation(this.populatedFeedback.classifications[0]?._id)
        : this.populatedFeedback.survey?.location
      return location
    },
    timestamp() {
      const timestamp = this.populatedFeedback.externalReview
        ? this.$moment(this.populatedFeedback.externalReview.timestamp).format('LL')
        : this.$moment(this.populatedFeedback.survey.created).format('LLL')
      return timestamp
    },
    highlightQuery() {
      if (this.selectShowHighlight) {
        const classificationToHighlight = this.populatedFeedback.classifications.find(
          (c) => c.category === this.selectShowHighlight
        )
        return classificationToHighlight?.text || ''
      } else {
        return ''
      }
    },
    highlightStyles() {
      const sentiment = this.selectDetailFilters.sentiment
      if (!sentiment) return
      return {
        padding: '0px',
        'background-color': 'white',
        'border-bottom': `2px solid ${sentiment === 'POSITIVE' ? '#008000' : '#ff0000'}`,
        'border-radius': '0px',
      }
    },
    feedback() {
      return this.populatedFeedback.externalReview
        ? this.populatedFeedback.externalReview.text
        : this.populatedFeedback.survey?.feedback
    },
  },
  methods: {
    badgeColor(sentiment) {
      return sentiment === 'POSITIVE' ? { 'bg-light-green': true } : { 'bg-light-red': true }
    },
  },
}
</script>

<style scoped>
.bg-light-green {
  background: #ccf7e5;
}
.bg-light-red {
  background: #ffcccb;
}
</style>
