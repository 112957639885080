import Vue from 'vue'

export default {
  list({ companyId, companyIds, archived }) {
    return Vue.http.post('tag/list', {
      companyId,
      archived,
      companyIds,
    })
  },
  create({ companyId, name }) {
    return Vue.http.post('tag', { companyId, name })
  },
  downloadMaterials({ userId, tagId, companyId }) {
    return Vue.http.post(`tag/${tagId}/materials`, { companyId, userId })
  },
  activateAction({ tagId, action, campaign }) {
    return Vue.http.post(`tag/${tagId}/action`, { action, campaign })
  },
  update({ tagId, company, fields }) {
    return Vue.http.patch(`tag/${tagId}`, { company, ...fields })
  },
  archive(tagId) {
    return Vue.http.delete(`tag/${tagId}`)
  },
}
