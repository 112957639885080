<template>
  <div class="d-flex align-items-center tag-filter px-2">
    <!-- tags/input -->
    <div class="d-flex align-items-center flex-wrap">
      <div v-for="(tag, index) in tags" :key="'tag' + index" class="tag-input__tag mr-1">
        <span class="px-2">
          {{ tag }}
        </span>
        <span @click="removeTag(index)" class="px-2 text-dark" style="border-left: 1px solid white">
          <i class="fa-solid fa-xmark fa-xs mb--2"></i>
        </span>
      </div>
      <input
        type="text"
        :placeholder="placeholder"
        class="tag-input__text ml-1"
        v-model="text"
        @keydown="addTagKeypress"
        @keydown.delete="removeLastTag"
        style="min-width: 300px"
      />
    </div>

    <!-- actions -->
    <div class="d-flex align-items-center">
      <i
        id="clear-btn"
        class="fa-light fa-circle-xmark text-primary"
        @click="removeAllTags"
        v-if="tags.length"
      ></i>
      <button
        id="apply-btn"
        class="btn btn-sm btn-primary ml-2"
        style="outline: none"
        @click="emitTags"
        :disabled="!tags.length && !text && !deletion"
      >
        Apply
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagFilter',
  props: {
    placeholder: {
      type: String,
      default: 'Enter a tag',
    },
    init: {
      type: Array,
    },
  },
  data: () => ({
    tags: [],
    text: '',
    deletion: false,
  }),
  mounted() {
    if (this.init) {
      this.tags = [...this.init]
    }
  },
  methods: {
    addTagKeypress(event) {
      if (event.code === 'Comma' || event.code === 'Enter') {
        event.preventDefault()
        let val = this.text.trim()

        if (val.length > 0) {
          this.tags.push(val)
          this.text = ''
        }
      }
    },
    addTag() {
      this.tags.push(this.text)
      this.text = ''
    },
    removeTag(index) {
      this.tags.splice(index, 1)
      this.deletion = true
    },
    removeAllTags() {
      this.tags = []
      this.deletion = true
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1)
      }
    },
    emitTags() {
      if (this.text.length) {
        this.addTag()
      }
      this.$emit('apply', this.tags)
      this.deletion = false
    },
  },
}
</script>

<style scoped>
::placeholder {
  color: #6e84a3;
}
::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #6e84a3;
}
.tag-filter {
  border: 1px solid #d2ddec;
  background-color: white;
  border-radius: 5px;
  height: 40.5px;
}
.tag-input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10;
}
.tag-input__tag {
  background-color: #edf2f9;
  border-radius: 2px;
}
.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}
.tag-input__text {
  border: none;
  outline: none;
  background: none;
}
#apply-btn:focus,
#apply-btn:active {
  outline: none;
  box-shadow: none;
}
#clear-btn:hover {
  background: var(--light) !important;
  border-radius: 50%;
}
</style>
