import IntegrationService from '@/services/IntegrationService'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async addIntegration({ dispatch }, payload) {
      let returnData = {}
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: `ADD_INTEGRATION_${payload.type}` },
        { root: true }
      )
      try {
        if (payload.integrationProviderId) {
          payload.externalLocationName = payload.credentials.externalLocationName
          payload.externalLocationId = payload.credentials.externalLocationId
          payload.externalCompanyId = payload.credentials.externalCompanyId
          payload.credentials = payload.credentials
          const p = {
            ...payload,
            ...mapIntegrationPayload(payload?.credentials),
          }
          returnData.data = await IntegrationService.createIntegration(p)
          returnData.success = true
        } else {
          returnData.data = await IntegrationService.create(payload)
          returnData.success = true
        }
      } catch (error) {
        console.log(error)
        returnData.data = error.body.message
        returnData.success = false
      }
      await dispatch('removeFromLoader', loadingUuid, { root: true })
      return returnData
    },
    async getIntegration({ dispatch }, integrationId) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: `GET_INTEGRATION_${integrationId}` },
        { root: true }
      )

      const result = await IntegrationService.getIntegration(integrationId)

      await dispatch('removeFromLoader', loadingUuid, { root: true })
      return result
    },
    async updateIntegration({ commit }, payload) {
      let response = null
      try {
        if (payload.fields.integrationProvider) {
          response = await IntegrationService.updateIntegration({
            integrationId: payload.integrationId,
            payload: {
              externalLocationId: payload?.fields?.credentials?.externalLocationId,
              externalLocationName: payload?.fields?.credentials?.externalLocationName,
              externalCompanyId: payload?.fields?.credentials?.externalCompanyId,
              credentials: payload?.fields?.credentials,
              oauth: payload?.fields?.oauth,
              ...mapIntegrationPayload({
                ...payload.fields,
                ...(payload.updatedFields || {}),
              }),
              // order matters here
              preventSurvey: payload?.fields?.preventSurvey,
            },
          })
        } else {
          response = await IntegrationService.update(payload)
        }
      } catch (error) {
        response = error
      }
      return response
    },
    async updateIntegrationOauth({}, payload) {
      let response = null
      try {
        response = await IntegrationService.updateIntegrationOauth({
          integrationProviderName: payload.integrationProviderName,
          oauth: payload.oauth,
        })
      } catch (error) {
        response = error
      }
      return response
    },
    async getChowNowLocations({ rootGetters }) {
      let response = await IntegrationService.getChowNowLocations({
        companyId: rootGetters['company/activeCompany']._id,
      })

      return response.data.data.locations
    },
    async getSquareLocations({ rootGetters }, oAuthName) {
      let response = await IntegrationService.getSquareLocations({
        companyId: rootGetters['company/activeCompany']._id,
        oAuthName,
      })

      return response.data.data.locations
    },
    async getQuLocations({ rootGetters }) {
      let response = await IntegrationService.getQuLocations({
        companyId: rootGetters['company/activeCompany']._id,
      })

      return response.data.data.locations
    },
  },
}

const mapIntegrationPayload = (target) => {
  const keys = Object.keys(target)
  const results = keys.reduce((acc, curr) => {
    let key = curr
    let value = target[key]
    if (key.includes('.')) {
      const j = key.split('.')
      value = j.reverse().reduce(
        (acc, curr, i) => ({
          [curr]: acc,
        }),
        target[key]
      )
      return {
        ...acc,
        ...value,
      }
    }
    return {
      ...acc,
      [key]: target[key],
    }
  }, {})
  return results
}
