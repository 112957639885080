import Vue from 'vue'

export default {
  fetchCampaigns(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'data-core', action: 'fetch-campaigns' },
      payload
    )
  },
  fetchCompanies(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'data-core', action: 'fetch-companies' },
      payload
    )
  },
  fetchIntegrations(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'data-core', action: 'fetch-integrations' },
      payload
    )
  },
  fetchLocations(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'data-core', action: 'fetch-locations' },
      payload
    )
  },
  fetchPromos(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'data-core', action: 'fetch-promos' },
      payload
    )
  },
  fetchUsers(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'data-core', action: 'fetch-users' },
      payload
    )
  },
}
