<template>
  <h1>Integrations</h1>
</template>

<script>
import CompanySettingsBase from './CompanySettingsBase'

export default {
  name: 'CompanySettingsIntegrations',
  extends: CompanySettingsBase,
}
</script>

<style scoped lang="scss"></style>
