<template>
  <div class="h-100">
    <div
      id="timelineList"
      :style="{
        height: timelineHeight + 'px',
        overflow: 'scroll',
        'overscroll-behavior-y': 'contain',
      }"
    >
      <!-- loader -->
      <div v-if="selectActiveConversationLoading" class="h-100">
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-spinner variant="primary" />
        </div>
      </div>

      <div v-else>
        <div v-if="selectTimelineEvents">
          <div v-if="selectTimelineLoading && !selectAllPreviousEventsLoaded">
            <div class="d-flex justify-content-center align-items-center mt-4">
              <b-spinner small variant="primary" />
            </div>
          </div>
          <!-- list div -->
          <Timeline :conversationId="selectActiveConversationId" />
        </div>

        <div v-else class="d-flex justify-content-center align-items-center h-100">
          <div class="text-center">
            <i class="fas fa-inbox" style="font-size: 32px"></i>
            <div>No messages</div>
          </div>
        </div>
      </div>
    </div>
    <!-- messaging component -->
    <MessageBuilder id="messageBuilder" class="message-builder" />
  </div>
</template>

<script>
import Timeline from '../Components/Timeline'
import MessageBuilder from '../Components/MessageBuilder'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const TimelineModuleV2 = createNamespacedHelpers('timelineV2')

export default {
  name: 'TimelineWrapper',
  components: {
    Timeline,
    MessageBuilder,
  },
  props: {
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timelineHeight: 0,
    resizeObserver: null,
    bodyResizeObserver: null,
    debouncedResizeHandler: null,
  }),
  mounted() {
    this.debouncedResizeHandler = debounce(this.resizeHandler, 200)
    this.resizeObserver = new ResizeObserver(this.debouncedResizeHandler)
    this.resizeObserver.observe(document.getElementById('messageBuilder'))
    this.bodyResizeObserver = new ResizeObserver(this.debouncedResizeHandler)
    this.bodyResizeObserver.observe(document.querySelector('body'))
    $('#timelineList')[0].addEventListener('scroll', this.evaluateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.evaluateScroll)
    this.resizeObserver.disconnect()
    this.bodyResizeObserver.disconnect()
  },
  computed: {
    ...ConversationModuleV2.mapGetters([
      'selectActiveConversationId',
      'selectActiveConversationLoading',
      'selectShowDetailsPane',
      'selectIsMobileView',
    ]),
    ...TimelineModuleV2.mapGetters([
      'selectTimelineEvents',
      'selectTimelineLoading',
      'selectAllPreviousEventsLoaded',
    ]),
  },
  methods: {
    ...TimelineModuleV2.mapActions(['fetchTimeline']),
    async evaluateScroll(event) {
      var element = event.target
      if (element.scrollTop <= 300) {
        if (!this.selectTimelineLoading && !this.selectAllPreviousEventsLoaded) {
          const oldScrollHeight = element.scrollHeight
          const bSpinnerHeight = 40
          await this.fetchTimeline()
          if (element.scrollTop === 0) {
            element.scrollTop = element.scrollHeight - oldScrollHeight - bSpinnerHeight
          }
        }
      }
    },
    resizeHandler() {
      this.setTimelineHeight()
    },
    setTimelineHeight() {
      const windowHeight = this.$route.name?.includes('winback')
        ? document.querySelector('body').clientHeight
        : window.innerHeight
      this.timelineHeight =
        windowHeight - $('#timelineHeader')[0].offsetHeight - $('#messageBuilder')[0].offsetHeight
      const el = document.querySelector('#timelineList')
      if (el) {
        const listHeight = el.getBoundingClientRect().height
        const diff = el.scrollHeight - el.scrollTop
        if (diff <= listHeight) {
          setTimeout(() => {
            el.scrollTop = el.scrollHeight
          }, 1)
        }
      }
    },
  },
}
</script>
