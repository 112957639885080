import CallToTextService from '@/services/CallToTextService'
import moment from 'moment-timezone'
import { isEqual } from 'lodash-es'

const COLORS = [
  '#D2DDEC',
  '#9BBCEA',
  '#639CE7',
  '#2C7BE5',
  '#2666BC',
  '#1F5192',
  '#193B69',
  '#12263F',
]

export default {
  namespaced: true,
  state: {
    calls: [],
    displayedCalls: [],
    filters: {},
    limit: 25,
    skip: 0,
    allCallsDisplayed: false,
    isLoadingData: false,
    isLoadingMore: false,
    totalCallsReceived: 0,
    totalCallsReceivedData: [],
    granularityDates: [],
    actionBreakdownData: [],
    colors: COLORS,
  },
  getters: {
    selectFilters: (state) => state.filters,
    selectLimit: (state) => state.limit,
    selectSkip: (state) => state.skip,
    selectIsLoadingData: (state) => state.isLoadingData,
    selectIsLoadingMore: (state) => state.isLoadingMore,
    selectTotalCallsReceived: (state) => state.totalCallsReceived,
    selectTotalCallsReceivedData: (state) => state.totalCallsReceivedData,
    selectGranularityDates: (state) => state.granularityDates,
    selectActionBreakdownData: (state) => state.actionBreakdownData,
    selectDisplayedCalls: (state) => state.displayedCalls,
    selectAllCallsDisplayed: (state) => state.allCallsDisplayed,
    selectColors: (state) => state.colors,
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_SKIP(state, skip) {
      state.skip = skip
    },
    SET_IS_LOADING_DATA(state, status) {
      state.isLoadingData = status
    },
    SET_IS_LOADING_MORE(state, status) {
      state.isLoadingMore = status
    },
    SET_TOTAL_CALLS_RECEIVED_DATA(state, data) {
      state.totalCallsReceivedData = data
    },
    SET_TOTAL_CALLS_RECEIVED(state, data) {
      state.totalCallsReceived = data
    },
    SET_GRANULARITY_DATES(state, dates) {
      state.granularityDates = dates
    },
    SET_ACTION_BREAKDOWN_DATA(state, data) {
      state.actionBreakdownData = data
    },
    SET_DISPLAYED_CALLS(state, newCalls) {
      state.displayedCalls = [...state.displayedCalls, ...newCalls]
    },
    RESET_CALL_DATA(state) {
      state.totalCallsReceived = 0
      state.totalCallsReceivedData = []
      state.displayedCalls = []
      state.skip = 0
      state.allCallsDisplayed = false
    },
    SET_ALL_CALLS_DISPLAYED(state, status) {
      state.allCallsDisplayed = status
    },
  },
  actions: {
    async applyFilters({ commit, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return
      commit('SET_FILTERS', filters)
    },

    async formatFilters({ getters, rootState, rootGetters }, doPopulateCustomer) {
      const { activeCompany } = rootState.company
      const filters = getters.selectFilters
      const payloadFilters = {
        companyIds: [activeCompany._id],
        createdAtRange: filters.dateRange || [
          moment().startOf('d').subtract(30, 'd').utc().format(),
          moment().endOf('day').utc().format(),
        ],
      }
      if (filters.locations) {
        payloadFilters.locationIds = Array.isArray(filters.locations)
          ? filters.locations
          : [filters.locations]
      } else {
        payloadFilters.locationIds = rootGetters['location/selectActiveLocations']
          .filter((l) => !l.hideFromReports)
          .map((l) => l._id)
      }
      return {
        filters: payloadFilters,
        skip: getters.selectSkip,
        limit: doPopulateCustomer ? getters.selectLimit : 0,
      }
    },

    async fetchCallHistory({ dispatch, commit, getters }) {
      if (getters.selectIsLoadingData) return
      commit('SET_IS_LOADING_DATA', true)
      commit('RESET_CALL_DATA')
      const [formattedChartFilters, formattedTableFilters] = await Promise.all([
        dispatch('formatFilters'),
        dispatch('formatFilters', true),
      ])

      const [chartResponse, tableResponse] = await Promise.all([
        CallToTextService.summary(formattedChartFilters),
        CallToTextService.list(formattedTableFilters),
      ])

      await dispatch('processCallData', chartResponse.body.data)
      commit('SET_DISPLAYED_CALLS', tableResponse.body.callEvents)
      commit('SET_SKIP', getters.selectLimit)
      commit('SET_IS_LOADING_DATA', false)
    },

    async processCallData({ commit, dispatch, getters }, data) {
      const { actionBreakdown, callsReceivedByDate, granularityRange, totalCount } = data
      const actions = Object.keys(actionBreakdown).sort()
      commit('SET_GRANULARITY_DATES', granularityRange)
      commit('SET_TOTAL_CALLS_RECEIVED', totalCount)
      commit(
        'SET_TOTAL_CALLS_RECEIVED_DATA',
        actions.map((k) => ({
          name: k,
          data: granularityRange.map((g) => callsReceivedByDate[g][k] ?? 0),
        }))
      )
      commit(
        'SET_ACTION_BREAKDOWN_DATA',
        actions.map((action) => ({
          name: action,
          count: actionBreakdown[action],
          percentage: Math.round((actionBreakdown[action] / totalCount) * 100),
          color: getters.selectColors[actions.indexOf(action)],
        }))
      )
    },

    async loadMoreDisplayedCalls({ commit, getters, dispatch }) {
      commit('SET_IS_LOADING_MORE', true)
      const { selectSkip, selectLimit } = getters

      const formattedFilters = await dispatch('formatFilters', true)
      const response = await CallToTextService.list(formattedFilters)
      const callEvents = response.body.callEvents

      commit('SET_DISPLAYED_CALLS', callEvents)
      commit('SET_SKIP', selectSkip + selectLimit)
      if (callEvents?.length < selectLimit) {
        commit('SET_ALL_CALLS_DISPLAYED', true)
      }
      commit('SET_IS_LOADING_MORE', false)
    },
  },
}
