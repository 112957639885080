export const subscriptions = [
  {
    title: 'Reputation',
    price: '49',
    features: [
      {
        name: 'Review Monitoring',
        check: true,
      },
      {
        name: 'Surveys',
        check: true,
      },
      {
        name: 'Feedback Alerts',
        check: true,
      },
      {
        name: 'Winback',
        check: true,
      },
    ],
    button: {
      text: 'Get Reputation',
    },
    handler(context) {
      console.log('Get reputation!', context)
    },
  },
  {
    title: 'Messaging',
    price: '149',
    features: [
      {
        name: '+ Reputation',
        check: true,
      },
      {
        name: 'Message',
        check: true,
      },
      {
        name: 'Smart Campaigns',
        check: true,
      },
      {
        name: 'Text Messages',
        text: 1000,
      },
    ],
    button: {
      text: 'Get Messaging',
      primary: true,
    },
    handler(context) {
      console.log('Get messaging!', context)
    },
  },
  {
    title: 'Messaging Pro',
    price: '199',
    features: [
      {
        name: '+ Messaging',
        check: true,
      },
      {
        name: 'Picture Messages',
        check: true,
      },
      {
        name: 'Mailchimp Integration',
        check: true,
      },
      {
        name: 'Text Messages',
        text: 2000,
      },
    ],
    button: {
      text: 'Get Messaging Pro',
    },
    handler(context) {
      console.log('Get messaging pro!', context)
    },
  },
]

export const subscriptionHelp = [
  {
    title: 'Need some help deciding?',
    description:
      "We can help you decide what plan fits your company based on what we've seen be successful for others. Let's talk!",
    button: 'Contact us',
  },
  {
    title: 'Want a custom plan?',
    description:
      'We work with companies big and small to build a plan that makes sense for them, and fits in their budget.',
    button: 'Build it',
  },
]

export const industries = [
  'Fast-casual restaurant',
  'Quick-Serve Restaurant',
  'Full-Service Restaurant',
  'Bar',
  'Nightclub',
  'Café',
  'Big-Box Retail',
  'Small-Footprint Retail',
  'Hair Salon',
  'Entertainment',
  'Law Firm',
  'Dental Practice',
  'Other',
]
