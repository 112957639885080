<template>
  <div class="m-2">
    <QuestionCreationModal ref="questionCreationModal" :key="refreshKey" />
    <QuestionsOffState v-if="!selectQuestionsExist" />
    <div v-else>
      <QuestionListFilters :key="filterRefreshKey" />
      <QuestionCardList v-if="!queryPending" />
      <!-- skeleton loader -->
      <div v-if="queryPending || selectQuestionsExist === null">
        <div class="mb-4 mx-auto" style="max-width: 900px" v-for="index in 4" :key="index">
          <b-skeleton height="150px" />
        </div>
      </div>
      <div class="text-center" v-if="!questions.length">
        No questions match the selected filters.
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsOffState from './OffStates/QuestionsOffState'
import QuestionCreationModal from '@/components/Modals/QuestionCreationModal'
import QuestionCardList from './QuestionCardList'
import QuestionListFilters from './QuestionListFilters'
import { createNamespacedHelpers } from 'vuex'

const CustomQuestionModule = createNamespacedHelpers('customQuestion')

// @vuese
// @group CustomQuestions
// This Component displays question data on the Custom Questions Page
export default {
  name: 'QuestionsView',
  components: {
    QuestionsOffState,
    QuestionCreationModal,
    QuestionCardList,
    QuestionListFilters,
  },
  data: () => ({
    showCreation: false,
  }),
  computed: {
    ...CustomQuestionModule.mapState([
      'refreshKey',
      'filterRefreshKey',
      'queryPending',
      'questions',
    ]),
    ...CustomQuestionModule.mapGetters(['selectQuestionsExist']),
  },
}
</script>
