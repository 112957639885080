import Vue from 'vue'
import Config from '@/config'
import axios from 'axios'

const { boldBI } = Config

export default {
  fetchReportDashboards(token) {
    return axios.get(boldBI + '/api/site/site1/v5.0/dashboards', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  fetchReportDashboardDrafts(token) {
    return axios.get(boldBI + '/api/site/site1/v5.0/dashboards/drafts', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  fetchToken() {
    return Vue.http.get('app-services/v2/boldbi/token')
  },
  deleteDashboard(dashboardId) {
    return Vue.http.delete(`app-services/v2/boldbi/dashboard/${dashboardId}`)
  },
}
