<template>
  <MultiSelect
    id="category"
    :show-labels="false"
    closeOnSelect
    placeholder="Other"
    open-direction="bottom"
    @input="updateCategory"
    v-model="category"
    :options="categories"
    label="name"
    track-by="_id"
  >
    <template slot="option" slot-scope="props">
      <div>
        <span>{{ props.option.name }}</span>
      </div>
    </template>
  </MultiSelect>
</template>

<script>
import MultiSelect from '@/components/Common/MultiSelect'

export default {
  name: 'CategoryFilterWithCaret',
  components: {
    MultiSelect,
  },
  props: {
    selected: {
      type: String,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    category: null,
  }),
  mounted() {
    if (this.selected) {
      this.category = this.categories.find((c) => c._id === this.selected)
    }
  },
  methods: {
    updateCategory(selection) {
      this.$emit('updateCategory', selection)
    },
  },
}
</script>
