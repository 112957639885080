import { firstName } from './name'

export const templatize = ({ customer, user = {}, company = {}, body, location = {} }) => {
  // Replace customer first name
  body = body.replace(/<Customer First Name>/g, customer.name ? firstName(customer.name) : '')

  // Replace user first name
  body = body.replace(/<Author First Name>/g, user.firstName ? firstName(user.firstName) : '')

  // Replace company name
  body = body.replace(/<Company Name>/g, company.name)

  // Replace review link
  body = body.replace(/<Review Link>/g, '<span class="text-primary">ovtn.io/review</span>')

  // Replace survey link
  body = body.replace(/<Survey Link>/g, '<span class="text-primary">ovtn.io/survey</span>')

  // Replace order link
  body = body.replace(/<Order Link>/g, '<span class="text-primary">ovtn.io/order</span>')

  // Replace location name
  body = body.replace(/<Location Name>/g, location.friendlyName || location.name || '')

  // Replace location city
  body = body.replace(/<Location City>/g, location.city || '')

  return body
}
