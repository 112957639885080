<template>
  <div class="message" v-if="order && integration && location">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-2">
        <Avatar :img="integrationDetails.img" size="xs" class="timeline-integration-avatar" />
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div v-show="orderNumber" class="order-number">{{ orderNumber }}</div>
          <div class="comment-text" role="alert">
            <span>
              Spent
              <strong>{{ orderTotal | currency }}</strong>
              <span v-if="items">
                on
                <span>{{ items }}</span>
              </span>
              via {{ integrationDetails.name }}
            </span>
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Order</span>
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">
                  {{ created | moment('LT') }}
                  {{ timezone }}
                  &#8226;
                  {{ location.friendlyName || location.city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
import { getIntegrationDetails, availableIntegrations } from '@/lib/modules/integration'
import { createNamespacedHelpers } from 'vuex'
const IntegrationModule = createNamespacedHelpers('integration')
const IntegrationProviderModule = createNamespacedHelpers('integrationProvider')

export default {
  name: 'OrderEvent',
  props: ['created', 'integration', 'location', 'order', 'timezone', 'eventId', 'companyId'],
  components: {
    Avatar,
  },
  data: () => ({
    integrationProviderId: null,
  }),
  computed: {
    ...IntegrationProviderModule.mapGetters(['selectIntegrationProviderById']),
    integrationDetails() {
      let name = null
      if (
        this.integrationProviderId &&
        this.selectIntegrationProviderById(this.integrationProviderId)
      ) {
        let { displayName, logo } = this.selectIntegrationProviderById(this.integrationProviderId)
        return { name: displayName, img: logo }
      } else {
        name = this.integration?.provider
      }

      return getIntegrationDetails(name) || {}
    },
    orderNumber() {
      const { posOrderNumber, orderProvider } = this.order
      return posOrderNumber && posOrderNumber.length < 20
        ? `${orderProvider ? `${orderProvider} ` : ''}ORDER #${posOrderNumber}`
        : ''
    },
    items() {
      const items = this.order.items
      if (!items.length) return null
      return items.reduce((itemString, item, index) => {
        if (itemString) {
          itemString += ', '
          if (index === items.length - 1) itemString += 'and '
        }
        if (item.quantity > 1) itemString += `(${item.quantity}) `
        const itemOptions = item?.options?.length ? this.joinOrderItemOptions(item?.options) : null
        return itemString + item.name + `${itemOptions ? itemOptions : ''}`
      }, '')
    },
    orderTotal() {
      const total =
        '$numberDecimal' in this.order.total ? this.order.total.$numberDecimal : this.order.total

      return this.integration.provider === availableIntegrations.skipTheDishes().provider
        ? total / 100
        : total
    },
  },
  methods: {
    ...IntegrationModule.mapActions(['getIntegration']),

    joinOrderItemOptions(options = []) {
      if (!options?.length) {
        return null
      }
      if (typeof options[0] === 'object') {
        options = options.map((opt) => opt.name)
      }
      if (!options?.length) {
        return null
      }
      // handle Fajita Pete Company's hungerRush integration only
      const fajitaPeteCompanyId = '61099e7a687c960009729cc0'
      if (this.integration?.provider !== 'hungerRush' && this.companyId !== fajitaPeteCompanyId) {
        return null
      }
      let optionsString = ''
      if (options.length > 1) {
        const lastItem = options[options.length - 1]
        const lastItemIndex = options.length - 1
        options = options.slice(0, lastItemIndex)
        optionsString = ` (${options?.join(', ')} & ${lastItem}) `
      } else {
        optionsString = ` (${options?.join(' ')}) `
      }
      return optionsString
    },
  },
  async mounted() {
    try {
      if (!Object.keys(this.integrationDetails).length) {
        const response = await this.getIntegration(this.integration._id)
        this.integrationProviderId = response.body.data.integration.integrationProvider
      }
    } catch (error) {
      console.log(`Error: No Integration details given as prop from analytic event ${this.eventId}`)
    }
  },
}
</script>

<style lang="scss" scoped>
.order-number {
  font-weight: bold;
}
</style>
