<template>
  <div class="row">
    <div v-if="onlineUser.avatar" class="col-auto">
      <div class="avatar">
        <img :src="onlineUser.avatar" class="avatar-img rounded-circle" />
      </div>
    </div>
    <div class="col-auto">
      <div class="form-group">
        <label class="mb-1">Avatar</label>
        <small class="form-text text-muted"
          >Please use an avatar no larger than 600px * 600px.</small
        >
        <FileUploader
          @uploaded="avatarUploaded"
          :button-text="onlineUser.avatar ? 'Change avatar' : 'Upload avatar'"
          button-class="btn-primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import FileUploader from '@/components/MainContent/FileUploader'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'AccountAvatarForm',
  components: {
    FileUploader,
  },
  computed: {
    ...mapState(['onlineUser']),
  },
  methods: {
    ...mapActions(['getOnlineUser']),
    async avatarUploaded(fileUrl) {
      await UserService.update({
        userId: this.onlineUser._id,
        fields: {
          avatar: fileUrl,
        },
      })

      await this.getOnlineUser()
    },
  },
}
</script>

<style scoped lang="scss">
.avatar {
  width: 6rem;
  height: 6rem;
}
</style>
