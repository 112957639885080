<template>
  <div>
    <div class="row">
      <form class="col-12">
        <div class="form-group mb-4">
          <label for="templateTitle">What's a good title for your template?</label>
          <input
            type="text"
            id="templateTitle"
            v-model="form.title"
            placeholder="Angry Customer"
            class="form-control"
            @input="updateTitle"
            v-b-popover.hover.topleft="'This is for your reference only.'"
            title="Title"
          />
        </div>
        <div class="form-group mb-2">
          <label for="templateTitle">What would you like your message to be?</label>
          <textarea
            v-model="form.body"
            @keyup="updateBody"
            ref="body"
            class="form-control sms text-message-orig"
            id="txt"
            maxlength="1000"
            name="content"
            :placeholder="`Hi <Customer First Name>, I'm truly sorry about your terrible experience...`"
            rows="4"
          ></textarea>
        </div>
        <div class="mb-4 mt-3">
          <h6 class="header-pretitle mt">
            Smart Tags
            <span
              class="fe fe-help-circle"
              v-b-popover.hover.topleft="
                'Add these to your message, and they will be replaced with their real values. For example, <Customer First Name> could turn to Jane if you are sending a message to Jane.'
              "
            ></span>
          </h6>
          <a
            v-for="tag in autoTemplateSmartTags"
            :key="tag.value"
            class="btn btn-white btn-sm mb-2 d-inline-block mr-2"
            href="javascript:;"
            @click="
              insertTagAtCursor(tag.value)
              updateBody()
            "
            >{{ tag.name }}</a
          >
        </div>
        <div>
          <a
            @click="saveTemplate"
            href="javascript:;"
            class="btn btn-block btn-lg btn-primary"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
            >Save</a
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { templatize } from '@/lib/template'
import { createNamespacedHelpers } from 'vuex'

const TemplateModule = createNamespacedHelpers('template')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')
const SmartTemplateTagsModule = createNamespacedHelpers('smartTemplateTags')

export default {
  name: 'CreateAutoTemplateForm',
  props: ['modal'],
  data: () => ({
    isLoading: false,
    form: {
      title: '',
      body: '',
    },
  }),
  mounted() {
    if (this.selectAutoTemplate._id) {
      this.form.title = this.selectAutoTemplate.title
      this.form.body = this.selectAutoTemplate.body
    }
  },
  computed: {
    ...TemplateModule.mapGetters(['selectAutoTemplate']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...UserModule.mapGetters(['selectOnlineUser']),
    ...SmartTemplateTagsModule.mapState(['templateTags']),
    sampleOutput() {
      return templatize({
        body: this.form.body,
        company: this.selectActiveCompany,
        customer: { name: 'Jane Doe' },
        user: this.selectOnlineUser,
      })
    },
    autoTemplateSmartTags() {
      const allowedTags = ['Customer First Name', 'Review Link']
      return this.templateTags.filter((tag) => allowedTags.includes(tag.name))
    },
  },
  methods: {
    ...TemplateModule.mapActions([
      'setAutoTemplate',
      'resetAutoTemplate',
      'createTemplate',
      'updateTemplate',
    ]),
    updateTitle() {
      this.setAutoTemplate({ ...this.selectAutoTemplate, title: this.form.title })
    },
    updateBody() {
      this.setAutoTemplate({ ...this.selectAutoTemplate, body: this.form.body })
      this.$refs.body.focus()
    },
    async saveTemplate() {
      if (this.form.title.length < 3) {
        this.$notify({
          title: 'Failed to create auto template.',
          text: 'Please give your auto template a title.',
          type: 'error',
        })
        return
      }
      this.isLoading = true

      let savedTemplate
      try {
        if (this.selectAutoTemplate._id) {
          savedTemplate = await this.updateTemplate(this.selectAutoTemplate)
        } else {
          savedTemplate = await this.createTemplate({
            ...this.selectAutoTemplate,
            company: this.selectActiveCompany._id,
            type: 'auto',
          })
        }
        this.isLoading = false
        this.resetAutoTemplate()

        this.$notify({
          title: 'Launched!',
          text: `Your template is ready to go.`,
        })
        if (!this.modal) {
          this.$router.push({ name: 'templates' })
        } else {
          this.$emit('saved', savedTemplate)
        }
      } catch (err) {
        console.log(err)
        this.isLoading = false
        this.$notify({
          title: 'Failed to create template.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    insertTagAtCursor(tagValue) {
      const textarea = document.getElementById('txt')
      if (textarea.selectionStart || textarea.selectionStart === 0) {
        var startPosition = textarea.selectionStart
        var endPosition = textarea.selectionEnd
        textarea.value =
          textarea.value.substring(0, startPosition) +
          tagValue +
          textarea.value.substring(endPosition, textarea.value.length)
        textarea.selectionEnd = startPosition + tagValue.length
      } else {
        textarea.value += tagValue
      }
      this.form.body = textarea.value
    },
  },
  watch: {
    'form.body': {
      immediate: true,
      handler() {
        this.form.body = this.form.body.replace(/’/g, "'")
      },
    },
  },
}
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 0px;
}
#templateTitle {
  text-transform: capitalize;
}
</style>
