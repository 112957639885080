<template>
  <div>
    <div v-if="!loading">
      <!-- text summaries -->
      <div class="mb-3" v-if="!wcLoading">
        <div class="row" v-if="isEnabled('AI_SUMMARY')">
          <div class="col mr-3">
            <InsightsTextSummary
              :showButton="true"
              :positiveSummary="selectSummaries.positiveSummary"
              :negativeSummary="selectSummaries.negativeSummary"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3" v-else>
        <div class="col mr-3">
          <b-skeleton height="160px" />
        </div>
      </div>

      <!-- leader cards -->
      <div class="row">
        <div class="col col-12 col-xl-6">
          <TopCategories :sentiment="'POSITIVE'" />
        </div>
        <div class="col col-12 col-xl-6">
          <TopCategories :sentiment="'NEGATIVE'" />
        </div>
      </div>

      <!-- chart cards -->
      <div class="row">
        <div class="col col-12 col-xl-6">
          <TotalMentions @selectedDay="setSelectedDay" />
        </div>
        <div class="col col-12 col-xl-6">
          <MentionsByDaypart :selectedDay="selectedDay" @selectedDay="setSelectedDay" />
        </div>
      </div>

      <!-- heatmap card -->
      <Heatmap />
    </div>

    <!-- loader -->
    <div v-else>
      <div class="row">
        <div class="col col-12 mb-3">
          <b-skeleton style="width: 100%" height="160px" />
        </div>
        <div class="col col-12 col-xl-6 mb-3" v-for="i of 4" :key="i">
          <b-skeleton style="width: 100%" height="289px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopCategories from '../cards/TopCategories'
import TotalMentions from '../cards/TotalMentions'
import MentionsByDaypart from '../cards/MentionsByDaypart'
import InsightsTextSummary from '../cards/InsightsTextSummary'
import Heatmap from '../cards/Heatmap'
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'ReportsSection',
  props: ['loading', 'wcLoading'],
  components: {
    TopCategories,
    TotalMentions,
    MentionsByDaypart,
    Heatmap,
    InsightsTextSummary,
  },
  data: () => ({
    selectedDay: null,
  }),
  computed: {
    ...InsightsModule.mapGetters(['selectSummaries']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
  },
  methods: {
    setSelectedDay(dayNumber) {
      this.selectedDay = dayNumber
    },
  },
}
</script>
