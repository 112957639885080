import BannerService from '../services/BannerService'

export default {
  namespaced: true,

  state: {
    banners: [],
  },

  getters: {
    selectBanners(state) {
      return state.banners.reverse()
    },
  },

  mutations: {
    SET_BANNERS(state, banners) {
      state.banners = banners
    },
  },

  actions: {
    hideBanner({getters, commit}, index) {
      let banners = getters.selectBanners
      banners.splice(index, 1)
      commit('SET_BANNERS', banners)
    },

    async fetchBanners({ rootState, commit }) {
      const { activeCompany } = rootState.company
      const response = await BannerService.getBanners({ companyId: activeCompany._id })
      commit('SET_BANNERS', response.data.banners)
    },
  },
}
