export const firstName = (name) => {
  if (!name) {
    return 'Anonymous'
  }
  let splitName = name.split(' ')
  return `${splitName[0]}`
}

export const checkName = (name) => {
  const anonymousNames = ['undefined', 'null']
  const nameParts = name
    ?.split(' ')
    .map((part) => part.trim())
    .filter((part) => part)
  const validatedName = nameParts
    ?.filter((part) => !anonymousNames.includes(part.toLowerCase()))
    .join(' ')
    .trim()
  if (!validatedName) {
    return 'Anonymous'
  }
  return validatedName
}
