<template>
  <div>
    <div v-if="selectPopulatedClassifications.length">
      <div v-for="(pc, index) in selectPopulatedClassifications" :key="index">
        <DetailListCard :populatedFeedback="pc" />
      </div>

      <!-- load more -->
      <div class="text-center" v-if="selectPopulatedClassifications.length < selectCount">
        <button class="btn btn-outline-primary" @click="loadMore" v-if="!loading">Load More</button>
        <b-skeleton width="100%" height="200px" class="mb-4" v-else />
      </div>
    </div>

    <div class="text-center" v-else>No data to show for current filters...</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DetailListCard from './DetailListCard'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'DetailsList',
  components: {
    DetailListCard,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...InsightsDetailsModule.mapGetters(['selectPopulatedClassifications', 'selectCount']),
    ...LocationModule.mapGetters(['selectLocationName']),
  },
  methods: {
    ...InsightsDetailsModule.mapActions(['fetchPopulatedClassifications']),
    async loadMore() {
      this.loading = true
      await this.fetchPopulatedClassifications()
      this.loading = false
    },
  },
}
</script>
