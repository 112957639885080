import AWS from 'aws-sdk'

export default {
  namespaced: true,
  state: {
    connector: null,
    uploadProgress: {},
  },
  getters: {
    selectConnector: (state) => state.connector,
    selectUploadProgress: (state) => (id) => state.uploadProgress[id],
  },
  mutations: {
    SET_CONNECTOR(state, connector) {
      state.connector = connector
    },
    SET_UPLOAD_PROGRESS(state, { id, progress }) {
      state.uploadProgress[id] = progress
    },
  },
  actions: {
    init({ commit, rootGetters }) {
      const credentials = new AWS.Credentials(
        rootGetters.selectConfigByName('AWS_S3_ACCESS_KEY'),
        rootGetters.selectConfigByName('AWS_S3_SECRET')
      )

      commit(
        'SET_CONNECTOR',
        new AWS.S3({
          region: rootGetters.selectConfigByName('AWS_S3_REGION'),
          credentials,
        })
      )
    },
    listObjects({ getters }, { id, bucket, prefix }) {
      getters.selectConnector.listObjectsV2(
        {
          Bucket: bucket,
          Prefix: prefix,
        },
        (error, data) => {
          if (error) {
            // Error Handler
            console.log(error)
          } else {
            return data
          }
        }
      )
    },
    async getObject({ getters }, { bucket, key }) {
      let getObjectResult = await getters.selectConnector
        .getObject({
          Bucket: bucket,
          Key: key,
        })
        .promise()

      return getObjectResult
    },
    upload({ commit, getters }, { id, bucket, key, metadata, fileObject }) {
      commit('setUploadProgress', { id, progress: '0' })

      return new Promise((resolve, reject) => {
        let reader = new FileReader()

        reader.onload = () => {
          let request = getters.selectConnector.upload({
            Bucket: bucket,
            Key: key,
            ContentType: fileObject.type,
            Body: reader.result,
            ACL: 'private',
            Metadata: metadata,
          })

          request.on('httpUploadProgress', (progress) => {
            commit('setUploadProgress', {
              id,
              progress: String(parseInt((progress.loaded * 100) / progress.total)),
            })
          })

          request.promise().then((response, error))
        }
      })
    },
    async uploadFile({ getters }, { Bucket, Key, Body, ContentType, Metadata }) {
      const response = await getters.selectConnector
        .upload({
          Bucket: Bucket,
          Key: Key,
          ContentType: ContentType,
          Body: Body,
          ACL: 'private',
          Metadata: Metadata,
        })
        .promise()
      return response
    },
  },
}
