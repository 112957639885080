import Vue from 'vue'

export default {
  get(payload) {
    return Vue.http.post('analytics', payload)
  },
  timeline(payload) {
    return Vue.http.post(`/analytics/timeline`, payload)
  },
  responseTime({ customerId, locationId }) {
    return Vue.http.post('analytics/response-time', { customerId, locationId })
  },
}
