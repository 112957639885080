const ratings = [
  { emoti: '😡', title: 'terrible', score: 1 },
  { emoti: '🙁', title: 'bad', score: 2 },
  { emoti: '😐', title: 'okay', score: 3 },
  { emoti: '🙂', title: 'good', score: 4 },
  { emoti: '😍', title: 'great', score: 5 },
]

export const surveyRatings = ratings

/**
 * This function is used to get a rating from the array of ratings.
 * @param {number} rating a number representing an index
 * @returns an object with emoti, title, score properties
 */
export const getRating = (rating) => {
  return ratings[rating - 1]
}

/**
 * This function is used to get friendlyName location
 * @param {*} event an object that contains the event data
 * @returns a string that represents a location
 */
export const getLocation = (event) => {
  const { location: { friendlyName, city } = {} } = event

  return friendlyName || city
}
