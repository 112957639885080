<template>
  <div>
    <div id="dashboard_container">
      <div id="errorModal" class="modal" v-show="showErrorModal">
        <p class="error-message">
          Sorry, there was an issue loading the dashboard builder. Please refresh the page and if
          issue persists contact customer support.
          <br />
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { BoldBI } from '@boldbi/boldbi-embedded-sdk'
import { createNamespacedHelpers } from 'vuex'
window.jQuery = $

const ReportModule = createNamespacedHelpers('report')
let boldbiBuilder = null

export default {
  name: 'BuilderView',
  data: () => ({
    errorMessage: '',
    showErrorModal: false,
  }),
  async beforeUnmount() {
    if (boldbiBuilder) {
      await this.destroyBI()
    }
  },
  async mounted() {
    await this.initialize()
    await this.fetchReportDashboardDrafts()
  },
  computed: {
    ...ReportModule.mapGetters(['selectActiveDashboard', 'selectBoldBiUrl', 'selectOBSUrl']),
  },
  methods: {
    ...ReportModule.mapActions(['fetchReportDashboardDrafts']),
    async destroyBI() {
      boldbiBuilder.destroyStyles()
      boldbiBuilder.destroy()
    },
    async initialize() {
      try {
        const data = {
          DashboardId: this.selectActiveDashboard?.Id,
          EmbedType: 'component',
          Environment: 'onpremise',
          ExpirationTime: '10000',
          ServerUrl: this.selectBoldBiUrl,
          SiteIdentifier: 'site/site1',
        }
        const authUrl = `${this.selectOBSUrl}/app-services/v2/boldbi/embed-detail`
        embedSample(data, authUrl)
      } catch (error) {
        this.errorMessage =
          'To compile and run the project, an embed config file needs to be required.'
        this.showErrorModal = true
      }

      function embedSample(data, authUrl) {
        if (boldbiBuilder) {
          boldbiBuilder.destroyStyles()
          boldbiBuilder.destroy()
        }
        const config = {
          serverUrl: data.ServerUrl + '/' + data.SiteIdentifier,
          ...(data.DashboardId ? { dashboardId: data.DashboardId } : []),
          embedContainerId: 'dashboard_container', // This should be the container id where you want to embed the dashboard designer
          embedType: BoldBI.EmbedType.Component,
          environment: BoldBI.Environment.Enterprise,
          mode: BoldBI.Mode.Design,
          width: '100%',
          height: window.innerHeight - $('#report-header')[0].offsetHeight + 'px',
          authorizationServer: {
            headers: {
              Authorization: localStorage.getItem('jwt'),
            },
            url: authUrl,
          },
          dashboardSettings: {
            themeSettings: {
              isLocalTheme: true,
            },
          },
          expirationTime: '100000',
        }
        boldbiBuilder = BoldBI.create(config)
        boldbiBuilder.loadDesigner()
        boldbiBuilder.addStyles()
      }
    },
  },
}
</script>
