<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('location')

export default {
  computed: {
    ...mapState(['activeLocation']),
  },
  methods: {
    ...mapMutations([
      'setActiveSettingsTab',
      'setActiveLocation',
      'setLocationPlatforms',
      'setLocationReviewSubscriptionsOrder',
    ]),
  },
  async mounted() {
    this.setActiveSettingsTab(this.$route.name)
  },
}
</script>
