import Vue from 'vue'

export default {
  fetchCredits({ companyId }) {
    return Vue.http.get(`app-services/v2/campaign/credits/${companyId}`)
  },

  // deprecated
  create(payload) {
    return Vue.http.post('campaign', payload)
  },
  update(payload) {
    return Vue.http.post('campaign/update', payload)
  },
  list(companyId) {
    return Vue.http.post('campaign/list', { companyId })
  },
  toggle(campaignId) {
    return Vue.http.post('campaign/toggle', { campaignId })
  },
  delete(campaignId) {
    return Vue.http.delete(`campaign/${campaignId}`)
  },
  sendTestMessage(payload) {
    return Vue.http.post('campaign/send-test-message', payload)
  },
}
