<template>
  <div>
    <div
      v-for="(entry, index) in reportDataAnswers"
      :key="index"
      class="border-bottom p-2 row align-items-center"
      style="min-height: 50px"
    >
      <div class="col-12 col-lg-3">
        {{ $moment(entry.created).format('M/D/YY LT') }}
      </div>
      <div class="col-12 col-lg-9">
        {{ entry.answer.SHORT_ANSWER }}
      </div>
    </div>
    <div class="row my-3" v-if="canLoadMore">
      <div class="col text-center">
        <button class="btn btn-outline-primary btn-sm" @click="showMore">Show more</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortAnswerReport',
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    limit: 5,
    skip: 15,
  }),
  computed: {
    reportDataAnswers() {
      return this.reportData.answers.slice(0, this.limit)
    },
    canLoadMore() {
      if (this.reportData.answers.length > this.limit) {
        return true
      }
      return false
    },
  },
  methods: {
    showMore() {
      this.limit += this.skip
    },
  },
}
</script>

<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
}
</style>
