<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <h2>{{ brandName }} is better together.</h2>
          <p class="text-muted">Missing a teammate? Invite them to start collaborating.</p>
          <button class="btn btn-primary" v-b-modal.inviteEmployeeModal>Invite a teammate</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InviteTeammateCard',
  computed: {
    ...mapGetters('user', ['brandName']),
  },
}
</script>

<style scoped lang="scss"></style>
