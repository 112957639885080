<template>
  <div class="message">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-0">
        <a href="javascript:;" class="btn btn-sm btn-success btn-rounded-circle mt-1"
          ><i class="fe fe-check icon-inner-adjust"></i
        ></a>
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <p class="comment-text text-capitalize">Completed Custom Question List</p>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">{{ created | moment('LT') }} {{ timezone }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompletedCustomQuestionListEvent',
  props: ['created', 'timezone'],
}
</script>
