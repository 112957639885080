<template>
  <div>
    <div v-if="initialized">
      <MessageList :hidePane="true" />
    </div>
  </div>
</template>

<script>
import MessageList from './SubViews/MessageList'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const WebsocketModuleV2 = createNamespacedHelpers('websocketV2')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'WinbackView',
  components: {
    MessageList,
  },
  data: () => ({
    showDetails: true,
    initialized: false,
    queryPending: true,
    fetchMorePending: false,
  }),
  async mounted() {
    this.updateIsWebview(true)
    await Promise.all([this.resetAllConversationData(), this.winbackCompanyChannelSubscribe()])
    await Promise.all([
      this.getUserLocationsByActiveCompany(),
      this.fetchConversations({ reset: true, trace: 'WinbackWebview' }),
    ])
    this.queryPending = false
    this.initialized = true
    await this.parseRouteDetails()
  },
  beforeDestroy() {
    this.winbackCompanyChannelUnsubscribe()
    this.resetAllConversationData()
  },
  computed: {
    ...CompanyModule.mapGetters(['activeCompany', 'selectCompany']),
    ...ConversationModuleV2.mapGetters(['selectIsMobileView', 'selectIsMobileView']),
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...ConversationModuleV2.mapActions([
      'fetchConversations',
      'resetAllConversationData',
      'setActiveConversation',
      'fetchSpecificConversation',
      'updateIsWebview',
    ]),
    ...WebsocketModuleV2.mapActions([
      'winbackCompanyChannelSubscribe',
      'winbackCompanyChannelUnsubscribe',
    ]),
    ...CompanyModule.mapActions(['setActiveCompany']),
    async parseRouteDetails() {
      // this function is only to account for deep linking
      const { conversationId } = this.$route.params
      try {
        if (conversationId) {
          await this.fetchSpecificConversation(conversationId)
        } else {
          // backward compatibility
          const { company: companyId, customer: customerId } = this.$route.query
          if (companyId && companyId !== this.activeCompany?._id) {
            const company = this.selectCompany(companyId)
            if (company) {
              await this.setActiveCompany(company)
            } else {
              throw new Error('No matching company for winback')
            }
          }
          if (customerId) {
            this.setActiveConversation({ customer: { _id: customerId } })
            if (this.selectIsMobileView) {
              this.$refs.timelineDrawer.open()
            }
          }
        }
      } catch (error) {
        this.$notify({ type: 'error', text: error.message })
      }
    },
  },
}
</script>

<style scoped>
.col {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
