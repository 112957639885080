<template>
  <b-textarea
    placeholder="Customer inputs text here..."
    rows="3"
    max-rows="6"
    disabled
  ></b-textarea>
</template>

<script>
// @vuese
// @group CustomQuestions
// A preview component for short answers on the custom question page.
export default {
  name: 'ShortAnswerType',
}
</script>
