import ConversationService from '@/services/ConversationService'
import moment from 'moment-timezone'

export default {
  namespaced: true,
  state: {
    conversations: [],
    activeConversation: undefined,
    listFilters: {},
    skipCount: 0,
    limit: 10,
    isFiltersBarOpen: false,
  },
  getters: {
    conversations: (state) => state.conversations,
    activeConversation: (state) => state.activeConversation,
    listFilters: (state) => state.listFilters,
    skipCount: (state) => state.skipCount,
    limit: (state) => state.limit,
    isFiltersBarOpen: (state) => state.isFiltersBarOpen,
  },
  mutations: {
    setLatestMessageData(state, message) {
      let conversation = state.conversations.find((convo) => convo._id == message.conversation.$oid)

      if (conversation) {
        conversation.lastMessage = {
          _id: message._id.$oid,
          body: message.body,
          media: message.media,
          conversation: message.conversation.$oid,
          created: moment(parseInt(message.created.$date.$numberLong)).toISOString(),
          type: message.type,
          updated: moment().toISOString(),
        }

        conversation.opened = []
        conversation.resolved = false

        let newConvos = state.conversations.map((convo) => {
          if (convo._id == conversation._id) {
            return conversation
          } else {
            return convo
          }
        })

        state.conversations = newConvos.sort(function (a, b) {
          return a.opened.length || moment(b.updated) - moment(a.updated)
        })
      }
    },
    setConversations(state, conversations) {
      state.conversations = [...conversations]
    },
    setActiveConversation(state, conversation) {
      state.activeConversation = conversation
    },
    setListFilters(state, filters) {
      state.listFilters = { ...filters }
    },
    setSkipCount(state, skipCount) {
      state.skipCount = skipCount
    },
    setLimit(state, limit) {
      state.limit = limit
    },
    setFiltersBarOpen(state, isFiltersBarOpen) {
      state.isFiltersBarOpen = isFiltersBarOpen
    },
  },
  actions: {
    async newMessage(context, message) {
      let conversation = context.getters.conversations.find(
        (convo) => convo._id == message.conversation.$oid
      )

      if (conversation) {
        context.commit('setLatestMessageData', message)

        if (context.getters.activeConversation) {
          await context.dispatch(
            'timeline/initializeTimeline',
            {
              conversation: context.getters.activeConversation,
              customer: context.rootState.timeline.customer,
              location: context.rootState.timeline.location,
            },
            { root: true }
          )
        }
      } else {
        context.dispatch('queryConversations')
      }
    },
    async queryConversations({ commit, rootGetters, getters }) {
      if (!rootGetters['company/activeCompany'] || !rootGetters['user/onlineUser']._id) {
        return
      }

      let companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]
      if (getters.listFilters.companies && getters.listFilters.companies.length > 0) {
        companies = companies.filter((company) =>
          getters.listFilters.companies.includes(company._id)
        )
      }

      let responses = await Promise.all(
        companies.map((company) => {
          return ConversationService.list({
            userId: rootGetters['user/onlineUser']._id,
            companyId: company._id,
            filters: getters.listFilters,
            skip: getters.skipCount,
            limit: getters.limit,
          })
        })
      )

      let conversations = responses.reduce(
        (previous, current) => previous.concat(current.body.conversations),
        []
      )
      conversations = conversations
        .filter((conversation) => {
          if (!conversation.lastMessage && !conversation.lastSurvey) {
            return false
          }

          conversation.timestamp = new Date(
            conversation.lastMessage
              ? conversation.lastMessage.created
              : conversation.lastSurvey.updated
          ).getTime()
          return true
        })
        .sort((a, b) => {
          return b.timestamp - a.timestamp
        })

      if (getters.skipCount > 0) {
        commit('setConversations', [...getters.conversations, ...conversations])
      } else {
        commit('setConversations', conversations)
      }
    },
    async open({ commit, rootState }, { conversationId }) {
      if (!conversationId || !rootState.user.onlineUser._id) {
        return
      }
      const response = await ConversationService.open({
        userId: rootState.user.onlineUser._id,
        conversationId,
      })

      const { count } = response.body

      commit('conversationV2/SET_CONVERSATION_COUNT', count, { root: true })
    },
    async resolve({ dispatch }, { conversationId, userId }) {
      await ConversationService.resolve({
        conversationId,
        userId,
      })
      await dispatch('queryConversations', {})
      await dispatch('conversationV2/setUnresolvedCount', { root: true })
    },
    async initActiveConversation({ commit }, conversation) {
      commit('setActiveConversation', conversation)
    },
    async create({ rootState, commit }, { customerId, locationId }) {
      const response = await ConversationService.create({
        customerId,
        locationId,
        companyId: rootState.company.activeCompany._id,
      })

      const { conversation } = response.body
      commit('setActiveConversation', conversation)
    },
    async reply(
      { commit, dispatch, rootState },
      { conversationId, locationId, companyId, customerId, promoId, message }
    ) {
      await ConversationService.reply({
        conversationId,
        locationId,
        companyId,
        customerId,
        userId: rootState.user.onlineUser._id,
        promoId,
        message,
      })
      commit('setSkipCount', 0)
      await dispatch('queryConversations', {})
    },
  },
}
