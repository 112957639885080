import AnalyticsService from '@/services/AnalyticsService'
import CustomerService from '../services/CustomerService'
import PromoService from '../services/PromoService'
import Websocket from '../lib/websocket'

export default {
  namespaced: true,
  state: {
    customer: undefined,
    location: undefined,
    events: undefined,
    skip: 0,
    limit: 100,
  },
  getters: {
    customer: (state) => state.customer,
    location: (state) => state.location,
    getSkip(state) {
      return state.skip
    },
    getLimit(state) {
      return state.limit
    },
    getEvents(state) {
      return state.events
    },
  },
  mutations: {
    setCustomer(state, customer) {
      state.customer = customer
    },
    setLocation(state, location) {
      state.location = location
    },
    setEvents(state, events) {
      state.events = events
    },
    PREPEND_EVENTS(state, { oldEvents, newEvents }) {
      state.events = newEvents.concat(oldEvents)
    },
    SET_SKIP(state, skip) {
      state.skip = skip
    },
  },
  actions: {
    async incrementSkip({ commit, getters }) {
      commit('SET_SKIP', getters.getSkip + getters.getLimit)
    },

    async resetSkip({ commit }) {
      commit('SET_SKIP', 0)
    },

    async fetchMoreTimelineEvents(
      { commit, getters, dispatch, rootGetters, rootState },
      { conversation }
    ) {
      await dispatch('incrementSkip')

      try {
        let response = await AnalyticsService.timeline({
          customerId: getters.customer._id,
          companyId: conversation ? conversation.company : rootGetters['company/activeCompany']._id,
          skip: getters.getSkip,
          limit: getters.getLimit,
        })
        if (response.body && response.body.events) {
          commit('PREPEND_EVENTS', {
            oldEvents: getters.getEvents,
            newEvents: response.body.events,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    async initializeTimeline(
      { commit, rootState, state, dispatch, getters },
      { customer, location, conversation }
    ) {
      await dispatch('resetSkip')

      // Setup active conversation
      dispatch('conversation/initActiveConversation', conversation, { root: true })

      // Clear the messages so it's loading if it's a new conversation
      if (state.customer && state.customer._id !== customer._id) {
        commit('setEvents', undefined)
      }

      // Set Customer
      commit('setCustomer', customer)

      try {
        // Set Events
        let response = await AnalyticsService.timeline({
          customerId: customer._id,
          companyId: conversation ? conversation.company : rootState.company.activeCompany._id,
          skip: getters.getSkip,
          limit: getters.getLimit,
        })
        if (response.body && response.body.events) {
          commit('setEvents', response.body.events)
        }

        // Set Location
        if (!location) {
          let response = await CustomerService.lastVisited({
            customerId: customer._id,
            companyId: conversation ? conversation.company : rootState.company.activeCompany._id,
          })
          if (response.body && response.body.location) {
            commit('setLocation', response.body.location)
          }
        } else {
          commit('setLocation', location)
        }
      } catch (error) {
        console.log(error)
      }

      if (rootState.user.WSInstance && state.location && rootState.user.onlineUser) {
        rootState.user.WSInstance.send(
          JSON.stringify({
            key: 'saveLocation',
            location: state.location._id,
            user: rootState.user.onlineUser._id,
          })
        )
      }
    },
    async getUsedUniqueCode(_, { customerId, promoId, redemptionTimestamp }) {
      try {
        return await PromoService.usedCode({ customerId, promoId, redemptionTimestamp })
      } catch (error) {
        console.log(error)
      }
    },
    async getResponseTime(_, { customerId, locationId }) {
      try {
        return AnalyticsService.responseTime({ customerId, locationId })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
