import IntegrationProviderService from '@/services/IntegrationProviderService'

export default {
  namespaced: true,
  state: {
    integrationProviders: [],
    disabledIntegrationProviders: [],
  },
  getters: {
    selectIntegrationProviderByName: (state) => (integrationProviderName) => {
      return state.integrationProviders.find(
        (integrationProvider) => integrationProviderName === integrationProvider.name
      )
    },
    selectIntegrationProviderById: (state) => (integrationProviderId) => {
      return state.integrationProviders.find(
        (integrationProvider) => integrationProviderId === integrationProvider._id
      )
    },
    selectIntegrationProviders: (state) => state.integrationProviders,
    selectDisabledIntegrationProviderByName: (state) => (integrationProviderName) => {
      return state.disabledIntegrationProviders.find(
        (integrationProvider) => integrationProviderName === integrationProvider.name
      )
    },
  },
  mutations: {
    SET_INTEGRATION_PROVIDERS(state, integrationProviders) {
      state.integrationProviders = integrationProviders
    },
    SET_DISABLED_INTEGRATION_PROVIDERS(state, integrationProviders) {
      state.disabledIntegrationProviders = integrationProviders
    },
  },
  actions: {
    async getIntegrationProviders({ commit, dispatch }) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: 'GET_INTEGRATION_PROVIDERS' },
        { root: true }
      )

      const result = await IntegrationProviderService.getIntegrationProviders()
      commit('SET_INTEGRATION_PROVIDERS', result.data.data.integrationProviders)

      const disabledProviders = await IntegrationProviderService.getIntegrationProviders({
        disabled: true,
      })
      commit('SET_DISABLED_INTEGRATION_PROVIDERS', disabledProviders.data.data.integrationProviders)
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
  },
}
