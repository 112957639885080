<template>
  <div>
    <div class="card w-100">
      <div class="row">
        <div class="col-12">
          <b-table
            :fields="fields"
            :items="selectDisplayedGiftCards"
            responsive
            hover
            :table-class="['card-table', 'table-nowrap']"
            small
          >
            <template #head()="{ label }">
              {{ label }}
              <i
                class="fas fa-info-circle text-muted ml-1 fa-sm"
                v-b-popover.hover="'HH:MM:SS'"
                v-if="label === 'Duration'"
              ></i>
            </template>

            <template #cell(createdAt)="{ value }">
              {{ $moment(value).format('MMM D LT') }}
              {{ $moment.tz($moment.tz.guess()).zoneAbbr() }}
            </template>

            <template #cell(customer)="{ value }">
              <span v-if="value && value.name" v-b-popover.hover="formattedPhone(value.phone)">
                {{ value.name }}
              </span>
              <span v-else-if="value && value.phone">
                {{ formattedPhone(value.phone) }}
              </span>
              <span v-else>Anonymous</span>
            </template>

            <template #cell(location)="data">
              <span class="badge badge-light" style="font-size: 12px">
                {{ selectLocationName(data.value) }}
              </span>
            </template>

            <template #cell(user)="{ value }">
              {{ selectUserNameById(value) }}
            </template>

            <template #cell(amount)="{ value }"> ${{ value / 100 }} </template>
          </b-table>
        </div>
      </div>
    </div>

    <div class="text-center" v-if="!selectAllGiftCardsDisplayed">
      <button @click="loadMoreDisplayedGiftCards" class="btn btn btn-sm btn-outline-primary">
        Load more gift cards
      </button>
    </div>
  </div>
</template>

<script>
import { formatPhone } from '@/lib/format'
import { createNamespacedHelpers } from 'vuex'

const GiftCardModule = createNamespacedHelpers('giftCard')
const LocationModule = createNamespacedHelpers('location')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'GiftCardTable',
  data: () => ({
    fields: [
      { key: 'createdAt', label: 'Created', class: 'align-middle' },
      { key: 'customer', label: 'Customer', class: 'align-middle' },
      { key: 'location', label: 'Location', class: 'align-middle' },
      { key: 'user', label: 'Sender', class: 'align-middle' },
      { key: 'amount', label: 'Amount', class: 'align-middle' },
    ],
  }),
  computed: {
    ...GiftCardModule.mapGetters(['selectDisplayedGiftCards', 'selectAllGiftCardsDisplayed']),
    ...LocationModule.mapGetters(['selectLocationName']),
    ...UserModule.mapGetters(['selectUserNameById']),
  },
  methods: {
    ...GiftCardModule.mapActions(['loadMoreDisplayedGiftCards']),
    formattedPhone(phone) {
      return formatPhone(phone)
    },
  },
}
</script>
