<template>
  <div id="insights-text-summary" class="card px-4 py-3 mb-3 h-100">
    <div v-if="!positiveSummary && !negativeSummary">- No summary for selected filters</div>

    <div v-else>
      <div v-if="positiveSummary">
        <div class="mb-1" style="font-size: 13px">Summary of Positive Reviews</div>
        <p>{{ positiveSummary }}</p>
      </div>

      <div v-if="negativeSummary">
        <div class="mb-1" style="font-size: 13px">Summary of Negative Reviews</div>
        <p>{{ negativeSummary }}</p>
      </div>

      <div class="text-right">
        <button class="btn btn-primary btn-sm" @click="showDetails" v-if="showButton">
          See Reviews
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InsightsTextSummary',
  props: {
    positiveSummary: {
      type: String,
    },
    negativeSummary: {
      type: String,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showDetails() {
      this.$router.push({
        path: '/insights/details',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>
