<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">Notify me about</h4>
            <div class="flex-grow-0">
              <button
                :class="['btn', 'btn-sm', notifications.paused ? 'btn-primary' : 'btn-white']"
                @click="update('paused')"
              >
                {{ pausedBtnText }}
              </button>
            </div>
          </div>
          <div v-if="/admin|location-manager/.test(user.role)" class="card-header">
            <div class="d-flex flex-wrap pb-3">
              <div v-if="user.role === 'admin'" class="mr-4">
                <h6 class="header-pretitle mt-3">Companies</h6>
                <MultiSelect
                  :disabled="pausedAll"
                  taggable
                  multiple
                  id="notify-companies"
                  :close-on-select="false"
                  :clear-on-select="false"
                  label="name"
                  track-by="_id"
                  :searchable="false"
                  :show-labels="false"
                  openDirection="below"
                  @input="update('companies')"
                  v-model="notifications.companies"
                  :options="companyOptions"
                  style="min-width: 200px; max-width: 400px"
                >
                  <template #placeholder
                    ><span> <span class="far fa-building mr-1"></span>All companies</span></template
                  >
                </MultiSelect>
              </div>
              <div class="">
                <h6 class="header-pretitle mt-3">Locations</h6>
                <MultiSelect
                  :disabled="pausedAll"
                  multiple
                  id="notify-locations"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :custom-label="locationName"
                  track-by="_id"
                  :show-labels="false"
                  openDirection="below"
                  :limitText="(count) => `+${count} more`"
                  :limit="2"
                  @input="update('locations')"
                  placeholder="Search locations"
                  showNoResults
                  v-model="notifications.locations"
                  :options="locationOptions"
                  style="min-width: 200px; max-width: 400px"
                >
                  <template #placeholder
                    ><span><span class="fe fe-map-pin mr-1"></span>All locations</span></template
                  >
                  <template #option="{ option }">
                    <div>
                      <span>{{ option.friendlyName || option.city }}</span>
                      <br />
                      <small
                        v-if="option.addressDetails && option.addressDetails.streetNumber"
                        class="text-muted small text-truncate"
                        >{{
                          `${option.addressDetails.streetNumber} ${option.addressDetails.streetName}`
                            | truncate(20)
                        }}</small
                      >
                    </div>
                  </template>
                  <template #noResult><span>No matching locations.</span></template>
                </MultiSelect>
              </div>
            </div>
          </div>
          <div class="table-responsive m-0">
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th style="width: 40%">Options</th>
                  <th class="text-center">Email</th>
                  <th class="text-center">SMS</th>
                  <th class="text-center">In App</th>
                </tr>
              </thead>
              <tbody class="font-size-base">
                <tr>
                  <td class="align-middle">Feedback Alerts</td>
                  <td class="text-center align-middle">
                    <MultiSelect
                      :disabled="pausedAll"
                      multiple
                      reposition
                      class="options"
                      :custom-label="ratingLabel"
                      track-by="score"
                      id="notify-ratings"
                      :limitText="(count) => `+${count} more`"
                      :limit="2"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :searchable="false"
                      :show-labels="false"
                      :showNoResults="false"
                      openDirection="below"
                      placeholder="All ratings"
                      @input="update('feedback.ratings')"
                      v-model="notifications.feedback.ratings"
                      :options="ratingOptions"
                      :modalEl="modalEl"
                      style="width: 100%"
                    >
                      <template #placeholder><span class="text-dark">All ratings </span></template>
                    </MultiSelect>
                  </td>
                  <td class="text-center align-middle">
                    <div class="custom-control custom-checkbox d-flex justify-content-center">
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="feedback-emal"
                        @change="update('feedback.through.email')"
                        v-model="notifications.feedback.through.email"
                      />
                      <label class="custom-control-label" for="feedback-emal"></label>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div
                      v-if="!hasTheApp"
                      class="custom-control custom-checkbox d-flex justify-content-center"
                    >
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="feedback-sms"
                        @change="update('feedback.through.sms')"
                        v-model="notifications.feedback.through.sms"
                      />
                      <label class="custom-control-label" for="feedback-sms"></label>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div
                      v-if="hasTheApp"
                      class="custom-control custom-checkbox d-flex justify-content-center"
                    >
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="feedback-push"
                        @change="update('feedback.through.push')"
                        v-model="notifications.feedback.through.push"
                      />
                      <label class="custom-control-label" for="feedback-push"></label>
                    </div>
                  </td>
                </tr>
                <tr v-if="/admin|location-manager/.test(user.role)">
                  <td class="align-middle">Summaries</td>
                  <td class="text-center align-middle">
                    <MultiSelect
                      :disabled="pausedAll"
                      reposition
                      class="options"
                      label="name"
                      track-by="value"
                      id="notify-summaries"
                      :searchable="false"
                      :show-labels="false"
                      :showNoResults="false"
                      openDirection="below"
                      placeholder="All summaries"
                      @input="update('summary.types')"
                      v-model="notifications.summary.types"
                      :options="summaryOptions"
                    >
                    </MultiSelect>
                  </td>
                  <td class="text-center align-middle">
                    <div class="custom-control custom-checkbox d-flex justify-content-center">
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="summary-emal"
                        @change="update('summary.through.email')"
                        v-model="notifications.summary.through.email"
                      />
                      <label class="custom-control-label" for="summary-emal"></label>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <!-- <div class="custom-control custom-checkbox d-flex justify-content-center">
                      <input
                        disabled
                        type="checkbox"
                        class="custom-control-input"
                        id="summary-sms"
                        @change="update('summary.through.sms')"
                        v-model="notifications.summary.through.sms"
                      />
                      <label class="custom-control-label" for="summary-sms"></label>
                    </div> -->
                  </td>
                  <td class="text-center align-middle">
                    <!-- <div class="custom-control custom-checkbox d-flex justify-content-center">
                      <input
                        disabled
                        type="checkbox"
                        class="custom-control-input"
                        id="summary-push"
                        @change="update('summary.through.push')"
                        v-model="notifications.summary.through.push"
                      />
                      <label class="custom-control-label" for="summary-push"></label>
                    </div> -->
                  </td>
                </tr>
                <tr>
                  <td class="align-middle">Customer Saved Alert</td>
                  <td class="align-middle"><!-- add other options here --></td>
                  <td class="align-middle"><!-- add email options here --></td>
                  <td class="align-middle"><!-- add sms options here --></td>
                  <td class="text-center align-middle">
                    <div class="custom-control custom-checkbox d-flex justify-content-center">
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="customer-saved-enabled"
                        @change="update('customerSavedAlerts.enabled')"
                        v-model="notifications.customerSavedAlerts.enabled"
                      />
                      <label class="custom-control-label" for="customer-saved-enabled"></label>
                    </div>
                  </td>
                </tr>
                <tr v-if="selectShowNotesSettings">
                  <td class="align-middle">Mentions of me in a conversation</td>
                  <td class="align-middle"><!-- add other options here --></td>
                  <td class="text-center align-middle">
                    <div class="custom-control custom-checkbox d-flex justify-content-center">
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="mentions-emal"
                        @change="update('internalNotes.mentions.through.email')"
                        v-model="notifications.internalNotes.mentions.through.email"
                      />
                      <label class="custom-control-label" for="mentions-emal"></label>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div
                      v-if="!hasTheApp"
                      class="custom-control custom-checkbox d-flex justify-content-center"
                    >
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="mentions-sms"
                        @change="update('internalNotes.mentions.through.sms')"
                        v-model="notifications.internalNotes.mentions.through.sms"
                      />
                      <label class="custom-control-label" for="mentions-sms"></label>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div
                      v-if="hasTheApp"
                      class="custom-control custom-checkbox d-flex justify-content-center"
                    >
                      <input
                        :disabled="pausedAll"
                        type="checkbox"
                        class="custom-control-input"
                        id="mentions-push"
                        @change="update('internalNotes.mentions.through.push')"
                        v-model="notifications.internalNotes.mentions.through.push"
                      />
                      <label class="custom-control-label" for="mentions-push"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <hr :class="[activeUser ? 'mt-0 mb-4' : 'mb-5']" />
    <FadeTransition>
      <div class="card mb-0">
        <div class="card-header flex-column justify-content-center align-items-start">
          <h3 class="my-2">Schedule</h3>
          <small class="form-text text-muted"
            >You'll only receive notifications within your scheduled hours.</small
          >
        </div>
        <div class="card-body" style="max-height: unset">
          <div
            v-for="(range, day) in notifications.schedule"
            :key="day"
            class="row mb-3 align-items-center"
          >
            <div class="col-sm-4 mb-1 d-flex text-capitalize">
              <div class="custom-control custom-checkbox d-flex justify-content-center mr-2">
                <input
                  :disabled="pausedAll"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`${day}-paused`"
                  @change="selectStartSchedule(day, 'none')"
                  :checked="notifications.schedule[day].start !== 'none'"
                />
                <label class="custom-control-label" :for="`${day}-paused`"></label>
              </div>
              {{ day }}
            </div>
            <div class="col-sm-9=8 d-flex align-items-center pl-0" style="min-height: 41px">
              <h5 class="text-muted m-0" v-if="range.start === 'none'">
                Notifications disabled for this day
              </h5>
              <div v-else>
                <TimePicker
                  :initValue="range.start"
                  :isDisabled="pausedAll"
                  :id="`${day}-start`"
                  placeholder="Start Time"
                  @updateTime="selectStartSchedule(day, $event, range.end)"
                  class="d-inline-block"
                  :key="day + 'start' + range.start"
                ></TimePicker>
                <TimePicker
                  :initValue="range.end"
                  :isDisabled="pausedAll"
                  :id="`${day}-end`"
                  placeholder="End Time"
                  @updateTime="selectEndShedule(day, $event, range.start)"
                  class="d-inline-block ml-4"
                  :key="day + 'end' + range.end"
                ></TimePicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { getRating, surveyRatings } from '@/lib/rating'
import MultiSelect from '@/components/Common/MultiSelect'
import FadeTransition from '@/components/Transitions/FadeTransition'
import { createNamespacedHelpers } from 'vuex'
import { cloneDeep } from 'lodash-es'
import TimePicker from '@/components/Common/TimePicker'

const userModule = createNamespacedHelpers('user')
const locationModule = createNamespacedHelpers('location')
const companyModule = createNamespacedHelpers('company')

const validHrs = [
  '12:00 AM',
  '1:00 AM',
  '2:00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM',
]

export default {
  name: 'AccountNotificationSettings',

  components: {
    FadeTransition,
    MultiSelect,
    TimePicker,
  },

  props: ['activeUser'],

  data: () => ({
    notifications: {
      paused: false,
      companies: [],
      locations: [],
      feedback: {
        ratings: [],
        through: { email: false, sms: false, push: false },
      },
      summary: {
        types: [],
        through: { email: false, sms: false, push: false },
      },
      customerSavedAlerts: {
        enabled: false,
      },
      internalNotes: {
        mentions: {
          through: { email: false, sms: false, push: false },
        },
      },
    },
    hours: {
      sunday: { startHrs: [...validHrs], endHrs: [...validHrs] },
      monday: { startHrs: [...validHrs], endHrs: [...validHrs] },
      tuesday: { startHrs: [...validHrs], endHrs: [...validHrs] },
      wednesday: { startHrs: [...validHrs], endHrs: [...validHrs] },
      thursday: { startHrs: [...validHrs], endHrs: [...validHrs] },
      friday: { startHrs: [...validHrs], endHrs: [...validHrs] },
      saturday: { startHrs: [...validHrs], endHrs: [...validHrs] },
    },
    summaryOptions: [
      { name: 'All summaries', value: 'all' },
      { name: 'Daily summary', value: 'daily' },
      { name: 'Weekly summary', value: 'weekly' },
    ],
    ratingOptions: surveyRatings.slice(0, 4),
  }),

  computed: {
    ...userModule.mapState(['onlineUser']),
    ...locationModule.mapState(['userLocations']),
    ...companyModule.mapState({
      activeCompany: 'activeCompany',
      companyOptions: 'companies',
    }),
    ...companyModule.mapGetters(['selectShowNotesSettings']),
    locationOptions() {
      const companyIds = this.notifications.companies.map((c) => c._id)
      return this.userLocations.filter((l) =>
        companyIds.length ? companyIds.includes(l.company) : true
      )
    },
    user() {
      return this.activeUser || this.onlineUser
    },
    hasTheApp() {
      return this.user.pushTokens && this.user.pushTokens.length
    },
    pausedBtnText() {
      return this.notifications.paused ? 'Enable all' : 'Disable all'
    },
    pausedAll() {
      return this.notifications.paused
    },
    modalEl() {
      return this.activeUser ? document.getElementById('editEmployeeModalBody') : null
    },
  },

  async mounted() {
    try {
      await Promise.all([this.getOnlineUser(), this.getUserLocations()])

      const {
        paused,
        companies,
        locations,
        summary,
        feedback,
        schedule,
        customerSavedAlerts,
        internalNotes,
      } = cloneDeep(this.user.notifications)

      summary.types = summary.types.length
        ? this.summaryOptions.find((s) => summary.types.includes(s.value))
        : this.summaryOptions[0]
      feedback.ratings = this.ratingOptions.filter((r) => feedback.ratings.includes(r.score))
      this.notifications = {
        ...this.notifications,
        paused,
        schedule,
        feedback,
        summary,
        customerSavedAlerts,
        companies: this.companyOptions.filter((c) => companies.includes(c._id)),
        locations: this.locationOptions.filter((l) => locations.includes(l._id)),
        internalNotes,
      }

      await this.setTimezoneIfNotExist()
    } catch (error) {
      console.error(error)
    }
  },

  methods: {
    ...userModule.mapActions(['getOnlineUser']),
    ...locationModule.mapActions(['getUserLocations']),

    async setTimezoneIfNotExist() {
      if (!this.user.timezone) {
        await UserService.update({
          userId: this.user._id,
          fields: {
            timezone: this.$moment.tz.guess(),
          },
        })
      }
    },
    async update(field) {
      const key = `notifications.${field}`
      const fields = {}

      switch (field) {
        case 'paused':
          const updatedPaused = !this.notifications.paused
          fields[key] = updatedPaused
          this.$set(this.notifications, field, updatedPaused)
          break
        case 'companies':
          fields[key] = this.notifications.companies.map((c) => c._id)
          const filteredLocations = this.notifications.locations.filter((l) =>
            fields[key].includes(l.company)
          )
          this.notifications.locations = filteredLocations
          fields['notifications.locations'] = filteredLocations.map((l) => l._id)
          break
        case 'locations':
          fields[key] = this.notifications.locations.map((l) => l._id)
          break
        case 'feedback.ratings':
          fields[key] = this.notifications.feedback.ratings.map((r) => r.score)
          break
        case 'feedback.through.email':
          fields[key] = this.notifications.feedback.through.email
          break
        case 'feedback.through.sms':
          fields[key] = this.notifications.feedback.through.sms
          break
        case 'feedback.through.push':
          fields[key] = this.notifications.feedback.through.push
          break
        case 'summary.types':
          const type = this.notifications.summary.types.value
          fields[key] = type === 'all' ? [] : [type]
          break
        case 'summary.through.email':
          fields[key] = this.notifications.summary.through.email
          break
        case 'summary.through.sms':
          fields[key] = this.notifications.summary.through.sms
          break
        case 'summary.through.push':
          fields[key] = this.notifications.summary.through.push
          break
        case 'customerSavedAlerts.enabled':
          fields[key] = this.notifications.customerSavedAlerts.enabled
          break
        case 'internalNotes.mentions.through.email':
          fields[key] = this.notifications.internalNotes.mentions.through.email
          break
        case 'internalNotes.mentions.through.sms':
          fields[key] = this.notifications.internalNotes.mentions.through.sms
          break
        case 'internalNotes.mentions.through.push':
          fields[key] = this.notifications.internalNotes.mentions.through.push
          break
        default:
      }

      if (fields[key] != null) {
        await UserService.update({
          userId: this.user._id,
          fields,
        })
        this.$notify({
          ignoreDuplicates: true,
          text: `Notifications saved.`,
        })
      }
    },

    locationName({ friendlyName, city }) {
      return friendlyName || city
    },

    ratingLabel({ emoti, title }) {
      return `${emoti} ${title}`
    },

    async selectStartSchedule(day, time, endTime) {
      if (time === 'none') {
        const updatedPaused = this.notifications.schedule[day].start === 'none' ? '' : 'none'
        const range = { start: updatedPaused, end: '' }
        this.$set(this.notifications.schedule, day, range)

        await UserService.update({
          userId: this.user._id,
          fields: {
            [`notifications.schedule.${day}`]: range,
          },
        })
        this.$notify({ ignoreDuplicates: true, text: `Notifications saved.` })
        return
      }

      if (time === this.notifications.schedule[day].start) return
      const originalStart = this.notifications.schedule[day].start
      this.notifications.schedule[day].start = time

      if (endTime) {
        if (!this.$moment(time, 'h:mm a').isBefore(this.$moment(endTime, 'h:mm a'))) {
          this.$notify({ type: 'error', text: `Start time must be before end time` })
          this.$nextTick(() => {
            this.notifications.schedule[day].start = originalStart
          })
          return
        }

        await UserService.update({
          userId: this.user._id,
          fields: {
            [`notifications.schedule.${day}`]: {
              start: time,
              end: endTime,
            },
          },
        })
        this.$notify({ ignoreDuplicates: true, text: `Notifications saved.` })
      }
    },

    async selectEndShedule(day, time, startTime) {
      if (time === this.notifications.schedule[day].end) return
      const originalEnd = this.notifications.schedule[day].end
      this.notifications.schedule[day].end = time

      if (startTime) {
        if (this.$moment(time, 'h:mm a').isBefore(this.$moment(startTime, 'h:mm a'))) {
          this.$notify({ type: 'error', text: `Start time must be before end time` })
          this.$nextTick(() => {
            this.notifications.schedule[day].end = originalEnd
          })
          return
        }

        await UserService.update({
          userId: this.user._id,
          fields: {
            [`notifications.schedule.${day}`]: {
              start: startTime,
              end: time,
            },
          },
        })
        this.$notify({ ignoreDuplicates: true, text: `Notifications saved.` })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.schedule-select {
  min-width: 120px;
}
::v-deep .b-form-spinbutton .btn {
  background: white;
}

::v-deep .b-form-btn-label-control.form-control > .btn {
  background: white;
}

::v-deep .multiselect {
  background: #fff;
  .multiselect__tags {
    padding-right: 32px;
  }
}
::v-deep .options.multiselect {
  .multiselect__tags {
    border: none !important;
    padding-left: 0;

    .multiselect__single {
      width: 145px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .multiselect__content-wrapper {
    border-top: 1px solid #e8e8e8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style>
