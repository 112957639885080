import { getUrlParameter } from '@/router'
import VendastaService from '@/services/VendastaService'

export default {
  namespaced: true,
  state: {
    accountId: undefined,
    code: undefined,
    vendastaNav: {
      navbarUrl: undefined,
      appId: 'MP-JR3NBDB2N7BJN6CMLX52MK7PPHBQJMDZ',
    },
  },
  mutations: {
    setVendastaNavbarUrl(state, navbarUrl) {
      state.vendastaNav.navbarUrl = navbarUrl
    },
    setVendastaAccountId(state, accountId) {
      state.accountId = accountId
    },
    setVendastaSessionCode(state, code) {
      state.code = code
    },
  },
  actions: {
    async isVendastaUser({ commit }) {
      // get Vendasta-related values we can use
      const jwt = localStorage.getItem('jwt')
      if (jwt) {
        const response = await VendastaService.checkVendastaJwt({ jwt })
        const { navbarUrl, accountId } = response.body

        // and if there are navbarUrl and accountId values present, return the needed info
        if (navbarUrl && accountId) {
          commit('setVendastaNavbarUrl', navbarUrl)
          commit('setVendastaAccountId', accountId)
        }
      }
    },
    async isVendastaRequest({ state, commit }) {
      if (window.location.href.includes('oauth/')) return
      commit('setVendastaAccountId', getUrlParameter('accountId'))
      commit('setVendastaSessionCode', getUrlParameter('code'))
      const isSignup = window.location.href.indexOf('signup') > -1 ? true : false

      // If we're not on the /signup page AND there's either an 'accountId' or 'code' url
      // parameter, that means it's a Vendasta user
      return !isSignup && (state.accountId || state.code)
    },
    async initVendastaSSO({ state, commit }) {
      try {
        const response = (async () => {
          const jwt = localStorage.getItem('jwt')
          return await VendastaService.vendastaSsoWorkflow({
            accountId: state.accountId,
            jwt,
            code: state.code,
          })
        })()
        const { isOvationAdmin, jwt, redirectUrl, navbarUrl } = response.body
        localStorage.setItem('isOvationAdmin', isOvationAdmin ? 'yes' : 'no')

        if (jwt) {
          localStorage.setItem('jwt', jwt)
          localStorage.setItem('isVendastaUser', 'yes')
        }
        if (redirectUrl) {
          // no valid session for Vendasta user. Redirect to Vendasta to log in
          window.location = redirectUrl
        }
        if (navbarUrl) {
          commit('setVendastaNavbarUrl', navbarUrl)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async initVendastaNavbar({ state }) {
      const vendastaNavBar = document.createElement('script')
      vendastaNavBar.setAttribute(
        'src',
        '//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js'
      )
      vendastaNavBar.setAttribute('data-url', state.vendastaNav.navbarUrl)
      vendastaNavBar.setAttribute('data-account-id', state.accountId)
      vendastaNavBar.setAttribute('data-app-id', state.vendastaNav.appId)
      document.getElementsByTagName('head')[0].appendChild(vendastaNavBar)
    },
  },
}
