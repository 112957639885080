<template>
  <div ref="uploader"></div>
</template>

<script>
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  name: 'FileUploader',
  props: ['buttonText', 'buttonClass'],
  watch: {
    buttonText() {
      this.$refs.uploader.querySelector('button').innerText = this.buttonText
    },
  },
  methods: {
    async uploadFile(file) {
      const button = this.$refs.uploader.querySelector('button')
      button.classList.add('is-loading')
      button.disabled = true

      const randomString = Math.random().toString(36).substring(7)
      const fileExtension = file.name.split('.').pop()
      const storageRef = firebase.storage().ref().child(`uploads/${randomString}.${fileExtension}`)

      const snap = await storageRef.put(file.data)
      const url = await snap.ref.getDownloadURL()

      this.$refs.uploader.querySelector('button').classList.add('is-loading')

      this.$emit('uploaded', url)
      button.classList.remove('is-loading')
      button.disabled = false
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: ['image/*', 'text/csv'],
      },
      onBeforeFileAdded: (file) => {
        this.uploadFile(file)
      },
    })

    uppy.use(FileInput, {
      target: this.$refs.uploader,
      locale: {
        strings: {
          chooseFiles: this.buttonText,
        },
      },
    })

    this.$refs.uploader
      .querySelector('button')
      .classList.add('btn', this.buttonClass || 'btn-white')
  },
}
</script>

<style scoped lang="scss">
::v-deep .btn-white {
  border-color: #d2ddec;
}
</style>
