import DataCoreService from '@/services/DataCoreService'

export default {
  namespaced: true,
  state: {
    activeCompanyIds: [],
    activeUserId: null,
    activeRouteName: null,
    oldCompanyIds: [],
    oldUserId: null,
    oldRouteName: null,
    campaigns: [],
    companies: [],
    integrations: [],
    locations: [],
    promos: [],
    users: [],
  },
  getters: {
    selectRawActiveCompanyIds: (state) => state.activeCompanyIds,
    selectActiveCompanyIds: (state) =>
      state.activeCompanyIds.length
        ? state.activeCompanyIds[0] === 'ALL_COMPANIES'
          ? state.companies.map((company) => company._id)
          : state.activeCompanyIds
        : state.activeCompanyIds,
    selectActiveCompanies: (state) =>
      state.companies.filter(
        (company) =>
          state.activeCompanyIds.includes('ALL_COMPANIES') ||
          state.activeCompanyIds.includes(company._id)
      ),
    selectActiveUserId: (state) => state.activeUserId,
    selectActiveRouteName: (state) => state.activeRouteName,
    selectOldCompanyIds: (state) => state.oldCompanyIds,
    selectOldUserId: (state) => state.oldUserId,
    selectOldRouteName: (state) => state.oldRouteName,
    selectCampaigns: (state) => state.campaigns,
    selectCampaignById: (state) => (campaignId) => {
      return state.campaigns.find((campaign) => campaignId == campaign._id)
    },
    selectCompanies: (state) => state.companies,
    selectCompanyById: (state) => (companyId) => {
      return state.companies.find((company) => companyId == company._id)
    },
    selectIntegrations: (state) => state.integrations,
    selectIntegrationById: (state) => (integrationId) => {
      return state.integrations.find((integration) => integrationId == integration._id)
    },
    selectLocations: (state) => state.locations,
    selectLocationById: (state) => (locationId) => {
      return state.locations.find((location) => locationId == location._id)
    },
    selectActiveLocations: (state) =>
      state.locations.filter(
        (location) =>
          state.activeCompanyIds.includes('ALL_COMPANIES') ||
          state.activeCompanyIds.includes(location.company)
      ),
    selectPromos: (state) => state.promos,
    selectPromoById: (state) => (promoId) => {
      return state.promos.find((promo) => promoId == promo._id)
    },
    selectUsers: (state) => state.users,
    selectUserById: (state) => (userId) => {
      return state.users.find((user) => userId == user._id)
    },
  },
  mutations: {
    SET_ACTIVE_COMPANY_IDS(state, activeCompanyIds) {
      state.activeCompanyIds = activeCompanyIds
    },
    SET_ACTIVE_USER_ID(state, activeUserId) {
      state.activeUserId = activeUserId
    },
    SET_ACTIVE_ROUTE_NAME(state, activeRouteName) {
      state.activeRouteName = activeRouteName
    },
    SET_OLD_COMPANY_IDS(state, oldCompanyIds) {
      state.oldCompanyIds = oldCompanyIds
    },
    SET_OLD_USER_ID(state, oldUserId) {
      state.oldUserId = oldUserId
    },
    SET_OLD_ROUTE_NAME(state, oldRouteName) {
      state.oldRouteName = oldRouteName
    },
    SET_CAMPAIGNS(state, campaigns) {
      state.campaigns = [...campaigns]
    },
    SET_COMPANIES(state, companies) {
      state.companies = [...companies]
    },
    SET_INTEGRATIONS(state, integrations) {
      state.integrations = [...integrations]
    },
    SET_LOCATIONS(state, locations) {
      state.locations = [...locations]
    },
    SET_PROMOS(state, promos) {
      state.promos = [...promos]
    },
    SET_USERS(state, users) {
      state.users = [...users]
    },
  },
  actions: {
    async init({ dispatch }) {
      await Promise.all([
        dispatch('fetchCampaigns'),
        dispatch('fetchCompanies'),
        dispatch('fetchIntegrations'),
        dispatch('fetchLocations'),
        dispatch('fetchPromos'),
        dispatch('fetchUsers'),
      ])
    },
    async setActiveCompanyIds({ commit, dispatch, getters, rootGetters }, activeCompany) {
      let activeCompanyIds = activeCompany ? [activeCompany._id] : []
      const { selectActiveCompanyIds } = getters
      commit('SET_OLD_COMPANY_IDS', selectActiveCompanyIds)
      commit('SET_ACTIVE_COMPANY_IDS', activeCompanyIds)

      let companyIds = activeCompany
        ? activeCompany._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies'].map((company) => company._id)
          : [activeCompany._id]
        : []
      dispatch('featureFlag/setFeatureFlagCompanies', companyIds, { root: true })
      if (companyIds.length && selectActiveCompanyIds.length) {
        dispatch(
          'websocket/subscribe',
          companyIds.map((companyId) => {
            return { key: 'ovationup-winback-conversation', value: companyId }
          }),
          { root: true }
        )
      }

      if (activeCompanyIds.length) {
        dispatch('routeInit')
      } else {
        dispatch('routeDestroy')
      }
    },
    async setActiveUserId({ commit, dispatch, getters }, user) {
      let activeUser = user ? user._id : null
      commit('SET_OLD_USER_ID', getters.selectActiveUserId)
      commit('SET_ACTIVE_USER_ID', activeUser)
      if (activeUser) {
        dispatch('routeInit')
      } else {
        dispatch('routeDestroy')
      }
    },
    async setActiveRoute({ commit, dispatch, getters }, route) {
      let routeName = route ? route.name : null
      commit('SET_OLD_ROUTE_NAME', getters.selectActiveRouteName)
      commit('SET_ACTIVE_ROUTE_NAME', routeName)
      if (routeName) {
        dispatch('routeInit')
      } else {
        dispatch('routeDestroy')
      }
    },
    async routeInit({ dispatch, getters }) {
      const { selectRawActiveCompanyIds, selectActiveRouteName, selectActiveUserId } = getters

      if (!selectRawActiveCompanyIds.length || !selectActiveRouteName || !selectActiveUserId) {
        return
      }

      switch (selectActiveRouteName) {
        case 'winback':
          dispatch('winback/winbackInit', null, { root: true })
          return
      }
    },
    async routeDestroy({ dispatch, getters, rootGetters }) {
      const { selectOldRouteName } = getters
      switch (selectOldRouteName) {
        case 'winback':
          dispatch('winback/resetAll', null, { root: true })
          return
      }
    },
    async fetchCampaigns({ rootGetters, commit }) {
      const onlineUser = rootGetters['user/onlineUser']
      let response = await DataCoreService.fetchCampaigns({
        companyIds: onlineUser.companies,
      })

      if (response.body.success !== true) {
        throw response.body
      }

      commit('SET_CAMPAIGNS', response.body.data)
    },
    async fetchCompanies({ rootGetters, commit }) {
      const onlineUser = rootGetters['user/onlineUser']
      let response = await DataCoreService.fetchCompanies({
        companyIds: onlineUser.companies,
      })

      if (response.body.success !== true) {
        throw response.body
      }

      commit('SET_COMPANIES', response.body.data)
    },
    async fetchIntegrations({ rootGetters, commit }) {
      const onlineUser = rootGetters['user/onlineUser']
      let response = await DataCoreService.fetchIntegrations({
        companyIds: onlineUser.companies,
        locationIds: onlineUser.role !== 'admin' ? onlineUser.locations : null,
      })

      if (response.body.success !== true) {
        throw response.body
      }

      commit('SET_INTEGRATIONS', response.body.data)
    },
    async fetchLocations({ rootGetters, commit }) {
      const onlineUser = rootGetters['user/onlineUser']
      let response = await DataCoreService.fetchLocations({
        companyIds: onlineUser.companies,
        locationIds: onlineUser.role !== 'admin' ? onlineUser.locations : null,
      })

      if (response.body.success !== true) {
        throw response.body
      }

      commit('SET_LOCATIONS', response.body.data)
    },
    async fetchPromos({ rootGetters, commit }) {
      const onlineUser = rootGetters['user/onlineUser']
      let response = await DataCoreService.fetchPromos({
        companyIds: onlineUser.companies,
      })

      if (response.body.success !== true) {
        throw response.body
      }

      commit('SET_PROMOS', response.body.data)
    },
    async fetchUsers({ rootGetters, commit }) {
      const onlineUser = rootGetters['user/onlineUser']
      let response = await DataCoreService.fetchUsers({
        companyIds: onlineUser.companies,
      })

      if (response.body.success !== true) {
        throw response.body
      }

      commit('SET_USERS', response.body.data)
    },
  },
}
