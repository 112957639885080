import Vue from 'vue'

export default {
  fetchCampaignSetups({ filters, lookups, populate }) {
    return Vue.http.post(`app-services/v2/campaign-setups/list`, { filters, lookups, populate })
  },
  fetchCampaignSetup({ companyId }) {
    return Vue.http.get(`app-services/v2/campaign-setups/${companyId}`)
  },
  deleteCampaignSetup({ campaignSetupId }) {
    return Vue.http.delete(`app-services/v2/campaign-setups/${campaignSetupId}`)
  },
  createCampaignSetup({ campaignSetup }) {
    return Vue.http.post(`app-services/v2/campaign-setups/`, campaignSetup)
  },
  updateCampaignSetup({ campaignSetupId, campaignSetup }) {
    return Vue.http.put(`app-services/v2/campaign-setups/${campaignSetupId}`, campaignSetup)
  },
  sendTestCampaign({ testData }) {
    return Vue.http.post(`app-services/v2/campaign-setups/send-test`, testData)
  },
  fetchTextUsage({ companyId, organizationId }) {
    return Vue.http.post(`app-services/v2/campaign-setups/text-usage`, {
      companyId,
      organizationId,
    })
  },
}
