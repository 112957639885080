/*eslint-env jquery*/

import Colors from './colors'

export const globalOptions = {
  responsive: true,
  maintainAspectRatio: false,
  defaultColor: Colors.primary[600],
  defaultFontColor: Colors.gray[600],
  defaultFontFamily: 'Cerebri Sans',
  defaultFontSize: 13,
  layout: {
    padding: 0,
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      usePointStyle: true,
      padding: 16,
    },
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: Colors.primary[700],
    },
    line: {
      tension: 0,
      lineTension: 0,
      borderWidth: 1.5,
      borderColor: Colors.primary[700],
      backgroundColor: Colors.transparent,
    },
    rectangle: {
      backgroundColor: Colors.primary[700],
    },
    arc: {
      borderWidth: 4,
      backgroundColor: Colors.primary[700],
    },
  },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    titleMarginBottom: 8,
    xPadding: 10,
    yPadding: 10,
    bodySpacing: 6,
    // displayColors: false,
    // titleFontColor: '#000',
    // backgroundColor: 'rgba(255, 255, 255)',
    callbacks: {
      title(item, data) {
        const [{ datasetIndex, label }] = item
        const title = data.tooltipTitle ? data.tooltipTitle[datasetIndex] : label

        return title
      },
      // labelTextColor(item, chart) {
      //   console.log({ item, data: chart.config })
      //   const [{ color }] = chart.config.data.datasets

      //   return color
      // },
    },
  },
}

export const doughnutOptions = {
  cutoutPercentage: 83,
}
