<template>
  <div class="stars mr-2">
    <i v-for="(star, idx) in fullStars" :key="star + idx" class="fas fa-star"></i>
    <i v-for="(star, idx) in emptyStars" :key="star + idx" class="far fa-star"></i>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: ['rating'],
  computed: {
    avgRating() {
      return Math.round(Number(this.rating))
    },
    fullStars() {
      return new Array(this.avgRating || 0).fill(Math.random())
    },
    emptyStars() {
      return new Array(5 - this.avgRating || 0).fill(Math.random())
    },
  },
}
</script>

<style scoped lang="scss"></style>
