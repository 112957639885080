<template>
  <div>
    <div v-if="!loading">
      <!-- text summaries -->
      <div class="d-flex flex-wrap ml--3" v-if="!wcLoading">
        <div class="ml-3 mt-2 mb-2" style="flex: 1 40%" v-if="isEnabled('AI_SUMMARY')">
          <InsightsTextSummary
            :showReviewButton="true"
            :positiveSummary="selectSummaries.positiveSummary"
            :negativeSummary="selectSummaries.negativeSummary"
          />
        </div>

        <div class="flex-grow-1 ml-3 mt-2 mb-2" v-if="isEnabled('WORDCLOUD')">
          <WordCloud
            @refreshDetails="$emit('refreshDetails')"
            v-if="selectWordCloud.length && !wcLoading"
          />
          <div class="card p-3 h-100" v-if="!selectWordCloud.length">- No data for wordcloud</div>
        </div>
      </div>
      <div class="d-flex" v-else>
        <b-skeleton height="160px" class="w-50 mr-3" />
        <b-skeleton height="160px" class="w-50" />
      </div>

      <!-- mentions over time -->
      <div class="d-flex flex-wrap mb-3 ml--3">
        <div class="ml-3 mt-2" style="flex: 1 60%">
          <MentionsOverTime />
        </div>

        <!-- subcategories -->
        <div class="flex-grow-1 ml-3 mt-2" v-if="showExtaDetails">
          <SubcategoryList
            :subcategories="selectSubcategories(selectDetailsFilterCategory._id)"
            @refreshDetails="$emit('refreshDetails')"
          />
        </div>
      </div>

      <!-- single row heatmap -->
      <div v-if="showExtaDetails">
        <SingleLineHeatmap
          :subcategories="selectSubcategories(selectDetailsFilterCategory._id)"
          @refreshDetails="$emit('refreshDetails')"
        />
      </div>

      <div v-if="showLocationMiniHeatmap">
        <LocationMiniHeatmap @refreshDetails="$emit('refreshDetails')" />
      </div>

      <!-- detailed list -->
      <DetailsList />
    </div>

    <!-- loader -->
    <div v-else>
      <div v-for="index in 4" :key="index">
        <b-skeleton width="100%" height="200px" class="mb-4" />
      </div>
    </div>
  </div>
</template>

<script>
import MentionsOverTime from '../cards/MentionsOverTime'
import SubcategoryList from '../cards/SubcategoryList'
import DetailsList from '../cards/DetailsList'
import SingleLineHeatmap from '../cards/SingleLineHeatmap'
import WordCloud from '../cards/WordCloud'
import LocationMiniHeatmap from '../cards/LocationMiniHeatmap'
import InsightsTextSummary from '../cards/InsightsTextSummary'
import { createNamespacedHelpers } from 'vuex'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')
const InsightsModule = createNamespacedHelpers('insights')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'InsightsDetails',
  props: ['loading', 'wcLoading'],
  components: {
    DetailsList,
    MentionsOverTime,
    SubcategoryList,
    SingleLineHeatmap,
    LocationMiniHeatmap,
    InsightsTextSummary,
    WordCloud,
  },
  computed: {
    ...InsightsDetailsModule.mapGetters([
      'selectDetailsFilterCategory',
      'selectDetailFilters',
      'selectSummaries',
      'selectWordCloud',
    ]),
    ...InsightsModule.mapGetters(['selectSubcategories']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    showExtaDetails() {
      return this.selectDetailsFilterCategory && !this.selectDetailsFilterCategory.path
    },
    showLocationMiniHeatmap() {
      return (
        this.selectDetailsFilterCategory?.path &&
        (!this.selectDetailFilters.locations || this.selectDetailFilters.locations?.length > 1)
      )
    },
  },
}
</script>
