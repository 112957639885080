<template>
  <div
    class="avatar"
    :class="[this.size ? 'avatar-' + this.size : '', this.status ? 'avatar-' + this.status : '']"
  >
    <img v-if="img" class="avatar-img rounded-circle" :src="img" />
    <span v-if="!img" class="avatar-title rounded-circle text-uppercase">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  props: ['img', 'firstName', 'lastName', 'size', 'status', 'name'],
  name: 'UserAvatar',
  computed: {
    initials() {
      if (this.firstName && this.lastName) {
        return `${this.firstName.substr(0, 1).toUpperCase()}${this.lastName
          .substr(0, 1)
          .toUpperCase()}`
      } else if (this.firstName) {
        return this.firstName.substr(0, 1).toUpperCase()
      } else if (this.name) {
        return `${this.name.split(' ').slice(0, -1).join(' ').substr(0, 1)}${this.name
          .split(' ')
          .slice(-1)
          .join(' ')
          .substr(0, 1)
          .toUpperCase()}`
      } else {
        return '?'
      }
    },
  },
}
</script>
