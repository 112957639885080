<template>
  <div>
    <div class="row">
      <form class="col-12">
        <div class="form-group mb-4">
          <label for="promoNickname mb-1">What do you want to call this offer internally?</label>
          <small class="form-text text-muted"
            >This nickname will not be displayed to your customers.</small
          >
          <input
            type="text"
            id="promoNickname"
            v-model="form.nickname"
            placeholder="Enter nickname"
            class="form-control"
            @input="updateNickname"
            v-b-popover.hover.topleft="
              'This is what you will see as the name of your offer internally.'
            "
            title="Nickname"
          />
        </div>
        <div class="form-group mb-4">
          <label for="promoTitle mb-1">What's a good title for your offer?</label>
          <small class="form-text text-muted"
            >Customers will see this title when they go to redeem this offer.</small
          >
          <input
            type="text"
            id="promoTitle"
            v-model="form.title"
            placeholder="20% Off Next Visit"
            class="form-control"
            @input="updateTitle"
            v-b-popover.hover.topleft="
              'This is what your customers will see when redeeming this offer.'
            "
            title="Title"
          />
        </div>
        <div class="form-group mb-4">
          <label for="promoTitle mb-1">What other details or terms do you want to add?</label>
          <small class="form-text text-muted"
            >Customers will see this additional information below the title.</small
          >
          <textarea
            v-model="form.description"
            @keyup="updateDescription"
            class="form-control sms text-message-orig"
            id="txt"
            maxlength="1000"
            name="content"
            placeholder="Only one per customer...."
            rows="6"
          ></textarea>
        </div>
        <div class="form-group mb-4">
          <label for="termsAndConditions mb-1"
            >Would you like to link them to additional terms? (optional)</label
          >
          <small class="form-text text-muted">
            This will add a link to the bottom of your terms above where customers will be
            redirected.
          </small>
          <input
            type="text"
            id="termsAndConditions"
            v-model="form.termsAndConditions"
            placeholder="https://www.example.com/termsandconditions"
            class="form-control"
            @input="updateTermsAndConditions"
          />
          <small class="form-text text-danger" v-if="!isValidURL && this.form.termsAndConditions">
            Please provide a properly formatted URL
          </small>
        </div>
        <div class="form-group mb-4">
          <label for="promoTitle mb-1">Where would you like your offer to be shown?</label>
          <small class="form-text text-muted"
            >Your offers will appear as options base on where you want it to be shown.</small
          >
          <MultiSelect
            id="offer-type"
            :allow-empty="false"
            :searchable="false"
            :show-labels="false"
            openDirection="below"
            label="name"
            track-by="value"
            preselectFirst
            placeholder="Select type"
            v-model="form.source"
            :options="sourceOptions"
          ></MultiSelect>
        </div>
        <div class="form-group mb-4">
          <label class="mb-1">Locations</label>
          <small class="form-text text-muted">
            By default, this offer will be available for all locations. If you'd only like certain
            locations to use this offer, select those locations below.
          </small>
          <CustomerLocationFilter
            ref="locationAccessPicker"
            :wideMode="true"
            v-on:updateLocations="setLocations"
            :defaultText="'All Locations'"
          />
        </div>
        <CreatePromoCodes />
        <hr class="mt-4 mb-5" />
        <div class="form-group mb-4">
          <CreatePromoExpiration id="promoExpirationPicker" />
        </div>
        <hr class="mt-4 mb-5" />
        <div>
          <a
            @click="savePromo"
            href="javascript:;"
            class="btn btn-block btn-lg btn-primary"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
            >Save</a
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CustomerLocationFilter from '@/components/Modules/Customer/CustomerFilters/CustomerLocationFilter'
import CreatePromoExpiration from './CreatePromoExpiration'
import CreatePromoCodes from '@/components/Modules/Promo/CreatePromo/PromoCodes/CreatePromoCodes'
import MultiSelect from '@/components/Common/MultiSelect'

const PromoModule = createNamespacedHelpers('promo')
const CompanyModule = createNamespacedHelpers('company')
export default {
  name: 'CreatePromoForm',
  props: ['modal'],
  components: {
    MultiSelect,
    CreatePromoCodes,
    CustomerLocationFilter,
    CreatePromoExpiration,
  },
  data: () => ({
    isLoading: false,
    form: {
      title: '',
      description: '',
      promoCode: '',
      nickname: '',
      termsAndConditions: '',
    },
    sourceOptions: [
      { name: 'Both campaign page and winback conversation', value: 'all' },
      { name: 'On winback conversation only', value: 'winback' },
      { name: 'On campaign page only', value: 'campaign' },
    ],
    isValidURL: true,
  }),
  computed: {
    ...PromoModule.mapState(['promo']),
    ...CompanyModule.mapState(['activeCompany']),
  },
  mounted() {
    if (this.promo._id) {
      this.form.nickname = this.promo.nickname
      this.form.title = this.promo.title
      this.form.description = this.promo.description
      this.form.termsAndConditions = this.promo.termsAndConditions
      this.$refs.locationAccessPicker.reset(this.promo.locations)
    }
    const source = this.promo.source
      ? this.sourceOptions.find((s) => s.value === this.promo.source)
      : this.sourceOptions[0]

    this.$set(this.form, 'source', source || this.sourceOptions[0])
  },
  beforeDestroy() {
    this.resetPromo()
  },
  methods: {
    ...PromoModule.mapMutations(['setTitle', 'setDescription', 'setLocations', 'resetPromo']),
    ...PromoModule.mapActions(['createPromo', 'setNickname', 'setTermsAndConditions']),

    updateTitle() {
      this.setTitle(this.form.title)
    },
    updateDescription() {
      this.setDescription(this.form.description)
    },
    updateNickname() {
      this.setNickname(this.form.nickname)
    },
    updateTermsAndConditions() {
      if (this.validateURL(this.form.termsAndConditions)) {
        this.setTermsAndConditions(this.form.termsAndConditions)
      } else {
        this.setTermsAndConditions(null)
      }
    },
    async savePromo() {
      if (this.form.title.length < 3) {
        this.$notify({
          title: 'Failed to create offer.',
          text: 'Please give your offer a title.',
          type: 'error',
        })
        return
      }
      this.isLoading = true

      const promo = this.promo
      promo.source = this.form.source.value === 'all' ? '' : this.form.source.value

      try {
        // create the new promo
        const response = await this.createPromo({
          promo: {
            ...promo,
            company: this.activeCompany,
          },
        })

        this.isLoading = false
        this.resetPromo()

        this.$notify({
          title: 'Launched!',
          text: `Your promo is ready to go.`,
        })
        this.$refs.locationAccessPicker.reset()
        if (!this.modal) {
          this.$router.push({ name: 'promos' })
        } else {
          this.$emit('saved', response.body.promo)
        }
      } catch (err) {
        this.isLoading = false
        this.$notify({
          title: 'Failed to create offer.',
          text: (err.body && err.body.message) || 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    validateURL(url) {
      const results = url.match(
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      this.isValidURL = results !== null
      return results !== null
    },
  },
}
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 0px;
}
#promoTitle {
  text-transform: capitalize;
}
</style>
