import Vue from 'vue'

export default {
  updateFeaturePreferences(id, payload) {
    return Vue.http.put(`app-services/v2/users/${id}/feature-preferences`, payload)
  },
  listUsers() {
    return Vue.http.get(`app-services/v2/users/company`)
  },
  getMe() {
    return Vue.http.get(`app-services/v2/users/me`)
  },

  // deprecated
  get() {
    return Vue.http.get(`user?webOrigin=${window.location.origin}`)
  },
  create(payload) {
    return Vue.http.post('user', payload)
  },
  update(payload) {
    return Vue.http.post('user/update', payload)
  },
  delete(userId) {
    return Vue.http.delete(`user/${userId}`)
  },
  deleteUserFromCompany(userId, companyId, onlineUserId) {
    return Vue.http.delete(`user/deleteCompany/${userId}/${companyId}/${onlineUserId}`)
  },
  createFromInvite(payload) {
    return Vue.http.post('user/create-from-invite', payload)
  },
  sendPhoneVerification(payload) {
    return Vue.http.post('user/send-verification', payload)
  },
  verifyPhoneCode(payload) {
    return Vue.http.post('user/verify-code', payload)
  },
  ssoLogin(payload) {
    return Vue.http.post('user/sso', payload)
  },
  checkValidPhone(payload) {
    return Vue.http.post('user/check-valid-phone', payload)
  },
}
