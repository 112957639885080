<template>
  <div ref="msg" v-if="_id">
    <Message
      :content="content"
      :user="user || {}"
      :timestamp="created"
      :media="message.media"
      :highlights="highlights"
      :type="message.type"
      :timezone="timezone"
      :customer="customer"
    ></Message>
  </div>
</template>

<script>
import Message from '@/components/Modules/Message/MessageV2'
import { createNamespacedHelpers } from 'vuex'
const TimelineModuleV2 = createNamespacedHelpers('timelineV2')
const UserModule = createNamespacedHelpers('user')

/**
 * MessageEventX
 * This component is a "connected" or "smart" component. Meaning it is
 * coupled to vuex state. All you need to do is pass the _id as a prop
 * and it knows how to select all the data from store state.
 */
export default {
  name: 'MessageEventX',
  props: ['_id', 'conversationId'],
  components: {
    Message,
  },
  mounted() {
    this.$refs.msg.scrollIntoView()
  },
  destroyed() {
    this.clearTempEvent({ conversationId: this.conversationId, eventId: this._id })
  },
  methods: {
    ...TimelineModuleV2.mapActions(['clearTempEvent']),
  },
  computed: {
    ...TimelineModuleV2.mapGetters(['selectTempEventById']),
    ...UserModule.mapGetters(['selectUserBrowserTimezone']),
    tempEvent() {
      return this.selectTempEventById(this._id)
    },
    created() {
      return this.tempEvent?.created
    },
    message() {
      return this.tempEvent?.message
    },
    user() {
      return this.tempEvent?.user
    },
    conversationMatchedMessage() {
      return this.tempEvent?.conversationMatchedMessage
    },
    timezone() {
      return this.selectUserBrowserTimezone
    },
    customer() {
      return this.tempEvent?.customer
    },

    highlights() {
      return (
        this.message &&
        this.conversationMatchedMessage &&
        this.conversationMatchedMessage.score &&
        this.message._id == this.conversationMatchedMessage._id
      )
    },
    content() {
      if (this.highlights) {
        let body = this.conversationMatchedMessage.body
        let highlights = this.conversationMatchedMessage.highlights
        if (highlights && highlights.length) {
          highlights.forEach((highlight) => {
            let replacements = highlight.texts
              .map((text) => {
                if (text.type === 'hit') {
                  return '<mark>' + text.value + '</mark>'
                } else {
                  return text.value
                }
              })
              .join('')

            let originals = highlight.texts
              .map((text) => {
                return text.value
              })
              .join('')

            body = body.replace(originals, replacements)
          })
        }
        return body
      }

      return this.message.body
    },
  },
}
</script>
