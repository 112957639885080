import Vue from 'vue'

export default {
  fetchSubscriptions({ filters }) {
    return Vue.http.post('app-services/v2/subscriptions/list', { filters })
  },
  createSession({ subscriptionId, returnUrl }) {
    return Vue.http.post(`app-services/v2/subscriptions/${subscriptionId}/session`, {
      returnUrl,
    })
  },
}
