<template>
  <div v-if="activeCompany" class="collapse navbar-collapse order-lg-first" id="navbar">
    <!-- brand logo -->
    <router-link :to="{ name: 'dashboard' }" style="min-width: 100px">
      <img
        v-if="onlineUser && onlineUser._id"
        :src="brandTextLogo"
        class="navbar-brand-img d-none d-lg-block my-4 mx-auto"
        style="position: relative"
      />
    </router-link>
    <!-- active company picker -->
    <ul class="navbar-nav my-3">
      <li class="company-picker nav-item py-3">
        <span class="dropdown">
          <a
            href="#!"
            role="button"
            aria-expanded="false"
            aria-haspopup="true"
            data-toggle="dropdown"
            id="company-picker-dropdown"
            class="h2 header-title text-white"
          >
            {{ activeCompany.friendlyName || activeCompany.name | truncate(16) }}
            <span class="fe fe-chevron-down ml-2" v-if="companies.length > 1"></span>
          </a>
          <ul
            aria-labelledby="company-picker-dropdown"
            class="dropdown-menu"
            v-if="companies.length > 1"
            style="position: fixed; top: 160px; left: 10px"
          >
            <li v-for="company of companySelectValues" :key="company._id">
              <a class="dropdown-item" href="#" @click="setCurrentActiveCompany(company)">{{
                company.friendlyName || company.name
              }}</a>
            </li>
          </ul>
        </span>
      </li>
    </ul>
    <!-- winback -->
    <ul class="navbar-nav mr-auto mt-3">
      <li
        class="nav-item"
        v-for="(navItem, idx) in navItems.filter(
          (item) => item.category == 'winback' && item.show == true
        )"
        :key="idx"
      >
        <router-link v-if="!navItem.dropdownItems" :to="{ name: navItem.route }" class="nav-link">
          <i :class="['mr-1', navItem.icon]"></i>
          {{ navItem.title }}
          <transition
            name="custom-classes-transition"
            enter-active-class="animated wobble"
            leave-active-class="animated rollOut"
          >
            <span
              v-if="navItem.route == 'winback' && selectUnresolvedCount"
              class="badge badge-success ml-3"
            >
              {{ selectUnresolvedCount }}
            </span>
          </transition>
        </router-link>
      </li>
    </ul>

    <!-- main -->
    <ul class="navbar-nav mr-auto mt-3">
      <li
        class="nav-item"
        v-for="(navItem, idx) in navItems.filter(
          (item) => item.category == 'main' && item.show == true
        )"
        :key="idx"
      >
        <router-link
          v-if="!navItem.dropdownItems"
          :to="{ name: navItem.route, params: { companyId: activeCompany._id } }"
          class="nav-link"
        >
          <i :class="['mr-1', navItem.icon]"></i>
          {{ navItem.title }}
          <span v-if="navItem.title == 'Call-to-Text'" class="badge nav-new-badge mr--4 ml-2">
            NEW
          </span>
        </router-link>
      </li>
    </ul>
    <!-- boosters -->
    <h4 class="navbar-heading mt-3 mb-0" v-if="!showAllCompanies">Boosters</h4>
    <ul class="navbar-nav mr-auto mt-1">
      <li
        class="nav-item"
        v-for="(navItem, idx) in navItems.filter(
          (item) => item.category == 'boosters' && item.show == true
        )"
        :key="idx"
      >
        <router-link
          v-if="!navItem.dropdownItems"
          :to="{ name: navItem.route, params: { companyId: activeCompany._id } }"
          class="nav-link"
        >
          <i :class="['mr-1', navItem.icon]"></i>
          {{ navItem.title }}
          <span v-if="navItem.title == 'Call-to-Text'" class="badge nav-new-badge mr--4 ml-2">
            NEW
          </span>
        </router-link>
      </li>
    </ul>
    <!-- report -->
    <div
      class="mt-4"
      v-if="navItems.filter((item) => item.category == 'reports' && item.show == true).length > 0"
    >
      <h4 class="navbar-heading mb-1">Reports</h4>
      <ul class="navbar-nav mr-auto">
        <li
          class="nav-item"
          v-for="(navItem, idx) in navItems.filter(
            (item) => item.category == 'reports' && item.show == true
          )"
          :key="idx"
        >
          <router-link v-if="!navItem.dropdownItems" :to="{ name: navItem.route }" class="nav-link">
            <!-- this is a one-off so that the right gift card icon shows -->
            <span style="width: 28px" v-if="navItem.icon === 'gift-card'">
              <CreditCardIcon size="1.15x" class="mt--1"></CreditCardIcon>
            </span>
            <i :class="navItem.icon" v-else></i>
            <span class="ml-1">{{ navItem.title }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- settings -->
    <ul
      class="navbar-nav mt-4"
      v-if="
        selectIsPermitted('navSettingsReadAccess') &&
        (!showAllCompanies ||
          (showAllCompanies && !!selectOrganization && isEnabled('ORG_CAMPAIGNS')))
      "
    >
      <li class="nav-item">
        <a
          class="nav-link"
          href="#settings-dropdown"
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="settings-dropdown"
        >
          <i class="fe fe-settings"></i> Settings
        </a>
        <div class="collapse" id="settings-dropdown" style="">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item" v-for="(navItem, idx) in companyNavigation" :key="idx">
              <router-link
                v-if="shouldShowNavItem(navItem.route)"
                :to="{ name: navItem.route }"
                class="nav-link"
                >{{ navItem.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <!-- account -->
    <ul class="navbar-nav mr-auto mt-2" v-if="!showAllCompanies">
      <li class="nav-item">
        <router-link :to="{ name: 'account-profile' }" class="nav-link">
          <i class="mr-1 far fa-user-circle fa-lg"></i>
          Account
        </router-link>
      </li>
    </ul>
    <!-- avatar -->
    <li class="d-flex justify-content-start align-items-center mt-auto pt-5 pb-3">
      <Avatar
        :img="onlineUser.avatar"
        :first-name="onlineUser.firstName"
        :last-name="onlineUser.lastName"
        size="sm"
        status="online"
      />
      <a @click="logout" href="javascript:;" class="logout px-4">Logout</a>
    </li>
  </div>
</template>

<script>
import { CreditCardIcon } from 'vue-feather-icons'
import { mapState, mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import { removeUserActiveCompanyId } from '@/store/company'
import Avatar from '@/components/MainContent/Avatar'

const AuthenticationModule = createNamespacedHelpers('authentication')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const LocationModule = createNamespacedHelpers('location')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'MainNav',
  components: {
    Avatar,
    CreditCardIcon,
  },
  computed: {
    ...mapState('user', ['onlineUser']),
    ...mapGetters('user', ['brandTextLogo', 'onlineUser']),
    ...mapGetters('featureFlag', ['isEnabled']),
    ...mapState('company', ['activeCompany', 'companies']),
    ...mapGetters('company', ['loyaltyEnabled', 'selectIsPermitted']),
    ...mapGetters('navigation', ['companyNavigation']),
    ...mapGetters('navigation', { cttNavItems: 'selectCTTAllowedNavigation' }),
    ...ConversationModuleV2.mapGetters(['selectUnresolvedCount']),
    ...LocationModule.mapGetters(['userActiveCompanyLocations']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    companySelectValues() {
      const activeCompanies = this.companies.filter((c) => c.status !== 'cancelled')
      return this.companies && this.companies.length > 1
        ? [{ name: 'All Companies', _id: 'ALL_COMPANIES' }, ...activeCompanies]
        : activeCompanies
    },
    settingsNavItems() {
      const allCompanySettings = ['Offers']
      return this.showAllCompanies && this.isEnabled('ORG_CAMPAIGNS')
        ? this.companyNavigation.filter((cn) => allCompanySettings.includes(cn.title))
        : this.companyNavigation
    },
    query() {
      return this.$route.query
    },
    navItems() {
      return [
        {
          title: 'Dashboard',
          route: 'dashboard',
          icon: 'fe fe-home',
          category: 'main',
          show: this.navShowDashboard,
        },
        {
          title: 'Leaderboard',
          route: 'leaderboard',
          icon: 'fe fe-bar-chart-2',
          category: 'main',
          show: this.navShowLeaderboard,
        },
        {
          title: 'Customers',
          route: 'customers',
          icon: 'fe fe-users',
          category: 'main',
          show: this.navShowCustomers,
        },
        {
          title: 'Insights',
          route: 'insights-summary',
          icon: 'far fa-lightbulb',
          category: 'boosters',
          show: this.navShowInsights,
        },
        {
          title: 'Call-to-Text',
          route: 'call-reports',
          icon: 'fa-regular fa-phone fa-pro-icon',
          category: 'boosters',
          show: this.navShowCalls,
        },
        {
          title: 'Report Builder',
          route: 'report-builder',
          icon: 'far fa-file-alt fa-md pl-1',
          category: 'reports',
          show: this.navShowReportBuilder,
        },
        {
          title: 'Advanced Reports',
          route: 'report-dashboard',
          icon: 'far fa-chart-line fa-md pl-1',
          category: 'reports',
          show: this.navShowReports,
        },
        {
          title: 'Reviews',
          route: 'reviews',
          icon: 'fe fe-star',
          category: 'reports',
          show: this.navShowReviews,
        },
        {
          title: 'Surveys',
          route: 'surveys',
          icon: 'fe fe-inbox',
          category: 'reports',
          show: this.navShowSurveys,
        },
        {
          title: 'Loyalty',
          route: 'loyalty',
          icon: 'fe fe-heart',
          category: 'reports',
          show: this.navShowLoyalty,
        },
        {
          title: 'Gift Cards',
          route: 'gift-card-reports',
          icon: 'gift-card',
          category: 'reports',
          show: this.navShowGiftCards,
        },
        {
          title: 'Winback',
          route: 'winback',
          icon: 'fe fe-message-square',
          category: 'winback',
          show: this.navShowWinback,
        },
        {
          title: 'Text Marketing',
          route: 'campaigns',
          icon: 'fe fe-message-circle',
          category: 'boosters',
          show: this.navShowCampaigns,
        },
        {
          title: 'Custom Questions',
          route: 'custom-questions-reports',
          icon: 'fas fa-list',
          category: 'boosters',
          show: this.navShowCustomQuestionsReports,
        },
        {
          title: 'Listings and Review Management',
          route: 'marqii',
          icon: 'far fa-billboard',
          category: 'boosters',
          show: this.navShowMarqii,
        },
      ]
    },
    isAdmin() {
      return this.onlineUser.role === 'admin'
    },
    isLocationManager() {
      return this.onlineUser.role === 'location-manager'
    },
    showAllCompanies() {
      return this.activeCompany && this.activeCompany._id == 'ALL_COMPANIES'
    },
    onlyShowCTT() {
      return this.activeCompany?.settings?.cttOnly
    },
    navShowDashboard() {
      return !this.onlyShowCTT
    },
    navShowLeaderboard() {
      return !this.onlyShowCTT
    },
    navShowCustomers() {
      return !this.showAllCompanies && !this.onlyShowCTT
    },
    navShowInsights() {
      return !this.onlyShowCTT && this.selectIsPermitted('insightsReadAccess')
    },
    navShowReportBuilder() {
      return !this.showAllCompanies && !this.onlyShowCTT && this.activeCompany.reportBuilderAccess
    },
    navShowReports() {
      return (
        !this.showAllCompanies &&
        this.activeCompany?.settings?.advancedReportsEnabled &&
        this.isEnabled('BI_REPORTS') &&
        !this.onlyShowCTT
      )
    },
    navShowReviews() {
      return !this.showAllCompanies && !this.onlyShowCTT
    },
    navShowSurveys() {
      return !this.onlyShowCTT
    },
    navShowLoyalty() {
      return !this.showAllCompanies && !this.onlyShowCTT && this.loyaltyEnabled
    },
    navShowWinback() {
      return this.selectIsPermitted('winbackReadAccess')
    },
    navShowMarqii() {
      return !this.onlyShowCTT && !this.showAllCompanies
    },
    navShowCustomQuestionsReports() {
      if (
        !this.showAllCompanies &&
        this.activeCompany &&
        this.activeCompany.customQuestionsEnabled &&
        !this.onlyShowCTT
      ) {
        return true
      } else {
        return false
      }
    },
    navShowCampaigns() {
      return (
        this.selectIsPermitted('campaignsReadAccess') &&
        !this.onlyShowCTT &&
        (!this.showAllCompanies ||
          (this.activeCompany?._id === 'ALL_COMPANIES' &&
            !!this.selectOrganization &&
            this.isEnabled('ORG_CAMPAIGNS')))
      )
    },
    navShowPromos() {
      return (
        this.selectIsPermitted('promosReadAccess') &&
        (!this.showAllCompanies ||
          (this.activeCompany?._id === 'ALL_COMPANIES' && !!this.selectOrganization))
      )
    },
    navShowKeywords() {
      return (
        !this.showAllCompanies && !this.onlyShowCTT && this.selectIsPermitted('keywordsReadAccess')
      )
    },
    navShowCalls() {
      return !this.showAllCompanies && this.isEnabled('IVR_ANALYTICS_AND_CALL_EVENTS')
    },
    navShowGiftCards() {
      return (
        !this.showAllCompanies &&
        this.userActiveCompanyLocations?.find((l) => l?.settings?.giftCardEnabled) &&
        !this.onlyShowCTT
      )
    },
    navShowCompanySettingsProfile() {
      return !this.showAllCompanies
    },
    navShowCompanySettingsLocations() {
      return !this.showAllCompanies
    },
    navShowCompanySettingsLinks() {
      return !this.showAllCompanies && !this.onlyShowCTT
    },
    navShowCompanySettingsTeam() {
      return !this.showAllCompanies
    },
    navShowCompanySettingsLoyalty() {
      return !this.showAllCompanies && !this.onlyShowCTT
    },
    navShowCompanySettingsResponses() {
      return (
        !this.showAllCompanies &&
        !this.onlyShowCTT &&
        this.activeCompany?.settings?.responsesEnabled &&
        this.isAdmin
      )
    },
    navShowSettingsTemplates() {
      return !this.showAllCompanies && !this.onlyShowCTT
    },
    navShowBilling() {
      return !this.showAllCompanies
    },
  },
  methods: {
    ...AuthenticationModule.mapActions(['resetAuth']),
    ...mapActions('conversationV2', ['setUnresolvedCount']),
    ...mapActions('company', ['setActiveCompany']),
    allCompaniesRedirect() {
      if (this.$route.path.startsWith('/winback') && !this.selectIsPermitted('winbackReadAccess')) {
        this.$router.push('/dashboard')
        return
      }

      if (this.onlyShowCTT && !this.cttNavItems.includes(this.$route.name)) {
        this.$router.push('/calls/reports')
      } else if (
        (this.activeCompany?._id == 'ALL_COMPANIES' &&
          (![
            'dashboard',
            'leaderboard',
            'winback',
            'insights',
            'insights-summary',
            'insights-details',
            'surveys',
            'campaigns',
            'create-campaign',
            'promos',
            'create-promo',
          ].includes(this.$route.name) ||
            (this.$route.name === 'campaigns' && !!!this.selectOrganization))) ||
        (['insights', 'insights-summary', 'insights-details'].includes(this.$route.name) &&
          !this.selectIsPermitted('insightsReadAccess'))
      ) {
        this.$router.push('/dashboard')
      }
    },
    async logout() {
      await this.resetAuth()
      removeUserActiveCompanyId(this.onlineUser._id)
      localStorage.removeItem('jwt')
      localStorage.removeItem('isOvationAdmin')
    },
    async setCurrentActiveCompany(company) {
      if (this.$route.name.includes('location-settings-platforms')) {
        this.$router.push({ name: 'company-settings-locations' })
      }
      await this.setActiveCompany(company)
    },
    shouldShowNavItem(route) {
      switch (route) {
        case 'promos':
          return this.navShowPromos
        case 'keywords':
          return this.navShowKeywords
        case 'company-settings-subscription':
          return this.navShowBilling
        case 'company-settings-profile':
          return this.navShowCompanySettingsProfile
        case 'company-settings-locations':
          return this.navShowCompanySettingsLocations
        case 'company-settings-links':
          return this.navShowCompanySettingsLinks
        case 'company-settings-team':
          return this.navShowCompanySettingsTeam
        case 'company-settings-loyalty':
          return this.navShowCompanySettingsLoyalty
        case 'company-settings-responses':
          return this.navShowCompanySettingsResponses
        case 'templates':
          return this.navShowSettingsTemplates
        default:
          return true
      }
    },
  },
  mounted() {
    this.setUnresolvedCount()
    this.allCompaniesRedirect()
  },
  watch: {
    activeCompany: function () {
      this.setUnresolvedCount()
      this.allCompaniesRedirect()
    },
    $route() {
      this.allCompaniesRedirect()
    },
  },
}
</script>

<style scoped lang="scss">
.fas,
.far {
  min-width: 1.75rem;
}
.navbar-heading {
  font-size: 12px;
  color: #6e84a3;
}
.logout {
  color: #ffffff80;
  &:hover {
    color: #ffffffbf;
  }
}
.company-picker {
  background-color: #0f2e69;
  text-align: center;
}
#company-picker-dropdown:hover {
  color: #ffffffbf;
}
.nav-new-badge {
  font-size: 10px;
  background-color: var(--indigo);
  color: white;
}
.fa-pro-icon {
  width: 28px;
}
</style>
