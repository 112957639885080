<template>
  <div class="card">
    <!-- {{device}} -->
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto text-center">
          <!-- Avatar -->
          <a href="#!" class="avatar avatar-lg kiosk">
            <img src="/img/illustrations/ipad-kiosk.svg" />
          </a>
        </div>
        <div class="col">
          <!-- Title -->
          <h4 class="card-title mb-1">
            <a href="#!">{{ device.device }}</a>
          </h4>

          <!-- Status -->
          <p class="card-text text-muted small">
            {{ device.installationId }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationSettingsDevicesCard',
  props: ['device'],
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss">
.avatar {
  &.tent,
  &.beacon {
    img {
      height: 65px;
    }
  }

  &.kiosk {
    padding-top: 8px;
    img {
      width: 75px;
    }
  }
}
</style>
