<template>
  <div>
    <button
      class="p-1 d-flex align-items-center"
      :class="{ 'btn btn-link': !amount, 'btn btn-primary': amount }"
      @click="showModal"
    >
      <CreditCardIcon
        size="1.56x"
        :style="{ color: amount ? 'white !important' : '#95aac9' }"
      ></CreditCardIcon>
      <span class="text-white ml-1" v-if="amount">${{ displayedAmount }}</span>
    </button>

    <GiftCardModal @setAmount="setAmount" @resetAmount="resetAmount" />
  </div>
</template>

<script>
import { CreditCardIcon } from 'vue-feather-icons'
import GiftCardModal from '@/components/Modals/GiftCardModal'

export default {
  name: 'GiftCardButton',
  components: { CreditCardIcon, GiftCardModal },
  data: () => ({
    amount: 0,
  }),
  computed: {
    displayedAmount() {
      if (this.amount % parseInt(this.amount) === 0) {
        return parseInt(this.amount)
      } else {
        return this.amount
      }
    },
  },
  methods: {
    setAmount(val) {
      this.amount = val
      this.$emit('setAmount', this.amount)
    },
    resetAmount() {
      this.amount = 0
      this.$emit('setAmount', null)
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'GiftCardModal')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep button > svg:hover {
  color: #12263f !important;
}
</style>
