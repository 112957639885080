import Vue from 'vue'

export default {
  blockCustomer(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'block-customer' },
      payload
    )
  },
  unblockCustomer(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'unblock-customer' },
      payload
    )
  },
  resolveConversation(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'resolve-conversation' },
      payload
    )
  },
  unresolveConversation(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'unresolve-conversation' },
      payload
    )
  },
  fetchConversations(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'fetch-conversations' },
      payload
    )
  },
  fetchResponseTimes(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'fetch-response-times' },
      payload
    )
  },
  fetchTags(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'fetch-tags' },
      payload
    )
  },
  fetchTimeline(payload) {
    return Vue.http.post(
      { apiName: 'ovationupProductApi', resource: 'winback', action: 'fetch-timeline' },
      payload
    )
  },
  sendReply(payload) {
    return Vue.http.post('conversation/reply', payload)
  },
}
