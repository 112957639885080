import Vue from 'vue'

export default {
  create(payload) {
    return Vue.http.post('app-services/v2/rule', payload)
  },
  list(payload) {
    return Vue.http.post('app-services/v2/rule/list', payload)
  },
  update(id, payload) {
    return Vue.http.put(`app-services/v2/rule/${id}`, payload)
  },
  deleteRule(id) {
    return Vue.http.delete(`app-services/v2/rule/${id}`)
  },
}
