import Config from '@/config'
import Pusher from 'pusher-js'

export default {
  namespaced: true,
  state: {
    pusher: null,
    userChannel: null,
    winbackChannel: null,
    connectionReady: false,
  },
  getters: {
    selectPusher: (state) => state.pusher,
    selectUserChannel: (state) => state.userChannel,
    selectWinbackChannel: (state) => state.winbackChannel,
    selectConnectionReady: (state) => state.connectionReady,
  },
  mutations: {
    SET_PUSHER(state, pusher) {
      state.pusher = pusher
    },
    SET_USER_CHANNEL(state, userChannel) {
      state.userChannel = userChannel
    },
    SET_WINBACK_CHANNEL(state, winbackChannel) {
      state.winbackChannel = winbackChannel
    },
    SET_CONNECTION_READY(state, status) {
      state.connectionReady = status
    },
  },
  actions: {
    async pusherConnect({ commit, dispatch, getters, rootGetters }) {
      if (
        getters.selectPusher ||
        !localStorage.getItem('jwt') === null ||
        !rootGetters['user/onlineUser']
      )
        return Promise.resolve()

      return new Promise((resolve, reject) => {
        const { apiApp, pusherKey } = Config

        const pusher = new Pusher(pusherKey, {
          cluster: 'mt1',
          forceTLS: true,
          activityTimeout: 30000, // sends a ping message after 30 seconds of no messages
          pongTimeout: 10000, // response to ping if it takes longer than 10 seconds assumes dead
          // TODO: set up to support multiple environments
          channelAuthorization: {
            headers: {
              authorization: localStorage.getItem('jwt'),
            },
            endpoint: `${apiApp}/app-services/v2/websocket-notification/channel/authorization`,
          },
          userAuthentication: {
            headers: {
              authorization: localStorage.getItem('jwt'),
            },
            endpoint: `${apiApp}/app-services/v2/websocket-notification/user/authorization`,
          },
        })

        pusher.signin()

        // implement this when other channels are built out
        pusher.connection.bind('connected', async () => {
          commit('SET_PUSHER', pusher)
          commit('SET_CONNECTION_READY', true)
          await dispatch('userChannelSubscribe')
          resolve()
        })

        pusher.connection.bind('disconnected', async () => {
          commit('SET_PUSHER', null)
          commit('SET_CONNECTION_READY', false)
          setTimeout(async () => {
            await dispatch('pusherConnect')
          }, 3000)
        })

        pusher.connection.bind('failed', async () => {
          commit('SET_PUSHER', null)
          commit('SET_CONNECTION_READY', false)
        })
      })

      // TODO: Handle setting up multiple subscriptions if 'all-companies' is selected
    },

    async pusherDisconnect({ commit, dispatch, getters }) {
      dispatch('userChannelUnsubscribe')
      if (getters.selectWinbackChannel) {
        dispatch('winbackCompanyChannelUnsubscribe')
      }

      // TODO: implement these when company/conversation channels are built out
      // dispatch('companyChannelUnsubscribe')
      // dispatch('conversationChannelUnsubscribe')

      getters.selectPusher.disconnect()
      commit('SET_PUSHER', null)
    },

    async userChannelSubscribe({ getters, rootGetters, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        const pusher = getters.selectPusher
        const channel = pusher.subscribe(`private-user-${rootGetters['user/onlineUser']._id}`)

        channel.bind('pusher:subscription_error', (error) => {
          console.log(error)
          reject(error)
        })
        channel.bind('pusher:subscription_succeeded', (val) => {
          resolve()
        })
        channel.bind('TIMELINE_EVENT_ADDED', (value) => {
          dispatch('timelineV2/processTimelineEventAdded', value.analyticEvent, { root: true })
        })
        channel.bind('CONVERSATION_UPDATED', (value) => {
          dispatch('conversationV2/processConversationUpdated', value.conversation, { root: true })
        })
        channel.bind('CONVERSATION_ADDED', (value) => {
          dispatch('conversationV2/processConversationAdded', value.conversation, { root: true })
        })
        channel.bind('PREVIEW_CUSTOM_FILTERS', (value) => {
          dispatch('campaignSetups/setCustomFiltersResponse', value, { root: true })
        })

        commit('SET_USER_CHANNEL', channel)
      })
    },

    async userChannelUnsubscribe({ getters, rootGetters }) {
      const pusher = getters.selectPusher
      getters.selectUserChannel.unbind_all()
      pusher.unsubscribe(`private-user-${rootGetters['user/onlineUser']._id}`)
    },

    async winbackCompanyChannelSubscribe({ getters, rootGetters, commit }) {
      return new Promise((resolve, reject) => {
        const pusher = getters.selectPusher
        const channel = pusher.subscribe(
          `presence-company-${rootGetters['company/activeCompany']._id}-winback`
        )
        channel.bind('pusher:subscription_error', (error) => {
          console.log(error)
          reject(error)
        })
        channel.bind('pusher:subscription_succeeded', (val) => {
          resolve()
        })

        commit('SET_WINBACK_CHANNEL', channel)
      })
    },

    async winbackCompanyChannelUnsubscribe({ getters, rootGetters }) {
      const pusher = getters.selectPusher
      getters.selectWinbackChannel.unbind_all()
      pusher.unsubscribe(getters.selectWinbackChannel?.name)
    },

    async companyChannelSubscribe({ getters, rootGetters }) {
      return new Promise((resolve, reject) => {
        const pusher = getters.selectPusher
        const channel = pusher.subscribe(
          `presence-company-${rootGetters['company/activeCompany']._id}`
        )
        channel.bind('pusher:subscription_error', (error) => {
          console.log(error)
          reject(error)
        })
        channel.bind('pusher:subscription_succeeded', (val) => {
          console.log(val)
          resolve()
        })
      })
    },

    async companyChannelUnsubscribe({ getters, rootGetters }) {
      const pusher = getters.selectPusher
      pusher.unsubscribe(`presence-company-${rootGetters['company/activeCompany']._id}`)
    },

    async conversationChannelSubscribe({ getters }, { conversationId }) {
      return new Promise((resolve, reject) => {
        const pusher = getters.selectPusher
        const channel = pusher.subscribe(`presence-conversation-${conversationId}`)
        channel.bind('pusher:subscription_error', (error) => {
          console.log(error)
          reject(error)
        })
        channel.bind('pusher:subscription_succeeded', (val) => {
          console.log(val)
          resolve()
        })
      })
    },

    async conversationChannelUnsubscribe({ getters }, { conversationId }) {
      const pusher = getters.selectPusher
      pusher.unsubscribe(`presence-conversation-${conversationId}`)
    },
  },
}
