<template>
  <multiselect
    placeholder="Type to search"
    style="min-width: 100px"
    selectLabel
    v-model="selectedSource"
    @input="updateSource"
    :searchable="true"
    :options="sources"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary"> <span class="fas fa-inbox"></span> {{ placeholder }} </span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const surveyModule = createNamespacedHelpers('survey')

export default {
  name: 'SingleSourceFilter',
  components: {
    Multiselect,
  },
  props: {
    placeholder: {
      type: String,
      default: 'All sources',
    },
  },
  data: () => ({
    selectedSource: '',
  }),
  computed: {
    ...surveyModule.mapState(['sources']),
  },
  methods: {
    reset(locations) {
      this.filteredLocations = []
    },
    updateSource(source) {
      this.$emit('updateSource', source)
    },
    sourceName({ name }) {
      return name
    },
  },
}
</script>
