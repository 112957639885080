<template>
  <div class="card px-4 pt-4 text-center h-100">
    <div class="muted-text">Top 3 Sending Users</div>
    <div class="mt-3">
      <div
        class="d-flex justify-content-between font-weight-bold mt-2"
        v-for="(userData, index) in topUsers"
        :key="index"
      >
        <span>{{ selectUserNameById(userData.id) }}</span>
        <span>{{ userData.count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')

export default {
  name: 'TopUsersCard',
  props: {
    // Array of objects in the form [{id: '', count: #}]
    topUsers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...UserModule.mapGetters(['selectUserNameById']),
  },
}
</script>

<style scoped>
.muted-text {
  color: #95aac9;
}
.main-text {
  font-size: 26px;
  font-weight: bold;
}
</style>
