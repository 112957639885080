import { showIntercom } from '@/lib/analytics'

export const surveyQuestions = [
  'None (instant)',
  'How was your experience?',
  'How was your visit?',
  'How was your food today?',
  'How would your rate your service?',
]

export const surveyDelays = ['15 minutes', '30 minutes', '1 bour', '2 hours', '1 day']

export function locationIsClaimed(self) {
  const duration = 5000

  self.$notify({
    title: 'Location claimed',
    text:
      'Sorry! This location has been claimed by someone else. If you believe this is a problem, please contact us.',
    type: 'error',
    duration,
  })

  setTimeout(() => showIntercom(self.$intercom), duration + 1000)
}
