<template>
  <b-modal id="questionCreationModal" size="lg" centered hide-footer hide-header body-class="p-0">
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>Create Question</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- form steps -->
      <FadeTransition>
        <QuestionDetailsStep v-if="step === 1" @updateForm="updateForm" :form="form" />
        <QuestionAudienceStep v-if="step === 2" @updateForm="updateForm" :form="form" />
        <QuestionOptionsStep v-if="step === 3" @updateForm="updateForm" :form="form" @save="save" />
      </FadeTransition>
    </div>
  </b-modal>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import QuestionDetailsStep from '@/components/Modules/CustomQuestions/QuestionCreation/Steps/QuestionDetailsStep'
import QuestionAudienceStep from '@/components/Modules/CustomQuestions/QuestionCreation/Steps/QuestionAudienceStep'
import QuestionOptionsStep from '@/components/Modules/CustomQuestions/QuestionCreation/Steps/QuestionOptionsStep'
import _ from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'QuestionCreationModal',
  components: {
    FadeTransition,
    QuestionDetailsStep,
    QuestionAudienceStep,
    QuestionOptionsStep,
  },
  data: () => ({
    validInputs: false,
    form: {
      question: '',
      questionData: { options: [] },
      type: '',
      category: '',
      filters: {
        respondedFilter: '',
        sourcesFilter: [],
        ratingsFilter: [],
        locationsFilter: [],
      },
      limits: {
        askOnce: false,
      },
      children: [],
    },
  }),
  computed: {
    ...QuestionModule.mapGetters({
      step: 'getCreationStep',
      categories: 'getCategories',
      isLoading: 'selectIsLoading',
    }),
  },
  methods: {
    ...QuestionModule.mapActions([
      'createCustomQuestion',
      'getCustomQuestions',
      'incrementRefreshKey',
      'resetCreationStep',
      'setIsLoading',
    ]),
    nextStep() {
      this.step += 1
    },
    previousStep() {
      this.step -= 1
    },
    updateForm(form) {
      this.form = form
    },
    async save() {
      if (this.isLoading) return
      this.setIsLoading(true)
      try {
        const formData = this.prepFormData()
        const response = await this.createCustomQuestion({ formData })
        this.getCustomQuestions()
        this.$notify({ text: 'Successfully saved new custom question!' })
        this.close()
        this.resetForm()
      } catch (error) {
        console.log(error)
        this.$notify({ text: 'Failed to save new custom question', type: 'error' })
      }
      this.setIsLoading(false)
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'questionCreationModal')
    },
    resetForm() {
      this.resetCreationStep()
      this.incrementRefreshKey()
    },
    prepFormData() {
      const formData = _.cloneDeep(this.form)
      formData.category = formData.category || this.categories.find((c) => c.name === 'Other')._id
      formData.filters.respondedFilter = formData.filters.respondedFilter || 'ALL'
      return formData
    },
  },
}
</script>
