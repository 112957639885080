<template>
  <div class="message" ref="note">
    <div class="row mt-1 mb-1 internal">
      <div class="col mr--2">
        <div class="comment-body">
          <p class="comment-text">
            <span v-html="content" class="text-dark"></span>
          </p>
          <div class="row" v-if="created">
            <div class="col-auto">
              <p class="comment-time text-dark">
                {{ created | moment('LT') }}
                {{ timezone }}
                <span v-if="location">
                  &#8226;
                  {{ location.friendlyName || location.city }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-2">
        <Avatar
          :img="user.avatar"
          :first-name="user.firstName"
          :last-name="user.lastName"
          :name="user.firstName + ' ' + user.lastName"
          size="xs"
          status=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'

export default {
  name: 'NoteEvent',
  props: ['created', 'note', 'user', 'timezone', 'location'],
  components: {
    Avatar,
  },
  mounted() {
    this.$refs.note.scrollIntoView()
  },
  computed: {
    content() {
      const { text } = this.note.mediaParts.find((mp) => mp.type === 'text')
      const { mentions } = this.note
      if (mentions?.length) {
        const extrapolatedRawMentions = text.match(/{[^}]*/gm)
        const extrapolatedMentions = extrapolatedRawMentions.reduce((acc, curr) => {
          acc.push(curr.replace(/{/gm, ''))
          return acc
        }, [])
        const interpolatedText = extrapolatedMentions.reduce((acc, curr) => {
          const regex = new RegExp(`@{${curr}}`)
          return acc.replace(
            regex,
            '<span style="background-color: #fbe6b1; padding: 1px; border-radius: 3px;">' +
              '@' +
              mentions.find((m) => m.user === curr).atFallbackText +
              '</span>'
          )
        }, text)
        return interpolatedText
      } else {
        return text
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.comment-body {
  background: #fdf3d8 !important;
}
.comment-text {
  &:after {
    border-left-color: #fdf3d8 !important;
  }
}
</style>
