<template>
  <div class="row justify-content-center">
    <div class="col-12 p-0" style="max-width: 900px">
      <div v-for="(report, index) of reports" :key="index">
        <div
          class="card shadow clickable"
          :class="{ selectedCard: selectedCardIndex === index }"
          @click="setSelectedCardIndex(index)"
        >
          <!-- question section -->
          <div class="px-4 pt-4 pb-0 row">
            <div class="col-8">
              <div class="card-title pb-0 mb-3 h4">
                {{ report.question.question }}
              </div>
              <div class="text-capitalize card-subtitle text-muted mt-1" style="font-size: 14px">
                {{ typeSubtitle(report.question.type) }}
              </div>
            </div>
            <div class="col-4">
              <!-- count -->
              <div class="text-right">
                <animated-number
                  :value="report.answerCount"
                  :formatValue="parseInt"
                  :duration="500"
                  class="font-weight-bold report-count"
                />
                <span class="report-count-text">
                  {{ report.answerCount === 1 ? 'reponse' : 'responses' }}
                </span>
              </div>
              <div class="text-right mb-2 mt-1">
                <span class="badge text-capitalize badge-soft-primary" style="font-size: 12px">
                  {{ getCategoryName(report.question.category) }}
                </span>
              </div>
            </div>
          </div>

          <!-- info section -->
          <div class="px-5 border-top">
            <ShortAnswerReport
              :reportData="report.reportData"
              v-if="report.question.type === 'SHORT_ANSWER' && report.answerCount"
            />
            <RatingScaleReport
              :reportData="report.reportData"
              v-if="report.question.type === 'RATING_SCALE' && report.answerCount"
            />
            <SelectionReport
              :reportData="report.reportData"
              :options="report.question.questionData.options"
              v-if="
                (report.question.type === 'SINGLE_SELECTION' ||
                  report.question.type === 'MULTIPLE_SELECTION') &&
                report.answerCount
              "
            />
          </div>
        </div>

        <!-- follow up question cards -->
        <div
          v-if="selectedCardIndex === index && report.childReports.length"
          class="d-flex align-items-end flex-column"
        >
          <div
            v-for="(childReport, index) of report.childReports"
            :key="index"
            class="card shadow"
            style="width: 90%"
          >
            <div class="text-center border-bottom p-2 bg-light rounded-top">
              <span class="font-weight-bold"> Follow up for: </span>
              <span v-if="report.question.type === 'RATING_SCALE'">
                <img
                  :src="`/img/ratings/${getParentOption({ report, childReport })}.png`"
                  :alt="`rating ${getParentOption({ report, childReport })} emoji`"
                  contain
                  height="20px"
                  class="mt--2 ml-2"
                />
              </span>
              <span v-else>
                {{ getParentOption({ report, childReport }) | truncate(85) }}
              </span>
            </div>
            <!-- question section -->
            <div class="px-4 pt-3 pb-0 row">
              <div class="col-8">
                <div class="card-title pb-0 mb-3 h4">
                  {{ childReport.question.question }}
                </div>
                <div class="text-capitalize card-subtitle text-muted mt-1" style="font-size: 14px">
                  {{ typeSubtitle(childReport.question.type) }}
                </div>
              </div>
              <div class="col-4">
                <!-- count -->
                <div class="text-right">
                  <animated-number
                    :value="childReport.answerCount"
                    :formatValue="parseInt"
                    :duration="500"
                    class="font-weight-bold report-count"
                  />
                  <span class="report-count-text">
                    {{ childReport.answerCount === 1 ? 'reponse' : 'responses' }}
                  </span>
                </div>
              </div>
            </div>

            <!-- info section -->
            <div class="px-5 border-top">
              <ShortAnswerReport
                :reportData="childReport.reportData"
                v-if="childReport.question.type === 'SHORT_ANSWER' && childReport.answerCount"
              />
              <RatingScaleReport
                :reportData="childReport.reportData"
                v-if="childReport.question.type === 'RATING_SCALE' && childReport.answerCount"
              />
              <SelectionReport
                :reportData="childReport.reportData"
                :options="childReport.question.questionData.options"
                v-if="
                  (childReport.question.type === 'SINGLE_SELECTION' ||
                    childReport.question.type === 'MULTIPLE_SELECTION') &&
                  childReport.answerCount
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShortAnswerReport from './Reports/ShortAnswerReport'
import SelectionReport from './Reports/SelectionReport'
import RatingScaleReport from './Reports/RatingScaleReport'
import AnimatedNumber from 'animated-number-vue'
import { createNamespacedHelpers } from 'vuex'

const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'ReportCardList',
  components: {
    ShortAnswerReport,
    SelectionReport,
    RatingScaleReport,
    AnimatedNumber,
  },
  data: () => ({
    selectedCardIndex: null,
  }),
  computed: {
    ...QuestionModule.mapState(['reports', 'reportsFilters']),
    ...QuestionModule.mapGetters(['getCategoryName']),
  },
  methods: {
    typeSubtitle(type) {
      return type.toLowerCase().split('_').join(' ')
    },
    setSelectedCardIndex(index) {
      this.selectedCardIndex = this.selectedCardIndex === index ? null : index
    },
    getParentOption({ report, childReport }) {
      const parentQuestion = report.question
      const childQuestion = childReport.question
      const optionIndex = parentQuestion.children.find((c) => c.question._id === childQuestion._id)
        .optionIndex
      return parentQuestion.questionData.options[optionIndex]
    },
  },
}
</script>

<style scoped>
.report-count {
  color: #6e84a3;
}
.report-count-text {
  color: #95aac9;
}
.selectedCard {
  border-left: 5px solid #2c7be5;
  transform: scale(1.02);
  position: relative;
  z-index: 1000;
}
.card.clickable:hover {
  transform: scale(1.02);
  transition-duration: 0.3s;
}
</style>
