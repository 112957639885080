<template>
  <div class="mx-5">
    <div v-if="initialized">
      <div v-if="selectSubscriptions.length">
        <div v-for="(subscription, index) in selectSubscriptions" :key="index">
          <SubscriptionCard :subscription="subscription" />
        </div>
      </div>
      <div class="text-center" v-else>-- No subscriptions --</div>
    </div>
    <div v-else>
      <div class="mb-3 reviewList" v-for="x in 3" :key="'skeleton-' + x">
        <b-skeleton height="150px" />
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionCard from './SubscriptionCard'
import { createNamespacedHelpers } from 'vuex'

const SubscriptionModule = createNamespacedHelpers('subscription')

export default {
  name: 'CompanySettingsSubscription',
  components: {
    SubscriptionCard,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.fetchSubscriptions()
    this.initialized = true
  },
  computed: {
    ...SubscriptionModule.mapGetters(['selectSubscriptions']),
  },
  methods: {
    ...SubscriptionModule.mapActions(['fetchSubscriptions']),
  },
}
</script>
