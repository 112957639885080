<template>
  <div>
    <div class="p-3" style="min-height: 500px">
      <!-- row 1 -->
      <div class="row align-items-center">
        <div class="col">
          <!-- question -->
          <div class="form-group">
            <label for="question" class="font-weight-bold">
              Custom Question
              <i
                class="fas fa-info-circle text-muted ml-1 fa-sm"
                v-b-popover:questionCreation.hover="questionInfo"
              ></i>
            </label>
            <input
              type="text"
              id="question"
              v-model="form.question"
              :placeholder="placeholderQuestion"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <!-- next rows (left & right) -->
      <div class="row">
        <!-- left side -->
        <div class="col-12 col-lg-5">
          <!-- row 2 -->
          <div class="row align-items-center">
            <!-- type tiles -->
            <div class="form-group col">
              <label for="type" class="font-weight-bold">
                Type
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="typeInfo"
                ></i>
              </label>
              <div id="type">
                <div
                  v-for="(type, index) of typeOptions"
                  :key="index"
                  class="w-50 d-inline-block px-2"
                >
                  <div
                    class="card shadow"
                    :class="{ active: form.type == type.value }"
                    @click="updateType(type.value)"
                  >
                    <div class="d-flex justify-content-center">
                      <img
                        :src="`/img/questionCreation/${type.img}`"
                        :alt="type.img"
                        contain
                        width="55"
                      />
                    </div>
                    <div class="text-center mb-3">{{ type.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- row 3 -->
          <div class="row align-items-center">
            <!-- category -->
            <div class="form-group col">
              <label class="font-weight-bold">
                Category
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="categoryInfo"
                ></i>
              </label>
              <CategoryFilterWithCaret
                :categories="getCategories"
                :selected="form.category"
                @updateCategory="updateCategory"
              />
            </div>
          </div>
        </div>

        <!-- right side -->
        <div class="col-12 col-lg-7 border-left pl-4">
          <div class="form-group mb-0">
            <label class="font-weight-bold">
              {{ previewTitle }}
              <i
                class="fas fa-info-circle text-muted ml-1 fa-sm"
                v-b-popover:questionCreation.hover="previewInfo"
              ></i>
            </label>
            <div class="row align-items-center">
              <div class="col">
                <div class="card shadow rounded p-4">
                  <div v-if="form.type">
                    <div class="mb-3">{{ form.question || placeholderQuestion }}</div>
                    <!-- type specific component render -->
                    <ShortAnswerType v-if="form.type === 'SHORT_ANSWER'" />
                    <RatingScaleType v-if="form.type === 'RATING_SCALE'" />
                    <SingleSelectionType
                      v-if="form.type === 'SINGLE_SELECTION'"
                      :inputTitle="getDefaultOptionInputTitle"
                      :existingSelections="form.questionData.options"
                      @updateOptions="updateOptions"
                    />
                    <MultipleSelectionType
                      v-if="form.type === 'MULTIPLE_SELECTION'"
                      :inputTitle="getDefaultOptionInputTitle"
                      :existingSelections="form.questionData.options"
                      @updateOptions="updateOptions"
                    />
                  </div>
                  <div v-else>
                    <div class="text-muted">Please select a question type to view the preview</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreationFooter :hideBack="true" :allowNext="validInput" />
  </div>
</template>

<script>
import CreationFooter from './CreationFooter'
import MultiSelect from '@/components/Common/MultiSelect'
import CategoryFilterWithCaret from '../Filters/CategoryFilterWithCaret'
import ShortAnswerType from '../PreviewTypes/ShortAnswerType'
import RatingScaleType from '../PreviewTypes/RatingScaleType'
import SingleSelectionType from '../PreviewTypes/SingleSelectionType'
import MultipleSelectionType from '../PreviewTypes/MultipleSelectionType'
import { createNamespacedHelpers } from 'vuex'

const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'QuestionDetailsStep',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  components: {
    CreationFooter,
    MultiSelect,
    CategoryFilterWithCaret,
    ShortAnswerType,
    RatingScaleType,
    MultipleSelectionType,
    SingleSelectionType,
  },
  data: () => ({
    validInputs: false,
    placeholderQuestion: 'How were the bathrooms?',
    questionInfo: `The question you want your customer to see. This cannot be changed later.`,
    typeInfo: `The type of question you want to ask. This cannot be changed later.`,
    categoryInfo: `Used for sorting between questions after creation. This can be changed later.`,
    previewInfo: `If the selected type is Single or Multiple Selection, there must be at least 2 answer options added to then be able to click Next.`,
    typeOptions: [
      { title: 'Short Answer', value: 'SHORT_ANSWER', img: 'shortanswer.png' },
      { title: 'Multiple Selection', value: 'MULTIPLE_SELECTION', img: 'multiselect.png' },
      { title: 'Rating Scale', value: 'RATING_SCALE', img: 'ratingscale.png' },
      { title: 'Single Selection', value: 'SINGLE_SELECTION', img: 'singleselect.png' },
    ],
  }),
  computed: {
    ...QuestionModule.mapGetters(['getCategories', 'getDefaultOptionInputTitle']),
    previewTitle() {
      const typeTitle = this.form.type
      if (typeTitle === 'SINGLE_SELECTION' || typeTitle === 'MULTIPLE_SELECTION') {
        return 'Preview Editor'
      } else {
        return 'Preview'
      }
    },
    validInput() {
      if (this.form.question !== '' && this.form.type != '') {
        if (this.isSelectionType && (!this.validOptionsLength || !this.validOptionsInput)) {
          return false
        }
        return true
      } else {
        return false
      }
    },
    isSelectionType() {
      if (['SINGLE_SELECTION', 'MULTIPLE_SELECTION'].includes(this.form.type)) {
        return true
      }
      return false
    },
    validOptionsLength() {
      if (this.isSelectionType && this.form.questionData.options.length < 2) {
        return false
      }
      return true
    },
    filteredOptions() {
      return this.form.questionData.options.filter((option) => {
        return option && option !== this.defaultOptionInputTitle
      })
    },
    validOptionsInput() {
      if (
        this.isSelectionType &&
        this.filteredOptions.length !== this.form.questionData.options.length
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    updateType(type) {
      this.form.type = type
      if (type === 'SHORT_ANSWER' && this.form.questionData.options.length) {
        this.form.questionData.options = []
      } else if (type === 'RATING_SCALE') {
        this.form.questionData.options = [1, 2, 3, 4, 5]
      } else if (this.form.questionData.options[0] === 1) {
        // wipe if options are ratings
        this.form.questionData.options = []
      }
    },
    updateCategory(category) {
      this.form.category = category._id
    },
    updateOptions(options) {
      this.form.questionData.options = options
    },
  },
}
</script>

<style scoped>
.active {
  border-color: var(--primary);
}
</style>
