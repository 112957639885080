import Vue from 'vue'

const GroupService = {
  listGroups,
}

export function listGroups(payload) {
  return Vue.http.post('app-services/v2/group/list', payload)
}

export default GroupService
