var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeCompany)?_c('div',{staticClass:"collapse navbar-collapse order-lg-first",attrs:{"id":"navbar"}},[_c('router-link',{staticStyle:{"min-width":"100px"},attrs:{"to":{ name: 'dashboard' }}},[(_vm.onlineUser && _vm.onlineUser._id)?_c('img',{staticClass:"navbar-brand-img d-none d-lg-block my-4 mx-auto",staticStyle:{"position":"relative"},attrs:{"src":_vm.brandTextLogo}}):_vm._e()]),_c('ul',{staticClass:"navbar-nav my-3"},[_c('li',{staticClass:"company-picker nav-item py-3"},[_c('span',{staticClass:"dropdown"},[_c('a',{staticClass:"h2 header-title text-white",attrs:{"href":"#!","role":"button","aria-expanded":"false","aria-haspopup":"true","data-toggle":"dropdown","id":"company-picker-dropdown"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.activeCompany.friendlyName || _vm.activeCompany.name,16))+" "),(_vm.companies.length > 1)?_c('span',{staticClass:"fe fe-chevron-down ml-2"}):_vm._e()]),(_vm.companies.length > 1)?_c('ul',{staticClass:"dropdown-menu",staticStyle:{"position":"fixed","top":"160px","left":"10px"},attrs:{"aria-labelledby":"company-picker-dropdown"}},_vm._l((_vm.companySelectValues),function(company){return _c('li',{key:company._id},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.setCurrentActiveCompany(company)}}},[_vm._v(_vm._s(company.friendlyName || company.name))])])}),0):_vm._e()])])]),_c('ul',{staticClass:"navbar-nav mr-auto mt-3"},_vm._l((_vm.navItems.filter(
        (item) => item.category == 'winback' && item.show == true
      )),function(navItem,idx){return _c('li',{key:idx,staticClass:"nav-item"},[(!navItem.dropdownItems)?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: navItem.route }}},[_c('i',{class:['mr-1', navItem.icon]}),_vm._v(" "+_vm._s(navItem.title)+" "),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animated wobble","leave-active-class":"animated rollOut"}},[(navItem.route == 'winback' && _vm.selectUnresolvedCount)?_c('span',{staticClass:"badge badge-success ml-3"},[_vm._v(" "+_vm._s(_vm.selectUnresolvedCount)+" ")]):_vm._e()])],1):_vm._e()],1)}),0),_c('ul',{staticClass:"navbar-nav mr-auto mt-3"},_vm._l((_vm.navItems.filter(
        (item) => item.category == 'main' && item.show == true
      )),function(navItem,idx){return _c('li',{key:idx,staticClass:"nav-item"},[(!navItem.dropdownItems)?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: navItem.route, params: { companyId: _vm.activeCompany._id } }}},[_c('i',{class:['mr-1', navItem.icon]}),_vm._v(" "+_vm._s(navItem.title)+" "),(navItem.title == 'Call-to-Text')?_c('span',{staticClass:"badge nav-new-badge mr--4 ml-2"},[_vm._v(" NEW ")]):_vm._e()]):_vm._e()],1)}),0),(!_vm.showAllCompanies)?_c('h4',{staticClass:"navbar-heading mt-3 mb-0"},[_vm._v("Boosters")]):_vm._e(),_c('ul',{staticClass:"navbar-nav mr-auto mt-1"},_vm._l((_vm.navItems.filter(
        (item) => item.category == 'boosters' && item.show == true
      )),function(navItem,idx){return _c('li',{key:idx,staticClass:"nav-item"},[(!navItem.dropdownItems)?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: navItem.route, params: { companyId: _vm.activeCompany._id } }}},[_c('i',{class:['mr-1', navItem.icon]}),_vm._v(" "+_vm._s(navItem.title)+" "),(navItem.title == 'Call-to-Text')?_c('span',{staticClass:"badge nav-new-badge mr--4 ml-2"},[_vm._v(" NEW ")]):_vm._e()]):_vm._e()],1)}),0),(_vm.navItems.filter((item) => item.category == 'reports' && item.show == true).length > 0)?_c('div',{staticClass:"mt-4"},[_c('h4',{staticClass:"navbar-heading mb-1"},[_vm._v("Reports")]),_c('ul',{staticClass:"navbar-nav mr-auto"},_vm._l((_vm.navItems.filter(
          (item) => item.category == 'reports' && item.show == true
        )),function(navItem,idx){return _c('li',{key:idx,staticClass:"nav-item"},[(!navItem.dropdownItems)?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: navItem.route }}},[(navItem.icon === 'gift-card')?_c('span',{staticStyle:{"width":"28px"}},[_c('CreditCardIcon',{staticClass:"mt--1",attrs:{"size":"1.15x"}})],1):_c('i',{class:navItem.icon}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(navItem.title))])]):_vm._e()],1)}),0)]):_vm._e(),(
      _vm.selectIsPermitted('navSettingsReadAccess') &&
      (!_vm.showAllCompanies ||
        (_vm.showAllCompanies && !!_vm.selectOrganization && _vm.isEnabled('ORG_CAMPAIGNS')))
    )?_c('ul',{staticClass:"navbar-nav mt-4"},[_c('li',{staticClass:"nav-item"},[_vm._m(0),_c('div',{staticClass:"collapse",attrs:{"id":"settings-dropdown"}},[_c('ul',{staticClass:"nav nav-sm flex-column"},_vm._l((_vm.companyNavigation),function(navItem,idx){return _c('li',{key:idx,staticClass:"nav-item"},[(_vm.shouldShowNavItem(navItem.route))?_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: navItem.route }}},[_vm._v(_vm._s(navItem.title)+" ")]):_vm._e()],1)}),0)])])]):_vm._e(),(!_vm.showAllCompanies)?_c('ul',{staticClass:"navbar-nav mr-auto mt-2"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'account-profile' }}},[_c('i',{staticClass:"mr-1 far fa-user-circle fa-lg"}),_vm._v(" Account ")])],1)]):_vm._e(),_c('li',{staticClass:"d-flex justify-content-start align-items-center mt-auto pt-5 pb-3"},[_c('Avatar',{attrs:{"img":_vm.onlineUser.avatar,"first-name":_vm.onlineUser.firstName,"last-name":_vm.onlineUser.lastName,"size":"sm","status":"online"}}),_c('a',{staticClass:"logout px-4",attrs:{"href":"javascript:;"},on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",attrs:{"href":"#settings-dropdown","data-toggle":"collapse","role":"button","aria-expanded":"false","aria-controls":"settings-dropdown"}},[_c('i',{staticClass:"fe fe-settings"}),_vm._v(" Settings ")])
}]

export { render, staticRenderFns }