<template>
  <multiselect
    :placeholder="placeholder"
    style="min-width: 100px"
    selectLabel
    v-model="selected"
    @input="updateFilters"
    :options="options"
    track-by="value"
    label="title"
  >
    <template slot="option" slot-scope="props">
      <span>{{ props.option.title }}</span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'RespondedFilter',
  components: {
    Multiselect,
  },
  props: {
    selection: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'All',
    },
  },
  mounted() {
    if (this.selection) {
      this.selected = this.options.find((o) => o.value === this.selection)
    }
  },
  data: () => ({
    selected: null,
    options: [
      { title: 'All', value: 'ALL' },
      { title: 'Responded', value: 'RESPONDED' },
      { title: 'Unresponded', value: 'UNRESPONDED' },
    ],
  }),
  methods: {
    updateFilters(selection) {
      this.$emit('updateResponded', selection.value)
    },
  },
}
</script>
