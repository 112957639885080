import InsightsService from '@/services/InsightsService'
import moment from 'moment-timezone'
import { isEqual } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    classifications: [],
    oppositeSentimentClassifications: [],
    oppositeSentimentMentions: {},
    mentions: {},
    detailFilters: {},
    populatedClassifications: [],
    positiveClassificationsByCategory: {},
    negativeClassificationsByCategory: {},
    feedbackCount: 0,
    feedbackCountBreakdown: {},
    skip: 0,
    limit: 20,
    count: 0,
    summaries: {},
    wordClouds: {},
    wordCloudQuantity: 40,
  },
  getters: {
    selectClassifications(state) {
      return state.classifications
    },
    selectAmKey: (state, getter, rootState, rootGetters) =>
      rootGetters['selectConfigByName']('AMKEY'),
    selectClassificationLocation: (state) => (id) => {
      const classification = state.classifications.find((c) => c._id === id)
      return (
        classification?.location ||
        (classification?.locations?.length && classification?.locations[0]) ||
        null
      )
    },
    selectShowHighlight(state, getters, rootState, rootGetters) {
      const subcategories = rootGetters['insights/selectNonOtherCategories'].filter((c) => c.path)
      if (
        subcategories.find((sc) => sc._id === state.detailFilters.category) &&
        state.detailFilters.sentiment
      ) {
        return state.detailFilters.category
      } else {
        return false
      }
    },
    selectDetailFilters(state) {
      return state.detailFilters
    },
    selectSkip(state) {
      return state.skip
    },
    selectPopulatedClassifications(state) {
      return state.populatedClassifications
    },
    selectCount(state) {
      return state.count
    },
    selectLimit(state) {
      return state.limit
    },
    selectSentiment(state) {
      return state.detailFilters.sentiment
    },
    selectDetailsFilterCategory(state, getters, rootState, rootGetters) {
      return rootGetters['insights/selectCategory'](state.detailFilters.category)
    },
    selectGranularity(state, getters) {
      const dateRange = getters.selectDetailFilters.dateRange
      const dateSpread = moment(dateRange[1]).diff(moment(dateRange[0]), 'd')
      let granularity
      if (dateSpread <= 14) granularity = 'day'
      else if (dateSpread <= 98) granularity = 'week'
      else if (dateSpread <= 420) granularity = 'month'
      else granularity = 'year'
      return granularity
    },
    selectMentions(state) {
      return state.mentions
    },
    selectAllMentions(state) {
      if (!state.detailFilters.sentiment) return state.mentions
      // combine mentions with oppositeSentimentMentions (don't alter originals)
      return Object.keys(state.mentions).reduce((combinedMentions, date) => {
        if (state.mentions[date] && state.oppositeSentimentMentions[date]) {
          combinedMentions[date] = {
            NEGATIVE:
              state.mentions[date].NEGATIVE + state.oppositeSentimentMentions[date].NEGATIVE,
            POSITIVE:
              state.mentions[date].POSITIVE + state.oppositeSentimentMentions[date].POSITIVE,
          }
        } else {
          combinedMentions[date] = { ...state.mentions[date] }
        }
        return combinedMentions
      }, {})
    },
    selectFeedbackCount(state) {
      return state.feedbackCount
    },
    selectPositiveClassificationsByCategory(state) {
      return state.positiveClassificationsByCategory
    },
    selectNegativeClassificationsByCategory(state) {
      return state.negativeClassificationsByCategory
    },
    selectCategoryPercentageNegative: (state) => (id) => {
      return (
        (
          state.negativeClassificationsByCategory[id]?.classifications.length / state.feedbackCount
        ).toFixed(2) * 100
      )
    },
    selectCategoryIssueCount: (state) => (id) => {
      return state.negativeClassificationsByCategory[id]?.classifications.length
    },
    selectLocationFeedbackCount: (state) => (id) => {
      const counts = state.feedbackCountBreakdown[id]
      return counts ? counts.reviewCount + counts.surveyCount : 0
    },
    selectLocationMiniHeatmapSeries(state, getters, rootState, rootGetters) {
      const categoryId = state.detailFilters.category
      if (!categoryId) return

      // const negativeClassificationsByCategory = state.negativeClassificationsByCategory
      const negativeClassifications =
        state.negativeClassificationsByCategory[categoryId]?.classifications
      if (!negativeClassifications.length) return

      const locations = state.detailFilters.locations?.length
        ? rootGetters['location/selectFilteredLocations'](state.detailFilters.locations)
        : state.detailFilters.companies?.length
        ? rootGetters['location/selectInsightsLocationsByCompanies'](state.detailFilters.companies)
        : rootGetters['location/selectInsightsLocations']

      let countByLocation = {}
      for (let location of locations) {
        countByLocation[location._id] = 0
      }

      negativeClassifications?.forEach((nc) => {
        if (nc.locations?.length) {
          nc.locations.map((ncl) =>
            countByLocation[ncl] >= 0 ? (countByLocation[ncl] += 1) : null
          )
        } else if (nc.location) {
          countByLocation[nc.location] += 1 // this is the deprecated way. 'locations' is the correct field
        }
      })

      const locationCounts = Object.entries(countByLocation).reduce((acc, curr) => {
        const locationFeedbackCount = getters.selectLocationFeedbackCount(curr[0])
        acc.push({
          name: curr[0],
          data: locationFeedbackCount ? Math.ceil((curr[1] / locationFeedbackCount) * 100) : 0,
          count: curr[1],
          totalCount: locationFeedbackCount,
        })
        return acc
      }, [])

      const top8 = locationCounts.sort((a, b) => (a.data < b.data ? 1 : -1)).slice(0, 8)

      return [
        {
          name: '',
          locationIds: top8.map((el) => el.name),
          data: top8.map((el) => el.data),
          counts: top8.map((el) => el.count),
          totalCounts: top8.map((el) => el.totalCount),
        },
      ]
    },
    selectSummaries: (state) => state.summaries,
    selectWordCloud: (state) => {
      const quantity = state.wordCloudQuantity
      const halfQuantity = quantity / 2
      const posWC = state.wordClouds.positiveWordcloud || []
      const negWC = state.wordClouds.negativeWordcloud || []

      let wordcloud = []
      if (posWC.length > halfQuantity && negWC.length > halfQuantity) {
        wordcloud = [...posWC.slice(0, halfQuantity), ...negWC.slice(0, halfQuantity)]
      } else if (posWC.length < halfQuantity && negWC.length < halfQuantity) {
        wordcloud = [...posWC, ...negWC]
      } else if (posWC?.length > halfQuantity) {
        const negQuantity = negWC.length
        wordcloud = [...negWC, ...posWC.slice(0, quantity - negQuantity)]
      } else if (negWC?.length > halfQuantity) {
        const posQuantity = posWC.length
        wordcloud = [...posWC, ...negWC.slice(0, quantity - posQuantity)]
      }

      const totalCount = wordcloud.reduce((acc, curr) => {
        acc += curr.count
        return acc
      }, 0)
      return wordcloud.reduce((acc, curr) => {
        acc.push({
          category: curr.word,
          value: ((curr.count / totalCount) * 100).toFixed(2),
          sentiment: curr.sentiment,
        })
        return acc
      }, [])
    },
  },
  mutations: {
    SET_CLASSIFICATIONS(state, classifications) {
      state.classifications = classifications
    },
    SET_DETAIL_FILTERS(state, detailFilters) {
      state.detailFilters = detailFilters
    },
    SET_DETAILED_CLASSIFICATIONS(state, populatedClassifications) {
      state.populatedClassifications = populatedClassifications
    },
    SET_SKIP(state, skip) {
      state.skip = skip
    },
    SET_COUNT(state, count) {
      state.count = count
    },
    SET_MENTIONS(state, mentions) {
      state.mentions = mentions
    },
    SET_FEEDBACK_COUNT(state, feedbackCount) {
      state.feedbackCount = feedbackCount
    },
    SET_POSITIVE_CLASSIFICATIONS_BY_CATEGORY(state, positiveClassificationsByCategory) {
      state.positiveClassificationsByCategory = positiveClassificationsByCategory
    },
    SET_NEGATIVE_CLASSIFICATIONS_BY_CATEGORY(state, negativeClassificationsByCategory) {
      state.negativeClassificationsByCategory = negativeClassificationsByCategory
    },
    SET_OPPOSITE_SENTIMENT_MENTIONS(state, mentions) {
      state.oppositeSentimentMentions = mentions
    },
    SET_OPPOSITE_SENTIMENT_CLASSIFICATIONS(state, classifications) {
      state.oppositeSentimentClassifications = classifications
    },
    SET_FEEDBACK_COUNT_BREAKDOWN(state, feedbackCountBreakdown) {
      state.feedbackCountBreakdown = feedbackCountBreakdown
    },
    SET_SUMMARIES(state, summaries) {
      state.summaries = summaries
    },
    SET_WORDCLOUDS(state, wordClouds) {
      state.wordClouds = wordClouds
    },
  },
  actions: {
    async applyDetailFilters({ commit, getters, dispatch }, detailFilters) {
      const { selectDetailFilters } = getters
      if (isEqual(detailFilters, selectDetailFilters)) return
      dispatch('clearPopulatedClassificationstate')
      commit('SET_DETAIL_FILTERS', detailFilters)
    },

    async clearPopulatedClassificationstate({ commit }) {
      commit('SET_SKIP', 0)
      commit('SET_DETAILED_CLASSIFICATIONS', [])
    },

    async formatDetailFilters({ getters, rootState, rootGetters }) {
      const { activeCompany } = rootState.company
      const filters = getters.selectDetailFilters
      const payloadFilters = {
        createdAtRange: filters.dateRange || [
          moment().startOf('d').subtract(30, 'd').utc().format(),
          moment().endOf('day').utc().format(),
        ],
      }
      if (activeCompany._id === 'ALL_COMPANIES') {
        if (filters.companies) {
          payloadFilters.companyIds = Array.isArray(filters.companies)
            ? filters.companies
            : [filters.companies]
        } else {
          payloadFilters.companyIds = rootGetters['company/selectInsightsCompanies'].map(
            (c) => c._id
          )
        }
      } else {
        payloadFilters.companyIds = [activeCompany._id]
      }
      if (filters.locations) {
        payloadFilters.locationIds = Array.isArray(filters.locations)
          ? filters.locations
          : [filters.locations]
      } else {
        payloadFilters.locationIds = rootGetters['location/selectInsightsLocations']
          .filter((l) => !l.hideFromReports && !l.disabled)
          .map((l) => l._id)
      }
      if (filters.sentiment) {
        payloadFilters.sentiment = filters.sentiment
      }
      if (filters.category) {
        // if parent category, pass all child id's too
        const isSubcategory = rootGetters['insights/selectCategory'](filters.category)?.path
        if (isSubcategory) {
          payloadFilters.categories = [filters.category]
        } else {
          payloadFilters.categories = rootGetters['insights/selectNonOtherCategories'].reduce(
            (acc, curr) => {
              if (curr.path === filters.category || curr._id === filters.category)
                [acc.push(curr._id)]
              return acc
            },
            []
          )
        }
      }
      if (filters.sources?.length) {
        payloadFilters.sources = filters.sources
      }
      return payloadFilters
    },

    async fetchFeedbackCount({ commit, dispatch }) {
      const response = await InsightsService.fetchFeedbackCount({
        filters: await dispatch('formatDetailFilters'),
      })
      commit('SET_FEEDBACK_COUNT', response.body.data)
    },

    async fetchFeedbackCountBreakdown({ commit, dispatch }) {
      const response = await InsightsService.fetchFeedbackCountBreakdown({
        filters: await dispatch('formatDetailFilters'),
      })
      commit('SET_FEEDBACK_COUNT_BREAKDOWN', response.body.data)
    },

    async fetchSummaries({ commit, dispatch, getters }) {
      const filters = getters.selectDetailFilters
      const response = await InsightsService.fetchSummaries({
        filters: await dispatch('formatDetailFilters'),
        ...(filters.keywords?.length ? { search: filters.keywords } : {}),
      })
      const { positiveSummary, negativeSummary, positiveWordcloud, negativeWordcloud } =
        response.body.data
      const summaries = { positiveSummary, negativeSummary }
      const wordClouds = { positiveWordcloud, negativeWordcloud }
      commit('SET_SUMMARIES', summaries)
      commit('SET_WORDCLOUDS', wordClouds)
    },

    async fetchPopulatedClassifications({ dispatch, commit, getters }) {
      const filters = getters.selectDetailFilters
      const response = await InsightsService.fetchPopulatedClassifications({
        filters: await dispatch('formatDetailFilters'),
        ...(filters.keywords?.length ? { search: filters.keywords } : {}),
        limit: getters.selectLimit,
        skip: getters.selectSkip,
      })
      commit('SET_SKIP', getters.selectSkip + getters.selectLimit)
      commit(
        'SET_DETAILED_CLASSIFICATIONS',
        getters.selectPopulatedClassifications.concat(response.body.data.aggregatedData)
      )
      commit('SET_COUNT', response.body.data.count)
    },

    async fetchClassifications({ dispatch, commit, getters }) {
      const filters = getters.selectDetailFilters
      let skip = 0
      const limit = 12000 // Adjust the limit as per your requirements
      let hasMoreData = true
      let allClassifications = []
      let safetyStop = 0
      while (hasMoreData) {
        const response = await InsightsService.fetchClassifications({
          filters: await dispatch('formatDetailFilters'),
          ...(filters.keywords?.length ? { search: filters.keywords } : {}),
          limit,
          skip,
        })

        const classifications = response.body.data
        if (classifications.length === limit) {
          skip += limit // Prepare 'skip' for the next iteration
        } else {
          hasMoreData = false // Exit the loop if no data is returned
        }
        allClassifications = allClassifications.concat(classifications)
        if (safetyStop > 20) throw new Error('Stopping fetch classification endless loop')
        safetyStop++
      }
      commit('SET_CLASSIFICATIONS', allClassifications)
      await dispatch('processClassifications', allClassifications)
    },

    async fetchOppositeSentimentClassifications({ dispatch, commit, getters }) {
      const filters = getters.selectDetailFilters
      let payloadFilters = { ...(await dispatch('formatDetailFilters')) }
      // if no sentiment then return; all classifications will be retrieved by 'fetchClassifications'
      if (!payloadFilters.sentiment) return
      payloadFilters.sentiment = payloadFilters.sentiment === 'POSITIVE' ? 'NEGATIVE' : 'POSITIVE'
      const response = await InsightsService.fetchClassifications({
        filters: payloadFilters,
        ...(filters.keywords?.length ? { search: filters.keywords } : {}),
      })
      commit('SET_OPPOSITE_SENTIMENT_CLASSIFICATIONS', response.body.data)
      await dispatch('processOppositeSentimentClassifications', response.body.data)
    },

    async processClassifications({ commit, getters, rootGetters }, classifications) {
      const categories = rootGetters['insights/selectNonOtherCategories']
      // data for splitting
      let positiveClassificationsByCategory = {}
      let negativeClassificationsByCategory = {}
      categories.forEach((cat) => {
        positiveClassificationsByCategory[cat._id] = { name: cat.name, classifications: [] }
        negativeClassificationsByCategory[cat._id] = { name: cat.name, classifications: [] }
      })

      const granularity = getters.selectGranularity
      const dateRange = getters.selectDetailFilters.dateRange
      let mentions = {}

      // iterate and build data structures
      classifications
        .filter((c) => !rootGetters['insights/selectOtherCategoryIds'].includes(c.category))
        .forEach((c) => {
          // split positive/negative processing
          c.sentiment === 'POSITIVE'
            ? positiveClassificationsByCategory[c.category].classifications.push(c)
            : negativeClassificationsByCategory[c.category].classifications.push(c)

          // processing for mentions over time chart
          const date =
            granularity === 'day'
              ? moment(c.feedbackTextCreated).format('YYYY-MM-DD')
              : granularity === 'week'
              ? moment(c.feedbackTextCreated).startOf('w').format('YYYY-MM-DD')
              : granularity === 'month'
              ? moment(c.feedbackTextCreated).startOf('M').format('YYYY-MM-DD')
              : moment(c.feedbackTextCreated).startOf('year').format('YYYY-MM-DD')

          const mention = mentions[`${date}`]
          if (mention) {
            mention[c.sentiment] += 1
          } else {
            mentions[`${date}`] = { POSITIVE: 0, NEGATIVE: 0 }
            mentions[`${date}`][c.sentiment] += 1
          }
        })

      // for mentions: add missing dates for each granularity
      let startDate = moment(dateRange[0]).startOf(granularity)
      const finalDate = moment(dateRange[1]).startOf(granularity)
      let completeMentions = {}
      while (startDate.isBefore(finalDate) || startDate.isSame(finalDate)) {
        const formattedDate = startDate.format('YYYY-MM-DD')
        completeMentions[formattedDate] = mentions[formattedDate] || {
          POSITIVE: 0,
          NEGATIVE: 0,
        }
        startDate.add(1, granularity)
      }

      commit('SET_POSITIVE_CLASSIFICATIONS_BY_CATEGORY', positiveClassificationsByCategory)
      commit('SET_NEGATIVE_CLASSIFICATIONS_BY_CATEGORY', negativeClassificationsByCategory)
      commit('SET_MENTIONS', completeMentions)
    },

    // this is for the Mentions over Time chart. We need to always show both positive and negative trends
    // but that is the only component to always need both. This will keep the classifications separate from
    // the other components while optimizing load times. Will only process the opposite sentiment if the
    // sentiment filter is set to 'null' (if both sentiments are already processed)
    async processOppositeSentimentClassifications(
      { commit, getters, rootGetters },
      classifications
    ) {
      const granularity = getters.selectGranularity
      const dateRange = getters.selectDetailFilters.dateRange
      let mentions = {}

      classifications.forEach((c) => {
        const date =
          granularity === 'day'
            ? moment(c.feedbackTextCreated).format('YYYY-MM-DD')
            : granularity === 'week'
            ? moment(c.feedbackTextCreated).startOf('w').format('YYYY-MM-DD')
            : granularity === 'month'
            ? moment(c.feedbackTextCreated).startOf('M').format('YYYY-MM-DD')
            : moment(c.feedbackTextCreated).startOf('year').format('YYYY-MM-DD')

        const mention = mentions[`${date}`]
        if (mention) {
          mention[c.sentiment] += 1
        } else {
          mentions[`${date}`] = { POSITIVE: 0, NEGATIVE: 0 }
          mentions[`${date}`][c.sentiment] += 1
        }
      })

      commit('SET_OPPOSITE_SENTIMENT_MENTIONS', mentions)
    },
  },
}
