<template>
  <div>
    <label class="mb-1">{{ title }}</label
    ><small class="form-text text-muted">{{ subtitle }}</small>
    <div class="row">
      <div class="col">
        <input
          type="text"
          v-model="promoCode"
          @input="setPromoCode"
          :placeholder="placeholder"
          class="form-control"
          :disabled="promoCodesAlreadyUploaded"
        />
      </div>
      <div class="col-auto pt-2 px-5">or</div>
      <div class="col-auto">
        <FileUploader
          @uploaded="fileUploaded"
          :button-text="uploadButtonText"
          button-class="btn-primary"
        />
      </div>
    </div>
    <div v-if="type === 'off-premise'" class="row mt-3">
      <div class="col">
        <label class="mb-1 mt-3">Off-Premise URL</label
        ><small class="form-text text-muted"
          >Redirect customers to this URL after they receive a code.</small
        >
        <input
          type="text"
          v-model="offPremiseLocationUrl"
          @input="setOffPremiseUrl"
          placeholder="https://company.com/order/online"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from '@/components/MainContent/FileUploader'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'CreatePromoCodeForm',
  props: ['type', 'placeholder'],
  components: {
    FileUploader,
  },
  data: () => ({
    promoCode: '',
    offPremiseLocationUrl: '',
  }),
  computed: {
    ...mapState('promo', ['promo']),
    title() {
      const typeText = this.type === 'in-store' ? 'In-Store' : 'Off-Premise'
      return `${typeText} Promo Code`
    },
    promoCodesAlreadyUploaded() {
      return (
        (this.type === 'in-store' && this.promo.hasUniqueInStoreCodes) ||
        (this.type === 'off-premise' && this.promo.hasUniqueOffPremiseCodes)
      )
    },
    subtitle() {
      if (this.promoCodesAlreadyUploaded) {
        return 'Promo codes have already been uploaded. You may upload additional codes if needed.'
      }

      const typeText = this.type === 'in-store' ? 'in-store' : 'off-premise'
      return `Add an ${typeText} promo code to this offer or upload a CSV file of unique codes.`
    },
    uploadButtonText() {
      if (
        (this.type === 'in-store' && this.promo.inStorePromoFile) ||
        (this.type === 'off-premise' && this.promo.offPremisePromoFile)
      ) {
        return 'Upload Again'
      } else if (this.promoCodesAlreadyUploaded) {
        return 'Upload Additional Codes'
      } else {
        return 'Upload Promo Codes'
      }
    },
  },
  methods: {
    ...mapMutations('promo', [
      'setInStorePromoCode',
      'setInStorePromoFile',
      'setOffPremisePromoCode',
      'setOffPremisePromoFile',
      'setOffPremiseLocationUrl',
    ]),
    setPromoCode() {
      if (this.type === 'in-store') {
        this.setInStorePromoFile(undefined)
        this.setInStorePromoCode(this.promoCode)
      } else {
        this.setOffPremisePromoFile(undefined)
        this.setOffPremisePromoCode(this.promoCode)
      }
    },
    setOffPremiseUrl(e) {
      this.setOffPremiseLocationUrl(this.offPremiseLocationUrl)
    },
    fileUploaded(fileUrl) {
      this.promoCode = ''
      if (this.type === 'in-store') {
        this.setInStorePromoCode(undefined)
        this.setInStorePromoFile(fileUrl)
      } else {
        this.setOffPremisePromoCode(undefined)
        this.setOffPremisePromoFile(fileUrl)
      }
    },
  },
  mounted() {
    this.offPremiseLocationUrl = this.promo.offPremiseLocationUrl

    if (this.type === 'in-store') {
      this.promoCode = this.promo.inStorePromoCode
    } else {
      this.promoCode = this.promo.offPremisePromoCode
    }
  },
}
</script>
