<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <label for="promoExpirationPicker">Automatically expire</label>
      <small class="form-text text-muted mb-3"
        >Optionally, you can have your offer expire automatically.</small
      >
      <b-dropdown variant="white" class="hide-caret">
        <template slot="button-content">
          <i class="fe fe-clock ml-1 text-muted" style="position: relative; top: 1px"></i>
          {{ selectedExpirationType }}
        </template>
        <b-dropdown-item
          v-for="expirationType in expirationTypes"
          :key="expirationType"
          class="dropdown-item"
          @click="setExpirationType(expirationType)"
          >{{ expirationType }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <div class="col-12 col-lg-6 mt-4 mt-lg-0">
      <div class="form-group">
        <div v-if="showExpirationDaysField">
          <label for="promoExpiresIn">Duration</label>
          <small class="form-text text-muted mb-3"
            >Setup how long a customer has to redeem the offer.</small
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">Expire after</div>
            </div>
            <input
              type="text"
              id="promoExpiresIn"
              :value="expiresIn"
              @input="onExpiresInChange"
              ref="expiresIn"
              class="form-control form-control-prepended"
            />
            <div class="input-group-append">
              <div class="input-group-text">days</div>
            </div>
          </div>
        </div>
        <div v-if="showExpirationDateField">
          <label for="promoExpiresAt">Expiration date</label>
          <small class="form-text text-muted mb-3"
            >Offer will be valid until the end of the selected date.</small
          >
          <flat-pickr
            id="promoExpiresAt"
            :value="expiresAt"
            class="form-control"
            @on-change="onDateChange"
            :config="{
              mode: 'single',
              dateFormat: 'M j, Y',
              enableTime: false,
              altInput: true,
              altFormat: 'M j, Y',
              wrap: true,
              key: 'expiresAt',
            }"
            placeholder="Choose a date"
            name="expiresAt"
            ref="expiresAt"
          ></flat-pickr>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const PromoModule = createNamespacedHelpers('promo')
export default {
  name: 'CreatePromoExpiration',
  data: () => ({
    expirationTypes: {
      never: 'Off. Offer never expires',
      date: 'Expire on a specific date',
      days: 'Expire after a time period',
    },
    selectedExpirationType: '',
    expiresAt: '',
    expiresIn: '',
  }),
  computed: {
    ...PromoModule.mapState(['promo']),
    showExpirationDaysField() {
      return this.selectedExpirationType === this.expirationTypes.days
    },
    showExpirationDateField() {
      return this.selectedExpirationType === this.expirationTypes.date
    },
  },
  methods: {
    ...PromoModule.mapMutations(['setExpiresAt', 'setExpiresIn']),
    onExpiresInChange(event) {
      this.resetExpiration()
      this.setExpiresIn(event.target.value)
    },
    onDateChange(selectedDates, dateString) {
      if (!selectedDates.length) return false
      this.resetExpiration()
      const parsedDate = this.$moment.utc(dateString, 'MMM D, YYYY').endOf('day').toISOString()
      this.setExpiresAt(parsedDate)
    },
    setExpirationType(expirationType, shouldFocus = true) {
      this.selectedExpirationType = expirationType
      if (expirationType === this.expirationTypes.never) {
        this.resetExpiration()
      }

      if (shouldFocus) {
        setTimeout(() => this.focusActiveInput())
      }
    },
    focusActiveInput() {
      if (this.selectedExpirationType === this.expirationTypes.date) {
        this.$refs.expiresAt.$el.focus()
      }

      if (this.selectedExpirationType === this.expirationTypes.days) {
        this.$refs.expiresIn.focus()
      }
    },
    resetExpiration() {
      this.setExpiresAt(null)
      this.setExpiresIn(null)
    },
    identifyExpirationType() {
      if (this.promo.expiresAt) {
        return this.expirationTypes.date
      }
      if (this.promo.expiresIn) {
        return this.expirationTypes.days
      }

      return this.expirationTypes.never
    },
  },
  mounted() {
    this.setExpirationType(this.identifyExpirationType(), false)

    this.expiresAt = this.promo.expiresAt
    this.expiresIn = this.promo.expiresIn
  },
}
</script>
