<template>
  <div class="card col-12 mt-7">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/no-customers.svg"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <h2>We haven't received any customer data yet.</h2>

          <p class="text-muted mb-4">
            Check on your custom questions, once they're made the data should come soon!
          </p>

          <router-link
            to="/custom-questions/questions"
            class="btn btn-lg btn-primary"
            v-if="selectIsPermitted('cqQuestionsReadAccess')"
          >
            Check On Your Questions
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'ReportsOffstate',
  computed: {
    ...CompanyModule.mapGetters(['selectIsPermitted']),
  },
}
</script>

<style scoped lang="scss"></style>
