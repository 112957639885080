import Vue from 'vue'
async function requestWithRetry(requestFunction) {
  let response
  try {
    response = await requestFunction()
  } catch (error) {
    console.log(error)
    try {
      response = requestFunction()
    } catch (error) {
      console.log(error)
    }
  }
  return response
}

export default {
  fetchPreview(customFilter) {
    const request = () =>
      Vue.http.post(`app-services/v2/custom-filters/preview-async`, { ...customFilter })
    return requestWithRetry(request)
  },
}
