<template>
  <div class="card px-4 pt-4 text-center h-100">
    <div class="muted-text">Total Calls Received</div>
    <div class="main-text mt-3">{{ selectTotalCallsReceived }}</div>
    <div>
      <apexchart
        type="bar"
        height="100%"
        :series="selectTotalCallsReceivedData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CTTModule = createNamespacedHelpers('callToText')

export default {
  name: 'TotalCallsReceived',
  computed: {
    ...CTTModule.mapGetters([
      'selectTotalCallsReceivedData',
      'selectTotalCallsReceived',
      'selectGranularityDates',
      'selectColors',
    ]),
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: this.selectColors,
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 1,
            dataLabels: { show: false },
          },
        },
        markers: { size: 0 },
        noData: { text: 'No data yet!' },
        grid: { show: false },
        yaxis: {
          axisBorder: { show: false },
          labels: { show: false },
        },
        dataLabels: { enabled: false },
        xaxis: {
          type: 'category',
          categories: this.selectGranularityDates,
          tooltip: { enabled: false },
          tickPlacement: 'on',
        },
        legend: { show: false },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return this.customToolTipLabels({ series, seriesIndex, dataPointIndex, w })
          },
        },
      }
    },
  },
  methods: {
    customToolTipLabels({ series, seriesIndex, dataPointIndex, w }) {
      const count = series[seriesIndex][dataPointIndex]
      const totalCount = series.reduce((acc, curr) => {
        acc += curr[dataPointIndex]
        return acc
      }, 0)
      const date = w.globals.categoryLabels[dataPointIndex]
      const color = w.globals.colors[dataPointIndex]
      const name = w.globals.seriesNames[seriesIndex]

      let template = `
        <div class="text-left"> 
          <small class="text-muted mb-2"> ${date} </small>
          <div class="d-flex mb-2">
            <div class="mt-2 mr-2" 
              style="height: 12px; width: 12px; border-radius: 6px; background-color: ${color};">
            </div>
            <small class="mt-1 text-capitalize"> ${name}: <strong> ${count}</strong></small>
          </div>
          <small class="text-muted"> Total actions: <strong> ${totalCount}</strong></small>
        </div>
      `
      return template
    },
  },
}
</script>

<style scoped>
.muted-text {
  color: #95aac9;
}
.main-text {
  font-size: 26px;
  font-weight: bold;
}
</style>
