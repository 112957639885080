<template>
  <div>
    <div v-if="initialized">
      <WinbackWebview />
    </div>
    <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <b-spinner variant="primary" />
    </div>
  </div>
</template>

<script>
import WinbackWebview from '@/components/Modules/Winback/WinbackWebview'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const WebsocketModuleV2 = createNamespacedHelpers('websocketV2')

export default {
  name: 'WinbackWebviewPage',
  components: {
    WinbackWebview,
  },
  computed: {
    ...CompanyModule.mapGetters(['activeCompany']),
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.refreshCompanyList()
    await this.parseRouteDetails()
    await this.pusherConnect()
    this.initialized = true
  },
  methods: {
    ...ConversationModuleV2.mapActions(['fetchSpecificConversation']),
    ...CompanyModule.mapActions(['setActiveCompany', 'refreshCompanyList']),
    ...WebsocketModuleV2.mapActions(['pusherConnect']),
    async parseRouteDetails() {
      // this function is only to account for deep linking
      const { conversationId } = this.$route.params
      try {
        if (conversationId) {
          await this.fetchSpecificConversation(conversationId)
        } else {
          // backward compatibility
          const { company: companyId, customer: customerId } = this.$route.query
          if (companyId && companyId !== this.activeCompany?._id) {
            const company = this.selectCompany(companyId)
            if (company) {
              await this.setActiveCompany(company)
            } else {
              throw new Error('No matching company for winback')
            }
          }
          if (customerId) {
            this.setActiveConversation({ customer: { _id: customerId } })
            if (this.selectIsMobileView) {
              this.$refs.timelineDrawer.open()
            }
          }
        }
      } catch (error) {
        this.$notify({ type: 'error', text: error.message })
      }
    },
  },
}
</script>
