<template>
  <Multiselect
    :show-labels="false"
    closeOnSelect
    :placeholder="placeholder"
    @input="updateFilters"
    v-model="category"
    :options="categories"
    track-by="_id"
    label="name"
  >
    <template slot="option" slot-scope="props">
      <div>
        <span>{{ props.option.name }}</span>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'CategoryFilter',
  components: {
    Multiselect,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'All Categories',
    },
  },
  data: () => ({
    category: '',
  }),
  methods: {
    updateFilters(selection) {
      this.$emit('updateCategory', selection)
    },
  },
}
</script>
