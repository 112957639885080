import Vue from 'vue'
import { throttle } from 'lodash-es'

const listConversations = throttle(
  (payload) => Vue.http.post('app-services/v2/conversations/list', payload),
  500,
  {
    leading: true,
  }
)
const listResponsetimes = throttle(
  (payload) => Vue.http.post('app-services/v2/conversations/response-times', payload),
  500,
  {
    leading: true,
  }
)
export default {
  fetchConversations(payload) {
    return listConversations(payload)
  },
  fetchConversation(conversationId) {
    return Vue.http.get(`app-services/v2/conversations/${conversationId}`)
  },
  fetchResponseTimes({ conversationIds }) {
    return listResponsetimes({ conversationIds })
  },
  fetchCount(payload) {
    return Vue.http.post(`app-services/v2/conversations/unresolved-count`, payload)
  },
  markOpen({ conversationId }) {
    return Vue.http.put(`app-services/v2/conversations/${conversationId}/open`)
  },
  sendReply({ conversationId, promoId, message, giftCardAmount }) {
    return Vue.http.post(`app-services/v2/conversations/${conversationId}/send-reply`, {
      promoId,
      message,
      giftCardAmount,
    })
  },
  markResolved({ conversationId }) {
    return Vue.http.put(`app-services/v2/conversations/${conversationId}/resolve`)
  },
  markUnresolved({ conversationId }) {
    return Vue.http.put(`app-services/v2/conversations/${conversationId}/unresolve`)
  },
  createConversation({ customer, location, company }) {
    return Vue.http.post(`app-services/v2/conversations`, { customer, location, company })
  },
  generateReply({ conversationId }) {
    return Vue.http.post(`app-services/v2/conversations/${conversationId}/generate-reply`, {})
  },
  fetchOpenRates(filters) {
    return Vue.http.post(`app-services/v2/conversations/open-rates`, { filters })
  },

  /******************** Old endpoints ******************/
  create(payload) {
    return Vue.http.post('conversation/create', payload)
  },
  list(payload) {
    return Vue.http.post('conversation/list', payload)
  },
  resolve(payload) {
    return Vue.http.post('conversation/resolve', payload)
  },
  open(payload) {
    return Vue.http.post('conversation/open', payload)
  },
  reply(payload) {
    return Vue.http.post('conversation/reply', payload)
  },
}
