<template>
  <b-modal
    id="createRoutingRuleModal"
    ref="createRoutingRuleModal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hide="close"
  >
    <div v-if="editMode" class="card-header h2">Edit Routing Rule</div>
    <div v-if="!editMode" class="card-header h2">Create Routing Rule</div>

    <div class="card-body">
      <div class="mb-4">
        <div class="mb-2">Select Sources</div>
        <Multiselect
          placeholder="Type to search"
          selectLabel
          :close-on-select="false"
          v-model="selectedSources"
          :multiple="true"
          @input="updateSources"
          :searchable="true"
          :showLabels="false"
          :limitText="(count) => `+${count} more`"
          :limit="5"
          :options="sources"
        >
          <span slot="noResult">No results.</span>
          <template slot="placeholder">
            <span class="text-secondary">Select sources</span>
          </template>
        </Multiselect>
      </div>
      <div class="mb-4">
        <div class="mb-2">Select Users</div>
        <div class="text-muted h5">
          Users who are added to a rule will only receive feedback according to the rule.
        </div>
        <Multiselect
          placeholder="Select users"
          selectLabel
          :close-on-select="false"
          :multiple="true"
          v-model="users"
          :searchable="true"
          :showLabels="false"
          :limitText="(count) => `+${count} more`"
          :options="selectEmployees"
          :custom-label="employeeName"
          track-by="_id"
        />
      </div>
    </div>
    <div v-if="error">
      <div class="alert alert-danger" role="alert">
        {{ error }}
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex">
        <button class="btn mr-2 ml-auto" @click="close()">Cancel</button>
        <button class="btn btn-primary" :disabled="disabled" @click="save">
          {{ selectedRule._id ? 'Update' : 'Create' }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const SurveyModule = createNamespacedHelpers('survey')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const RuleModule = createNamespacedHelpers('rule')

export default {
  name: 'CreateRoutingRuleModal',
  props: {
    selectedRule: {
      type: Object,
    },
    rules: {
      type: Array,
    },
  },
  components: {
    Multiselect,
  },
  data: () => ({
    users: [],
    error: null,
    loading: false,
    editMode: false,
    selectedSources: [],
  }),
  watch: {
    selectedRule: {
      immediate: true,
      handler() {
        if (Object.keys(this.selectedRule).length) {
          this.users = this.selectedRule?.users?.map((id) => this.selectEmployee(id))
          this.selectedSources = this.selectedRule?.sources
          this.editMode = true
        }
      },
    },
  },
  computed: {
    ...RuleModule.mapGetters(['selectRules']),
    ...SurveyModule.mapGetters(['selectSources']),
    ...LocationModule.mapGetters(['selectActiveLocations']),
    ...CompanyModule.mapGetters(['selectEmployees', 'activeCompany', 'selectEmployee']),

    sources() {
      return this.selectSources
    },
    disabled() {
      return this.error || this.loading || !this.selectedSources?.length || !this.users?.length
    },
    disabledOptions() {
      return this.rules
        .filter((rule) => this.selectedRule?._id?.toString() !== rule?._id?.toString())
        .reduce((acc, curr) => [...acc, ...(curr.sources || [])], [])
    },
    routingRules() {
      return this.selectRules(this.activeCompany?._id)?.filter((rule) => rule?.type === 'ROUTING')
    },
  },
  methods: {
    ...RuleModule.mapActions(['persistRule']),
    ...SurveyModule.mapActions(['getSources']),

    updateSources(value) {
      this.selectedSources = value
    },
    employeeName(user) {
      return `${user?.firstName} ${user?.lastName}`
    },
    save() {
      const persist = async () => {
        this.loading = true
        try {
          await this.persistRule({
            ...(this.selectedRule?._id ? { _id: this.selectedRule._id } : {}),
            type: 'ROUTING',
            users: this.users.map((user) => user._id),
            sources: this.selectedSources,
          })
          this.close()
        } catch (error) {
          this.error = 'Oops an error occurred, please refresh the page and try again.'
        }
        this.loading = false
      }

      if (this.isRoutingRuleValid()) {
        persist()
      } else {
        this.$notify({
          type: 'error',
          title: `Failed to ${this.selectedRule._id ? 'update' : 'create'} rule`,
          text: 'A rule with one or more of the same sources already exist.',
          duration: 4000,
        })
      }
    },
    close() {
      this.selectedSources = this.users = []
      this.error = null
      this.editMode = false
      this.$refs.createRoutingRuleModal?.hide()
      this.$emit('clearRule')
    },
    isRoutingRuleValid() {
      let isValid = true
      for (let source of this.selectedSources) {
        const foundOne = this.routingRules?.find(
          (rule) => rule?._id !== this.selectedRule?._id && rule?.sources?.includes(source)
        )
        if (foundOne) isValid = false
      }
      return isValid
    },
  },
  async mounted() {
    await this.getSources()
  },
}
</script>
