import Vue from 'vue'

export default {
  vendastaSsoWorkflow(payload) {
    return Vue.http.post('vendor-pasta/sso', payload)
  },
  checkVendastaJwt(payload) {
    return Vue.http.post('vendor-pasta/jwt', payload)
  },
}
