<template>
  <multiselect
    selectLabel
    v-model="filteredLocation"
    @input="updateLocation"
    :searchable="true"
    :showLabels="false"
    :options="activeLocations"
    :custom-label="locationName"
    track-by="_id"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary"> <span class="fe fe-map-pin"></span> {{ placeholder }} </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span>{{ props.option.friendlyName || props.option.city }}</span>
        <br />
        <small class="text-muted small text-truncate">
          {{
            `${props.option.addressDetails.streetNumber} ${props.option.addressDetails.streetName}`
              | truncate(20)
          }}
        </small>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const locationModule = createNamespacedHelpers('location')

export default {
  name: 'SingleLocationFilter',
  components: {
    Multiselect,
  },
  props: {
    placeholder: {
      type: String,
      default: 'All locations',
    },
    locations: {
      type: Array,
    }
  },
  data: () => ({
    filteredLocation: undefined,
  }),
  computed: {
    ...locationModule.mapState(['userActiveCompanyLocations']),
    activeLocations() {
      return this.locations || this.userActiveCompanyLocations
    },
  },
  methods: {
    updateLocation(location) {
      this.$emit('updateLocation', location)
    },
    locationName({ friendlyName, city }) {
      return friendlyName || city
    },
  },
}
</script>
