<template>
  <form class="mb-4">
    <div class="alert alert-light" role="alert" v-if="!company.loyalty.integrated">
      <i class="fe fe-info"></i>&nbsp; <strong>Loyalty Lite</strong> is based on checkins via the
      in-store kiosk. If you'd like your loyalty system to be based on spend or transaction volume,
      please contact us about integrating with your point of sale system.
    </div>

    <!-- Program setup -->
    <div class="row">
      <div class="col-12">
        <div v-if="company.loyalty.integrated">
          <div class="form-group">
            <h3 class="mt-2 mb-4">
              How do customers earn {{ pluralize(this.company.loyalty.pointLabel) }}?
            </h3>

            <CompanySettingsLoyaltyProgram
              :program="company.loyalty.program"
              v-on:programSelected="setProgram"
            />
            <hr class="mt-3 mb-4" />
          </div>
        </div>
        <!-- Cover image for rewards page -->
        <h3 class="mt-2 mb-4">Design</h3>
        <div v-if="company" class="row">
          <div v-if="company.loyalty.coverPhoto" class="col-auto">
            <div class="avatar">
              <img :src="company.loyalty.coverPhoto" class="avatar-img rounded" />
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label class="mb-1">Cover Photo</label>
              <small class="form-text text-muted"
                >This will show when customers review their rewards. Please use a photo at least
                1200px * 600px.</small
              >
              <FileUploader
                @uploaded="coverUploaded"
                :button-text="company.loyalty.coverPhoto ? 'Change cover' : 'Upload cover'"
                button-class="btn-primary"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Design -->
      <div class="col-12">
        <hr class="mt-3 mb-4" />
        <div class="form-group">
          <label class="mb-1">Callout Text</label>
          <small class="form-text text-muted"
            >This shows up in big bold letters inviting customers to join your loyalty
            program.</small
          >
          <input
            maxlength="29"
            v-model="company.loyalty.callout"
            placeholder="Get some free food."
            class="form-control"
            type="text"
          />
        </div>
        <div class="form-group">
          <label class="mb-1">Points Terminology</label>
          <small class="form-text text-muted"
            >Don't like points? Give a star, bean, cat....it's up to your imagination.</small
          >
          <!-- Input -->
          <input
            :value="company.loyalty.pointLabel.toLowerCase()"
            @input="company.loyalty.pointLabel = $event.target.value.toLowerCase()"
            class="form-control"
            type="text"
          />
        </div>
      </div>
    </div>

    <!-- Rules & Points -->
    <div class="row">
      <div class="col-12">
        <hr class="mt-3 mb-4" />
        <h3 class="mt-2 mb-4">Rules</h3>
        <div class="form-group">
          <label class="mb-1">
            {{
              company.loyalty.integrated && company.loyalty.program === 'spend' ? 'Dollar' : 'Visit'
            }}
            to <span class="text-capitalize">{{ company.loyalty.pointLabel }}</span> Conversion
          </label>
          <small class="form-text text-muted"
            >How many {{ pluralize(company.loyalty.pointLabel) }} does a customer get per
            {{
              company.loyalty.integrated && company.loyalty.program === 'spend'
                ? 'dollar'
                : 'visit'
            }}?</small
          >
          <!-- Input -->
          <div class="input-group input-group-merge mb-3">
            <input
              v-model="company.loyalty.pointConversion"
              class="form-control form-control-prepended"
              type="number"
            />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <span class="fe fe-star"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" v-if="company.loyalty.integrated">
          <label class="mb-1">Minimum Spend</label>
          <small class="form-text text-muted"
            >How much does a customer need to spend to be eligible to earn
            {{ pluralize(company.loyalty.pointLabel) }}?</small
          >
          <div class="input-group input-group-merge mb-3">
            <input
              v-model="company.loyalty.minimumSpend"
              class="form-control form-control-prepended"
              type="number"
            />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <span class="fe fe-dollar-sign"></span>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-3 mb-4" />
        <!-- Rewards -->
        <div class="form-group">
          <h3 class="mt-2 mb-4">Rewards</h3>
          <CompanySettingsLoyaltyRewards
            :rewards="company.loyalty.rewards"
            :pointLabel="company.loyalty.pointLabel"
            v-on:addRewardOption="addReward"
            v-on:removeRewardOption="removeReward"
          />
        </div>
        <hr class="mt-3 mb-4" />
        <!-- Automated follow-up surveys -->
        <div class="form-group">
          <h3 class="mt-2 mb-4">Surveys</h3>
          <div class="form-group">
            <label class="mb-1">Automatic Follow-up</label>
            <small class="form-text text-muted"
              >Whether or not a survey gets sent after each customer visit.</small
            >
            <div class="row">
              <div class="col-auto">
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    v-model="company.loyalty.sendSurveyAfterCheckin"
                    class="custom-control-input"
                    id="toggleTwo"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="toggleTwo"></label>
                </div>
              </div>
              <div class="col ml--2">
                <small class="text-muted">{{
                  company.loyalty.sendSurveyAfterCheckin ? 'Enabled' : 'Disabled'
                }}</small>
              </div>
            </div>
          </div>
          <div v-if="company.loyalty.sendSurveyAfterCheckin">
            <div class="form-group">
              <label class="mb-1">Incentive</label>
              <small class="form-text text-muted"
                >How many {{ pluralize(company.loyalty.pointLabel) }} does a customer receive for
                completing the survey?</small
              >
              <div class="input-group input-group-merge mb-3">
                <input
                  v-model="company.loyalty.surveyIncentive"
                  class="form-control form-control-prepended"
                  type="number"
                />
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <span class="fe fe-star"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="mb-1">
                Follow-up Delay
                <span class="text-muted">(in minutes)</span>
              </label>
              <small class="form-text text-muted"
                >After a checkin, how many minutes should we wait before asking them to take a
                survey?</small
              >
              <div class="input-group input-group-merge mb-3">
                <input
                  v-model="company.loyalty.surveyDelay"
                  class="form-control form-control-prepended"
                  type="number"
                />
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <span class="fe fe-clock"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="mb-1">
                Survey Throttle
                <span class="text-muted">(in days)</span>
              </label>
              <small class="form-text text-muted"
                >How many days need to have passed since the last survey invitation for the customer
                to be eligible to receive an additional follow-up survey?</small
              >
              <div class="input-group input-group-merge mb-3">
                <input
                  v-model="company.loyalty.surveyThrottle"
                  class="form-control form-control-prepended"
                  type="number"
                />
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <span class="fe fe-sunrise"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-3 mb-4" />
        <div class="form-group">
          <button @click="update" type="button" class="btn btn-primary col-12">Update</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import CompanySettingsBase from '../CompanySettingsBase'
import CompanySettingsLoyaltyProgram from './CompanySettingsLoyaltyProgram'
import CompanySettingsLoyaltyRewards from './CompanySettingsLoyaltyRewards'
import CompanyService from '@/services/CompanyService'
import FileUploader from '@/components/MainContent/FileUploader'
const pluralize = require('pluralize')

export default {
  name: 'CompanySettingsLoyalty',
  extends: CompanySettingsBase,
  components: {
    FileUploader,
    CompanySettingsLoyaltyProgram,
    CompanySettingsLoyaltyRewards,
  },
  data: () => ({
    company: {
      loyalty: {
        coverPhoto:
          'https://s3.us-east-2.amazonaws.com/assets.ovationup.com/mobile/loyalty-cover.png',
        program: 'spend',
        callout: 'Get some free food.',
        pointConversion: 1,
        minimumSpend: 1,
        pointLabel: 'point',
        rewards: [{ title: 'Free slice', points: 10 }],
        sendSurveyAfterCheckin: true,
        surveyDelay: 30,
        surveyIncentive: 1,
        surveyThrottle: 1,
      },
    },
  }),
  methods: {
    pluralize: pluralize,
    /**
     * Inits the company
     */
    async initCompany() {
      if (!this.activeCompany.loyalty) return this.update()
      this.company = this.activeCompany
    },
    setProgram(program) {
      this.company.loyalty.program = program
    },
    addReward(reward) {
      this.company.loyalty.rewards.push(reward)
      this.update()
    },
    removeReward(rewardIndex) {
      this.company.loyalty.rewards.splice(rewardIndex, 1)
      this.update()
    },
    /**
     * Updates changed company fields
     */
    async update() {
      await CompanyService.update({
        companyId: this.company._id,
        fields: {
          loyalty: this.company.loyalty,
        },
      })

      await this.refreshCompanyList()

      this.$notify({
        title: 'Loyalty updated',
        text: 'Your loyalty preferences were saved.',
      })
    },
    /**
     * Saves the uploaded file URL as the company's logo
     */
    async coverUploaded(fileUrl) {
      await CompanyService.update({
        companyId: this.company._id,
        fields: {
          'loyalty.coverPhoto': fileUrl,
        },
      })

      await this.refreshCompanyList()
      this.initCompany()
    },
  },
  mounted() {
    this.initCompany()
  },
}
</script>

<style scoped lang="scss">
.avatar {
  width: 6rem;
  height: 6rem;
}
.input-group-prepend {
  span {
    position: relative;
    bottom: 1px;
  }
}
</style>
