<template>
  <div>
    <div v-if="!hidePane" class="bd-bottom-gray p-3">
      <div class="d-flex justify-content-between pane-title" style="min-height: 30px">
        <span :class="{ 'font-weight-bold': boldTitle }">
          <slot name="close"></slot>
          <slot name="title"></slot>
          <slot name="titleIcon"></slot>
        </span>
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaneHeader',
  props: {
    boldTitle: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    hidePane: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.bd-bottom-gray {
  border-bottom: 1.5px solid #d2ddec;
}
.pane-title {
  font-size: 20px;
  font-weight: 500;
}
</style>
