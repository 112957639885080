<template>
  <div>
    <form @submit.prevent="updateProfile" class="mb-4">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>First name</label>
            <input
              v-model="form.firstName"
              class="form-control"
              type="text"
              placeholder="Jane"
              autocomplete="fname"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>Last name</label>
            <input
              v-model="form.lastName"
              class="form-control"
              type="text"
              placeholder="Doe"
              autocomplete="lname"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>Email address</label>
            <input
              v-model="form.email"
              class="form-control"
              type="email"
              placeholder="name@address.com"
              autocomplete="email"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>Mobile Number</label>
            <input
              v-model="form.phone"
              class="form-control mb-3"
              data-mask="(000) 000-0000"
              placeholder="(000) 000-0000"
              v-mask="'(###) ###-####'"
              autocomplete="tel"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>Time zone</label>
            <MultiSelect
              id="user-timezone"
              class="mb-3"
              label="name"
              track-by="value"
              :allow-empty="false"
              :show-labels="false"
              openDirection="below"
              placeholder="Search timezone"
              v-model="form.timezone"
              :options="timezones"
            >
              <template #noResult><span>No matching timezone.</span></template>
            </MultiSelect>
          </div>
        </div>
        <div class="col-12">
          <button
            class="btn btn-primary"
            type="submit"
            :class="{ 'is-loading': isLoading }"
            :disabled="!formValid || isLoading"
          >
            Update profile
          </button>
        </div>
      </div>
    </form>
    <hr class="my-5" />
    <AccountAvatarForm />
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import MultiSelect from '@/components/Common/MultiSelect'
import AccountAvatarForm from '@/components/Modules/User/Account/AccountAvatarForm'

import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'AccountProfileForm',

  components: {
    MultiSelect,
    AccountAvatarForm,
  },

  computed: {
    ...mapState(['onlineUser']),
    formValid() {
      return (
        this.form.firstName &&
        this.form.lastName &&
        this.form.email &&
        this.form.phone &&
        this.form.timezone
      )
    },
  },

  data: () => ({
    isLoading: false,
    form: {},
    timezones: [],
  }),

  watch: {
    onlineUser: {
      immediate: true,
      handler(updatedUser) {
        const guessedTimezone = this.$moment.tz.guess()
        const timezone = updatedUser.timezone || guessedTimezone

        this.form = {
          ...updatedUser,
          timezone: this.joinTimezone(timezone),
        }
      },
    },
  },

  async mounted() {
    this.timezones = [
      ...this.$moment.tz.zonesForCountry('US'),
      ...this.$moment.tz.zonesForCountry('CA'),
    ].map(this.joinTimezone)

    if (!this.onlineUser.timezone) {
      await UserService.update({
        userId: this.onlineUser._id,
        fields: {
          timezone: this.$moment.tz.guess(),
        },
      })
    }
  },

  methods: {
    ...mapActions(['getOnlineUser']),
    async updateProfile() {
      this.isLoading = true

      await UserService.update({
        userId: this.onlineUser._id,
        fields: {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          phone: this.form.phone,
          email: this.form.email,
          timezone: this.form.timezone.value,
        },
      })

      this.$notify({
        title: 'Profile updated',
        text: 'Your preferences have been saved.',
      })

      await this.getOnlineUser()

      this.isLoading = false
    },
    joinTimezone(timezone) {
      return {
        name: this.$moment.tz(new Date(), timezone).format('(UTCZ) ') + timezone,
        value: timezone,
      }
    },
  },
}
</script>
