<template>
  <div class="card mr-3 p-4">
    <div class="px-4">
      <h4 class="text-muted mb-4 text-center" style="font-size: 14px">{{ title }}</h4>
      <div v-if="categoryListWithMentions.length">
        <div
          v-for="(option, index) in categoryListWithMentions"
          :key="index"
          class="d-flex justify-content-between mb-3 align-items-center"
        >
          <div class="">
            <div class="h2 mb-0">{{ option.name }}</div>
            <div class="" :style="{ color: mentionColor }">{{ option.mentions }} mentions</div>
          </div>
          <button class="btn btn-outline-primary btn-sm" @click="showDetails(option.id)">
            View Details
          </button>
        </div>
      </div>
      <div v-else>- No mentions yet</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')

export default {
  name: 'TopCategories',
  props: {
    sentiment: {
      type: String,
      required: true,
      validator(value) {
        return ['POSITIVE', 'NEGATIVE'].includes(value)
      },
    },
  },
  computed: {
    ...InsightsModule.mapGetters(['selectTopStrengths', 'selectTopIssues']),
    categoryList() {
      return this.sentiment === 'POSITIVE' ? this.selectTopStrengths : this.selectTopIssues
    },
    mentionColor() {
      return this.sentiment === 'POSITIVE' ? '#33D691' : '#FC6D87'
    },
    title() {
      return this.sentiment === 'POSITIVE' ? 'Top Strengths' : 'Top Issues'
    },
    categoryListWithMentions() {
      return this.categoryList.filter((c) => c.mentions > 0)
    },
  },
  methods: {
    showDetails(categoryId) {
      this.$router.push({
        path: '/insights/details',
        query: { ...this.$route.query, sentiment: this.sentiment, category: categoryId },
      })
    },
  },
}
</script>
