<template>
  <form @submit.prevent="nextLoginStep">
    <div class="row">
      <div class="form-group col-6">
        <label>First name</label>
        <input
          v-model="firstName"
          type="text"
          class="form-control"
          placeholder="Jane"
          autocomplete="fname"
        />
      </div>

      <div class="form-group col-6">
        <label>Last name</label>
        <input
          v-model="lastName"
          type="text"
          class="form-control"
          placeholder="Doe"
          autocomplete="lname"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Mobile Number</label>
        <input
          v-model="phone"
          type="text"
          class="form-control"
          placeholder="(000) 000-0000"
          v-mask="'(###) ###-####'"
          autocomplete="tel"
          :disabled="isVerifyingPhone"
        />
      </div>
    </div>
    <SlideUpTransition>
      <div v-if="isVerifyingPhone && !errMessage" class="form-group">
        <label>What is the code sent to {{ phone | phone }}?</label>
        <input v-model="phoneCode" type="tel" class="form-control" placeholder="123456" />
      </div>

      <div v-if="errMessage" class="alert alert-danger" role="alert">{{ errMessage }}</div>
    </SlideUpTransition>

    <button
      type="submit"
      :disabled="!phone || isLoading || (isVerifyingPhone && phoneCode.length < 6)"
      :class="{ 'is-loading': isLoading || (isVerifyingPhone && phoneCode.length < 6) }"
      class="btn btn-lg btn-block btn-primary mb-3"
    >
      Login
    </button>
  </form>
</template>

<script>
import UserService from '@/services/UserService'
import SlideUpTransition from '@/components/Transitions/SlideUpTransition'
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'AddInfoForm',
  components: {
    SlideUpTransition,
  },
  data() {
    return {
      isLoading: false,
      errMessage: false,
      firstName: '',
      lastName: '',
      phone: '',
      phoneCode: '',
      isVerifyingPhone: false,
    }
  },
  computed: {
    ...UserModule.mapState(['onlineUser']),
  },
  methods: {
    nextLoginStep() {
      if (!this.isVerifyingPhone) {
        this.sendPhoneVerification()
      } else if (this.isVerifyingPhone) {
        this.verifyPhoneCode()
      }
    },
    async sendPhoneVerification() {
      this.errMessage = ''
      this.isVerifyingPhone = true

      try {
        await UserService.sendPhoneVerification({
          phone: this.phone,
          isLogin: false,
          webOrigin: window.location.origin,
        })
      } catch (err) {
        this.errMessage = err.body.message
        this.isVerifyingPhone = false
      }
    },
    async verifyPhoneCode() {
      try {
        this.isLoading = true
        await UserService.update({
          userId: this.onlineUser._id,
          fields: {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
          },
        })

        const response = await UserService.verifyPhoneCode({
          code: this.phoneCode,
        })

        const { jwt } = response.body
        localStorage.setItem('jwt', jwt)

        this.$emit('authenticated')
      } catch (err) {
        console.log(err)
        this.isLoading = false
        this.errMessage = err.body.message
        this.isVerifyingPhone = false
        this.phoneCode = ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
.password-mask:hover {
  cursor: pointer;
}
</style>
