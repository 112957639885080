import Config from '@/config'
import { removeUserActiveCompanyId } from '@/store/company'

import router from '../router'
import store from '../store'

export function interceptor(request, next) {
  const { api, apiv2, apiApp } = Config
  const jwt = localStorage.getItem('jwt')

  if (typeof request.url == 'object') {
    if (!apiv2[request.url.apiName]) {
      throw `Missing apiName - ${request.url.apiName} in config.`
    }

    if (request.url.action) {
      request.headers.set('ovation-action', request.url.action)
    }

    request.headers.set('client-identifier', store.getters['user/onlineUser']._id)

    request.url = `${apiv2[request.url.apiName]}/${request.url.resource}`
  } else if (request.url.startsWith('app-')) {
    request.url = `${apiApp}/${request.url}`
  } else {
    request.url = `${api}/${request.url}`
  }

  if (jwt) {
    request.headers.set('Authorization', jwt)
  }
}
