import ReviewSubscriptionService from '@/services/ReviewSubscriptionService'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async enableReviewSubscription({ commit }, { reviewSubscriptionId, locationId }) {
      await ReviewSubscriptionService.enable(reviewSubscriptionId, locationId)
    },
    async disableReviewSubscription({ commit }, { reviewSubscriptionId, locationId }) {
      await ReviewSubscriptionService.disable(reviewSubscriptionId, locationId)
    },
    async updateReviewSubscription(
      { commit },
      { reviewSubscriptionId, locationId, modifications }
    ) {
      await ReviewSubscriptionService.update(reviewSubscriptionId, locationId, modifications)
    },
  },
}
