import Vue from 'vue'

export default {
  create(payload) {
    return Vue.http.post('customer', payload)
  },
  list(payload) {
    return Vue.http.post('customer/list', payload)
  },
  totalCustomerCount({ companyId }) {
    return Vue.http.get(`customer/total-count/${companyId}`)
  },
  listExport(payload) {
    return Vue.http.post('app-services/v2/customer/export-list', payload)
  },
  fetchMetrics({ customerId, companyId }) {
    return Vue.http.get(`app-services/v2/customer/${customerId}/metrics/${companyId}`)
  },
  lastVisited(payload) {
    return Vue.http.post('customer/last-visited', payload)
  },
  chooseWinner(payload) {
    return Vue.http.post('customer/choose-winner', payload)
  },
  pastWinners(payload) {
    return Vue.http.post('customer/past-winners', payload)
  },
  block(payload) {
    return Vue.http.post('customer/block', payload)
  },
  unblock(payload) {
    return Vue.http.post('customer/unblock', payload)
  },
  delete(payload) {
    return Vue.http.post('customer/delete', payload)
  },
  winbackList(payload) {
    return Vue.http.post('customer/winback-list', payload)
  },
  import(payload) {
    return Vue.http.post('customer/import', payload, { timeout: 300000 })
  },
  export(payload) {
    return Vue.http.post('customer/export', payload, { timeout: 300000 })
  },
  getCustomerData(payload) {
    return Vue.http.post('customer/get-by-id', payload)
  },
  tag({ companyId, customerId, tagId, op }) {
    return Vue.http.patch(`customer/${customerId}/tag`, { companyId, tagId, op })
  },
  blockCustomer({ companyId, customerId }) {
    return Vue.http.put(`app-services/v2/customer/${customerId}/block/${companyId}`)
  },
  unblockCustomer({ companyId, customerId }) {
    return Vue.http.put(`app-services/v2/customer/${customerId}/unblock/${companyId}`)
  },
}
