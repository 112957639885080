import Vue from 'vue'

export default {
  createPromo({ promo }) {
    return Vue.http.post(`app-services/v2/promos/`, promo)
  },
  updatePromo({ promoId, promo }) {
    return Vue.http.put(`app-services/v2/promos/${promoId}`, promo)
  },
  getPromo({ promoId }) {
    return Vue.http.get(`app-services/v2/promos/${promoId}`)
  },
  listPromos({ filters }) {
    return Vue.http.post(`app-services/v2/promos/list`, { filters })
  },
  deletePromo({ promoId }) {
    return Vue.http.delete(`app-services/v2/promos/${promoId}`)
  },

  // deprecated
  create(payload) {
    return Vue.http.post('promo', payload)
  },
  list(payload) {
    return Vue.http.post('app-services/v2/promos/list', payload)
  },
  toggle(promoId) {
    return Vue.http.post('promo/toggle', { promoId })
  },
  remove(promoId) {
    return Vue.http.post('promo/remove', { promoId })
  },
  usedCode({ customerId, promoId, redemptionTimestamp }) {
    return Vue.http.post('promo/usedCode', { customerId, promoId, redemptionTimestamp })
  },
}
