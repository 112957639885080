<template>
  <div class="card h-100 px-4" style="min-width: 330px">
    <div class="p-4 text-center">
      <h4 class="text-muted mb-0" style="font-size: 14px">Subcategories</h4>
    </div>

    <div style="height: 155px">
      <div
        v-for="(subcategory, index) in subcategoryPage"
        :key="index"
        class="d-flex justify-content-between mb-1 px-4 "
      >
        <button class="btn btn-link pl-0" @click="showDetails(subcategory._id)">
          <div class="h3 mb-0 text-dark">{{ subcategory.name }}</div>
        </button>
        <button class="btn btn-link" @click="showDetails(subcategory._id)">
          <div class="h3 mb-0 text-dark">{{ subcategory.issueCount }} issues</div>
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-3" v-if="pages > 1">
      <nav>
        <ul class="pagination pagination-sm mb-0">
          <li
            class="page-item"
            :class="{ active: index === page }"
            v-for="index of pages"
            :key="index"
            @click="page = index"
          >
            <span class="page-link">{{ index }}</span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')

export default {
  name: 'SubcategoryList',
  props: {
    subcategories: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    ...InsightsDetailsModule.mapGetters(['selectFeedbackCount', 'selectCategoryIssueCount']),
    pages() {
      return Math.ceil(this.subcategories.length / 4)
    },
    detailedSubcategories() {
      return this.subcategories
        .map((s) => {
          return { ...s, issueCount: this.selectCategoryIssueCount(s._id) }
        })
        .sort((a, b) => (a.issueCount < b.issueCount) ? 1 : -1)
    },
    subcategoryPage() {
      const start = (this.page - 1) * 5
      return this.detailedSubcategories.slice(start, start + 4)
    },
  },
  methods: {
    showDetails(categoryId) {
      this.$router.replace({
        path: '/insights/details',
        query: { ...this.$route.query, category: categoryId, preventRedirect: true },
      })
      this.$emit('refreshDetails')
    },
  },
}
</script>

<style scoped>
.page-item.active .page-link {
  color: #12263f;
  border-color: gray;
  background: white;
}
</style>
