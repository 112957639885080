<template>
  <div style="width: 150px">
    <b-form-timepicker
      v-model="value"
      :disabled="isDisabled"
      @hidden="selectTime"
      :key="initValue"
      :placeholder="placeholder"
    ></b-form-timepicker>
  </div>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    initValue: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'No time selected'
    },
  },
  data: () => ({
    value: '',
  }),
  watch: {
    initValue: {
      handler() {
        this.value = this.initValue ? this.$moment(this.initValue, 'h:mm a').format('HH:mm:ss') : ''
      },
      immediate: true,
    },
  },
  methods: {
    selectTime() {
      this.$emit(
        'updateTime',
        this.value ? this.$moment(this.value, 'HH:mm:ss').format('h:mm A') : ''
      )
    },
  },
}
</script>
