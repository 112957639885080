<template>
  <div id="spacer">
    <QuestionListPreviewModal ref="questionListPreviewModal" />
    <div class="row justify-content-center" id="filters">
      <div class="card px-4 pt-4 w-100">
        <div class="row">
          <div class="col-12 mb-0">
            <!-- filters -->
            <div>
              <QuestionCount
                class="float-right mt--2"
                :isSearching="queryPending"
                v-if="listFilters.status === 'ACTIVE'"
              />
              <div class="w-auto h5 text-muted">
                Preview List Filters
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:filters.hover.bottom="previewInfo"
                ></i>
              </div>
            </div>
            <hr class="my-1" />

            <div class="mb-3 clearfix">
              <!-- status -->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Status
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.bottom="statusInfo"
                  ></i>
                </h6>
                <StatusFilter @updateStatus="filterStatus" />
              </div>

              <!-- category -->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Category
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.bottom="categoryInfo"
                  ></i>
                </h6>
                <CategoryFilter
                  :categories="categories"
                  placeholder="Any Category"
                  @updateCategory="filterByCategory"
                />
              </div>

              <!-- location -->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Location
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.bottom="locationInfo"
                  ></i>
                </h6>
                <LocationFilter placeholder="Any location" @updateLocation="filterByLocation" />
              </div>

              <!-- rating -->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Rating
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.bottom="ratingInfo"
                  ></i>
                </h6>
                <SingleRatingFilter placeholder="Any rating" @updateRating="filterByRating" />
              </div>

              <!-- source -->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Source
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.bottom="sourceInfo"
                  ></i>
                </h6>
                <SingleSourceFilter placeholder="Any source" @updateSource="filterBySource" />
              </div>

              <!-- responded -->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Responded
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.bottom="respondedInfo"
                  ></i>
                </h6>
                <RespondedFilter placeholder="Any" @updateResponded="filterResponded" />
              </div>

              <!-- buttons -->
              <div class="d-inline-block mb-2 filter-container-right">
                <button
                  class="btn mr-2"
                  style="margin-top: 32px"
                  @click="sendTest"
                  :disabled="!filteredCount"
                >
                  Send Test
                </button>
                <button class="btn" style="margin-top: 32px" @click="reset">Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleSourceFilter from './QuestionCreation/Filters/SingleSourceFilter'
import SingleRatingFilter from './QuestionCreation/Filters/SingleRatingFilter'
import QuestionCount from './QuestionCreation/Filters/QuestionCount'
import RespondedFilter from './QuestionCreation/Filters/RespondedFilter'
import StatusFilter from './QuestionCreation/Filters/StatusFilter'
import SingleLocationFilter from './QuestionCreation/Filters/SingleLocationFilter'
import CategoryFilter from './QuestionCreation/Filters/CategoryFilter'
import QuestionListPreviewModal from '@/components/Modals/QuestionListPreviewModal'
import { createNamespacedHelpers, mapState } from 'vuex'
const CustomQuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'QuestionListFilters',
  components: {
    SingleSourceFilter,
    SingleRatingFilter,
    RespondedFilter,
    StatusFilter,
    QuestionCount,
    LocationFilter: SingleLocationFilter,
    CategoryFilter,
    QuestionListPreviewModal,
  },
  data: () => ({
    previewInfo: `Set filters to preview the question list that would be sent to customers whose
      inital survey matches the specified criteria.`,
    statusInfo: `The status of the question itself. Only "Active" questions will display to customers`,
    ratingInfo: `The rating a customer left in the inital short survey.`,
    sourceInfo: `The source that a short survey came in from.`,
    respondedInfo: `Whether or not the customer's feedback has been responded to in winback by
      the time the question list is sent to them.`,
    locationInfo: `The location for which the customer left the initial short survey.`,
    categoryInfo: `This will filter the list below to only show questions marked with the matching category.`,
  }),
  beforeDestroy() {
    this.setDefaultFilters()
  },
  computed: {
    ...CustomQuestionModule.mapState([
      'listFilters',
      'filteredCount',
      'queryPending',
      'categories',
    ]),
  },
  methods: {
    ...CustomQuestionModule.mapActions(['getCustomQuestions']),
    ...CustomQuestionModule.mapMutations([
      'setListFilters',
      'resetFilters',
      'setQueryPending',
      'setDefaultFilters',
    ]),

    sendTest() {
      this.$refs.questionListPreviewModal.initialize()
      this.$root.$emit('bv::show::modal', 'questionListPreviewModal')
    },

    async reset() {
      this.resetFilters()
      if (!this.queryPending) {
        this.setQueryPending(true)
        await this.getCustomQuestions()
        this.setQueryPending(false)
      }
    },

    async applyFilters(filters) {
      this.setListFilters(filters)
      if (!this.queryPending) {
        this.setQueryPending(true)
        await this.getCustomQuestions()
        this.setQueryPending(false)
      }
    },

    filterByRating(rating) {
      if (rating) {
        this.applyFilters({ ...this.listFilters, rating: rating.score })
      } else {
        this.applyFilters({ ...this.listFilters, rating: null })
      }
    },

    filterBySource(source) {
      this.applyFilters({ ...this.listFilters, source })
    },

    filterByLocation(location) {
      this.applyFilters({ ...this.listFilters, location: location._id })
    },

    filterResponded(selection) {
      if (selection) {
        this.applyFilters({ ...this.listFilters, responded: selection })
      } else {
        this.applyFilters({ ...this.listFilters, responded: null })
      }
    },

    filterByCategory(category) {
      this.applyFilters({ ...this.listFilters, category: category._id })
    },

    filterStatus(status) {
      this.applyFilters({ ...this.listFilters, status: status.value })
    },
  },
}
</script>

<style scoped>
#filters {
  display: block !important;
  position: relative;
  z-index: 1001;
}
.filter-container {
  height: 80px;
  float: left;
  padding: 0px !important;
}
.filter-container-right {
  height: 80px;
  float: right;
  padding: 0px !important;
}
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
</style>
