<template>
  <div>
    <!-- Locations  -->
    <div class="location-section">
      <div class="card form-control mb-4 py-1 justify-content-center" style="height: 46px">
        <FuzzySearch
          :arrayData="sortedLocations"
          :keys="['city', 'friendlyName', 'displayName']"
          @filteredResults="applyResults"
          :key="sortedLocations[0]._id"
        />
      </div>
      <div class="row">
        <div v-for="location in locations" :key="location._id" class="col-12 col-md-6">
          <CompanySettingsLocationCard :location="location" />
        </div>
      </div>
    </div>
    <!-- Locations end -->
  </div>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapGetters } from 'vuex'
import CompanySettingsBase from '../CompanySettingsBase'
import CompanySettingsLocationCard from './CompanySettingsLocationCard'
import FuzzySearch from '@/components/Modules/Filters/ArrayFuzzySearch'

const { mapState } = createNamespacedHelpers('user')

export default {
  name: 'CompanySettingsLocations',
  extends: CompanySettingsBase,
  components: {
    CompanySettingsLocationCard,
    FuzzySearch,
  },
  data: () => ({
    locations: null,
  }),
  computed: {
    ...mapGetters('location', [
      'userActiveCompanyLocations',
      'selectActiveLocations',
      'selectDisabledLocations',
    ]),
    ...mapState(['onlineUser']),
    sortedLocations() {
      return this.selectActiveLocations.concat(this.selectDisabledLocations)
    },
  },
  methods: {
    ...mapActions('location', ['getUserLocationsByActiveCompany']),
    applyResults(locationResults) {
      this.locations = locationResults
    },
  },
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    this.locations = this.sortedLocations
  },
}
</script>

<style scoped lang="scss">
.location-group:hover {
  cursor: pointer;
}
</style>
