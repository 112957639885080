<template>
  <div class="container sign-up">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-6 col-lg-6 pt-5">
        <FadeTransition>
          <div v-if="initialized">
            <div v-if="!cognitoLogin">
              <h1 class="display-4 text-center mb-3">Confirm your mobile number</h1>
              <p class="text-muted text-center mb-5">
                {{ invite.inviter.firstName }} has invited you to join the
                <strong>{{ companyNamesText() }}</strong> {{ isMultiple ? 'teams.' : 'team.' }}
              </p>
              <InvitedForm :invite="invite" @joined="joined" />
            </div>
            <div v-else>
              <amplify-authenticator username-alias="email" initial-auth-state="signup">
                <amplify-sign-up
                  slot="sign-up"
                  username-alias="email"
                  :header-text="`${
                    invite.inviter.firstName
                  } has invited you to join the ${companyNamesText()} ${
                    isMultiple ? 'teams.' : 'team.'
                  }`"
                  :form-fields.prop="signUpConfig.formFields"
                  :handleSignUp.prop="handleSignUpWithTerms"
                >
                </amplify-sign-up>
              </amplify-authenticator>
            </div>
          </div>
          <div v-if="errMessage" class="alert alert-danger" role="alert">
            {{ errMessage }}
          </div>
        </FadeTransition>
      </div>
    </div>
  </div>
</template>

<script>
import InvitedForm from '@/components/Modules/User/InvitedForm'
import FadeTransition from '@/components/Transitions/FadeTransition'
import CompanyService from '@/services/CompanyService'
import { trackEvent } from '@/lib/analytics'
import { Auth } from 'aws-amplify'

export default {
  name: 'InvitePage',
  components: {
    InvitedForm,
    FadeTransition,
  },
  data: () => ({
    initialized: false,
    cognitoLogin: false,
    errMessage: undefined,
    invite: {},
    signUpConfig: {
      formFields: [
        {
          type: 'email',
          label: 'Email Address',
          placeholder: 'Email',
          inputProps: { disabled: true },
        },
        {
          type: 'password',
          label: 'Password',
          placeholder: 'Password',
        },
      ],
    },
    companies: [],
    isMultiple: false,
    termsAccepted: false,
  }),
  methods: {
    joined() {
      this.$router.push({ name: 'dashboard' })
    },
    handleSignUp(formData) {
      const param = {
        ...formData,
        attributes: {
          ...formData.attributes,
          'custom:time_zone': this.$moment.tz.guess(),
          'custom:token': this.$route.params.token,
          'custom:terms_accepted': String(this.termsAccepted),
        },
      }

      try {
        trackEvent(this.$intercom, 'Terms of Use Accepted', {
          timestamp: this.$moment().toISOString(),
          value: this.termsAccepted,
          user: this.invite.employee,
        })
      } catch (error) {
        console.error('#handleSignUp: Failed to track event', error)
      }

      return Auth.signUp(param)
    },
    companyNamesText() {
      let nameText = ''
      if (this.companies.length === 1) {
        const company = this.companies[0]
        nameText = company.name
      } else if (this.companies.length === 2) {
        const company = this.companies[0]
        const company1 = this.companies[1]
        nameText = `${company.name} and ${company1.name}`
      } else {
        const company = this.companies[0]
        const company1 = this.companies[1]
        const teamCount = this.companies.length - 2
        nameText = `${company.name}, ${company1.name}, and ${teamCount} other`
        if (teamCount > 1) this.isMultiple = true
      }
      return nameText
    },
    async handleSignUpWithTerms(formData) {
      const message = `By creating an account, you agree to Ovation's
          <a href="https://ovationup.com/terms-of-use/" target="_blank">Terms of Use</a> and 
          <a href="https://ovationup.com/privacy-policy/" target="_blank">Privacy Policy</a>.
        `
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Terms & Conditions' } }),
      ])
      const contentVNode = h('div', [
        h('label', { class: ['mb-0'], domProps: { innerHTML: message } }),
      ])

      const termsAccepted = await this.$bvModal.msgBoxConfirm(contentVNode, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Accept',
        cancelTitle: 'Decline',
        cancelVariant: 'danger',
        centered: true,
      })

      if (termsAccepted) {
        this.termsAccepted = true
        return this.handleSignUp(formData)
      } else {
        const error = new Error('You must accept the terms and conditions to sign up.')
        return Promise.reject(error)
      }
    },
  },
  async mounted() {
    trackEvent(this.$intercom, 'Accepting signup invitation')
    try {
      const response = await CompanyService.getInvite(this.$route.params.token)
      const { invite } = response.body
      this.invite = invite
      if (this.invite.cognitoAuthentication === true) {
        this.signUpConfig.formFields[0].value = invite.employee.email
        this.cognitoLogin = true
      }
      if (invite.company) {
        this.companies = [invite.company]
      } else {
        this.companies = [...invite.companies]
      }
      this.initialized = true
    } catch (err) {
      this.errMessage = err.body.message
    }
  },
}
</script>

<style scoped lang="scss"></style>
