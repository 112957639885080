import moment from 'moment-timezone'
import Config from '@/config'

export function formatTime({ seconds, minutes }) {
  minutes = minutes || Math.round(seconds / 60)
  let hours, days, remainingMinutes
  const ONE_DAY_MINUTES = 1440 // 24 * 60 = 1440

  if (minutes < 0) {
    minutes *= -1
    return `-${formatTime({ minutes })}`
  } else if (minutes < 60) {
    return `${minutes}m`
  } else if (minutes > 60 && minutes < ONE_DAY_MINUTES) {
    hours = Math.floor(minutes / 60)
    remainingMinutes = minutes % 60
    return `${hours}h ${remainingMinutes}m`
  } else {
    days = Math.floor(minutes / 60 / 24)
    remainingMinutes = minutes % ONE_DAY_MINUTES
    hours = Math.floor(remainingMinutes / 60)
    remainingMinutes = remainingMinutes % 60
    return `${days}d ${hours}h`
  }
}

export function formatDate(rawDate) {
  const date = moment(rawDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).local()
  const dateRef = moment(rawDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).local().startOf('day')
  const currentRef = moment().startOf('day')
  const dateDiff = currentRef.diff(dateRef, 'days')

  if (dateDiff === 0) return date.format('LT')
  else if (dateDiff === 1) return 'Yesterday'
  else if (dateDiff > 1 && dateDiff <= 7) return date.format('dddd')
  else if (moment(date).year() === moment().year()) return date.format('MMM D')
  else return date.format('M/D/YY')
}

export function formatPhone(phone) {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(-4)}`
}

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function convertS3toAssetUrl(originalUrl) {
  const pathStart = originalUrl.indexOf('integration-providers/')
  const relevantPath = originalUrl.substring(pathStart)
  const cleanPath = relevantPath.split('?')[0]
  return Config.assetsUrl + cleanPath
}
