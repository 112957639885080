<template>
  <div>
    <label> Role </label>
    <select v-model="role" class="form-control form-control-lg">
      <option v-if="showAdmin" value="admin">Administrator</option>
      <option value="location-manager">Manager</option>
      <option value="employee">Employee</option>
    </select>
    <p v-if="role === 'admin'" class="form-text text-muted mt-2 mb-1">
      Adminstrators have access to all locations.
    </p>
  </div>
</template>

<script>
export default {
  name: 'RolePicker',
  props: ['value', 'showAdmin'],
  data() {
    return { role: this.value }
  },
  watch: {
    role(role) {
      this.$emit('input', role)
    },
  },
  methods: {
    reset(role = '') {
      this.role = role
    },
  },
}
</script>

<style scoped lang="scss"></style>
