<template>
  <div class="card px-4 pt-4 text-center h-100">
    <div class="muted-text mb-3">Action Breakdown</div>
    <div v-for="(action, index) of sortedBreakdownData" :key="index" class="mb-1">
      <div class="row p-0 align-items-center">
        <!-- action -->
        <div class="col-4 d-flex text-capitalize text-left">
          {{ action.name }}
        </div>

        <!-- count -->
        <div class="col-3 d-flex p-0 justify-content-center">
          <div style="font-size: 20px; margin-right: -15px" class="font-weight-bold mt-1">
            {{ action.count }}
          </div>
        </div>

        <!-- percentage bar -->
        <div class="col-5">
          <div class="progress bg-light" style="min-height: 22px">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${action.percentage}%; background-color: ${action.color}`"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CTTModule = createNamespacedHelpers('callToText')

export default {
  name: 'ActionBreakdown',
  computed: {
    ...CTTModule.mapGetters(['selectActionBreakdownData']),
    sortedBreakdownData() {
      return [...this.selectActionBreakdownData].sort((a, b) =>
        a.count > b.count ? -1 : b.count > a.count ? 1 : 0
      )
    },
  },
}
</script>

<style scoped>
.muted-text {
  color: #95aac9;
}
</style>
