<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-expand-lg navbar-dark"
    id="nav-main"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler mr-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style="z-index: 1000"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div v-if="userExists" class="nav navbar-nav flex-fill justify-content-center d-lg-none">
        <img :src="brandSymbolLogo" class="navbar-brand-img d-sm-block mx-auto" />
      </div>
      <div v-if="userExists" class="navbar-user d-md-none">
        <Avatar
          :img="onlineUser.avatar"
          :first-name="onlineUser.firstName"
          :last-name="onlineUser.lastName"
          size="sm"
          status="online"
        />
      </div>
      <FadeTransition><MainNav /></FadeTransition>
    </div>
  </nav>
</template>

<script>
import MainNav from './MainNav'
import Avatar from '@/components/MainContent/Avatar'
import FadeTransition from '@/components/Transitions/FadeTransition'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Nav',
  components: {
    MainNav,
    Avatar,
    FadeTransition,
  },
  computed: {
    ...mapState('user', ['onlineUser']),
    ...mapGetters('user', ['brandSymbolLogo']),
    userExists() {
      return this.onlineUser && this.onlineUser._id
    },
  },
}
</script>

<style scoped lang="scss">
.navbar-dark {
  background-color: #0c2556;
  border-color: #0c2556;
}
</style>
