import config from '@/config'
import { isEqual } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    connection: null,
    connectionReady: false,
    connectionRetries: 0,
    subscriptions: [],
  },
  getters: {
    connection: (state) => state.connection,
    connectionReady: (state) => state.connectionReady,
    connectionRetries: (state) => state.connectionRetries,
    subscriptions: (state) => state.subscriptions,
  },
  mutations: {
    setConnection: (state, connection) => {
      state.connection = connection

      if (connection == null) {
        state.connectionReady = false
      }
    },
    setConnectionReady: (state, ready) => (state.connectionReady = ready),
    addSubscription: (state, subscription) => {
      state.subscriptions.push(subscription)
    },
    removeSubscription: (state, subscriptionToRemove) => {
      state.subscriptions = subscriptions.map((subscription) => {
        if (
          subscription.key != subscriptionToRemove.key &&
          subscription.value != subscriptionToRemove.value
        ) {
          return subscription
        }
      })
    },
    clearSubscriptions: (state) => (state.subscriptions = []),
    incrementConnectionRetries: (state) => (state.connectionRetries += 1),
    resetConnectionRetries: (state) => (state.connectionRetries = 0),
  },
  actions: {
    connect(context) {
      if (!context.getters.connection) {
        const jwt = localStorage.getItem('jwt')
        const ws = new WebSocket(
          `${config.apiv2.ovationupWebsocketApi}?token=${jwt}&client-identifier=${context.rootState.user?.onlineUser?._id}`
        )

        ws.onopen = (socket) => {
          context.commit('resetConnectionRetries')
          let retries = 0
          const interval = setInterval(() => {
            retries += 1
            if (retries > 200) clearInterval(interval)
            else if (socket.readyState === socket.OPEN) {
              context.commit('setConnectionReady', true)

              let companyIds = context.rootGetters['dataCore/selectActiveCompanyIds'].map(
                (companyId) => {
                  return { key: 'ovationup-winback-conversation', value: companyId }
                }
              )

              if (companyIds.length) {
                context.dispatch('subscribe', companyIds)
              }
              clearInterval(interval)
            }
          }, 50)
        }

        ws.onclose = () => {
          context.commit('setConnection', null)

          if (context.getters.connectionRetries <= 5) {
            context.dispatch('attemptReconnect')
          } else {
            console.log('Tried to reconnect 5 times. Stopping retry')
          }
        }

        ws.onmessage = (messageReq) => {
          let message = JSON.parse(messageReq.data)
          switch (message.module) {
            case 'winback':
              context.dispatch('winback/websocketMessage', message, { root: true })
              switch (message.action) {
                case 'new-message':
                  context.dispatch('conversation/newMessage', message.data, { root: true })
                  break
              }
              break
            case 'exports':
              switch (message.action) {
                case 'export-complete':
                  context.dispatch('exports/exportComplete', message, { root: true })
                  break
              }
          }
        }

        ws.onerror = (error) => {
          console.log('V2 Error')
          console.log(error)
          context.commit('incrementConnectionRetries')
        }

        context.commit('setConnection', ws)
      }
    },
    disconnect(context) {
      if (context.getters.connection) {
        context.getters.connection.close()
        context.commit('setConnection', null)
        context.commit('clearSubscriptions')
      }
    },
    attemptReconnect(context) {
      setTimeout(() => {
        context.dispatch('connect')
      }, 1000)
    },
    sendMessage(context, message) {
      context.getters.connection.send(JSON.stringify(message))
    },
    subscribe(context, subscriptions) {
      if (context.getters.connection) {
        subscriptions.forEach((subscription) => {
          if (
            !context.getters.subscriptions.some((sub) => {
              return isEqual(sub, subscription)
            })
          ) {
            context.commit('addSubscription', subscription)
            context.dispatch('sendMessage', { action: 'subscribe', message: subscription })
          }
        })
      }
    },
    removeSubscriptions(context, subscriptions) {
      subscriptions.forEach((subscription) => {
        context.commit('removeSubscription', subscription)
      })
    },
  },
}
