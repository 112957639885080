import { isAfter } from 'date-fns'

export const CAMPAIGN_SETUPS_STATUSES = {
  ACTIVE: 'active',
  INITIALIZED: 'initialized',
  SENDING: 'sending',
  COMPLETED: 'completed',
  DRAFT: 'draft',
  DISABLED: 'disabled',
}

export const TEMPLATE_CHUNK_TYPES = {
  TEXT: 'text',
  MEDIA: 'media',
}

export const isEditableCampaignSetup = (campaignSetup) => {
  switch (campaignSetup.status) {
    case 'completed':
      return false
    case 'draft':
      return true
    default:
      break
  }

  switch (campaignSetup.type) {
    case 'tag':
    case 'auto':
      return true
    case 'blast':
      const isFutureBlast = isAfter(campaignSetup.startDate, new Date())
      return isFutureBlast
    default:
      return false
  }
}