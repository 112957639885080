import GiftCardService from '@/services/GiftCardService'
import moment from 'moment-timezone'
import { isEqual } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    giftCards: [],
    displayedGiftCards: [],
    allGiftCardsDisplayed: false,
    filters: {},
    isLoadingData: false,
    limit: 25,
    skip: 0,
  },
  getters: {
    selectGiftCards: (state) => state.giftCards,
    selectFilters: (state) => state.filters,
    selectIsLoadingData: (state) => state.isLoadingData,
    selectLimit: (state) => state.limit,
    selectSkip: (state) => state.skip,
    selectDisplayedGiftCards: (state) => state.displayedGiftCards,
    selectAllGiftCardsDisplayed: (state) => state.allGiftCardsDisplayed,
    selectTotalAmountSent: (state) =>
      state.giftCards.reduce((acc, curr) => {
        return (acc += curr.amount / 100)
      }, 0),
    selectQuantitySent: (state) => state.giftCards?.length || 0,
    selectTopUsers: (state) => {
      const counts = state.giftCards.reduce((acc, curr) => {
        if (!acc[curr.user]) {
          acc[curr.user] = 1
        } else {
          acc[curr.user] += 1
        }
        return acc
      }, {})
      const sortedCountsArray = Object.entries(counts)
        .map((e) => ({ id: e[0], count: e[1] }))
        .sort((a, b) => b.count - a.count)
      return sortedCountsArray.slice(0, 3)
    },
  },
  mutations: {
    SET_GIFT_CARDS(state, giftCards) {
      state.giftCards = giftCards
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_IS_LOADING_DATA(state, status) {
      state.isLoadingData = status
    },
    SET_SKIP(state, skip) {
      state.skip = skip
    },
    SET_ALL_GIFT_CARDS_DISPLAYED(state, status) {
      state.allGiftCardsDisplayed = status
    },
    SET_DISPLAYED_GIFT_CARDS(state, giftCards) {
      state.displayedGiftCards = [...state.displayedGiftCards, ...giftCards]
    },
    RESET_GIFT_CARD_DATA(state) {
      state.giftCards = []
      state.displayedGiftCards = []
      state.skip = 0
      state.allGiftCardsDisplayed = false
    },
  },
  actions: {
    async applyFilters({ commit, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return
      commit('SET_FILTERS', filters)
    },

    async formatFilters({ getters, rootState, rootGetters }) {
      const { activeCompany } = rootState.company
      const filters = getters.selectFilters
      const payloadFilters = {
        companyIds: [activeCompany._id],
        createdAtRange: filters.dateRange || [
          moment().startOf('d').subtract(30, 'd').utc().format(),
          moment().endOf('day').utc().format(),
        ],
      }
      if (filters.locations) {
        payloadFilters.locationIds = Array.isArray(filters.locations)
          ? filters.locations
          : [filters.locations]
      } else {
        payloadFilters.locationIds = rootGetters['location/selectActiveLocations']
          .filter((l) => !l.hideFromReports)
          .map((l) => l._id)
      }
      return payloadFilters
    },

    async fetchGiftCards({ dispatch, commit }) {
      commit('SET_IS_LOADING_DATA', true)
      commit('RESET_GIFT_CARD_DATA')

      const formattedFilters = await dispatch('formatFilters')
      const response = await GiftCardService.list({ filters: formattedFilters, skip: 0, limit: 0 })

      commit('SET_GIFT_CARDS', response.body.data.giftCards)
      dispatch('loadMoreDisplayedGiftCards')
      commit('SET_IS_LOADING_DATA', false)
    },

    async loadMoreDisplayedGiftCards({ commit, getters, dispatch }) {
      const { selectSkip, selectLimit, selectGiftCards } = getters

      const giftCards = selectGiftCards.slice(selectSkip, selectSkip + selectLimit)

      commit('SET_DISPLAYED_GIFT_CARDS', giftCards)
      commit('SET_SKIP', selectSkip + selectLimit)
      if (giftCards?.length < selectLimit) {
        commit('SET_ALL_GIFT_CARDS_DISPLAYED', true)
      }
    },
  },
}
