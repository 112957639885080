<template>
  <div>
    <GiftCardFilters @filtersApplied="fetchGiftCards" class="mb-4" />
    <div v-if="!selectIsLoadingData">
      <GiftCardReports v-if="selectGiftCards.length" />
      <div class="text-center" v-else>-- No data for the selected filters --</div>
    </div>
    <div v-else>
      <div class="d-flex">
        <b-skeleton height="200px" width="50%" />
        <b-skeleton height="200px" width="50%" class="ml-3" />
      </div>
      <div class="mt-3">
        <b-skeleton height="450px" width="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import GiftCardFilters from '../Components/GiftCardFilters'
import GiftCardReports from '../Components/GiftCardReports'
import { createNamespacedHelpers } from 'vuex'

const GiftCardModule = createNamespacedHelpers('giftCard')

export default {
  name: 'ReportsView',
  components: {
    GiftCardFilters,
    GiftCardReports,
  },
  computed: {
    ...GiftCardModule.mapGetters(['selectIsLoadingData', 'selectGiftCards']),
  },
  methods: {
    ...GiftCardModule.mapActions(['fetchGiftCards']),
  },
}
</script>
