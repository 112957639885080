<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto">
          <Avatar
            :img="employee.avatar"
            :first-name="employee.firstName"
            :last-name="employee.lastName"
            size="lg"
          />
        </div>
        <div class="col ml--2">
          <h4 class="card-title mb-1">
            <span>{{ employeeName }}</span>
          </h4>
          <p class="card-text small mb-1">{{ employeeRole }}</p>
          <p v-if="locationNames" class="card-text text-muted small">
            {{ locationNames }}
          </p>
        </div>
        <div v-if="editAvailable || deleteAvailable" class="col-auto">
          <div class="dropdown">
            <a
              href="#!"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
            >
              <i class="fe fe-more-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                v-if="editAvailable"
                @click="$emit('edit')"
                href="javascript:;"
                class="dropdown-item"
                >Edit</a
              >
              <a
                v-if="deleteAvailable"
                @click="$emit('delete')"
                href="javascript:;"
                class="dropdown-item"
              >
                <span class="text-danger">Delete</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeammateBase from './TeammateBase'

export default {
  name: 'TeammateCard',
  extends: TeammateBase,
  computed: {
    editAvailable() {
      if (this.employee.role === 'admin' && this.onlineUser.role === 'admin') return true
      if (this.employee.role !== 'admin' && this.onlineUser.role === 'admin') return true

      const onlineUserLocationIds = this.onlineUser.locations || []
      const employeeLocationIds = this.employee.locations.map((location) => location._id)

      const managerCanViewEmployee = onlineUserLocationIds.some((locationId) =>
        employeeLocationIds.includes(locationId)
      )

      return this.onlineUser.role === 'location-manager' && managerCanViewEmployee
    },
    deleteAvailable() {
      if (this.onlineUser._id === this.employee._id) {
        return false
      } else if (
        this.onlineUser.role === 'admin' ||
        (this.onlineUser.role === 'location-manager' && this.employee.role === 'employee') ||
        (this.onlineUser.role === 'location-manager' &&
          this.employee.role === 'location-manager' &&
          this.employee.locations?.some((l) => this.onlineUser.locations?.includes(l._id)))
      ) {
        return true
      }
      return false
    },
  },
}
</script>
