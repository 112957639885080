<template>
  <div :class="{ 'bd-right-gray': borderRight }" class="full-height">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Pane',
  props: {
    borderRight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.bd-right-gray {
  border-right: 1.5px solid #d2ddec;
}
/* the -30 margin bottom is due to 'APP' padding causing scrolling */
.full-height {
  height: 100%;
  margin-bottom: -30px;
}
</style>
