import { Auth, Hub } from 'aws-amplify'

import router from '../router'

export default {
  namespaced: true,

  state: {
    authUser: null,
    unauthorized: false,
  },

  getters: {
    selectAuthUser: (state) => state.authUser,
    selectUnauthorized: (state) => state.unauthorized,
  },

  mutations: {
    SET_AUTH_USER(state, authUser) {
      state.authUser = authUser
    },
    SET_UNAUTHORIZED(state, unauthorized) {
      state.unauthorized = unauthorized
    },
  },

  actions: {
    setUnauthorized({ commit }, unauthorized) {
      commit('SET_UNAUTHORIZED', unauthorized)
    },
    async createAuthEventBus({ commit, dispatch }, initializeApp) {
      Hub.listen('auth', async ({ payload }) => {
        const { event } = payload
        switch (event) {
          case 'signIn':
            await dispatch('setCurrentUser')
            await initializeApp()
            break

          case 'signOut':
            await dispatch('resetAuth')
            break
        }
      })

      await dispatch('setCurrentUser')
      await initializeApp()
    },
    async setCurrentUser({ commit, dispatch, getters }) {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        commit('SET_AUTH_USER', currentUser)
        if (!currentUser.signInUserSession.idToken.payload.ovation_token) {
          await dispatch('resetAuth')
        } else {
          localStorage.setItem('jwt', currentUser.signInUserSession.idToken.payload.ovation_token)
        }
      } catch (e) {
        if (getters.selectAuthUser) {
          await dispatch('resetAuth')
        }
      }
    },
    async resetAuth({ commit, getters }) {
      if (getters.selectAuthUser) {
        await Auth.signOut()
      }

      commit('SET_AUTH_USER', null)
    },
  },
}
