<template>
  <div>
    <b-dropdown :variant="selectedPromo ? 'primary' : 'white'">
      <template slot="button-content">
        <span>
          {{ displayName }}
        </span>
      </template>
      <b-dropdown-item
        href="#"
        @click.prevent="handlePromoSelected(promo)"
        v-for="promo in specialPromos"
        :key="promo._id"
      >
        {{ promo.nickname }}
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item
        href="#"
        @click.prevent="handlePromoSelected(promo)"
        v-for="promo in promos"
        :key="promo._id"
      >
        {{ promo.nickname || promo.title }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

const PromoModule = createNamespacedHelpers('promo')
const TemplateModule = createNamespacedHelpers('template')

export default {
  name: 'AssignPromoDropdown',
  data() {
    return {
      specialPromos: [
        {
          _id: uuidv4(),
          nickname: 'No offer',
          noOffer: true,
        },
        {
          _id: uuidv4(),
          nickname: 'Prompt each time',
          promptEachTime: true,
        },
      ],
      selectedPromo: null,
    }
  },
  computed: {
    ...PromoModule.mapGetters(['promos', 'selectPromoName']),
    ...TemplateModule.mapGetters(['template']),

    displayName() {
      return this.selectedPromo
        ? this.selectedPromo.nickname || this.selectedPromo.title
        : 'No offer'
    },
  },
  methods: {
    ...PromoModule.mapActions(['queryPromosWithSource']),

    handlePromoSelected(promo) {
      this.selectedPromo = promo
      this.$emit('promoSelected', promo)
    },
  },
  async mounted() {
    await this.queryPromosWithSource()
    if (this.template && this.template._id) {
      if (this.template.promo) {
        this.selectedPromo = this.promos.find((promo) => promo._id === this.template.promo)
      } else if (this.template.promptEachTime) {
        this.selectedPromo = this.specialPromos.find((sp) => sp.promptEachTime)
      } else if (!this.template.promo && !this.template.promptEachTime) {
        this.selectedPromo = this.specialPromos.find((sp) => sp.noOffer)
      }
    }
  },
}
</script>
