<template>
  <div>
    <div class="row">
      <div class="col-4">
        <!-- total gift cards sent -->
        <MetricCard title="Total Gift Cards Sent" :value="selectQuantitySent" valueType="number" />
      </div>
      <div class="col-4">
        <!-- total money sent -->
        <MetricCard
          title="Total Gift Card Value Sent"
          :value="selectTotalAmountSent"
          valueType="money"
        />
      </div>
      <div class="col-4">
        <!-- top users -->
        <TopUsersCard :topUsers="selectTopUsers" />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <!-- table list render -->
        <GiftCardTable />
      </div>
    </div>
  </div>
</template>

<script>
import MetricCard from './MetricCard'
import TopUsersCard from './TopUsersCard'
import GiftCardTable from '../Reports/GiftCardTable'
import { createNamespacedHelpers } from 'vuex'

const GiftCardModule = createNamespacedHelpers('giftCard')

export default {
  name: 'CallReports',
  components: {
    MetricCard,
    GiftCardTable,
    TopUsersCard,
  },
  computed: {
    ...GiftCardModule.mapGetters(['selectTotalAmountSent', 'selectQuantitySent', 'selectTopUsers']),
  },
}
</script>
