import Vue from 'vue'

export default {
  list({ companyId, filters }) {
    return Vue.http.post(`custom-question/list`, { companyId, filters })
  },
  create({ companyId, formData }) {
    return Vue.http.post('custom-question', { companyId, formData })
  },
  update({ questionId, fields }) {
    return Vue.http.put(`custom-question/${questionId}`, { fields })
  },
  getReports({ userId, companyId, filters }) {
    return Vue.http.post('custom-question-report/list', { userId, companyId, filters })
  },
  fetchCategories({ companyId }) {
    return Vue.http.get(`custom-question-category/${companyId}`)
  },
  sendCustomQuestionList({ surveyId }) {
    return Vue.http.post('custom-question-list/send-list', { surveyId })
  },
  sendCustomQuestionListPreview({ preview, phoneNumbers }) {
    return Vue.http.post('custom-question-list/send-preview', { preview, phoneNumbers })
  },
  updateCustomQuestionSettings({ companyId, fields }) {
    return Vue.http.put(`custom-question-setting/${companyId}`, { fields })
  },
  getCustomQuestionSettings({ companyId }) {
    return Vue.http.get(`custom-question-setting/${companyId}`)
  },
  generateMessage(fields) {
    return Vue.http.post('custom-question-list/generate-message', fields)
  },
  exportList(payload) {
    return Vue.http.post('app-services/v2/custom-question/export-list', payload)
  },
}
