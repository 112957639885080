<template>
  <div>
    <!-- filters -->
    <InsightsFilters
      class="mb-4"
      @filtersApplied="refreshSummary"
      @refresh="refreshFilters"
      :key="refreshKey"
    />

    <!-- reports -->
    <InsightsSummary :loading="loading" :wcLoading="wcLoading" />
  </div>
</template>

<script>
import InsightsFilters from './InsightsFilters'
import InsightsSummary from './InsightsSummary'
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'SummaryView',
  components: {
    InsightsFilters,
    InsightsSummary,
  },
  data: () => ({
    loading: true,
    wcLoading: true,
    refreshKey: 0,
  }),
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    ...FeatureFlagModule.mapGetters(['isEnabled']),
  },
  methods: {
    ...InsightsModule.mapActions([
      'fetchClassifications',
      'fetchFeedbackCount',
      'fetchFeedbackCountBreakdown',
      'fetchSummaries',
    ]),
    async refreshSummary() {
      this.loadBasic()
      this.isEnabled('AI_SUMMARY') ? this.loadSummaries() : (this.wcLoading = false)
    },
    refreshFilters() {
      this.refreshKey += 1
    },
    async loadBasic() {
      this.loading = true
      await Promise.all([
        this.fetchClassifications(),
        this.fetchFeedbackCount(),
        this.fetchFeedbackCountBreakdown(),
      ])
      this.loading = false
    },
    async loadSummaries() {
      this.wcLoading = true
      await this.fetchSummaries()
      this.wcLoading = false
    },
  },
}
</script>
