import Vue from 'vue'

async function requestWithRetry(requestFunction, requestName) {
  try {
    return requestFunction()
  } catch (error) {
    try {
      return requestFunction(route, payload)
    } catch (error) {
      Vue.notify({
        type: 'error',
        text: `Error: Could not retrieve data for metric: ${requestName}`,
      })
    }
  }
}

export default {
  // survey
  fetchTrends({ filters, granularity }) {
    const request = () => Vue.http.post('app-services/v2/survey/trends', { filters, granularity })
    return requestWithRetry(request, 'Trends')
  },
  surveySentCount({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/sent-count', { filters })
    return requestWithRetry(request, 'Survey Sent Count')
  },
  surveyTakeRate({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/take-rate', { filters })
    return requestWithRetry(request, 'Survey Take Rate')
  },
  surveyResponseRate({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/response-rate', { filters })
    return requestWithRetry(request, 'Survey Response Rate')
  },
  surveyAverageRating({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/average-rating', { filters })
    return requestWithRetry(request, 'Survey Average Rating')
  },
  surveyCompletedCount({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/completed-count', { filters })
    return requestWithRetry(request, 'Survey Completed Count')
  },
  surveyAverageResponseTime({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/average-response-time', { filters })
    return requestWithRetry(request, 'Survey Average Response Time')
  },
  surveyScores({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/survey-scores', { filters })
    return requestWithRetry(request, 'Survey Scores')
  },
  averageRatingBySource({ filters }) {
    const request = () =>
      Vue.http.post('app-services/v2/survey/average-rating-by-source', { filters })
    return requestWithRetry(request, 'Satisfaction By Channel')
  },
  volumeBySource({ filters }) {
    const request = () => Vue.http.post('app-services/v2/survey/volume-by-source', { filters })
    return requestWithRetry(request, 'Volume by Source')
  },
  satisfactionDayparts({ filters, dayparts }) {
    const request = () =>
      Vue.http.post('app-services/v2/survey/average-rating-by-daypart', {
        filters,
        dayParts: dayparts,
      })
    return requestWithRetry(request, 'Satisfaction By Daypart')
  },

  // customer
  customerSavedCount({ filters }) {
    const request = () => Vue.http.post('app-services/v2/customer/saved', { filters })
    return requestWithRetry(request, 'Customer Saved Count')
  },
  customerCreatedCount({ filters }) {
    const request = () => Vue.http.post('app-services/v2/customer/created-count', { filters })
    return requestWithRetry(request, 'Customer Created Count')
  },

  // review
  reviewCreatedCount({ filters }) {
    const { createdAtRange, ...restOfFilters } = filters
    const request = () =>
      Vue.http.post('app-services/v2/external-review/created-count', {
        filters: {
          ...restOfFilters,
          timestampRange: createdAtRange,
        },
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
  reviewAverageOnlineRating({ filters }) {
    const { createdAtRange, ...restOfFilters } = filters
    const request = () =>
      Vue.http.post('app-services/v2/external-review/average-online-rating', {
        filters: {
          ...restOfFilters,
          timestampRange: createdAtRange,
        },
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
  currentReviewsAndRatings({ filters }) {
    const { createdAtRange, ...restOfFilters } = filters
    const request = () =>
      Vue.http.post('app-services/v2/external-review/current-review-and-rating', {
        filters: {
          ...restOfFilters,
          timestampRange: createdAtRange,
        },
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
  reviewsAndRatings({ filters, granularity }) {
    const request = () =>
      Vue.http.post('app-services/v2/external-review/reviews-and-ratings', {
        filters,
        granularity,
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
  reviewsPerMonth({ filters }) {
    const { createdAtRange, ...restOfFilters } = filters
    const request = () =>
      Vue.http.post('app-services/v2/external-review/reviews-per-month', {
        filters: {
          ...restOfFilters,
          timestampRange: createdAtRange,
        },
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
  clicksToReview({ filters }) {
    const request = () =>
      Vue.http.post('app-services/v2/external-review/clicks-to-review', {
        filters: filters,
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
  locationRatingAndCount({ filters }) {
    const request = () =>
      Vue.http.post('app-services/v2/external-review/location-rating-and-count', {
        filters: filters,
      })
    return requestWithRetry(request, 'Review Average Online Rating')
  },
}
