export default {
  namespaced: true,
  state: {
    tags: [
      {
        name: 'Customer First Name',
        value: '<Customer First Name>',
      },
      {
        name: 'Review Link',
        value: '<Review Link>',
      },
      {
        name: 'Survey Link',
        value: '<Survey Link>',
      },
      {
        name: 'Order Link',
        value: '<Order Link>',
      },
    ],
  },
}
