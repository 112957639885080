<template>
  <div>
    <div v-for="(percentage, selectionIndex, index) in reportData" :key="index">
      <div class="row border-bottom py-2 px-0 align-items-center" style="min-height: 50px">
        <div class="col-12 col-sm-7">
          {{ options[selectionIndex] }}
        </div>
        <div class="col-12 col-sm-5">
          <PercentBar :percentage="percentage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PercentBar from './PercentBar.vue'

export default {
  name: 'SingleSelectionReport',
  props: {
    reportData: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    PercentBar,
  },
}
</script>
