<template>
  <div class="message">
    <div class="row mt-1 mb-1 message" :class="{ internal: user.role || automated }">
      <div class="col-auto mt-2" v-if="!user.role && !automated">
        <Avatar :name="customer.name" size="xs" status="" />
      </div>
      <div
        class="col"
        :class="{ 'mr--2': user.role || automated, 'ml--2': !user.role && !automated }"
      >
        <div class="comment-body">
          <div class="mms-container" v-if="media && media.length > 0">
            <div
              v-for="(mms, index) of media.filter(
                (m) => m !== null && m !== undefined && typeof m == 'object'
              )"
              :key="`${mms.key}${index}`"
            >
              <MessageImage
                v-if="!mms.key.endsWith('.smil')"
                class="mb-2"
                :s3source="mms"
                :alt="`Message image`"
              />
            </div>
          </div>
          <p v-if="content" class="comment-text">
            <span v-if="highlights" v-html="content" :style="highlightStyle"></span>
            <span v-else>{{ content }}</span>
            <span v-if="label" class="badge badge-soft-primary ml-1 mb-1 mt-n1"
              >&nbsp;{{ label }}</span
            >
          </p>
          <div class="row" v-if="timestamp">
            <div class="col-auto">
              <p class="comment-time">
                {{ timestamp | moment('LT') }}
                {{ timezone }}
                <span v-if="location">
                  &#8226;
                  {{ location.friendlyName || location.city }}
                </span>
                <span v-if="automated"> &#8226; {{ automatedMessage }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-2" v-if="user.role || automated">
        <Avatar
          :img="avatarImage"
          :first-name="user.firstName"
          :last-name="user.lastName"
          :name="user.name"
          size="xs"
          status=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
import MessageImage from './MessageImageV2'

export default {
  name: 'Message',
  props: [
    'content',
    'user',
    'timestamp',
    'promo',
    'label',
    'media',
    'highlights',
    'type',
    'timezone',
    'location',
    'customer',
    'automatedResponse',
  ],
  components: {
    Avatar,
    MessageImage,
  },
  computed: {
    highlightStyle() {
      return {
        '--mark-text-color': '#EF6C00',
      }
    },
    automated() {
      return this.automatedResponse
    },
    avatarImage() {
      return this.user.avatar || (this.automated ? '/img/icons/ovation-icon.png' : null)
    },
    automatedMessage() {
      return this.automated === 'survey-ai-response'
        ? 'Automated AI Response'
        : 'Automated Template Response'
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep mark {
  color: var(--mark-text-color) !important;
}
</style>
