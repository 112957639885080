<template>
  <b-modal
    ref="modal"
    id="companyLegalModal"
    hide-footer
    hide-header
    body-class="p-0"
    size="lg"
    @show="show()"
  >
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="card-header-title">We need some information</h1>
        </div>
        <div class="col-auto">
          <button @click="$bvModal.hide('companyLegalModal')" type="button" class="close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div class="row align-items-center mt-3">
        <div class="col">
          <div class="card-detail-text">
            In order to complete the setup of your account, we need you to fill out the information
            below. This message will continue to appear until an admin from your company has
            submitted the information. If you have questions, please contact support@ovationup.com.
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 mt-3">
          <label>Legal Business Name<span class="text-danger ml-2">*</span></label>
          <input
            v-model="company.businessName"
            type="text"
            class="form-control"
            :disabled="loading"
          />
        </div>
        <div class="col-6 mt-3">
          <label>Corporate Website<span class="text-danger ml-2">*</span></label>
          <input
            v-model="company.corporateWebsite"
            type="text"
            class="form-control"
            :disabled="loading"
          />
        </div>
        <div class="col-6 mt-3">
          <label>Country of Incorporation<span class="text-danger ml-2">*</span></label>
          <select
            v-model="company.countryOfIncorporation"
            class="form-control"
            data-toggle="select"
            :disabled="loading"
          >
            <option selected value="" disabled>Choose one</option>
            <option v-for="country in countries" :key="country.isoCode" :value="country.isoCode">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3">
          <label>State/Province of Incorporation<span class="text-danger ml-2">*</span></label>
          <select
            v-model="company.stateOfIncorporation"
            class="form-control"
            data-toggle="select"
            :disabled="loading"
          >
            <option selected value="" disabled>Choose one</option>
            <option
              v-for="state in statesOfIncorporation"
              :key="state.isoCode"
              :value="state.isoCode"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 mb-2">
          <h3 class="card-header-title">Company Headquarters</h3>
        </div>

        <div class="col-6 mt-3">
          <label>Street<span class="text-danger ml-2">*</span></label>
          <input
            v-model="company.businessAddress1"
            type="text"
            class="form-control"
            :disabled="loading"
          />
        </div>

        <div class="col-6 mt-3">
          <label>Apartment, Suite, etc.</label>
          <input
            v-model="company.businessAddress2"
            type="text"
            class="form-control"
            :disabled="loading"
          />
        </div>
        <div class="col-6 mt-3">
          <label>Country<span class="text-danger ml-2">*</span></label>
          <select
            v-model="company.country"
            class="form-control"
            data-toggle="select"
            :disabled="loading"
          >
            <option selected value="" disabled>Choose one</option>
            <option v-for="country in countries" :key="country.isoCode" :value="country.isoCode">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3">
          <label>State/Province<span class="text-danger ml-2">*</span></label>
          <select
            v-model="company.state"
            class="form-control"
            data-toggle="select"
            :disabled="loading"
          >
            <option selected value="" disabled>Choose one</option>
            <option v-for="state in states" :key="state.isoCode" :value="state.isoCode">
              {{ state.name }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3">
          <label>City<span class="text-danger ml-2">*</span></label>
          <select
            v-model="company.city"
            class="form-control"
            data-toggle="select"
            :disabled="loading"
          >
            <option selected value="" disabled>Choose one</option>
            <option v-for="city in cities" :key="city.name" :value="city.name">
              {{ city.name }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3">
          <label>Zip/Postal Code<span class="text-danger ml-2">*</span></label>
          <input v-model="company.zip" type="text" class="form-control" :disabled="loading" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col text-right">
          <span v-if="errorMessage" style="color: red" class="mr-3">{{ errorMessage }}</span>
          <button class="btn btn-primary" :disabled="!readyToSubmit || loading" @click="update">
            <b-spinner class="mx-5" small variant="light" v-if="loading" />
            <span class="mx-4" v-else>Submit</span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { Country, State, City } from 'country-state-city'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanyLegalModal',
  components: {},
  data: () => ({
    company: {},
    errorMessage: null,
  }),
  computed: {
    ...CompanyModule.mapGetters(['activeCompany']),
    ...mapGetters(['selectIsLoading']),
    readyToSubmit() {
      return (
        this.company.businessName &&
        this.company.countryOfIncorporation &&
        this.company.stateOfIncorporation &&
        this.company.corporateWebsite &&
        this.company.businessAddress1 &&
        this.company.state &&
        this.company.city &&
        this.company.zip
      )
    },
    loading() {
      return this.selectIsLoading('UPDATE_COMPANY_LEGAL_DATA')
    },
    countries() {
      return [
        { name: 'United States', isoCode: 'US' },
        { name: 'Canada', isoCode: 'CA' },
      ]
    },
    statesOfIncorporation() {
      return this.company && this.company.countryOfIncorporation
        ? State.getStatesOfCountry(this.company.countryOfIncorporation)
        : []
    },
    cities() {
      return this.company && this.company.state
        ? City.getCitiesOfState(this.company.country, this.company.state)
        : []
    },
    states() {
      return this.company && this.company.country
        ? State.getStatesOfCountry(this.company.country)
        : []
    },
  },
  watch: {
    activeCompany(val) {
      this.populate()
    },
  },
  methods: {
    ...CompanyModule.mapActions(['updateCompanyLegalData']),
    populate() {
      if (this.activeCompany) {
        this.company = {
          businessName: this.activeCompany.businessName,
          countryOfIncorporation: this.activeCompany.countryOfIncorporation,
          stateOfIncorporation: this.activeCompany.stateOfIncorporation,
          corporateWebsite: this.activeCompany.corporateWebsite,
          businessAddress1: this.activeCompany.businessAddress1,
          businessAddress2: this.activeCompany.businessAddress2,
          country: this.activeCompany.country,
          state: this.activeCompany.state,
          city: this.activeCompany.city,
          zip: this.activeCompany.zip,
        }
      }
    },
    show() {
      this.populate()
    },
    async update() {
      const result = await this.updateCompanyLegalData({
        companyId: this.activeCompany._id,
        fields: this.company,
      })

      if (result.success) {
        this.$notify({ text: 'Successfully saved changes.', type: 'success' })
        this.$bvModal.hide('companyLegalModal')
      } else {
        if (result.message) {
          this.errorMessage = result.message
        } else {
          this.errorMessage = 'Sorry, something went wrong. Please contact us for help.'
        }
      }
    },
  },
}
</script>
<style lang="scss">
#companyLegalModal {
  .card-detail-text {
    color: #95aac9;
  }
}
</style>
