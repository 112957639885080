<template>
  <div>
    <CallReportsFilters @filtersApplied="fetchCallHistory" class="mb-4" />
    <div v-if="!selectIsLoadingData">
      <CallReports v-if="selectTotalCallsReceived" />
      <div class="text-center" v-else>-- No data for the selected filters --</div>
    </div>
    <div v-else>
      <div class="d-flex">
        <b-skeleton height="200px" width="40%" />
        <b-skeleton height="200px" width="60%" class="ml-3" />
      </div>
      <div class="mt-3">
        <b-skeleton height="450px" width="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import CallReportsFilters from '../Components/CallReportsFilters'
import CallReports from '../Components/CallReports'
import { createNamespacedHelpers } from 'vuex'

const CTTModule = createNamespacedHelpers('callToText')

export default {
  name: 'ReportsView',
  components: {
    CallReportsFilters,
    CallReports,
  },
  computed: {
    ...CTTModule.mapGetters(['selectIsLoadingData', 'selectTotalCallsReceived']),
  },
  methods: {
    ...CTTModule.mapActions(['fetchCallHistory']),
  },
}
</script>
