import Vue from 'vue'

export default {
  search({ companyIds, filters }) {
    return Vue.http.post('app-services/v2/external-review/search', { companyIds, filters })
  },
  fetchOldestTimestamp({ companyIds }) {
    return Vue.http.post('app-services/v2/external-review/oldest-timestamp', {
      filters: { companyIds },
    })
  },

  // deprecated
  list(payload) {
    return Vue.http.post('app-services/v2/external-review/search', payload)
  },
}
