import SurveyService from '@/services/SurveyService'

const SURVEY_SOURCES = {
  'qr-code': 'QR Code',
  'qr-tent': 'QR Tent',
  'qr-card': 'QR Card',
  checkin: 'Checkin',
  kiosk: 'Kiosk',
  'text-to-win': 'Text',
  imported: 'Imported',
  manual: 'Single Entry',
  integration: 'Integration',
  web: 'Web',
  dsp: 'DSP',
  'first-party': 'First Party',
  olo: 'Olo',
  revel: 'Revel',
  hungerRush: 'HungerRush',
  grubhub: 'Grubhub',
  lunchbox: 'Lunchbox',
}

const DEFAULT_DATERANGE = {
  date: '30',
  defaultValue: 90,
  name: 'In the last',
  type: 'ago',
  unit: 'days',
  tzOffset: new Date().getTimezoneOffset(),
}

export default {
  namespaced: true,
  state: {
    surveys: [],
    SURVEY_SOURCES,
    listFilters: {
      dateSettings: DEFAULT_DATERANGE,
    },
    count: 0,
    totalSurveyCount: 0,
    isSearching: false,
    sources: [],
  },
  getters: {
    selectSources(state) {
      return state?.sources
    },
    selectFilters(state) {
      return state.listFilters
    },
    selectSurveySources: (state) => state.SURVEY_SOURCES,
  },
  mutations: {
    setSurveys(state, surveys) {
      state.surveys = [...surveys]
    },
    setCount(state, count) {
      state.count = count
    },
    setTotalSurveyCount(state, totalCount) {
      state.totalSurveyCount = totalCount
    },
    setIsSearching(state, isSearching) {
      state.isSearching = isSearching
    },
    setListFilters(state, filters) {
      state.listFilters = filters
    },
    setSources(state, sources) {
      state.sources = sources
    },
    resetListFilters(state) {
      state.listFilters = { dateSettings: DEFAULT_DATERANGE }
    },
  },
  actions: {
    async resetFilters({ commit }) {
      commit('resetListFilters')
    },

    async getSurveys({ commit, rootState, state, getters, rootGetters }, { skip, limit }) {
      if (!limit) limit = 50
      if (!skip) skip = 0
      if (!rootState.company.activeCompany || !rootState.user.onlineUser._id) {
        return
      }
      const activeCompanyId = rootState.company.activeCompany._id
      let filters = getters.selectFilters?.companyIds?.length
        ? getters.selectFilters
        : {
            ...getters.selectFilters,
            companyIds:
              activeCompanyId === 'ALL_COMPANIES'
                ? rootGetters['company/companies'].map((c) => c._id)
                : [activeCompanyId],
          }

      const response = await SurveyService.list({
        userId: rootState.user.onlineUser._id,
        companyId: activeCompanyId,
        filters,
        limit,
        skip,
      })
      const { surveys, count, totalSurveyCount } = response.body.data

      if (skip > 0) {
        commit('setSurveys', [...state.surveys, ...surveys])
      } else {
        commit('setSurveys', surveys)
      }
      commit('setCount', count)
      commit('setTotalSurveyCount', totalSurveyCount)
    },

    async getSources({ commit, rootState }) {
      if (!rootState.company.activeCompany || !rootState.user.onlineUser._id) {
        return
      }
      const response = await SurveyService.listSources({
        filters: { companyIds: [rootState.company.activeCompany._id] },
      })
      commit('setSources', response.data.data.sources)
    },

    // TODO: this is tech debt to support insights/surveys ALL_COMPANIES view. a new endpoint needs to
    // be made in OBS that supports multiple companyIds and then this can be converted to use that.
    async fetchMultipleCompanySources(
      { commit, rootState, rootGetters },
      { insightsOnly = false } = {}
    ) {
      const activeCompanyId = rootState.company.activeCompany._id
      const companyIds =
        activeCompanyId !== 'ALL_COMPANIES'
          ? [activeCompanyId]
          : insightsOnly
          ? rootGetters['company/selectInsightsCompanies'].map((c) => c._id)
          : rootGetters['company/companies'].map((c) => c._id)
      const response = await SurveyService.listSources({
        filters: { companyIds },
      })
      commit(
        'setSources',
        [...new Set(response.data.data.sources)].sort((a, b) =>
          a.localeCompare(b, undefined, { sensitivity: 'base' })
        )
      )
    },

    async generateSurveysCSV({ rootState, state, rootGetters, getters }) {
      if (!rootState.company.activeCompany || !rootState.user.onlineUser._id) {
        return
      }
      const activeCompanyId = rootState.company.activeCompany._id
      let filters = getters.selectFilters?.companyIds
        ? getters.selectFilters
        : {
            ...getters.selectFilters,
            companyIds:
              activeCompanyId === 'ALL_COMPANIES'
                ? rootGetters['company/companies'].map((c) => c._id)
                : [activeCompanyId],
          }

      return await SurveyService.export({
        userId: rootState.user.onlineUser._id,
        companyId: activeCompanyId,
        filters,
        utcOffset: new Date().getTimezoneOffset() / -60,
      })
    },
  },
}
