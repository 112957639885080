<template>
  <multiselect
    placeholder="Type to search"
    style="min-width: 100px"
    selectLabel
    v-model="selectedSources"
    multiple
    :closeOnSelect="false"
    @close="updateSource"
    @remove="removeAndUpdate"
    :searchable="true"
    :options="sources"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary"> <span class="fas fa-inbox"></span> {{ placeholder }} </span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const surveyModule = createNamespacedHelpers('survey')

export default {
  name: 'SingleSourceFilter',
  components: {
    Multiselect,
  },
  props: {
    placeholder: {
      type: String,
      default: 'All sources',
    },
    init: {
      type: Array,
    },
  },
  data: () => ({
    selectedSources: [],
  }),
  mounted() {
    if (this.init) {
      this.selectedSources = this.init
    }
  },
  computed: {
    ...surveyModule.mapState(['sources']),
  },
  methods: {
    reset(locations) {
      this.selectedSources = []
    },
    removeAndUpdate(source) {
      this.$emit(
        'updateSources',
        this.selectedSources.filter((s) => s !== source)
      )
    },
    updateSource() {
      this.$emit('updateSources', this.selectedSources)
    },
  },
}
</script>
