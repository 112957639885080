<template>
  <div class="card mb-0 px-4 py-3 h-100" style="min-width: 450px; min-height: 289px">
    <div class="insight-wordcloud h-100" id="insightwc"></div>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5wc from '@amcharts/amcharts5/wc'
import { createNamespacedHelpers } from 'vuex'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')

export default {
  name: 'WordCloud',
  data: () => ({
    green: '#33D691',
    red: '#FC6D87',
  }),
  async mounted() {
    const formattedWordCloud = await this.formatWordCloud()

    am5.addLicense(this.selectAmKey)
    var root = am5.Root.new('insightwc')
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    )

    // Create series
    var series = container.children.push(
      am5wc.WordCloud.new(root, {
        angles: 0,
        randomness: 0.1,
        minFontSize: am5.percent(5),
      })
    )

    // add click ability - must come before setting the data
    series.labels.template.events.on('click', (ev) => {
      const search = ev.target.dataItem.get('category') // this is the search word/phrase
      const sentiment = ev.target.dataItem.dataContext.sentiment
      this.$router.replace({
        path: '/insights/details',
        query: {
          ...this.$route.query,
          keywords: [search],
          sentiment: sentiment,
          preventRedirect: true,
        },
      })
      this.$emit('refreshDetails')
    })

    // 'value' field needs to be a number
    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ['value'],
    })

    series.labels.template.setAll({
      templateField: 'labelSettings',
      cursorOverStyle: 'pointer',
      fontFamily: '',
    })

    // load the data into the wordcloud - do this last
    series.data.setAll(formattedWordCloud)
  },
  computed: {
    ...InsightsDetailsModule.mapGetters(['selectWordCloud', 'selectAmKey']),
  },
  methods: {
    async formatWordCloud() {
      return this.selectWordCloud.reduce((acc, curr) => {
        acc.push({
          category: curr.category,
          value: curr.value,
          labelSettings: {
            fill: am5.color(curr.sentiment === 'POSITIVE' ? this.green : this.red),
          },
          sentiment: curr.sentiment,
        })
        return acc
      }, [])
    },
  },
}
</script>

<style scoped>
.insight-wordcloud {
  width: 100%;
  height: 288px;
}
</style>
