// temp fix for vue-multiselect positioning until v3.0
<template>
  <vue-multiselect-original :ref="$attrs.id" v-bind="$attrs" v-on="listeners">
    <template #caret
      ><span v-if="!hideCaret" :class="['caret', opened && 'caret-opened']"></span
    ></template>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"
      ><slot v-if="name !== 'caret'" :name="name" v-bind="slotData"
    /></template>
  </vue-multiselect-original>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiSelect',

  inheritAttrs: false,

  props: {
    reposition: {
      type: Boolean,
      default: false,
    },
    hideCaret: {
      type: Boolean,
      default: false,
    },
    // needed to repostion dropdown correctly when used in modal
    modalEl: {
      type: HTMLDivElement,
    },
  },

  data: () => ({
    opened: false,
    listeners: {},
  }),

  components: {
    'vue-multiselect-original': Multiselect,
  },

  watch: {
    $listeners: {
      immediate: true,
      handler(newListeners) {
        this.listeners = {
          ...newListeners,
          open: (...args) => {
            if (newListeners.open) {
              newListeners.open(...args)
            }
            this.opened = true
            this.onOpen(...args)
          },
          close: (...args) => {
            if (newListeners.close) {
              newListeners.close(...args)
            }
            this.opened = false
          },
        }
      },
    },
  },

  mounted() {
    const el = this.modalEl || window
    el.addEventListener('scroll', this.onOpen)
  },

  destroyed() {
    const el = this.modalEl || window
    el.removeEventListener('scroll', this.onOpen)
  },

  methods: {
    onOpen() {
      if (this.reposition) {
        if (!this.$attrs.id) {
          console.error('No multiselect id')
          return
        }

        const { top, height, width, left } = this.$el.getBoundingClientRect()
        const ref = this.$refs[this.$attrs.id]

        const modaltop = this.modalEl ? this.modalEl.parentElement.getBoundingClientRect().top : 0

        if (ref) {
          ref.$refs.list.style.width = `${width}px`
          ref.$refs.list.style.position = 'fixed'
          ref.$refs.list.style.bottom = 'auto'
          ref.$refs.list.style.top = `${top + height - modaltop}px`
          // ref.$refs.list.style.left = `${left}px`
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.caret {
  pointer-events: none;

  &:after {
    display: block;
    content: '\e92e';
    color: #95aac9;
    font-family: Feather;
    font-size: 16px;
    padding: 8px;
    position: absolute;
    right: 0;
    transition: transform 0.2s;
  }

  &.caret-opened:after {
    transform: rotate(-180deg);
  }
}
::v-deep .multiselect__tags {
  padding-right: 27px !important;
}
</style>
