<script>
import Avatar from '@/components/MainContent/Avatar'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')

export default {
  name: 'TeammateCard',
  props: ['employee'],
  components: {
    Avatar,
  },
  computed: {
    ...mapState(['onlineUser']),
    employeeName() {
      return `${this.employee.firstName} ${this.employee.lastName} ${
        this.onlineUser._id === this.employee._id ? '(You)' : ''
      }`
    },
    employeeRole() {
      if (this.employee.role === 'admin') return 'Administrator'
      if (this.employee.role === 'location-manager') return 'Manager'
      if (this.employee.role === 'employee') return 'Employee'
      return 'Employee'
    },
    locationNames() {
      if (this.employee.role !== 'admin') {
        return this.employee.locations
          .map(({ friendlyName, city }) => friendlyName || city)
          .join(', ')
      }

      return null
    },
  },
}
</script>
