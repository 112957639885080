import CampaignSetupService from '@/services/CampaignSetupsService'
import LocationService from '@/services/LocationService'
import { assignIn, omit } from 'lodash-es'
import { TEMPLATE_CHUNK_TYPES } from '@/lib/modules/campaignSetups'
import moment from 'moment-timezone'
import Vue from 'vue'

const defaultCampaignSetup = () => ({
  status: '',
  templateChunks: [
    {
      type: TEMPLATE_CHUNK_TYPES.TEXT,
      template: '',
    },
  ],
  title: '',
  type: '', // auto/blast
  autoSettings: {
    endDate: undefined,
    matchDelay: 0,
  },
  blastSettings: {
    estimatedCreditUsage: 0,
  },
  description: '',
  promo: undefined,
  startDate: undefined,
  isMMS: false,
  isUnicode: false,
  isTextWithPicture: false,
  blastScheduleValidated: true,
})

export default {
  namespaced: true,
  state: {
    campaignSetups: [],
    statusFilter: 'all',
    campaignSetup: defaultCampaignSetup(),
    location: undefined,
    isLoadingCampaignSetups: false,
    textUsage: {
      textCount: 0,
      totalCount: 1000, // 1000 is just a placeholder on this and creditsRemaining
      creditsRemaining: 1000,
    },
    isSavingOrUpdating: false,
    customFiltersResponse: undefined,
  },
  getters: {
    selectCampaignSetup: (state) => state.campaignSetup,
    selectCampaignSetups: (state) => state.campaignSetups,
    selectCreditsRemaining: (state) => state.textUsage.creditsRemaining,
    selectStatusFilter: (state) => state.statusFilter,
    selectLocation: (state) => state.location,
    selectMessageLength: (state, getters, rootState, rootGetters) => {
      const baseFooterLength = 34 // "reply stop to..."
      const textTemplateChunk = state.campaignSetup.templateChunks.find(
        (tc) => tc.type === TEMPLATE_CHUNK_TYPES.TEXT
      )
      const message = textTemplateChunk?.template || ''
      const linkCount =
        (message.match(new RegExp('<Review Link>', 'g')) || []).length +
        (message.match(new RegExp('<Survey Link>', 'g')) || []).length +
        (message.match(new RegExp('<Order Link>', 'g')) || []).length

      const customerNameCount = (message.match(new RegExp('<Customer First Name>', 'g')) || [])
        .length

      let formattedMessageBody = message
        .replace(/<Review Link>/g, '')
        .replace(/<Survey Link>/g, '')
        .replace(/<Order Link>/g, '')
        .replace(/<Customer First Name>/g, '')

      const entityName =
        rootGetters['company/selectActiveCompany']._id === 'ALL_COMPANIES'
          ? rootGetters['organization/selectOrganization'].name
          : rootGetters['company/selectActiveCompany'].name

      const messageLength =
        formattedMessageBody.length +
        (state.campaignSetup.promo ? 33 : 0) +
        linkCount * 23 +
        customerNameCount * 10 +
        baseFooterLength +
        entityName.length
      return messageLength
    },
    selectSmsCount: (state, getters) => {
      if (state.campaignSetup.isMMS) {
        if (state.campaignSetup.isUnicode && !state.campaignSetup.isTextWithPicture) {
          if (getters.selectMessageLength <= 70) {
            return 1
          } else {
            return Math.ceil(getters.selectMessageLength / 67)
          }
        } else if (!state.campaignSetup.isUnicode && state.campaignSetup.isTextWithPicture) {
          return Math.ceil(getters.selectMessageLength / 1000) * 2
        } else {
          return Math.ceil(getters.selectMessageLength / 500) * 2
        }
      } else {
        if (getters.selectMessageLength <= 160) {
          return 1
        } else {
          return Math.ceil(getters.selectMessageLength / 153)
        }
      }
    },
    selectCampaignSetupMessage: (state) => {
      return state.campaignSetup.templateChunks.find((tc) => tc.type === TEMPLATE_CHUNK_TYPES.TEXT)
        .template
    },
    selectIsLoadingCampaignSetups: (state) => state.isLoadingCampaignSetups,
    selectTextUsage: (state) => state.textUsage,
    selectIsSavingOrUpdating: (state) => state.isSavingOrUpdating,
    selectCustomFiltersResponse: (state) => state.customFiltersResponse,
  },
  mutations: {
    SET_CAMPAIGN_SETUPS(state, campaignSetups) {
      state.campaignSetups = campaignSetups
    },
    SET_STATUS_FILTER(state, status) {
      state.statusFilter = status
    },
    SET_TYPE(state, campaignType) {
      state.campaignSetup.type = campaignType
    },
    SET_TITLE(state, title) {
      state.campaignSetup.title = title
    },
    SET_PROMO(state, promoId) {
      state.campaignSetup.promo = promoId
    },
    SET_CAMPAIGN_SETUP(state, campaignSetup) {
      state.campaignSetup = campaignSetup
    },
    SET_MESSAGE_TYPE(state, { MMSType, unicodeType, isPicture }) {
      state.campaignSetup.isMMS = MMSType
      state.campaignSetup.isUnicode = unicodeType
      state.campaignSetup.isTextWithPicture = isPicture
    },
    RESET_CAMPAIGN_SETUP(state) {
      state.campaignSetup = defaultCampaignSetup()
    },
    SET_LOCATION(state, location) {
      state.location = location
    },
    SET_BLAST_SCHEDULE_VALIDATED(state, status) {
      state.campaignSetup.blastScheduleValidated = status
    },
    SET_ESTIMATED_CREDIT_USAGE(state, credit) {
      state.campaignSetup.blastSettings.estimatedCreditUsage = credit
    },
    SET_CAMPAIGN_SETUP_TO_EDIT(state, campaignSetup) {
      state.campaignSetup = assignIn(defaultCampaignSetup(), campaignSetup)
    },
    SET_CAMPAIGN_SETUP_STATUS(state, status) {
      state.campaignSetup.status = status
    },
    SET_IS_LOADING_CAMPAIGN_SETUPS(state, status) {
      state.isLoadingCampaignSetups = status
    },
    SET_TEXT_USAGE(state, textUsage) {
      state.textUsage = textUsage
    },
    SET_IS_SAVING_OR_UPDATING(state, status) {
      state.isSavingOrUpdating = status
    },
    SET_CUSTOM_FILTERS_RESPONSE(state, response) {
      state.customFiltersResponse = response
    },
  },
  actions: {
    async fetchTextUsage({ commit, rootGetters }) {
      const payload = {}
      const activeCompany = rootGetters['company/selectActiveCompany']
      if (activeCompany._id === 'ALL_COMPANIES') {
        payload.organizationId = rootGetters['organization/selectOrganization']._id
      } else {
        payload.companyId = activeCompany._id
      }
      const response = await CampaignSetupService.fetchTextUsage(payload)
      commit('SET_TEXT_USAGE', response.body.data)
    },

    async formatCampaignSetup({ rootGetters }, campaignSetup) {
      const organization = rootGetters['organization/selectOrganization']
      const activeCompany = rootGetters['company/selectActiveCompany']
      const newCampaignSetup = { ...campaignSetup }
      if (campaignSetup.autoSettings) {
        newCampaignSetup.autoSettings = {
          ...campaignSetup.autoSettings,
          matchDelay: parseInt(campaignSetup.autoSettings.matchDelay),
        }
      }
      if (organization && activeCompany._id === 'ALL_COMPANIES') {
        newCampaignSetup.organization = organization._id
      }
      if (
        campaignSetup.blastSettings?.scheduled === false &&
        moment(campaignSetup.startDate).isAfter(moment())
      ) {
        newCampaignSetup.blastSettings.scheduled = true
      }

      return newCampaignSetup
    },

    async fetchCampaignSetups({ rootGetters, commit }) {
      commit('SET_IS_LOADING_CAMPAIGN_SETUPS', true)
      const companies = rootGetters['company/selectRelevantCompanies']
      const companyIds = companies.map((c) => c._id)
      const activeCompany = rootGetters['company/selectActiveCompany']
      const organization = rootGetters['organization/selectOrganization']
      const response = await CampaignSetupService.fetchCampaignSetups({
        filters: {
          companyIds,
          ...(activeCompany._id === 'ALL_COMPANIES' ? { organizationIds: [organization._id] } : {}),
        },
        lookups: { campaignExecutions: true },
        populate: [{ path: 'customFilter' }],
      })
      commit('SET_CAMPAIGN_SETUPS', response.body.data.campaignSetups)
      commit('SET_IS_LOADING_CAMPAIGN_SETUPS', false)
    },

    async updateCampaignSetup({ dispatch }, { campaignSetupId, campaignSetup }) {
      const formattedCustomFilter = await dispatch('customFilter/formatFilterPayload', null, {
        root: true,
      })
      const formattedCampaignSetup = await dispatch('formatCampaignSetup', campaignSetup)
      const completeCampaignSetup = {
        ...formattedCampaignSetup,
        ...(['active', 'initialized', 'draft'].includes(formattedCampaignSetup.status)
          ? { startDate: campaignSetup.startDate || moment().toISOString() }
          : {}),
        customFilter: formattedCustomFilter,
      }
      return CampaignSetupService.updateCampaignSetup({
        campaignSetupId,
        campaignSetup: completeCampaignSetup,
      })
    },

    async createCampaignSetup({ dispatch }, { campaignSetup }) {
      const formattedCustomFilter = await dispatch('customFilter/formatFilterPayload', null, {
        root: true,
      })
      const formattedCampaignSetup = await dispatch('formatCampaignSetup', campaignSetup)
      const completeCampaignSetup = {
        ...formattedCampaignSetup,
        customFilter: formattedCustomFilter,
        startDate: campaignSetup.startDate || moment().toISOString(),
      }
      return CampaignSetupService.createCampaignSetup({ campaignSetup: completeCampaignSetup })
    },

    async deleteCampaignSetup({ dispatch }, campaignSetupId) {
      const response = await CampaignSetupService.deleteCampaignSetup({ campaignSetupId })
      dispatch('fetchTextUsage')
      return response
    },

    async startEditingCampaignSetup({ commit, dispatch }, campaignSetup) {
      dispatch('customFilter/setCustomFilterFromCampaignSetup', campaignSetup.customFilter, {
        root: true,
      })
      let strippedCampaignSetup = omit(campaignSetup, ['customFilter'])
      commit('SET_CAMPAIGN_SETUP_TO_EDIT', strippedCampaignSetup)
    },

    async startDuplicatingCampaignSetup({ dispatch }, campaignSetup) {
      let newCampaignSetup = omit(campaignSetup, [
        '__v',
        '_id',
        'campaignExecutions',
        'companies',
        'createdAt',
        'createdBy',
        'recipients',
        'redemptionPercentage',
        'redemptions',
        'startDate',
        'status',
        'updatedAt',
      ])
      newCampaignSetup.title = 'copy of ' + newCampaignSetup.title
      dispatch('startEditingCampaignSetup', newCampaignSetup)
    },

    async getDefaultLocation({ rootGetters, commit }) {
      const activeCompany = rootGetters['company/selectActiveCompany']
      const response = await LocationService.getForUser({
        userId: rootGetters['user/selectOnlineUser'],
        companyId:
          activeCompany._id === 'ALL_COMPANIES'
            ? rootGetters['company/selectCompanies'][0]._id
            : activeCompany._id,
      })
      const { locations } = response.body.data
      const location = locations.length ? locations[0] : {}
      commit('SET_LOCATION', location)
    },

    async sendCampaignSetupTestMessage({}, payload) {
      await CampaignSetupService.sendTestCampaign({ testData: payload })
    },

    async setCampaignSetupStatus({ commit, getters }, status) {
      const campaignSetup = getters.selectCampaignSetup
      const statusToSave = status
        ? status
        : campaignSetup.status
        ? campaignSetup.status
        : campaignSetup.type === 'auto'
        ? 'active'
        : 'initialized'
      commit('SET_CAMPAIGN_SETUP_STATUS', statusToSave)
    },

    async setStatusFilter({ commit }, status) {
      commit('SET_STATUS_FILTER', status)
    },

    async setMessageType({ commit }, { MMSType, unicodeType, isPicture }) {
      commit('SET_MESSAGE_TYPE', { MMSType, unicodeType, isPicture })
    },

    async resetCampaignSetup({ commit, dispatch }) {
      commit('RESET_CAMPAIGN_SETUP')
      await dispatch('customFilter/resetCustomFilter', null, { root: true })
    },

    async setCampaignSetup({ commit }, campaignSetup) {
      commit('SET_CAMPAIGN_SETUP', campaignSetup)
    },

    async setEstimatedCreditUsage({ commit }, creditUsage) {
      commit('SET_ESTIMATED_CREDIT_USAGE', creditUsage)
    },

    async validateBlastSchedule({ commit, getters, dispatch }, toggleStatus) {
      const campaignSetup = getters.selectCampaignSetup
      if (campaignSetup.type === 'auto') return
      if (
        toggleStatus === true &&
        (!campaignSetup.startDate || !moment(campaignSetup.startDate).isAfter(moment()))
      ) {
        commit('SET_BLAST_SCHEDULE_VALIDATED', false)
      } else {
        commit('SET_BLAST_SCHEDULE_VALIDATED', true)
      }
      if (toggleStatus === false && campaignSetup.startDate) {
        // clear start date
        dispatch('setCampaignSetup', {
          ...campaignSetup,
          startDate: undefined,
        })
      }
    },

    async setIsLoadingCampaignSetups({ commit }, status) {
      commit('SET_IS_LOADING_CAMPAIGN_SETUPS', status)
    },

    async setIsSavingOrUpdating({ commit }, status) {
      commit('SET_IS_SAVING_OR_UPDATING', status)
    },

    setCustomFiltersResponse({ commit, dispatch, rootGetters }, response) {
      if (!response) return

      const { success, data, message } = response
      const { records, count } = data || {}
      const loadMore = rootGetters['customFilter/selectIsLoadingMore']
      const eventLimitReached = rootGetters['customFilter/selectIsEventLimitReached']

      if (success) {
        if (loadMore && records?.length) {
          const previewCustomerList = rootGetters['customFilter/selectPreviewCustomerList']
          dispatch('customFilter/setPreviewCustomerList', [...previewCustomerList, ...records], {
            root: true,
          })
        } else {
          dispatch('customFilter/setPreviewCustomerList', records, { root: true })
          if (eventLimitReached) {
            dispatch('customFilter/setIsEventLimitReached', false, { root: true })
          }
        }
        dispatch('customFilter/setAudiencePreviewCount', count, { root: true })
      } else {
        const notificationType = message === 'Unexpected status code 413' ? 'warn' : 'error'
        const notificationText =
          message === 'Unexpected status code 413'
            ? 'Unable to load all the customers. The list is too long.'
            : 'Something went wrong while loading customer preview'

        Vue.notify({ type: notificationType, text: notificationText })

        if (message === 'Unexpected status code 413') {
          dispatch('customFilter/setIsEventLimitReached', true, { root: true })
        }
      }

      commit('SET_CUSTOM_FILTERS_RESPONSE', response)
      dispatch('customFilter/setIsFetchAudiencePreview', false, { root: true })
    },
  },
}
