<template>
  <div class="message" v-if="platform">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-1">
        <a href="javascript:;" v-if="!platform" class="btn btn-sm btn-warning btn-rounded-circle"
          ><i class="fe fe-star icon-top-adjust text-white"></i
        ></a>
        <Avatar
          class="mt-1"
          v-if="platform"
          :img="`/theme/img/platforms/${platform.source || platform}.svg`"
          size="xs"
          status=""
        />
      </div>
      <div class="col ml--3">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span>
              Clicked to leave a
              <span v-if="platform">
                <span class="text-capitalize"> {{ platform.source || platform }}</span>
              </span>
              review.
            </span>
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">{{ created | moment('LT') }} {{ timezone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
export default {
  name: 'LeftReviewEvent',
  props: ['created', 'platform', 'timezone'],
  components: { Avatar },
}
</script>
