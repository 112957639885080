import LoyaltyService from '@/services/LoyaltyService'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async sendLoyaltyPoints(_, { customerId, locationId, companyId, points }) {
      await LoyaltyService.sendLoyaltyPoints({
        customerId,
        locationId,
        companyId,
        points,
      })
    },
  },
}
