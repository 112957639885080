<template>
  <div id="spacer">
    <div class="row justify-content-center" id="reports-filters">
      <div class="card px-4 w-100">
        <div class="row">
          <div class="col-12 mb-0">
            <!-- filters -->
            <div class="py-2 clearfix">
              <!--status-->
              <div class="d-inline-block mb-2 mr-4 filter-container">
                <h6 class="header-pretitle mt-3">
                  Status
                  <i
                    class="fas fa-info-circle text-muted ml-1 fa-sm"
                    v-b-popover:filters.hover.top="statusInfo"
                  ></i>
                </h6>
                <StatusFilter @updateStatus="filterStatus" />
              </div>

              <!-- date -->
              <div class="dropdown d-inline-block mr-4 mb-2 pt--2 filter-container date-filter">
                <h6 class="header-pretitle mt-3">Date</h6>
                <DateFilter @updateDateSettings="filterByDate" />
              </div>

              <!-- location -->
              <div
                v-if="locations.length > 1"
                class="dropdown d-inline-block mr-4 mb-2 filter-container"
              >
                <h6 class="header-pretitle mt-3">Location</h6>
                <GroupingFilter
                  :nonGroupMembers="locations"
                  nonGroupMemberLabel="Locations"
                  memberType="location"
                  @update="groupingUpdate"
                  :init="{}"
                  :key="activeCompany._id + locations.map((l) => l._id).join()"
                  :width="180"
                />
              </div>

              <!-- category -->
              <div class="dropdown d-inline-block mr-4 mb-2 filter-container">
                <h6 class="header-pretitle mt-3">Category</h6>
                <CategoryFilter :categories="categories" @updateCategory="filterByCategory" />
              </div>

              <!-- buttons -->
              <div class="d-inline-block mb-2 filter-container-right">
                <button class="btn mr-3" style="margin-top: 32px" @click="reset">Reset</button>
                <button
                  class="btn btn-primary"
                  style="margin-top: 32px"
                  @click.prevent="confirmExport"
                >
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomQuestionDateFilter from './QuestionCreation/Filters/CustomQuestionDateFilter'
import CategoryFilter from './QuestionCreation/Filters/CategoryFilter'
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import StatusFilter from './QuestionCreation/Filters/StatusFilter'
import { createNamespacedHelpers } from 'vuex'

const CustomQuestionModule = createNamespacedHelpers('customQuestion')
const LocationModule = createNamespacedHelpers('location')
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'ReportsFilters',
  components: {
    DateFilter: CustomQuestionDateFilter,
    GroupingFilter,
    CategoryFilter,
    StatusFilter,
  },
  data() {
    return {
      filteredGroups: null,
      filteredGroupMembers: null,
      filteredLocations: null,
      statusInfo: `The status of the question itself. Only "Active" questions will display to customers`,
    }
  },
  computed: {
    ...CustomQuestionModule.mapState(['reportsFilters', 'queryPending', 'categories']),
    ...LocationModule.mapState({ locations: 'userActiveCompanyLocations' }),
    ...CustomQuestionModule.mapGetters(['selectReportsFilters']),
    ...UserModule.mapGetters(['onlineUser']),
    ...CompanyModule.mapGetters(['activeCompany']),
  },
  methods: {
    ...CustomQuestionModule.mapMutations(['setReportsFilters', 'resetFilters', 'setQueryPending']),
    ...CustomQuestionModule.mapActions(['getReports', 'exportCustomQuestions']),
    ...GroupModule.mapActions(['selectGroupLocationIds']),

    async reset() {
      this.resetFilters()
      this.getFilteredReports()
    },

    async applyFilters(filters) {
      this.setReportsFilters(filters)
      this.getFilteredReports()
    },

    async getFilteredReports() {
      if (!this.queryPending) {
        this.setQueryPending(true)
        await this.getReports()
        this.setQueryPending(false)
      }
    },

    filterByDate(dateSettings = {}) {
      this.applyFilters({ ...this.reportsFilters, dateSettings })
    },

    filterStatus(status) {
      this.applyFilters({ ...this.reportsFilters, status: status.value })
    },

    async groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null

      const disabledIds = val && val.disabledNonGroupMemberIds ? val.disabledNonGroupMemberIds : []
      let locationsToFilter = [
        ...new Set([
          ...(this.filteredLocations || []),
          ...(this.filteredGroupMembers || []),
          ...disabledIds,
        ]),
      ]

      this.applyFilters({
        ...this.reportsFilters,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
      })
    },

    filterByCategory(category) {
      this.applyFilters({ ...this.reportsFilters, category: category._id })
    },

    async confirmExport() {
      const message = `
        A CSV file will be created with the filters
        that are currently applied.`

      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Export' } }),
      ])

      let exportConfirmed = await this.$bvModal.msgBoxConfirm(message, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Export',
        cancelTitle: 'Cancel',
        centered: true,
      })

      if (exportConfirmed) {
        await this.startFileExport()
      }
    },

    async startFileExport() {
      const exportPayload = {
        filters: this.selectReportsFilters,
        userId: this.onlineUser._id,
        companyId: this.activeCompany._id,
      }

      try {
        const h = this.$createElement
        const titleVNode = h('div', [
          h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Your export has been initiated' } }),
        ])

        const email = this.onlineUser.email || ''

        const messageVNode = h('p', {
          domProps: {
            innerHTML: `This could take a couple of minutes.
        If you need to leave our site, we'll email the export to <strong>${email}</strong>`,
          },
        })

        const okayMessageBox = await this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          size: 'md',
          okTitle: 'Okay',
          centered: true,
        })

        if (okayMessageBox) {
          await this.exportCustomQuestions(exportPayload)
        }

        this.exportLoading = false
      } catch (error) {
        this.$notify({
          type: 'error',
          duration: 5000,
          title: `Exporting customers failed! Try again`,
        })
      }
    },
  },
}
</script>

<style scoped>
.date-filter {
  top: 0px;
}
#reports-filters {
  display: block !important;
  position: relative;
  z-index: 900;
}
.filter-container {
  height: 80px;
  float: left;
  padding: 0px !important;
}
.filter-container-right {
  height: 80px;
  float: right;
  padding: 0px !important;
}
.popover {
  z-index: 1050; /* Adjust as needed */
}
</style>
