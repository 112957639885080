<template>
  <div class="card mr-3" style="height: 320px">
    <div class="px-4 pt-4 mb-auto text-center">
      <h4 class="text-muted" style="font-size: 14px">Mentions By Day</h4>
    </div>
    <div class="pb-3 px-4" style="height: 240px">
      <apexchart
        type="bar"
        height="100%"
        width="95%"
        :series="selectTotalMentions"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')

export default {
  name: 'TotalMentions',
  data: () => ({
    weekDayKey: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  }),
  computed: {
    ...InsightsModule.mapGetters(['selectTotalMentions', 'selectTopDrivers', 'selectCategoryName']),
    chartOptions() {
      return {
        chart: {
          height: 240,
          type: 'bar',
          toolbar: { show: false },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$emit('selectedDay', config.dataPointIndex)
            },
          },
          zoom: { enabled: false },
        },
        colors: ['#FC6D87', '#33D691'],
        plotOptions: {
          bar: {
            borderRadius: 10,
          },
        },
        grid: { show: true, borderColor: '#D2DDEC', strokeDashArray: 2 },
        noData: { text: 'No data to show' },
        dataLabels: { enabled: false },
        xaxis: {
          categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
          tooltip: { enabled: false },
          tickPlacement: 'on',
        },
        yaxis: {
          labels: { show: true },
          axisBorder: { show: true },
          labels: { formatter: (val) => parseInt(val) },
        },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return this.customToolTipLabels({ series, seriesIndex, dataPointIndex, w })
          },
        },
        stroke: {
          width: 0.5,
        },
        legend: { show: false },
      }
    },
  },
  methods: {
    customToolTipLabels({ series, seriesIndex, dataPointIndex, w }) {
      // get the positive or negative mentions count
      const sentimentCount = series[seriesIndex][dataPointIndex]
      // get the weekday labels from the xaxis
      const categoryLabel = w.globals.categoryLabels[dataPointIndex]
      const weekDayIndex = w.globals.categoryLabels.indexOf(categoryLabel)
      const labelTile = this.weekDayKey[weekDayIndex]
      const sentiment = seriesIndex === 0 ? 'negative' : 'positive'
      // get the top 3 category drivers for positive & negatibe mentions
      const sentimentColor = seriesIndex === 0 ? '#FC6D87' : '#33D691'
      const topDrivers = this.selectTopDrivers[weekDayIndex][sentiment].map((td) => {
        return {
          name: this.selectCategoryName(td.categoryId),
          count: td.count,
        }
      })

      // build template
      let template = `
      <div> 
        <small class="text-muted"> ${labelTile} </small>
        <div class="d-flex mb-2">
         <div class="mt-2 mr-2" 
         style="height: 12px; width: 12px; border-radius: 6px; background-color: ${sentimentColor};">
         </div>
         <small class="mt-1 text-capitalize"> ${sentiment}: <strong> ${sentimentCount}</strong></small>
        </div>
        <small class="text-muted"> Drivers </small>
        <div class="px-3 ml-1 mb-1">`
      // append to drivers to template
      topDrivers.forEach((d) => {
        if (d.count > 0) {
          template += `<small class="mt-1"> ${d.name} - ${d.count}</small> <br>`
        }
      })
      // append the closing tags to template
      template += `</div></div>`
      return template
    },
  },
}
</script>
