<template>
  <form @submit.prevent="join">
    <div class="row">
      <div class="form-group col-12">
        <label>What is the code sent to {{ invite.employee.phone | phone }}?</label>
        <input v-model="phoneCode" type="tel" class="form-control" placeholder="123456" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="form-check ml-1">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="termsAccepted"
            id="termsAccepted"
          />
          <label class="form-check-label" for="termsAccepted">
            By creating an account, you agree to Ovation's
            <a href="https://ovationup.com/terms-of-use/" target="_blank">Terms of Use</a> and
            <a href="https://ovationup.com/privacy-policy/" target="_blank">Privacy Policy</a>.
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-if="errMessage" class="alert alert-danger" role="alert">
          {{ errMessage }}
        </div>
        <button
          type="submit"
          class="btn btn-lg btn-block btn-primary mb-3"
          :class="{ 'is-loading': isLoading }"
          :disabled="!formValid || isLoading || !termsAccepted"
        >
          Join team
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')
import UserService from '@/services/UserService'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'InvitedForm',
  props: ['invite'],
  computed: {
    formValid() {
      return this.phoneCode.length > 5
    },
  },

  methods: {
    ...mapActions(['getOnlineUser']),
    async join() {
      this.isLoading = true
      try {
        await UserService.verifyPhoneCode({
          code: this.phoneCode,
        })
        const timezone = this.$moment.tz.guess()
        const response = await UserService.createFromInvite({
          token: this.$route.params.token,
          timezone,
          termsAccepted: this.termsAccepted,
        })

        const { jwt } = response.body
        localStorage.setItem('jwt', jwt)

        await this.getOnlineUser()

        trackEvent(this.$intercom, 'Terms of Use Accepted', {
          timestamp: this.$moment().toISOString(),
          value: this.termsAccepted,
          user: this.invite.employee,
        })
        this.$emit('joined')
      } catch (err) {
        this.isLoading = false
        this.errMessage = err.body.message
      }
    },
  },
  data: () => ({
    phoneCode: '',
    errMessage: '',
    isLoading: false,
    termsAccepted: false,
  }),
  async mounted() {
    try {
      await UserService.sendPhoneVerification({
        phone: this.invite.employee.phone,
        webOrigin: window.location.origin,
      })
    } catch (err) {
      this.errMessage = err.body.message
    }
  },
}
</script>
