import CustomerService from '@/services/CustomerService'
import { cloneDeep } from 'lodash-es'

const DEFAULT_DATE_FILTER = {
  date: 30,
  defaultValue: 90,
  name: 'In the last',
  type: 'ago',
  unit: 'days',
}

export default {
  namespaced: true,
  state: {
    customers: [],
    listFilters: { dateSettings: DEFAULT_DATE_FILTER },
    skipCount: 0,
    limit: 50,
    count: 0,
    totalCustomerCount: 0,
    isSearching: false,
    metrics: {},
    importParams: {},
  },
  getters: {
    selectIsSearching(state) {
      return state.isSearching
    },
    selectCustomerById: (state) => (customerId) =>
      state.customers.find((c) => c._id === customerId),
    selectMetrics: (state) => state.metrics,
    selectImportParams: (state) => state.importParams,
    selectCount: (state) => state.count,
    selectCustomers: (state) => state.customers,
    selectListFilters: (state) => state.listFilters,
  },
  mutations: {
    addCustomers(state, customers) {
      state.customers = state.customers.concat(customers)
    },
    setCustomers(state, customers) {
      state.customers = [...customers]
    },
    setListFilters(state, filters) {
      state.listFilters = { ...filters }
    },
    resetListFilters(state) {
      state.listFilters = { dateSettings: DEFAULT_DATE_FILTER }
    },
    setSkipCount(state, skip) {
      state.skipCount = skip
    },
    setLimit(state, limit) {
      state.limit = limit
    },
    setCount(state, count) {
      state.count = count
    },
    setTotalCustomerCount(state, totalCount) {
      state.totalCustomerCount = totalCount
    },
    setIsSearching(state, isSearching) {
      state.isSearching = isSearching
    },
    updateCustomer(state, customer) {
      state.customers = state.customers.map((c) => (c._id === customer._id ? customer : c))
    },
    SET_METRICS(state, metrics) {
      state.metrics = metrics
    },
    SET_IMPORT_PARAMS(state, importParams) {
      state.importParams = importParams
    },
    SET_LIST_FILTERS(state, filters) {
      state.listFilters = { ...filters }
    },
    SET_SKIP_COUNT(state, skip) {
      state.skipCount = skip
    },
  },
  actions: {
    async queryCustomers({ commit, state, rootState }, { loadMore }) {
      commit('setIsSearching', true)
      let filters = cloneDeep(state.listFilters)
      if (filters.search) filters.dateSettings = {}
      const response = await CustomerService.list({
        companyId: rootState.company.activeCompany._id,
        filters,
        skip: state.skipCount,
        limit: state.limit,
      })
      const { customers, skip, count } = response.body

      if (loadMore) {
        if (customers.length !== 0) {
          commit('addCustomers', customers)
        }
      } else {
        commit('setCustomers', customers)
      }

      commit('setCount', count)
      commit('setSkipCount', skip)
      commit('setIsSearching', false)
    },
    async queryCustomerCount({ commit, rootState }) {
      const response = await CustomerService.totalCustomerCount({
        companyId: rootState.company.activeCompany._id,
      })
      commit('setTotalCustomerCount', response.data.totalCount)
    },
    async getFilteredCSV({ commit, state, rootState }) {
      const response = await CustomerService.listExport({
        companyId: rootState.company.activeCompany._id,
        filters: state.listFilters,
      })
      return response
    },
    async chooseWinner({ state, rootState }) {
      const response = await CustomerService.chooseWinner({
        companyId: rootState.company.activeCompany._id,
        filters: state.listFilters,
      })
      const { winner } = response.body

      return winner
    },
    async getWinners({ rootState }) {
      const response = await CustomerService.pastWinners({
        companyId: rootState.company.activeCompany._id,
      })
      const { winners } = response.body

      return winners
    },
    async deleteCustomer({ dispatch, commit, rootState }, customer) {
      await CustomerService.delete({
        customerId: customer._id,
        companyId: rootState.company.activeCompany._id,
      })

      commit('setSkipCount', 0)
      await dispatch('queryCustomers', {})
    },

    async tagCustomer({ rootState, commit }, { customerId, tagId }) {
      const { activeCompany } = rootState.company

      if (!activeCompany || activeCompany._id == 'ALL_COMPANIES') return
      const { body } = await CustomerService.tag({
        companyId: activeCompany._id,
        customerId,
        tagId,
        op: 'add',
      })
      commit('updateCustomer', body.customer)
    },
    async untagCustomer({ rootState, commit }, { customerId, tagId }) {
      const { activeCompany } = rootState.company

      if (!activeCompany || activeCompany._id == 'ALL_COMPANIES') return
      const { body } = await CustomerService.tag({
        companyId: activeCompany._id,
        customerId,
        tagId,
        op: 'remove',
      })
      commit('updateCustomer', body.customer)
    },

    async blockCustomer(
      { commit, dispatch },
      { companyId, customerId, conversationId, customerObj }
    ) {
      const response = await CustomerService.blockCustomer({ companyId, customerId })

      if (response.body.success !== true) {
        throw response.body
      } else if (conversationId && customerObj) {
        await dispatch(
          'conversationV2/conversationChange',
          {
            conversationData: {
              _id: conversationId,
              customer: { ...customerObj, blocked: [...customerObj.blocked, companyId] },
            },
            trace: 'blockCustomer',
          },
          { root: true }
        )
      }
    },

    async unblockCustomer(
      { commit, dispatch },
      { companyId, customerId, conversationId, customerObj }
    ) {
      const response = await CustomerService.unblockCustomer({ companyId, customerId })

      if (response.body.success !== true) {
        throw response.body
      } else if (conversationId && customerObj) {
        await dispatch(
          'conversationV2/conversationChange',
          {
            conversationData: {
              _id: conversationId,
              customer: {
                ...customerObj,
                blocked: customerObj.blocked.filter((c) => c !== companyId),
              },
            },
            trace: 'unblockCustomer',
          },
          { root: true }
        )
      }
    },

    async fetchMetrics({ commit }, { customerId, companyId }) {
      const response = await CustomerService.fetchMetrics({ customerId, companyId })
      commit('SET_METRICS', response.body.data.metrics)
    },

    setImportParams({ commit }, importParams) {
      commit('SET_IMPORT_PARAMS', importParams)
    },

    async importCustomers({ commit, getters, rootGetters }, { mappedFields, preflight }) {
      const response = await CustomerService.import({
        ...getters.selectImportParams,
        mappedFields,
        preflight,
        userId: rootGetters['user/onlineUser']._id,
      })
    },

    async setListFilters({ commit }, filters) {
      commit('SET_LIST_FILTERS', filters)
    },

    async setSkipCount({ commit }, skip) {
      commit('SET_SKIP_COUNT', skip)
    },
  },
}
