<template>
  <div>
    <div class="mb-4">
      <div class="row">
        <div class="col-12">
          <div v-if="company._id" class="row">
            <div v-if="company.settings && company.settings.logo" class="col-auto">
              <div class="avatar-large">
                <img :src="company.settings.logo" class="avatar-img rounded-circle" />
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label class="mb-1">Logo</label>
                <small class="form-text text-muted"
                  >Please use a logo no larger than 1200px * 1200px.</small
                >
                <FileUploader
                  @uploaded="logoUploaded"
                  :button-text="
                    company.settings && company.settings.logo ? 'Change logo' : 'Upload logo'
                  "
                  button-class="btn-primary"
                />
              </div>
            </div>
          </div>
          <hr class="mt-3 mb-4" />
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="mb-1">Theme Color</label>
            <small class="form-text text-muted"
              >Please use a dark color. This is used on marketing materials and the iPad
              kiosk.</small
            >
            <ChromeColorPicker v-model="company.settings.color" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr class="mt-3 mb-4" />
          <div class="form-group">
            <label class="mb-1">Company name</label>
            <small class="form-text text-muted"
              >This name will be publicly used in communications to your customers.</small
            >
            <!-- Input -->
            <input v-model="company.name" class="form-control" type="text" />
            <div class="text-danger" v-if="!validCompanyName">
              Company name must contain GSM Characters Only. Please remove: {{ offendingChars }}
            </div>
          </div>
          <div class="form-group">
            <label class="mb-1">Industry</label>
            <MultiSelect
              id="company-industry"
              :allow-empty="false"
              :searchable="false"
              :show-labels="false"
              openDirection="below"
              :options="industries"
              v-model="company.industry"
              placeholder="Select industry"
            />
          </div>
          <div class="form-group">
            <button
              @click="updateProfile"
              type="button"
              class="btn btn-primary"
              :disabled="!validCompanyName"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr class="mt-3 mb-4" />
          <div class="form-group">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div>
                <h2 class="mb-1">Incentives</h2>
              </div>
              <button class="btn btn-primary btn-sm" @click.prevent="incentiveModalShown = true">
                Create Incentive
              </button>
            </div>
            <div class="card">
              <table class="table table-sm table-wrap card-table">
                <thead>
                  <tr>
                    <th scope="col" style="width: 10%">Type</th>
                    <th scope="col" style="width: 30%">Prize</th>
                    <th scope="col">Sources</th>
                    <th scope="col">Language</th>
                    <th scope="col">Default</th>
                    <th scope="col" style="width: fit-content"></th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr v-for="i of incentives" :key="i.prize">
                    <td class="align-middle text-capitalize">
                      {{ i.type }}
                    </td>
                    <td class="align-middle">
                      {{ i.prize }}
                    </td>
                    <td class="align-middle mt--2">
                      <template v-if="i.sources">
                        <span
                          :key="s"
                          v-for="s of i.sources"
                          style="font-size: 12px"
                          class="badge badge-light my-1 mr-2"
                          >{{ s }}</span
                        ></template
                      >
                      <span
                        v-else
                        style="font-size: 12px"
                        class="badge badge-soft-primary my-1 mr-1"
                        >Default</span
                      >
                    </td>
                    <!-- language -->
                    <td class="align-middle mt--2">
                      <template>
                        <span style="font-size: 12px" class="badge badge-light my-1 mr-2">{{
                          languageName(i.language)
                        }}</span></template
                      >
                    </td>
                    <td class="align-middle mt--2">
                      <template>
                        <span style="font-size: 12px" class="badge badge-light my-1 mr-2">{{
                          i.default ? 'true' : ''
                        }}</span></template
                      >
                    </td>
                    <td class="align-middle">
                      <div class="d-flex justify-content-end">
                        <button class="btn btn-white btn-sm" @click.prevent="editIncentive(i)">
                          Edit
                        </button>
                        <button
                          v-if="i.sources"
                          class="btn btn-white btn-sm ml-2"
                          @click.prevent="removeIncentive(i._id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- incentives modal -->
    <b-modal
      hide-footer
      hide-header
      body-class="p-0"
      id="incentive-modal"
      v-model="incentiveModalShown"
      @hidden="resetIncentiveModal"
    >
      <div class="modal-card card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h4 v-if="defaultInEdit" class="card-header-title">Edit Default Incentive</h4>
              <h4 v-else-if="activeIncentive._id" class="card-header-title">
                Edit Custom Incentive
              </h4>
              <h4 v-else class="card-header-title">New Custom Incentive</h4>
            </div>
            <div class="col-auto">
              <button @click="closeIncentiveModal" type="button" class="close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>
        <div ref="incentiveModalBody" class="card-body" style="max-height: 650px">
          <div class="row">
            <div class="col-12 mb-4">
              <!-- type -->
              <div class="form-group">
                <label for="incentive-type">Type</label>
                <MultiSelect
                  id="incentive-type"
                  :allow-empty="false"
                  :searchable="false"
                  :show-labels="false"
                  openDirection="below"
                  placeholder="Select type"
                  :options="incentiveTypes"
                  :customLabel="capitalize"
                  v-model="activeIncentive.type"
                />
              </div>
              <!-- prize -->
              <div class="form-group">
                <label for="incentive-prize" class="m-0">Prize</label>
                <small v-if="activeIncentive.type === 'sweepstakes'" class="form-text text-muted"
                  >Complete the sentence: Enter for a chance to win
                  <span
                    class="ml-1 d-inline-block"
                    style="border-bottom: 1px solid #95aac9; width: 80px"
                  ></span
                ></small>
                <small v-else class="form-text text-muted">
                  Complete the sentence: Enter your information to receive
                  <span
                    class="ml-1 d-inline-block"
                    style="border-bottom: 1px solid #95aac9; width: 80px"
                  ></span>
                </small>
                <input
                  id="incentive-prize"
                  v-model="activeIncentive.prize"
                  placeholder="Prize"
                  class="form-control"
                  type="text"
                />
              </div>
              <!-- language -->
              <div class="form-group" v-if="!defaultInEdit">
                <label for="incentive-type">Language</label>
                <MultiSelect
                  id="incentive-type"
                  :searchable="false"
                  :show-labels="false"
                  openDirection="below"
                  placeholder="Select language"
                  :options="languages"
                  v-model="selectedLanguage"
                  track-by="name"
                  label="name"
                />
              </div>
              <!-- offer -->
              <div v-if="activeIncentive.type === 'instant'" class="form-group">
                <label for="incentive-offer">Offer</label>
                <MultiSelect
                  id="incentive-offer"
                  :allow-empty="false"
                  :searchable="false"
                  :show-labels="false"
                  openDirection="below"
                  placeholder="Select offer"
                  :options="promos"
                  :customLabel="customLabel"
                  v-model="selectedPromo.promo"
                  @input="handlePromoSelected"
                />
              </div>
              <div class="form-group" v-if="activeIncentive.type === 'instant'">
                <label class="switch">
                  <input type="checkbox" v-model="activeIncentive.promoLimit" />
                  <span class="slider round"></span>
                </label>
                <p class="text-muted">Limit one offer per customer</p>
              </div>
              <!-- default incentive -->
              <div class="form-group mb-3" v-if="activeIncentive.type === 'sweepstakes'">
                <p class="m-0">Default</p>
                <small class="form-text text-muted">
                  Set this incentive as the default incentive for the selected language
                </small>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="activeIncentive.default"
                    @change="onDefaultChanged"
                  />
                  <span class="slider round"></span>
                </label>
                <p class="text-danger" v-if="defaultIncentiveExists">
                  An incentive marked as "default" with the selected language already exists. Please
                  update the current default incentive before continuing.
                </p>
              </div>
              <!-- sources dropdown -->
              <div v-if="!defaultInEdit" class="form-group">
                <label class="m-0" for="incentive-sources">Sources</label>
                <small class="form-text text-muted">Segment incentives by survey's sources</small>
                <MultiSelect
                  multiple
                  reposition
                  hideSelected
                  showNoResults
                  :show-labels="false"
                  openDirection="below"
                  id="incentive-sources"
                  placeholder="Select sources"
                  :modalEl="incentiveModalEl"
                  :options="availableSources"
                  v-model="activeIncentive.sources"
                >
                  <template #noResult><span>No matching source.</span></template>
                </MultiSelect>
              </div>
              <!-- custom source -->
              <div class="form-group" v-if="!defaultInEdit">
                <label class="m-0" for="incentive-sources">Custom Sources</label>
                <small class="form-text text-muted">
                  Type sources separated by a comma - No spaces allowed
                </small>
                <input type="text" class="form-control" v-model="customSources" />
              </div>
            </div>
            <div class="col-12">
              <button
                @click="saveIncentive"
                :disabled="isLoading || !isFormValid"
                class="btn btn-primary col-12"
                :class="{ 'is-loading': isLoading }"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- escalation rules section -->
    <div class="pb-5">
      <div class="row">
        <div class="col-12">
          <hr class="mt-3 mb-4" />
          <div class="form-group">
            <div class="mb-3">
              <h2 class="mb-1">Escalation Rules</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!rulesLoading">
        <div class="col-12">
          <div v-if="!company.settings.escalationsEnabled" class="text-center">
            <p>Unlock the ability to better manage your customer feedback with escalation rules!</p>
            <a href="mailto:hello@ovationup.com">
              <i class="fas fa-envelope"></i>
              Contact us</a
            >
            to get started today.
          </div>
          <div v-if="company.settings.escalationsEnabled">
            <h3>When should feedback be escalated?</h3>
            <div class="form-group form-inline">
              <span class="mr-1">After</span>
              <input
                v-model="company.settings.escalationsDefaultMinutes"
                class="form-control mr-1"
                type="number"
                style="max-width: 72px"
                min="5"
                max="1440"
                step="5"
                @change="updateEscalationTimeSetting"
              />
              <span>minutes</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0">Rules</h3>
              <button class="btn btn-outline-primary" v-b-modal.createRuleModal>+ Add Rule</button>
            </div>
            <div class="mt-4">
              <div v-if="!rules || !rules.length" class="p-3">
                <p class="text-center">No rules configured</p>
              </div>
              <div v-else>
                <div v-for="rule of rules" :key="rule._id" class="form-group">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-6">
                          <div class="d-flex flex-wrap">
                            <span
                              v-for="location of rule.locations"
                              :key="location"
                              style="font-size: 15px"
                              class="badge badge-soft-primary mr-1 mb-1"
                            >
                              {{ selectLocationName(location) }}
                            </span>
                          </div>
                        </div>

                        <div class="col-5 d-flex">
                          <div class="mr-5 d-flex align-items-center">
                            <i class="fas fa-arrow-circle-right text-muted fa-lg"></i>
                          </div>
                          <div class="d-flex flex-wrap">
                            <div v-for="(user, index) of rule.users" :key="index">
                              <Avatar
                                :first-name="getEmployeeFirstname(user)"
                                :last-name="getEmployeeLastname(user)"
                                :class="{ 'ml--3': index }"
                                size="md"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-1 align-items-center text-right">
                          <div class="dropdown d-inline-block">
                            <a
                              class="dropdown-ellipses dropdown-toggle"
                              data-toggle="dropdown"
                              href="#!"
                              role="button"
                            >
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a
                                @click="editRule(rule)"
                                v-b-modal.createRuleModal
                                class="dropdown-item"
                                href="javascript:;"
                              >
                                <span>Edit</span>
                              </a>
                              <a
                                @click="removeRule(rule)"
                                class="dropdown-item"
                                href="javascript:;"
                              >
                                <span class="text-danger">Delete</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- routing rules section -->
    <div v-if="company.settings.routingRulesEnabled" class="pb-5">
      <div class="row">
        <div class="col-12">
          <hr class="mt-3 mb-4" />
          <div class="form-group">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-1">Routing Rules</h2>
              <button class="btn btn-outline-primary" v-b-modal.createRoutingRuleModal>
                + Add Rule
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!rulesLoading">
        <div class="col-12">
          <div v-if="company.settings.routingRulesEnabled">
            <div class="mt-2">
              <div v-if="!routingRules || !routingRules.length" class="p-3">
                <p class="text-center">No rules configured</p>
              </div>
              <div v-else>
                <div v-for="rule of routingRules" :key="rule._id" class="form-group">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-6">
                          <div class="d-flex flex-wrap">
                            <span
                              v-for="source of rule.sources"
                              :key="source"
                              style="font-size: 15px"
                              class="badge badge-soft-primary mr-1 mb-1"
                            >
                              {{ source }}
                            </span>
                          </div>
                        </div>

                        <div class="col-5 d-flex">
                          <div class="mr-5 d-flex align-items-center">
                            <i class="fas fa-arrow-circle-right text-muted fa-lg"></i>
                          </div>
                          <div class="d-flex flex-wrap">
                            <div v-for="(user, index) of rule.users" :key="index">
                              <Avatar
                                :first-name="getEmployeeFirstname(user)"
                                :last-name="getEmployeeLastname(user)"
                                :class="{ 'ml--3': index }"
                                size="md"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-1 align-items-center text-right">
                          <div class="dropdown d-inline-block">
                            <a
                              class="dropdown-ellipses dropdown-toggle"
                              data-toggle="dropdown"
                              href="#!"
                              role="button"
                            >
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a
                                @click="editRoutingRule(rule)"
                                v-b-modal.createRoutingRuleModal
                                class="dropdown-item"
                                href="javascript:;"
                              >
                                <span>Edit</span>
                              </a>
                              <a
                                @click="removeRule(rule)"
                                class="dropdown-item"
                                href="javascript:;"
                              >
                                <span class="text-danger">Delete</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-6">
      <div class="row">
        <div class="col-12">
          <hr class="mt-3 mb-4" />
          <div class="form-group">
            <div class="mb-3">
              <h2 class="mb-1">Weekly Summary Settings</h2>
              <p class="text-muted">
                This determines which day of the week the weekly summary emails are sent and which
                days are included in the summary
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <div class="pr-3 mt-3">
            <p>Send summary emails each</p>
          </div>
          <div class="pr-3 d-flex align-items-center">
            <select
              v-model="startOfWeek"
              class="form-select form-control"
              @change="handleDayOfWeekChanged"
              aria-label="Default select example"
            >
              <option value="" selected>Select day of the week</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div class="pr-3 mt-3" v-if="calculateDayOfWeek">
            <p>Summarizing the previous week {{ calculateDayOfWeek }}</p>
          </div>
        </div>
        <div class="col-3 mt-3" v-if="calculateDayOfWeek">
          <label for="endOfWeek">End of week day (optional)</label>
          <select
            v-model="endOfWeek"
            id="endOfWeek"
            class="form-select form-control"
            @change="handleDayOfWeekChanged"
            aria-label="Default select example"
          >
            <option value="" selected>Select day of the week</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
        </div>
      </div>
    </div>
    <CreateRuleModal :selectedRule="selectedRule" @clearRule="selectedRule = {}" :rules="rules" />
    <CreateRoutingRuleModal
      :rules="routingRules"
      :selectedRule="selectedRoutingRule"
      @clearRule="selectedRoutingRule = {}"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { Chrome } from 'vue-color'
import { cloneDeep, debounce } from 'lodash-es'
import { evaluateGsmText } from '@/lib/gsm.js'
import { mapActions, mapState, mapGetters } from 'vuex'

import MultiSelect from '@/components/Common/MultiSelect'

import CreateRuleModal from '@/components/Modals/CreateRuleModal'
import CompanySettingsBase from './CompanySettingsBase'
import { industries } from '@/lib/modules/company'
import CompanyService from '@/services/CompanyService'
import FileUploader from '@/components/MainContent/FileUploader'
import Avatar from '@/components/MainContent/Avatar'
import CreateRoutingRuleModal from '@/components/Modals/CreateRoutingRuleModal'

const PromoModule = createNamespacedHelpers('promo')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const RuleModule = createNamespacedHelpers('rule')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')

const defaultLanguage = { name: 'English', code: 'en' }

export default {
  name: 'CompanySettingsProfile',

  extends: CompanySettingsBase,

  components: {
    MultiSelect,
    FileUploader,
    ChromeColorPicker: Chrome,
    CreateRuleModal,
    Avatar,
    CreateRoutingRuleModal,
  },

  data: () => ({
    isLoading: false,
    rulesLoading: true,
    incentiveModalEl: null,
    incentiveModalShown: false,
    industries: [...industries],
    incentiveTypes: ['sweepstakes', 'instant'],
    company: {
      industry: 'Other',
      settings: { color: '#2C7BE5', giveaway: 'a $100 gift card' },
    },
    selectedRule: {},
    defaultInEdit: false,
    activeIncentive: {},
    customSources: '',
    selectedPromo: {
      promo: null,
    },
    selectedLanguage: { ...defaultLanguage },
    languages: [{ ...defaultLanguage }, { name: 'French', code: 'fr' }],
    selectedRoutingRule: {},
    startOfWeek: '',
    endOfWeek: '',
    defaultIncentiveExists: false,
  }),

  computed: {
    ...mapState('tag', { sources: 'tags' }),
    ...mapGetters('location', ['selectActiveLocations']),
    ...PromoModule.mapGetters(['promos']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...RuleModule.mapGetters(['selectRules']),
    ...CompanyModule.mapGetters(['selectEmployees', 'activeCompany', 'selectEmployee']),
    ...LocationModule.mapGetters(['selectLocationName']),

    availableSources() {
      const taken = this.incentives.reduce((acc, cur) => acc.concat(cur.sources), [])
      return this.sources.map((s) => s.name).filter((name) => !taken.includes(name))
    },
    isFormValid() {
      const { prize, sources = [] } = this.activeIncentive
      const hasPrize = !!prize
      const hasSources = !!(sources.length || this.customSources)

      let hasCustomSourceSpaces = false
      if (this.customSources) {
        hasCustomSourceSpaces = /\s/g.test(this.customSources)
      }

      if (this.defaultInEdit) {
        return hasPrize
      }

      return hasPrize && hasSources && !hasCustomSourceSpaces && !this.defaultIncentiveExists
    },
    validCompanyName() {
      return evaluateGsmText(this.company.name).status
    },
    offendingChars() {
      return evaluateGsmText(this.company.name).offendingChars
    },
    incentives() {
      const { defaultIncentive = {}, incentives = [] } = this.company
      return [defaultIncentive, ...incentives]
    },
    rules() {
      return this.selectRules(this.activeCompany._id)?.filter((rule) => rule?.type === 'ESCALATION')
    },
    routingRules() {
      return this.selectRules(this.activeCompany._id)?.filter((rule) => rule?.type === 'ROUTING')
    },
    calculateDayOfWeek() {
      const daysAsNumbers = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
      }
      const numbersAsDays = {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday',
      }
      if (!this.startOfWeek) {
        return ''
      }
      const endDayOfWeek = this.endOfWeek || numbersAsDays[daysAsNumbers[this.startOfWeek] - 1 || 7]
      return `(${this.startOfWeek} to ${endDayOfWeek})`
    },
  },

  watch: {
    activeCompany: {
      deep: true,
      immediate: true,
      handler(company) {
        this.company = company
      },
    },

    'company.name': {
      immediate: true,
      handler() {
        this.company.name = this.company.name.replace(/’/g, "'")
      },
    },

    async incentiveModalShown(shown) {
      await this.$nextTick()
      this.incentiveModalEl = this.$refs.incentiveModalBody || null
    },
  },

  async mounted() {
    await this.getSources()
    await this.queryPromos()
    this.rulesLoading = true
    await this.getRules()
    this.rulesLoading = false
    if (this.company?.settings?.startOfWeek) {
      this.startOfWeek = this.company.settings.startOfWeek
    }
    if (this.company?.settings?.endOfWeek) {
      this.endOfWeek = this.company.settings.endOfWeek
    }
  },

  methods: {
    ...mapActions('tag', { getSources: 'getTags' }),
    ...RuleModule.mapActions(['getRules', 'persistRule', 'deleteRule']),
    ...PromoModule.mapActions(['queryPromos']),
    languageName(code) {
      return this.languages.find((l) => l.code === code)?.name || 'English'
    },
    handlePromoSelected() {
      const { promo } = this.selectedPromo
      if (promo && promo._id) {
        this.activeIncentive.promo = [promo._id]
      }
    },
    async updateProfile() {
      const { _id: companyId, name, industry, settings } = this.company

      await CompanyService.update({
        companyId,
        fields: {
          name,
          industry,
          'settings.color': settings.color.hex,
        },
      })
      await this.refreshCompanyList()

      this.$notify({
        title: 'Company updated',
        text: 'Your settings were saved.',
      })
    },

    async logoUploaded(fileUrl) {
      await CompanyService.update({
        companyId: this.company._id,
        fields: {
          'settings.logo': fileUrl,
        },
      })
      await this.refreshCompanyList()
    },

    updateEscalationTimeSetting: debounce(function () {
      const { settings } = this.company
      if (settings.escalationsDefaultMinutes < 5) settings.escalationsDefaultMinutes = 5
      else if (settings.escalationsDefaultMinutes > 1440) settings.escalationsDefaultMinutes = 1440
      this.updateEscalationSettings()
    }, 1000),

    async updateEscalationSettings() {
      const { settings } = this.company
      const escalationsDefaultMinutes = 60

      await CompanyService.update({
        companyId: this.company._id,
        fields: {
          'settings.escalationsDefaultMinutes':
            settings.escalationsDefaultMinutes || escalationsDefaultMinutes,
        },
      })
      await this.refreshCompanyList()
    },

    async saveIncentive() {
      this.isLoading = true
      const fields = {}

      if (this.customSources) {
        this.activeIncentive.sources = [
          ...(this.activeIncentive.sources?.length ? this.activeIncentive.sources : []),
          ...this.customSources.split(',').filter((s) => s),
        ]
      }

      if (!this.defaultInEdit) {
        this.activeIncentive.language = this.selectedLanguage.code
      }

      if (this.defaultInEdit) {
        fields.defaultIncentive = this.activeIncentive
      } else {
        const withoutDefault = this.incentives.slice(1)
        fields.incentives = this.activeIncentive._id
          ? withoutDefault.map((i) =>
              this.activeIncentive._id === i._id ? this.activeIncentive : i
            )
          : [...withoutDefault, this.activeIncentive]
      }

      try {
        await CompanyService.update({ companyId: this.company._id, fields })
        await this.refreshCompanyList()

        this.$notify({
          title: 'Company updated',
          text: `Your ${this.defaultInEdit ? 'default' : 'custom'} incentive was saved.`,
        })

        this.closeIncentiveModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    async removeIncentive(removedIncentiveId) {
      this.isLoading = true
      const fields = {}

      const withoutDefault = this.incentives.slice(1)
      fields.incentives = withoutDefault.filter((i) => i._id !== removedIncentiveId)

      try {
        await CompanyService.update({ companyId: this.company._id, fields })
        await this.refreshCompanyList()

        this.$notify({
          title: 'Company updated',
          text: `Your custom incentive was removed.`,
        })

        this.closeIncentiveModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    editIncentive(incentive) {
      this.defaultInEdit = !incentive.sources
      this.activeIncentive = cloneDeep(incentive)
      this.selectedLanguage = this.languages.find((l) => l.code === incentive.language) || {
        ...defaultLanguage,
      }
      this.selectedPromo.promo = this.promos.find((promo) => promo._id === incentive.promo)
      this.incentiveModalShown = true
    },

    closeIncentiveModal() {
      this.incentiveModalShown = false
      this.resetIncentiveModal()
    },

    resetIncentiveModal() {
      this.defaultInEdit = false
      this.activeIncentive = {}
      this.customSources = ''
      this.selectedLanguage = { ...defaultLanguage }
      this.selectedPromo.promo = null
      this.defaultIncentiveExists = false
    },

    capitalize(val) {
      return val[0].toUpperCase() + val.slice(1)
    },
    customLabel(val) {
      return val.nickname || val.title
    },
    editRule(rule) {
      this.selectedRule = rule
    },
    removeRule(rule) {
      this.deleteRule(rule._id)
    },
    editRoutingRule(rule) {
      this.selectedRoutingRule = rule
    },
    getEmployeeFirstname(user) {
      return this.selectEmployee(user)?.firstName
    },
    getEmployeeLastname(user) {
      return this.selectEmployee(user)?.lastName
    },
    handleDayOfWeekChanged: debounce(async function (e) {
      e.preventDefault()
      try {
        await CompanyService.update({
          companyId: this.company._id,
          fields: {
            'settings.endOfWeek': this.endOfWeek,
            'settings.startOfWeek': this.startOfWeek,
          },
        })
        await this.refreshCompanyList()
        this.$notify({
          title: 'Updated!',
          text: 'The day of the week was updated successfully',
        })
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: "Oops, something went wrong. We're unable to update the day of the week",
        })
      }
    }, 200),
    onDefaultChanged(event) {
      const isChecked = event?.target?.checked
      const language = this.selectedLanguage?.code
      if (isChecked === true) {
        const defaultExists = this.incentives?.find(
          (i) =>
            i.language === language && i.default === true && i._id !== this.activeIncentive?._id
        )
        if (defaultExists) {
          this.defaultIncentiveExists = true
        }
      } else {
        this.defaultIncentiveExists = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.avatar-large {
  width: 6rem;
  height: 6rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 23px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
