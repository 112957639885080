<template>
  <Vue2DateRangePicker
    autoApply
    ref="picker"
    opens="right"
    showDropdowns
    :ranges="ranges"
    :minDate="minDate"
    :maxDate="maxDate"
    v-model="selectedRange"
    singleDatePicker="range"
    :key="minDate"
    class="w-100"
  >
    <!-- input -->
    <template v-slot:input="i">
      <i class="fe fe-calendar mr-2 text-muted"></i>
      <span class="text-secondary" style="font-size: 14px"
        >{{ formatInput(i.startDate) }} - {{ formatInput(i.endDate) }}</span
      >
    </template>
    <!-- ranges -->
    <template #ranges="r">
      <div class="ranges">
        <ul>
          <li
            v-for="([start, end], name) in r.ranges"
            :key="name"
            @click="r.clickRange([start, end])"
          >
            <span class="mr-2 name">{{ name }}</span>
            <small class="text-muted">{{ formatRanges(start) }} - {{ formatRanges(end) }}</small>
          </li>
        </ul>
      </div>
    </template>
  </Vue2DateRangePicker>
</template>

<script>
import Vue2DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'DateRangePicker',
  components: {
    Vue2DateRangePicker,
  },
  props: ['minDate', 'dateRange'],
  data() {
    const today = this.$moment().toDate()
    const endOfToday = this.$moment().endOf('day').toDate()
    const yesterday = this.$moment().subtract(1, 'day').toDate()
    const last30Days = [this.$moment().subtract(30, 'days').toDate(), today]
    const minDate = this.$moment(this.minDate).toDate()

    return {
      maxDate: this.$moment().endOf('month').add(1, 'day').toDate(),
      selectedRange: {
        startDate: last30Days[0],
        endDate: last30Days[1],
      },
      ranges: {
        'This Week': [this.$moment().startOf('week').toDate(), today],
        'Last week': [
          this.$moment().subtract(1, 'w').startOf('week').toDate(),
          this.$moment().subtract(1, 'w').endOf('week').toDate(),
        ],
        'Last month': [
          this.$moment().subtract(1, 'M').startOf('month').toDate(),
          this.$moment().subtract(1, 'M').endOf('month').toDate(),
        ],
        'Last 30 days': last30Days,
        'Last Quarter': [
          this.$moment().subtract(1, 'Q').startOf('quarter').toDate(),
          this.$moment().subtract(1, 'Q').endOf('quarter').toDate(),
        ],
        'Year to Date': [this.$moment().startOf('year').toDate(), today],
        'Last Year': [
          this.$moment().subtract(1, 'y').startOf('year').toDate(),
          this.$moment().subtract(1, 'y').endOf('year').toDate(),
        ],
        'All time': [minDate, today],
      },
    }
  },
  watch: {
    selectedRange({ startDate, endDate }) {
      const dateRange = [this.formatQueryStart(startDate), this.formatQueryEnd(endDate)]
      this.$emit('update', dateRange)
    },
  },
  methods: {
    formatInput(date) {
      return this.$moment(date).format('MMM D, YYYY')
    },
    formatRanges(date) {
      return this.$moment(date).format(`MMM D, YY`)
    },
    formatQueryStart(date) {
      return this.$moment(date).startOf('day').utc().format()
    },
    formatQueryEnd(date) {
      return this.$moment(date).endOf('day').utc().format()
    },
  },
  mounted() {
    if (!this.dateRange) {
      const { startDate, endDate } = this.selectedRange
      this.$emit('update', [this.formatQueryStart(startDate), this.formatQueryEnd(endDate)])
    } else {
      this.selectedRange = { startDate: this.dateRange[0], endDate: this.dateRange[1] }
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .reportrange-text {
  width: 100% !important;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
::v-deep .daterangepicker {
  min-width: 100% !important;
  border: 1px solid #edf2f9;
  border-radius: 5px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  &:before {
    border-bottom: 0;
  }

  .calendars {
    display: flex;
    flex-wrap: nowrap;
  }

  .ranges {
    width: max-content;

    li {
      color: var(--secondary);
      padding: 12px 15px;

      .name {
        font-size: 14px;
      }

      &:hover {
        color: var(--primary);
        background-color: var(--light);
      }
    }
  }
  .drp-calendar {
    border-left: 1px solid var(--light) !important;
  }
  .calendar-table {
    table {
      border-collapse: separate;
      border-spacing: 2px;
    }
    th {
      font-weight: normal;
      color: var(--secondary);
    }
    td {
      color: var(--muted);

      &.in-range {
        background-color: #e9f0fa;
      }
      &.active {
        color: #fff !important;
        background-color: var(--primary) !important;
      }
    }
  }
  select.monthselect,
  input.yearselect {
    font-size: 14px;
    color: var(--dark);
  }
}
.vue-daterange-picker ::v-deep .reportrange-text {
  border: 1px solid #d2ddec;
}
</style>
