<template>
  <div class="card col-12 mt-4">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/activation.svg"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 180px"
          />
          <h2>
            Your activation code is
            <span class="text-primary">{{ activationCode }}</span>
          </h2>

          <p class="text-muted">You can use this code to link a device to {{ brandName }}.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LocationSettingsDevicesOffstate',
  props: ['activationCode'],
  computed: {
    ...mapGetters('user', ['brandName']),
  },
}
</script>

<style scoped lang="scss"></style>
