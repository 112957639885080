<template>
  <div>
    <popper
      ref="popperRef"
      v-if="currentPrefix"
      class="date-popper"
      trigger="click"
      v-on:show="initialize()"
      :options="{
        placement: 'bottom',
        modifiers: {
          offset: { offset: '0,6px' },
          flip: { enabled: false },
          gpuAcceleration: true,
        },
      }"
    >
      <div class="popper">
        <div class="row mt-0">
          <div class="col">
            <h6 class="header-pretitle mb--4 mt-3 text-left">Select a date range</h6>
          </div>
          <div class="col text-right">
            <button @click="reset()" class="btn btn-outline-secondary btn-sm mb--2">Reset</button>
          </div>
        </div>
        <hr />
        <div class="input-group">
          <div class="input-group-prepend">
            <!-- Prefix Dropdown Dropdown -->
            <button
              class="btn btn-white dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentPrefix.name }}
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="javascript:;" data-toggle="true" @click="reset()"
                >All time</a
              >
              <a
                v-for="prefix in prefixes"
                :key="prefix.type"
                data-toggle="true"
                class="dropdown-item"
                href="javascript:;"
                @click="setPrefix(prefix)"
                >{{ prefix.name }}</a
              >
            </div>
          </div>

          <flat-pickr
            ref="rangeRef"
            v-model="currentPrefix.date"
            v-if="currentPrefix.type === 'range'"
            class="form-control"
            :config="{
              mode: 'range',
              dateFormat: 'M j, Y',
              altFormat: 'M j, Y',
              altInput: true,
              inline: true,
              wrap: false,
            }"
            placeholder="Choose a range"
            name="date"
            @on-close="applyFilters"
          ></flat-pickr>

          <input
            v-if="currentPrefix.type === 'ago'"
            type="text"
            class="form-control"
            placeholder="90"
            v-model="currentPrefix.date"
            @keyup="applyFilters"
          />
          <div class="input-group-append" v-if="currentPrefix.type === 'ago'">
            <button
              class="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentPrefix.unit }}
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="javascript:;" @click="setUnit('days')">days</a>
              <a class="dropdown-item" href="javascript:;" @click="setUnit('weeks')">weeks</a>
              <a class="dropdown-item" href="javascript:;" @click="setUnit('months')">months</a>
            </div>
          </div>

          <flat-pickr
            v-if="currentPrefix.type === 'since' || currentPrefix.type === 'on'"
            ref="sinceRef"
            v-model="currentPrefix.date"
            class="form-control"
            :config="{
              mode: 'single',
              dateFormat: 'M j, Y',
              altInput: true,
              inline: true,
              wrap: false,
            }"
            placeholder="Choose a date"
            name="date"
            @on-close="applyFilters"
          ></flat-pickr>
        </div>
        <button
          @click="applyFilters()"
          href="javascript:;"
          v-if="currentPrefix.type != 'ago'"
          class="btn btn-primary mt-3 w-100"
        >
          Apply
        </button>
      </div>

      <button slot="reference" class="btn btn-outline-secondary" style="font-size: 14px">
        <span class="fe fe-clock"></span>
        {{
          currentPrefix.date
            ? `${currentPrefix.name} ${currentPrefix.date}${
                currentPrefix.unit ? ' ' + currentPrefix.unit : ''
              }`
            : 'All time'
        }}
      </button>
    </popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import { createNamespacedHelpers } from 'vuex'
const CustomQuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'CustomQuestionDateFilter',
  components: {
    popper: Popper,
  },
  data: () => ({
    prefixes: [
      { name: 'In the last', type: 'ago', unit: 'days', date: undefined },
      { name: 'From', type: 'range', date: undefined },
      { name: 'Since', type: 'since', date: undefined },
      { name: 'On', type: 'on', date: undefined },
    ],
    currentPrefix: {
      name: 'In the last',
      type: 'ago',
      unit: 'days',
      date: undefined,
      defaultValue: 90,
    },
    showCustomRange: false,
  }),
  computed: {
    ...CustomQuestionModule.mapState(['reportsFilters']),
  },
  methods: {
    setPrefix(prefix) {
      this.currentPrefix = prefix
      if (prefix.defaultValue) {
        this.currentPrefix.date = prefix.defaultValue
      } else {
        this.currentPrefix.date = undefined
      }
    },
    setUnit(unit) {
      this.currentPrefix.unit = unit
      this.applyFilters()
    },
    reset() {
      this.currentPrefix = { type: undefined }
      this.$refs.popperRef.doClose()
      this.applyFilters()
      setTimeout(() => {
        this.setPrefix(this.prefixes[0])
      }, 1000)
    },
    applyFilters() {
      let context = this
      setTimeout(() => {
        if (context.$refs.popperRef && context.currentPrefix.type !== 'ago')
          context.$refs.popperRef.doClose()
        context.$emit('updateDateSettings', context.currentPrefix)
      }, 100)
    },
    initialize() {
      if (this.reportsFilters && this.reportsFilters.dateSettings) {
        this.currentPrefix = { ...this.reportsFilters.dateSettings }
      } else if (!this.currentPrefix) {
        this.setPrefix(this.prefixes[0])
      }
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>
