const isProduction = process.env.NODE_ENV === 'production'
const isStaging = process.env.NODE_ENV === 'staging'
const isTest = ['test', 'local'].includes(process.env.NODE_ENV)
const isLocal = process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'headless'

export default {
  isProduction,
  isTest,
  isStaging,
  isLocal,
  firebase: isProduction
    ? {
        apiKey: 'AIzaSyARx8z2QLkrMZWyNCRbCMmeNm3pH1iMTIQ',
        authDomain: 'ovation-v2.firebaseapp.com',
        databaseURL: 'https://ovation-v2.firebaseio.com',
        projectId: 'ovation-v2',
        storageBucket: 'ovation-v2.appspot.com',
        messagingSenderId: '690925511448',
      }
    : {
        apiKey: 'AIzaSyDm0d4cKy1NrwEjbaplqzBpO6NAmI91JmM',
        authDomain: 'ovation-v2-dev.firebaseapp.com',
        databaseURL: 'https://ovation-v2-dev.firebaseio.com',
        projectId: 'ovation-v2-dev',
        storageBucket: 'ovation-v2-dev.appspot.com',
        messagingSenderId: '57123254012',
      },
  intercom: isProduction ? 'tbu5bls0' : 'ix7dwy5j',
  apiv2: {
    ovationupProductApi: `https://${
      isProduction ? 'vwgjlc8i7g' : isStaging ? 'ye6ske3ta9' : '07b9hqdu73'
    }.execute-api.us-east-1.amazonaws.com/${isLocal ? 'test' : process.env.NODE_ENV}`,
    ovationupWebsocketApi: `wss://${
      isProduction ? '1z1r3i2b8c' : isStaging ? 'dv1w5kpyc1' : '4iyi1gikq5'
    }.execute-api.us-east-1.amazonaws.com/${isLocal ? 'test' : process.env.NODE_ENV}`,
  },
  baseAppUrl: isProduction
    ? 'https://app.ovationup.com'
    : isStaging
    ? 'https://app-staging.ovationup.com'
    : isLocal
    ? 'http://localhost:5173'
    : 'https://app-test.ovationup.com',
  surveyUrl: isProduction
    ? 'https://survey.ovationup.com'
    : isStaging
    ? 'https://survey-staging.ovationup.com'
    : isTest
    ? 'https://survey-test.ovationup.com'
    : 'http://localhost:8081',
  api: isProduction
    ? 'https://apis.ovationup.com/app/v1'
    : isTest || isStaging || isLocal
    ? 'https://sandbox-apis.ovationup.com/app/v1'
    : 'http://localhost:3000/app/v1',
  apiApp: isProduction ? 'https://api.ovationup.com' : 'https://api-test.ovationup.com',
  pusherKey: isProduction ? 'c9f5ee935d96de951cb3' : 'dd306af8edb6cf0d4b45',
  vendastaPartnerId: '5d78299955bcfb1db38bca61',
  sandboxApi: 'https://sandbox-apis.ovationup.com/app/v1',
  websocketApi: isProduction
    ? 'wss://jbt2yh7xsc.execute-api.us-east-1.amazonaws.com/production'
    : 'wss://bj78p558jb.execute-api.us-east-1.amazonaws.com/sandbox',
  icons: {
    ovation:
      'https://firebasestorage.googleapis.com/v0/b/ovation-v2-dev.appspot.com/o/uploads%2F22bla9.jpeg?alt=media&token=e08e266a-8787-4506-974f-3d273bcd94f8',
  },
  boldBI: isProduction ? 'https://bi.ovationup.com/bi' : 'https://bi-test.ovationup.com/bi',
  assetsUrl: 'https://assets.ovationup.com/',
}
