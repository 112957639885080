<template>
  <div>
    <div class="card-body" id="timelineBody" style="min-height: 55vh">
      <div class="row">
        <div class="quick-response-wrapper">
          <canvas
            id="timelineConfetti"
            style="position: absolute; width: 90%; height: 75%"
          ></canvas>
        </div>
        <div class="col-12">
          <div class="timeline" v-if="selectTimelineEvents.length">
            <div v-for="eventGroup of selectTimelineEvents" :key="eventGroup.timestamp">
              <!-- timestamp for grouped events -->
              <div v-if="eventGroup" class="text-center mb-2 mt-1">
                <span style="font-size: 10px">
                  {{ eventGroup.timestamp }}
                </span>
              </div>
              <!-- end timestamp -->

              <div
                v-for="event of eventGroup.events"
                :key="event._id"
                class="row"
                :class="{
                  'justify-content-end':
                    event.user ||
                    event.type === 'sent-campaign' ||
                    event.type === 'sent-custom-question-list' ||
                    event.type === 'sent-promo' ||
                    event.type === 'sent-gift-card' ||
                    event.type === 'internal-note-added' ||
                    event.message?.automatedResponse,
                }"
              >
                <GiftCardSentEvent
                  v-if="event.type === 'sent-gift-card'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :giftCard="event.giftCard"
                  :timezone="selectUserBrowserTimezone"
                />
                <CallEvent
                  v-else-if="event.type === 'incoming-call'"
                  :id="`timeline-${event._id}`"
                  :call="event.callEvent"
                  :timezone="selectUserBrowserTimezone"
                  :location="event.location"
                />
                <NoteEvent
                  v-else-if="event.type === 'internal-note-added'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :note="event.note"
                  :user="selectUserById((event.user && event.user._id) || event.user)"
                  :timezone="selectUserBrowserTimezone"
                  :location="event.location"
                />
                <OrderEvent
                  v-else-if="event.type === 'order'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :integration="selectIntegrationById(event.integration)"
                  :location="event.location"
                  :order="event.order"
                  :timezone="selectUserBrowserTimezone"
                  :eventId="event._id"
                  :companyId="company._id"
                />
                <CampaignEvent
                  v-else-if="event.type === 'sent-campaign'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :campaign="event.campaignSetup || event.campaign"
                  :timezone="selectUserBrowserTimezone"
                />
                <CheckinEvent
                  v-else-if="event.type === 'checkin' && event.location"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :location="event.location"
                  :timezone="selectUserBrowserTimezone"
                />
                <LoyaltyEvent
                  v-else-if="event.type === 'earned-points' || event.type === 'spent-points'"
                  :id="`timeline-${event._id}`"
                  :type="event.type"
                  :created="event.created"
                  :loyalty="event.loyalty"
                  :pointLabel="company ? company.loyalty.pointLabel : null"
                  :timezone="selectUserBrowserTimezone"
                />
                <FeedbackEvent
                  v-else-if="selectActiveConversation && event.type === 'left-feedback'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :survey="event.survey"
                  :user="selectUserById((event.user && event.user._id) || event.user)"
                  :conversationMatchedSurvey="selectActiveConversation.matchedSurvey"
                  :timezone="selectUserBrowserTimezone"
                  :location="event.location"
                  :customer="event.customer"
                />
                <LeftReviewEvent
                  v-else-if="event.type === 'left-review'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :platform="getPlatformName(event)"
                  :timezone="selectUserBrowserTimezone"
                />
                <MessageEvent
                  v-else-if="
                    selectActiveConversation &&
                    (event.type === 'outgoing-message' || event.type === 'incoming-message')
                  "
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :message="event.message"
                  :user="selectUserById((event.user && event.user._id) || event.user) || event.user"
                  :conversationMatchedMessage="selectActiveConversation.matchedMessage"
                  :timezone="selectUserBrowserTimezone"
                  :customer="event.customer"
                />
                <PromoRedeemEvent
                  v-else-if="event.type === 'redeem-promo'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :promo="event.promo"
                  :customerId="
                    selectActiveConversationCustomer ? selectActiveConversationCustomer._id : null
                  "
                  :timezone="selectUserBrowserTimezone"
                />
                <PromoSentEvent
                  v-else-if="event.type === 'sent-promo'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :promo="event.promo"
                  :user="selectUserById((event.user && event.user._id) || event.user)"
                  :timezone="selectUserBrowserTimezone"
                />
                <SurveyEvent
                  v-else-if="event.type === 'left-survey'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :survey="event.survey"
                  :location="event.location || {}"
                  :timezone="selectUserBrowserTimezone"
                />
                <CompletedCustomQuestionListEvent
                  v-else-if="event.type === 'completed-custom-question-list'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :timezone="selectUserBrowserTimezone"
                />
                <SentCustomQuestionListEvent
                  v-else-if="event.type === 'sent-custom-question-list'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :timezone="selectUserBrowserTimezone"
                />
              </div>
            </div>
            <div class="timeline" v-if="tempEvents.length">
              <div v-for="event of tempEvents" :key="event._id" class="row justify-content-end">
                <MessageEventX
                  v-if="event.type === 'outgoing-message'"
                  :_id="event._id"
                  :conversationId="conversationId"
                  :id="`timeline-${event._id}`"
                />
                <NoteEvent
                  v-else-if="event.type === 'internal-note-added'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :note="event.note"
                  :user="selectUserById((event.user && event.user._id) || event.user)"
                  :timezone="selectUserBrowserTimezone"
                  :location="event.location"
                />
                <GiftCardSentEvent
                  v-else-if="event.type === 'sent-gift-card'"
                  :id="`timeline-${event._id}`"
                  :created="event.created"
                  :giftCard="event.giftCard"
                  :timezone="selectUserBrowserTimezone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'confetti-js'
import CampaignEvent from '../TimelineEvents/CampaignEvent'
import CheckinEvent from '../TimelineEvents/CheckinEvent'
import OrderEvent from '../TimelineEvents/OrderEvent'
import SurveyEvent from '../TimelineEvents/SurveyEvent'
import FeedbackEvent from '../TimelineEvents/FeedbackEvent'
import LeftReviewEvent from '../TimelineEvents/LeftReviewEvent'
import MessageEvent from '../TimelineEvents/MessageEvent'
import MessageEventX from '../TimelineEvents/MessageEventX'
import PromoRedeemEvent from '../TimelineEvents/PromoRedeemEvent'
import PromoSentEvent from '../TimelineEvents/PromoSentEvent'
import LoyaltyEvent from '../TimelineEvents/LoyaltyEvent'
import NoteEvent from '../TimelineEvents/NoteEvent'
import CallEvent from '../TimelineEvents/CallEvent'
import GiftCardSentEvent from '../TimelineEvents/GiftCardSentEvent'
import CompletedCustomQuestionListEvent from '../TimelineEvents/CompletedCustomQuestionListEvent'
import SentCustomQuestionListEvent from '../TimelineEvents/SentCustomQuestionListEvent'
import { createNamespacedHelpers, mapGetters } from 'vuex'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const TimelineModuleV2 = createNamespacedHelpers('timelineV2')
const DataCoreModule = createNamespacedHelpers('dataCore')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'Timeline',
  props: ['conversationId'],
  components: {
    CampaignEvent,
    CheckinEvent,
    FeedbackEvent,
    LeftReviewEvent,
    MessageEvent,
    MessageEventX,
    PromoRedeemEvent,
    PromoSentEvent,
    SurveyEvent,
    OrderEvent,
    LoyaltyEvent,
    CompletedCustomQuestionListEvent,
    SentCustomQuestionListEvent,
    NoteEvent,
    CallEvent,
    GiftCardSentEvent,
  },
  data: () => ({
    celebrationTimeout: null,
  }),
  computed: {
    ...TimelineModuleV2.mapGetters([
      'selectTimelineEvents',
      'selectIsWithinCelebrationTime',
      'selectTimelineLoading',
      'selectTempEvents',
    ]),
    ...ConversationModuleV2.mapGetters([
      'selectActiveConversation',
      'selectActiveConversationCustomer',
    ]),
    ...DataCoreModule.mapGetters([
      'selectCampaignById',
      'selectCompanyById',
      'selectIntegrationById',
      'selectLocationById',
      'selectUserById',
    ]),
    ...UserModule.mapGetters(['selectUserBrowserTimezone']),
    loading() {
      return this.selectTimelineLoading
    },
    company() {
      return this.selectActiveConversation
        ? this.selectCompanyById(this.selectActiveConversation.company)
        : null
    },
    shouldCelebrate() {
      return this.selectIsWithinCelebrationTime
    },
    tempEvents() {
      const conversation = this.selectActiveConversation
      if (!conversation) return []
      const conversationId = conversation._id
      return this.selectTempEvents(conversationId)
    },
  },
  methods: {
    ...TimelineModuleV2.mapActions(['setIsWithinCelebrationTime']),
    showConfetti() {
      const confettiSettings = {
        size: 1.5,
        target: 'timelineConfetti',
      }
      const confetti = new ConfettiGenerator(confettiSettings)
      confetti.render()
      return confetti
    },
    getPlatformName(event) {
      return event?.reviewSubscription?.network
    },
  },
  watch: {
    shouldCelebrate: {
      handler(value) {
        if (value) {
          const confetti = this.showConfetti()
          this.celebrationTimeout = setTimeout(() => {
            confetti.clear()
            this.setIsWithinCelebrationTime(false)
          }, 10000) // show for 10 seconds
        }
      },
    },
  },
  destroyed() {
    if (this.celebrationTimeout) {
      clearTimeout(this.celebrationTimeout)
      this.setIsWithinCelebrationTime(false)
    }
  },
}
</script>

<style scoped lang="scss">
.arrow-button {
  background: transparent !important;

  span {
    top: -2px;
    position: relative;
    opacity: 0.6;
    font-size: 18px;
    &:hover {
      opacity: 1;
    }
  }
}
.btn-pointer {
  cursor: pointer;
}
.celebration-banner {
  border: 0px;
  background-color: var(--primary);
}
</style>
