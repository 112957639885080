import Vue from 'vue'
import Vuex from 'vuex'
import ActionFilterModule from './action-filter'
import AnalyticsModule from './analytics'
import AuthenticationModule from './authentication'
import BannerModule from './banner'
import CallToTextModule from './call-to-text'
import CampaignModule from './campaign'
import CampaignSetupsModule from './campaign-setups'
import CompanyModule from './company'
import ConversationModule from './conversation'
import ConversationModuleV2 from './conversationV2'
import CustomerModule from './customer'
import CustomerProductsModule from './customer-products'
import CustomFilterModule from './custom-filter'
import CustomQuestionModule from './custom-question'
import DashboardModule from './dashboard'
import DataCoreModule from './data-core'
import ExportsModule from './exports'
import FeatureFlagModule from './feature-flag'
import GiftCardModule from './gift-card'
import GroupModule from './group'
import InsightsDetailsModule from './insights-details'
import InsightsModule from './insights'
import IntegrationModule from './integration'
import IntegrationProviderModule from './integration-provider'
import KeywordModule from './keyword'
import LocationModule from './location'
import LoyaltyModule from './loyalty'
import NavigationModule from './navigation'
import OrganizationModule from './organization'
import PromoModule from './promo'
import PromoModuleV2 from './promoV2'
import ReportModule from './report'
import ReportBuilderModule from './report-builder'
import ReviewModule from './review'
import ReviewSubscriptionModule from './review-subscription'
import RuleModule from './rule'
import S3Module from './s3'
import SettingsService from '../services/SettingsService'
import SmartTagsModule from './smart-tags'
import SmartTemplateTagsModule from './smart-tags-template'
import SubscriptionModule from './subscription'
import SurveyModule from './survey'
import TagModule from './tag'
import TemplateModule from './template'
import TimelineModule from './timeline'
import TimelineModuleV2 from './timelineV2'
import UserModule from './user'
import VendastaModule from './vendasta'
import WebsocketModule from './websocket'
import WebsocketModuleV2 from './websocketV2'
import WinbackModule from './winback'

const { v4: uuidv4 } = require('uuid')

Vue.use(Vuex)

export const storeConfig = {
  modules: {
    actionFilter: ActionFilterModule,
    analytics: AnalyticsModule,
    authentication: AuthenticationModule,
    banner: BannerModule,
    callToText: CallToTextModule,
    campaign: CampaignModule,
    campaignSetups: CampaignSetupsModule,
    company: CompanyModule,
    conversation: ConversationModule,
    conversationV2: ConversationModuleV2,
    customer: CustomerModule,
    customerProducts: CustomerProductsModule,
    customFilter: CustomFilterModule,
    customQuestion: CustomQuestionModule,
    dashboard: DashboardModule,
    dataCore: DataCoreModule,
    exports: ExportsModule,
    featureFlag: FeatureFlagModule,
    giftCard: GiftCardModule,
    group: GroupModule,
    insights: InsightsModule,
    insightsDetails: InsightsDetailsModule,
    integration: IntegrationModule,
    integrationProvider: IntegrationProviderModule,
    keyword: KeywordModule,
    location: LocationModule,
    loyalty: LoyaltyModule,
    navigation: NavigationModule,
    organization: OrganizationModule,
    promo: PromoModule,
    promoV2: PromoModuleV2,
    report: ReportModule,
    reportBuilder: ReportBuilderModule,
    review: ReviewModule,
    reviewSubscription: ReviewSubscriptionModule,
    rule: RuleModule,
    s3: S3Module,
    smartTags: SmartTagsModule,
    smartTemplateTags: SmartTemplateTagsModule,
    subscription: SubscriptionModule,
    survey: SurveyModule,
    tag: TagModule,
    template: TemplateModule,
    timeline: TimelineModule,
    timelineV2: TimelineModuleV2,
    user: UserModule,
    vendasta: VendastaModule,
    websocket: WebsocketModule,
    websocketV2: WebsocketModuleV2,
    winback: WinbackModule,
  },
  state: {
    loader: [],
    config: {},
  },
  getters: {
    selectConfigByName: (state) => (name) => {
      return state.config[name]
    },
    selectLoader: (state) => state.loader,
    selectIsLoading: (state) => (eventNames) => {
      return state.loader.find((item) => eventNames.includes(item.name)) ? true : false
    },
    selectLoadingEventByName: (state) => (name) => {
      return state.loader.find((item) => name === item.name)
    },
  },
  mutations: {
    ADD_TO_LOADER(state, loadingEvent) {
      if (!state.loader.find((item) => item.uuid === loadingEvent.uuid)) {
        state.loader = [...state.loader, loadingEvent]
      }
    },
    REMOVE_FROM_LOADER(state, eventUuid) {
      state.loader = [...state.loader.filter((item) => item.uuid !== eventUuid)]
    },
    REMOVE_FROM_LOADER_BY_NAME(state, eventNames) {
      state.loader = [...state.loader.filter((item) => !eventNames.includes(item.name))]
    },
    RESET_CONFIG(state) {
      state.config = {}
    },
    RESET_LOADER(state) {
      state.loader = []
    },
    SET_CONFIG(state, config) {
      state.config = config
    },
  },
  actions: {
    addToLoader({ commit }, loadingEvent) {
      loadingEvent.uuid = uuidv4()
      commit('ADD_TO_LOADER', loadingEvent)
      return loadingEvent.uuid
    },
    removeFromLoader({ commit }, eventUuid) {
      commit('REMOVE_FROM_LOADER', eventUuid)
    },
    removeFromLoaderByName({ commit }, eventNames) {
      commit('REMOVE_FROM_LOADER_BY_NAME', eventNames)
    },
    resetLoader({ commit }) {
      commit('RESET_LOADER')
    },
    resetSettings({ commit }) {
      commit('RESET_CONFIG')
    },
    async setConfig({ commit, dispatch }) {
      try {
        const result = await SettingsService.getSettings()
        commit('SET_CONFIG', result.body.data.config)
        dispatch('s3/init')
      } catch (error) {
        // ignore error
      }
    },
  },
}

export default new Vuex.Store(storeConfig)
