import RuleService from '@/services/RuleService'

export default {
  namespaced: true,
  state: {
    rulesByCompany: {},
  },
  getters: {
    selectRules: (state, getters, rootState, rootGetters) => (id) => {
      const companyId = id || rootGetters['company/activeCompany']._id
      return state.rulesByCompany[companyId] || []
    },
  },
  mutations: {
    SET_RULES(state, [companyId, rules]) {
      state.rulesByCompany = { ...state.rulesByCompany, [companyId]: rules }
    },
  },
  actions: {
    async getRules({ commit, rootGetters }, company) {
      const companyId = company || rootGetters['company/activeCompany']._id
      const response = await RuleService.list({ companyIds: [companyId] })
      commit('SET_RULES', [companyId, response.body.data])
    },

    async persistRule({ rootGetters, dispatch }, data) {
      let response
      const payload = {
        _id: data._id,
        companyId: data.company,
        locationIds: data.locations,
        type: data.type,
        userIds: data.users,
        sources: data?.sources,
      }
      if (payload._id) {
        // update
        response = await RuleService.update(payload._id, payload)
      } else {
        // create
        const companyId = rootGetters['company/activeCompany']._id
        payload.type = data?.type || 'ESCALATION'
        payload.companyId = companyId
        response = await RuleService.create(payload)
      }
      await dispatch('getRules')
    },

    async deleteRule({ dispatch }, ruleId) {
      await RuleService.deleteRule(ruleId)
      await dispatch('getRules')
    },
  },
}
