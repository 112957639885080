const ANALYTIC_EVENT_TYPES = {
  CHOOSE_ACTION: 'CHOOSE_ACTION',
  LEFT_SURVEY: 'LEFT_SURVEY',
  LEFT_FEEDBACK: 'LEFT_FEEDBACK',
  LEFT_REVIEW: 'LEFT_REVIEW',
  SENT_CAMPAIGN: 'SENT_CAMPAIGN',
  COME_FROM: 'COME_FROM',
  REDEEM_PROMO: 'REDEEM_PROMO',
}

const ANALYTIC_EVENT_TYPES_V2 = {
  CHOOSE_ACTION: 'choose-action',
  LEFT_SURVEY: 'left-survey',
  LEFT_FEEDBACK: 'left-feedback',
  LEFT_REVIEW: 'left-review',
  SENT_CAMPAIGN: 'sent-campaign',
  REDEEM_PROMO: 'redeem-promo',
}

export default {
  namespaced: true,
  state: {
    FILTER_KEYS: {
      DID: 'did',
      EVENT: 'eventType',
      CAMPAIGN: 'campaignId',
      SOURCE: 'source',
      PROMO: 'promoId',
    },
    ANALYTIC_EVENT_TYPES: { ...ANALYTIC_EVENT_TYPES },
    ANALYTIC_EVENT_TYPES_V2: { ...ANALYTIC_EVENT_TYPES_V2 },
    ANALYTIC_EVENTS: [
      { name: 'Leave a survey', type: ANALYTIC_EVENT_TYPES.LEFT_SURVEY },
      { name: 'Leave feedback', type: ANALYTIC_EVENT_TYPES.LEFT_FEEDBACK },
      { name: 'Leave a review', type: ANALYTIC_EVENT_TYPES.LEFT_REVIEW },
      { name: 'Receive campaign', type: ANALYTIC_EVENT_TYPES.SENT_CAMPAIGN },
      //{ name: 'Come from', type: ANALYTIC_EVENT_TYPES.COME_FROM },
      { name: 'Redeem offer', type: ANALYTIC_EVENT_TYPES.REDEEM_PROMO },
    ],
    ANALYTIC_EVENTS_V2: [
      { name: 'Leave a survey', type: ANALYTIC_EVENT_TYPES_V2.LEFT_SURVEY },
      { name: 'Leave feedback', type: ANALYTIC_EVENT_TYPES_V2.LEFT_FEEDBACK },
      { name: 'Leave a review', type: ANALYTIC_EVENT_TYPES_V2.LEFT_REVIEW },
      { name: 'Receive campaign', type: ANALYTIC_EVENT_TYPES_V2.SENT_CAMPAIGN },
      { name: 'Redeem offer', type: ANALYTIC_EVENT_TYPES_V2.REDEEM_PROMO },
    ],
    FILTER_STATUSES: {
      CHOOSE_ACTION: 'CHOOSE_ACTION',
      CHOOSE_CAMPAIGN: ANALYTIC_EVENT_TYPES.SENT_CAMPAIGN,
      CHOOSE_SOURCE: ANALYTIC_EVENT_TYPES.COME_FROM,
      CHOOSE_PROMO: ANALYTIC_EVENT_TYPES.REDEEM_PROMO,
    },
    FILTER_STATUSES_V2: {
      CHOOSE_ACTION: 'CHOOSE_ACTION',
      CHOOSE_CAMPAIGN: ANALYTIC_EVENT_TYPES_V2.SENT_CAMPAIGN,
      CHOOSE_SOURCE: ANALYTIC_EVENT_TYPES_V2.COME_FROM,
      CHOOSE_PROMO: ANALYTIC_EVENT_TYPES_V2.REDEEM_PROMO,
    },
  },
  getters: {
    selectFilterKeys: (state) => state.FILTER_KEYS,
    selectAnalyticEventTypes: (state) => state.ANALYTIC_EVENT_TYPES,
    selectAnalyticEventTypesV2: (state) => state.ANALYTIC_EVENT_TYPES_V2,
    selectAnalyticEvents: (state) => state.ANALYTIC_EVENTS,
    selectAnalyticEventsV2: (state) => state.ANALYTIC_EVENTS_V2,
    selectFilterStatuses: (state) => state.FILTER_STATUSES,
    selectFilterStatusesV2: (state) => state.FILTER_STATUSES_V2,
  }
}
