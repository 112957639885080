<template>
  <div class="card draggable" :class="{ disabled }">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto text-center platform-content">
          <a href="#!" class="avatar avatar-md">
            <img :src="platformDetails.img" class="avatar-img rounded" />
          </a>
        </div>
        <div class="col platform-content">
          <h4 class="card-title pt-2">
            <a href="#!">{{ platformDetails.name }}</a>
          </h4>
          <p v-if="platformRating" class="card-text small mb-0">
            <StarRating :rating="platformRating" />
            {{ `${platformRating} (${platformReviewCount})` }}
          </p>
        </div>
        <div class="col-auto d-flex align-items-center">
          <div v-if="reviewSubscription.googleBusinessProfileId">
            <i class="fe fe-check-circle text-success"></i>
          </div>
          <!-- Dropdown -->
          <div class="dropdown">
            <a
              href="#!"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
            >
              <i class="fe fe-more-vertical"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(17px, 25px, 0px);
              "
            >
              <a
                v-if="platformDetails.reviewUrl"
                :href="platformDetails.reviewUrl"
                class="dropdown-item"
                target="_blank"
              >
                Open review page
              </a>
              <a
                @click="editGoogle"
                href="javascript:;"
                class="dropdown-item"
                v-if="platform.network === 'google'"
              >
                {{
                  reviewSubscription.googleBusinessProfileId
                    ? 'Edit Business Profile ID'
                    : 'Add Business Profile ID'
                }}
              </a>
              <a @click="toggleEnabled" href="javascript:;" class="dropdown-item">
                {{ disabled ? 'Enable' : 'Disable' }}
              </a>
              <a @click="toggleHidden" href="javascript:;" class="dropdown-item">
                {{ hidden ? 'Show on review request page' : 'Hide from review request page' }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GoogleProfileIdModal
      ref="googleIdModal"
      @save="updateLocationGoogleId"
      :location="selectActiveLocation"
      :reviewSubscription="reviewSubscription"
      v-if="platform.network === 'google'"
    />
  </div>
</template>

<script>
import StarRating from '@/components/Modules/Review/StarRating'
import GoogleProfileIdModal from '@/components/Modals/GoogleProfileIdModal'
import { getPlatformDetails } from '@/lib/modules/platform'
import { createNamespacedHelpers } from 'vuex'
const reviewSubscriptionModule = createNamespacedHelpers('reviewSubscription')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'LocationSettingPlatformCard',
  props: ['platform'],
  components: {
    StarRating,
    GoogleProfileIdModal,
  },
  data() {
    return {
      disabled: this.platform.disabled,
      hidden: this.platform.hidden,
    }
  },
  computed: {
    ...LocationModule.mapGetters(['selectActiveLocation', 'selectSubscriptionIndex']),
    platformDetails() {
      return getPlatformDetails(this.reviewSubscription)
    },
    platformRating() {
      return this.reviewSubscription.globalRating
    },
    platformReviewCount() {
      return this.reviewSubscription?.reviewCount?.available?.total
    },
    reviewSubscription() {
      return this.platform.reviewSubscription
    },
  },
  methods: {
    ...reviewSubscriptionModule.mapActions([
      'disableReviewSubscription',
      'enableReviewSubscription',
    ]),
    ...LocationModule.mapActions([
      'addGoogleBusinessProfileId',
      'updateLocation',
      'updateSubscriptionHidden',
    ]),
    editGoogle() {
      this.$refs.googleIdModal.open()
    },
    updateLocationGoogleId(profileId) {
      this.addGoogleBusinessProfileId({
        locationId: this.selectActiveLocation._id,
        reviewSubscription: this.reviewSubscription._id,
        googleBusinessProfileId: profileId,
      })
    },
    async toggleEnabled() {
      this.disabled = !this.disabled

      if (this.disabled === true) {
        await this.disableReviewSubscription({
          reviewSubscriptionId: this.reviewSubscription._id,
          locationId: this.selectActiveLocation._id,
        })
      } else if (this.disabled === false) {
        await this.enableReviewSubscription({
          reviewSubscriptionId: this.reviewSubscription._id,
          locationId: this.selectActiveLocation._id,
        })
      }

      this.$notify({
        title: 'Platform updated',
        text: `${this.platformDetails.name} has been ${this.disabled ? 'disabled' : 'enabled'}.`,
      })
    },
    async toggleHidden() {
      const index = this.selectActiveLocation?.subscriptions?.findIndex(
        (s) => s._id === this.platform._id
      )
      const status = !!!this.platform.hidden
      await this.updateSubscriptionHidden({ index, status })
      this.hidden = status
      await this.updateLocation({
        locationId: this.selectActiveLocation._id,
        fields: {
          [`subscriptions.${index}.hidden`]: status,
        },
      })
      this.$notify({
        title: 'Platform updated',
        text: `${this.platformDetails.name} will now ${this.hidden ? 'be hidden' : 'show'}.`,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.card.disabled .platform-content {
  opacity: 0.3;
  transition: all linear 100ms;
}

.card.draggable {
  cursor: pointer;
}
</style>
