// Platform data
import Config from '@/config'
import store from '@/store'
import { convertS3toAssetUrl } from '../format'

const TYPES = {
  POLL: 'poll',
  WEBHOOK: 'webhook',
}

const INTEGRATIONS = {
  citygro: () => ({
    type: TYPES.POLL,
    provider: 'citygro',
    name: 'CityGro',
    description: 'Send surveys after check-in.',
    img: `/img/integrations/citigro.png`,
    inputs: [
      {
        name: 'token',
        label: 'API Token',
        type: 'text',
      },
      {
        name: 'accountId',
        label: 'Account ID',
        type: 'text',
      },
    ],
  }),
  skipTheDishes: () => ({
    type: TYPES.POLL,
    provider: 'skipTheDishes',
    name: 'Skip the Dishes',
    description: 'Send surveys after delivery.',
    img: `/img/integrations/skipthedishes.png`,
    inputs: [
      {
        name: 'username',
        label: 'Username',
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        type: 'password',
      },
      {
        name: 'restaurantId',
        label: 'Restaurant ID',
        type: 'text',
      },
    ],
  }),
  olo: () => ({
    type: TYPES.WEBHOOK,
    provider: 'olo',
    name: 'Olo',
    description: 'Send surveys after pickup/delivery',
    img: '/img/integrations/olo.png',
    isOrg: {
      name: 'isOrg',
      label: 'Is it a Franchise',
      type: 'checkbox',
    },
    clipboards: [
      {
        name: 'sandbox.destinationUrl',
        label: 'Sandbox Destination Url',
        type: 'clipboard',
        urlWithOrgId: getOrgSandboxWebhookUrl('olo'),
        urlWithCompanyId: getSandboxWebhookUrl('olo'),
      },
      {
        name: 'production.destinationUrl',
        label: 'Production Destination Url',
        type: 'clipboard',
        urlWithOrgId: getOrgProdWebhookUrl('olo'),
        urlWithCompanyId: getProdWebhookUrl('olo'),
      },
    ],
    inputs: [
      {
        name: 'production.sharedSecretKey',
        label: 'Production Shared Secret Key',
        type: 'text',
      },
      {
        name: 'sandbox.sharedSecretKey',
        label: 'Sandbox Shared Secret Key',
        type: 'text',
      },
      {
        name: 'storeNumber',
        label: 'Store Number',
        type: 'text',
      },
    ],
  }),
  revel: () => ({
    type: TYPES.WEBHOOK,
    provider: 'revel',
    name: 'Revel',
    description: 'Send surveys after order completed',
    img: '/img/integrations/revel.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Webhook Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('revel'),
      },
    ],
    inputs: [
      {
        name: 'apiDomain',
        label: 'API Domain',
        type: 'text',
      },
      {
        name: 'apiKey',
        label: 'API Key',
        type: 'text',
      },
      {
        name: 'apiSecret',
        label: 'API Secret',
        type: 'text',
      },
      {
        name: 'webhookSecretKey',
        label: 'Webhook Secret Key',
        type: 'text',
      },
      {
        name: 'establishmentId',
        label: 'Establishment ID',
        type: 'text',
      },
    ],
  }),
  menufy: () => ({
    type: TYPES.WEBHOOK,
    provider: 'menufy',
    name: 'Menufy',
    description: 'Send surveys after order completed',
    img: '/img/integrations/menufy.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Webhook Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('menufy'),
      },
    ],
    inputs: [
      {
        name: 'webhookKey',
        label: 'Webhook Key',
        type: 'text',
      },
      {
        name: 'menufyLocationId',
        label: 'Menufy Location ID',
        type: 'text',
      },
    ],
  }),
  hungerRush: () => ({
    type: TYPES.WEBHOOK,
    provider: 'hungerRush',
    name: 'HungerRush',
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/hungerrush.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('hungerRush'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  grubhub: () => ({
    type: TYPES.WEBHOOK,
    provider: 'grubhub',
    name: 'Grubhub',
    description: 'Send surveys after order delivery',
    img: '/img/integrations/grubhub.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('grubhub'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  toast: () => ({
    type: TYPES.WEBHOOK,
    provider: 'toast',
    name: 'Toast',
    description: 'Send surveys after order completed',
    img: '/img/integrations/toast.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('toast'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  lunchbox: () => ({
    type: TYPES.WEBHOOK,
    provider: 'lunchbox',
    name: 'Lunchbox',
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/lunchbox.jpeg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Webhook Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('lunchbox'),
      },
    ],
    inputs: [
      {
        name: 'secretKey',
        label: 'Secret Key',
        type: 'text',
      },
      {
        name: 'externalId',
        label: 'External ID',
        type: 'text',
      },
      {
        name: 'appId',
        label: 'App ID (Required for Ghost Kitchen only)',
        type: 'text',
      },
    ],
  }),
  bbot: () => ({
    type: TYPES.WEBHOOK,
    provider: 'bbot',
    name: 'Bbot',
    description: 'Send surveys after order completed',
    img: '/img/integrations/bbot.jpg',
  }),
  hngr: () => ({
    type: TYPES.WEBHOOK,
    provider: 'hngr',
    name: 'Hngr',
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/hngr.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('hngr'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  wackys: () => ({
    type: TYPES.WEBHOOK,
    provider: 'wackys',
    name: `Wacky's`,
    description: 'Send surveys after order completed',
    img: '/img/integrations/wackys.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('wackys'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  goParrot: () => ({
    type: TYPES.WEBHOOK,
    provider: 'goParrot',
    name: `GoParrot`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/goparrot.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('goparrot'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  freshop: () => ({
    type: TYPES.WEBHOOK,
    provider: 'freshop',
    name: `Freshop`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/freshop.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('freshop'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  silverware: () => ({
    type: TYPES.WEBHOOK,
    provider: 'silverware',
    name: `Silverware`,
    description: 'Send surveys after order completed',
    img: '/img/integrations/silverware.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('silverware'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  say2eat: () => ({
    type: TYPES.WEBHOOK,
    provider: 'say2eat',
    name: `Say2eat`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/say2eat.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('say2eat'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  chowNow: () => ({
    type: TYPES.WEBHOOK,
    provider: 'chowNow',
    name: `ChowNow`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/chownow.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('chownow'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  ritual: () => ({
    type: TYPES.WEBHOOK,
    provider: 'ritual',
    name: `Ritual`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/ritual.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('ritual'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  '9Fold': () => ({
    type: TYPES.WEBHOOK,
    provider: '9Fold',
    name: `9Fold`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/9fold.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('9fold'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  flipdish: () => ({
    type: TYPES.WEBHOOK,
    provider: 'flipdish',
    name: `Flipdish`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/flipdish.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('flipdish'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  slice: () => ({
    type: TYPES.WEBHOOK,
    provider: 'slice',
    name: `Slice`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/slice.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('slice'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'To Email',
        type: 'text',
      },
    ],
  }),
  silverMineSubs: () => ({
    type: TYPES.WEBHOOK,
    provider: 'silverMineSubs',
    name: `Silver Mine Subs`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/silverminesubs.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('silverminesubs'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  ally: () => ({
    type: TYPES.WEBHOOK,
    provider: 'ally',
    name: `Ally`,
    description: 'Send surveys after order is delivered',
    img: '/img/integrations/ally.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('ally'),
      },
    ],
    inputs: [
      {
        name: 'locationId',
        label: 'Location ID',
        type: 'text',
      },
    ],
  }),
  rrt: () => ({
    type: TYPES.WEBHOOK,
    provider: 'rrt',
    name: `RRT`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/rrt.jpg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('rrt'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  como: () => ({
    type: TYPES.WEBHOOK,
    provider: 'como',
    name: `COMO`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/como.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('como'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  openDining: () => ({
    type: TYPES.WEBHOOK,
    provider: 'openDining',
    name: `Open Dining`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/opendining.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('opendining'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  activeMenus: () => ({
    type: TYPES.WEBHOOK,
    provider: 'activeMenus',
    name: `Active Menus`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/activeMenus.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('activemenus'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),

  craver: () => ({
    type: TYPES.WEBHOOK,
    provider: 'craver',
    name: `Craver`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/craver.jpeg',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('craver'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  appFront: () => ({
    type: TYPES.WEBHOOK,
    provider: 'appFront',
    name: `Appfront`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Appfront.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('appfront'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  reef: () => ({
    type: TYPES.WEBHOOK,
    provider: 'reef',
    name: `REEF`,
    description: 'Send surveys after order pickup/delivery',
    img: 'https://firebasestorage.googleapis.com/v0/b/ovation-v2.appspot.com/o/uploads%2Fug2fl.jpeg?alt=media&token=c48a9e6d-10a2-4edf-b9f3-798add56df77',
    clipboards: [],
    inputs: [],
  }),
  squareOnlineOrdering: () => ({
    type: TYPES.WEBHOOK,
    provider: 'squareOnlineOrdering',
    name: `Square Online Ordering`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Square.webp',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('squareonlineordering'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  bentoBox: () => ({
    type: TYPES.WEBHOOK,
    provider: 'bentoBox',
    name: `Bentobox`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/bentobox.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('bentobox'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  incentivio: () => ({
    type: TYPES.WEBHOOK,
    provider: 'incentivio',
    name: `Incentivio`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/incentivio.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('incentivio'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  owner: () => ({
    type: TYPES.WEBHOOK,
    provider: 'owner',
    name: `Owner`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Owner.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('owner'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
    ],
  }),
  thrive: () => ({
    type: TYPES.WEBHOOK,
    provider: 'thrive',
    name: `Thrive`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Thrive.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('thrive'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  giveX: () => ({
    type: TYPES.WEBHOOK,
    provider: 'giveX',
    name: `GiveX`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/GiveX.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('givex'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'storeNumber',
        label: 'Store Number',
        type: 'number',
      },
    ],
  }),
  togoTech: () => ({
    type: TYPES.WEBHOOK,
    provider: 'togoTech',
    name: `ToGo Tech`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/TogoTech.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('togotech'),
      },
    ],
    inputs: [
      {
        name: 'fromEmail',
        label: 'From Email',
        type: 'text',
      },
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  ordereze: () => ({
    type: TYPES.WEBHOOK,
    provider: 'ordereze',
    name: `Ordereze`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Ordereze.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('ordereze'),
      },
    ],
    inputs: [],
  }),
  brygid: () => ({
    type: TYPES.WEBHOOK,
    provider: 'brygid',
    name: `BRYGID`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/BRYGID.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('brygid'),
      },
    ],
    inputs: [
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  chkplz: () => ({
    type: TYPES.WEBHOOK,
    provider: 'chkplz',
    name: `CHK PLZ`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/CHKPLZ.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('chkplz'),
      },
    ],
    inputs: [
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  ondemand: () => ({
    type: TYPES.WEBHOOK,
    provider: 'ondemand',
    name: `OnDemand`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Ondemand.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('ondemand'),
      },
    ],
    inputs: [
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  aloha: () => ({
    type: TYPES.WEBHOOK,
    provider: 'aloha',
    name: `Aloha`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Aloha.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('aloha'),
      },
    ],
    inputs: [
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        type: 'text',
      },
    ],
  }),
  bonchon: () => ({
    type: TYPES.WEBHOOK,
    provider: 'bonchon',
    name: `Bonchon Webform`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Bonchon.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('bonchon'),
      },
    ],
    inputs: [
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  popMenu: () => ({
    type: TYPES.WEBHOOK,
    provider: 'popMenu',
    name: `Pop Menu`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/PopMenu.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('popmenu'),
      },
    ],
    inputs: [
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  360: () => ({
    type: TYPES.WEBHOOK,
    provider: '360',
    name: `360`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/imenu360.png',
    clipboards: [],
    inputs: [],
  }),
  captain: () => ({
    type: TYPES.WEBHOOK,
    provider: 'captain',
    name: `Captain`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/captain.png',
    clipboards: [],
    inputs: [],
  }),
  glyde: () => ({
    type: TYPES.WEBHOOK,
    provider: 'glyde',
    name: `Glyde`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/glyde.png',
    clipboards: [],
    inputs: [],
  }),
  ambassador: () => ({
    type: TYPES.WEBHOOK,
    provider: 'ambassador',
    name: `Ambassador`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/Ambassador.png',
    clipboards: [
      {
        name: 'webhookUrl',
        label: 'Endpoint Url',
        type: 'clipboard',
        urlWithCompanyId: getProdWebhookUrl('ambassador'),
      },
    ],
    inputs: [
      {
        name: 'locationName',
        label: 'Location Name',
        type: 'text',
      },
    ],
  }),
  Auphan: () => ({
    type: TYPES.WEBHOOK,
    provider: 'Auphan',
    name: `Auphan`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/auphan.png',
    clipboards: [],
    inputs: [],
  }),
  Moduurn: () => ({
    type: TYPES.WEBHOOK,
    provider: 'Moduurn',
    name: `Moduurn`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/moduurn.png',
    clipboards: [],
    inputs: [],
  }),
  Dominos: () => ({
    type: TYPES.WEBHOOK,
    provider: 'Dominos',
    name: `Domino's`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/dominos_ca.png',
    clipboards: [],
    inputs: [],
  }),
  kitchenUnited: () => ({
    type: TYPES.WEBHOOK,
    provider: 'kitchenUnited',
    name: `Kitchen United`,
    description: 'Send surveys after order pickup/delivery',
    img: '/img/integrations/kitchenUnited.png',
    clipboards: [],
    inputs: [],
  }),
}

export function getIntegrationDetails(integrationProviderName) {
  if (INTEGRATIONS[integrationProviderName]) {
    return INTEGRATIONS[integrationProviderName]()
  } else {
    const integrationProvider =
      store.getters['integrationProvider/selectIntegrationProviderByName'](
        integrationProviderName
      ) ??
      store.getters['integrationProvider/selectDisabledIntegrationProviderByName'](
        integrationProviderName
      )
    if (integrationProvider) {
      return {
        ...integrationProvider,
        provider: integrationProviderName,
        img: convertS3toAssetUrl(integrationProvider.logo),
      }
    }
  }
}

function getNewProdWebhookUrl(provider) {
  return (companyId) => `https://api.ovationup/integrations/${provider}/${companyId}`
}

function getProdWebhookUrl(provider) {
  return (companyId) => `${Config.api}/webhook/${provider}/${companyId}`
}

function getSandboxWebhookUrl(provider) {
  return (companyId) => `${Config.sandboxApi}/webhook/${provider}/${companyId}`
}

function getOrgProdWebhookUrl(provider) {
  return (orgId) => `${Config.api}/webhook/${provider}/org/${orgId}`
}

function getOrgSandboxWebhookUrl(provider) {
  return (orgId) => `${Config.sandboxApi}/webhook/${provider}/org/${orgId}`
}

const filtered = { ...INTEGRATIONS }
delete filtered.Dominos

export const availableIntegrations = filtered
