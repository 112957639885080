<template>
  <div id="app">
    <FadeTransition>
      <!-- <Nav v-if="!$route.meta.hideNav" /> -->
    </FadeTransition>
    <FadeTransition>
      <FullLoader v-if="!initialized" />
      <router-view v-if="initialized" />
    </FadeTransition>
    <notifications />
    <CompanyLegalModal />
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav'
import FadeTransition from '@/components/Transitions/FadeTransition'
import FullLoader from '@/components/MainContent/FullLoader'
import UserService from '@/services/UserService'
import CompanyLegalModal from '@/components/Modals/CompanyLegalModal'
import Config from '@/config'
import { initRouterView } from '@/router'
import { createNamespacedHelpers } from 'vuex'
import { removeUserActiveCompanyId, setUserActiveCompanyId } from '@/store/company'

const AuthenticationModule = createNamespacedHelpers('authentication')
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const VendastaModule = createNamespacedHelpers('vendasta')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const WebsocketModule = createNamespacedHelpers('websocket')
const WebsocketModuleV2 = createNamespacedHelpers('websocketV2')
const DataCoreModule = createNamespacedHelpers('dataCore')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')

export default {
  name: 'App',
  components: {
    Nav,
    FadeTransition,
    FullLoader,
    CompanyLegalModal,
  },
  data: () => ({
    companyId: null,
    initialized: false,
  }),
  computed: {
    ...AuthenticationModule.mapGetters(['selectAuthUser', 'selectUnauthorized']),
    ...CompanyModule.mapGetters([
      'activeCompany',
      'selectCompanyLegalModalShown',
      'selectCompanies',
    ]),
    ...WebsocketModule.mapState(['connection']),
    ...UserModule.mapState(['onlineUser', 'WSInstance']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds', 'selectActiveUserId']),
    ...WebsocketModuleV2.mapGetters(['selectPusher']),
    readyForWebsocketConnection() {
      return (
        this.selectActiveCompanyIds.length &&
        this.selectActiveUserId != null &&
        localStorage.getItem('jwt') != null
      )
    },
    needToShowCompanyLegalModal() {
      if (
        this.activeCompany &&
        this.activeCompany._id !== 'ALL_COMPANIES' &&
        !this.selectCompanyLegalModalShown &&
        this.onlineUser.role === 'admin'
      ) {
        return (
          !this.activeCompany.businessName ||
          !this.activeCompany.corporateWebsite ||
          !this.activeCompany.stateOfIncorporation ||
          !this.activeCompany.businessAddress1 ||
          !this.activeCompany.city ||
          !this.activeCompany.state ||
          !this.activeCompany.zip
        )
      }
      return false
    },
  },
  watch: {
    activeCompany: {
      async handler(val, oldVal) {
        this.setActiveCompanyIds(val)
        if (val && !oldVal) {
          await this.getFeatureFlags()
          this.pusherConnect()
        }
        this.showCompanyLegalModal()
        this.setUnresolvedCount()
      },
    },
    onlineUser: {
      handler(val) {
        this.setActiveUserId(val)
      },
    },
    $route: {
      handler: function (val) {
        this.setActiveRoute(val)
      },
    },
    readyForWebsocketConnection(val) {
      if (val) {
        this.connect()
      } else {
        this.disconnect()
      }
    },
    async selectUnauthorized(val) {
      if (val === true) {
        await this.resetAuth()
        if (this.onlineUser) {
          removeUserActiveCompanyId(this.onlineUser._id)
        }
        localStorage.removeItem('jwt')
        localStorage.removeItem('isOvationAdmin')
      }
    },
  },
  methods: {
    ...AuthenticationModule.mapActions(['resetAuth', 'createAuthEventBus', 'setUnauthorized']),
    ...CompanyModule.mapActions([
      'setCompanyLegalModalShown',
      'setActiveCompany',
      'setAllCompanies',
    ]),
    ...WebsocketModule.mapActions(['connect', 'disconnect']),
    ...WebsocketModuleV2.mapActions(['pusherConnect', 'pusherDisconnect']),
    ...VendastaModule.mapActions([
      'initVendastaNavbar',
      'isVendastaUser',
      'isVendastaRequest',
      'initVendastaSSO',
    ]),
    ...FeatureFlagModule.mapActions(['getFeatureFlags']),
    ...DataCoreModule.mapActions(['setActiveCompanyIds', 'setActiveUserId', 'setActiveRoute']),
    ...ConversationModuleV2.mapActions(['setUnresolvedCount']),
    async initSSO() {
      const searchParams = new URLSearchParams(window.location.search)
      const ssoToken = searchParams.get('ssoToken')
      this.companyId = searchParams.get('cid')

      if (ssoToken) {
        try {
          await this.resetAuth()
          const response = await UserService.ssoLogin({ ssoToken })
          const { isOvationAdmin, jwt } = response.body

          localStorage.setItem('isOvationAdmin', isOvationAdmin ? 'yes' : 'no')
          localStorage.setItem('jwt', jwt)
        } catch (err) {
          console.log(err)
        }
      }
    },
    async vendastaWorkflow() {
      if (localStorage.getItem('isVendastaUser') === 'yes') {
        await this.isVendastaUser()
      } else if (await this.isVendastaRequest()) {
        await this.initVendastaSSO()
      } else {
        return
      }
      // it's a vendasta user or request; initialize the navbar
      this.initVendastaNavbar()
    },
    showCompanyLegalModal() {
      if (this.needToShowCompanyLegalModal) {
        this.setCompanyLegalModalShown(true)
        this.$bvModal.show('companyLegalModal')
      }
    },
    async initializeApp() {
      this.setUnauthorized(false)
      await this.vendastaWorkflow()
      await this.initSSO()
      await initRouterView(this)
      this.initialized = true
      this.showCompanyLegalModal()
      await this.pusherConnect()
    },
    async startInitialization() {
      await this.createAuthEventBus(this.initializeApp)
      document.querySelector('body').style.overscrollBehavior = 'none'
    },
    async handleIncomingMessage(event) {
      const allowableOrigins = Config.isProduction
        ? ['https://app.ovationup.com']
        : Config.isStaging
        ? ['https://app-staging.ovationup.com']
        : Config.isLocal
        ? ['http://localhost:5173', 'http://localhost:4173']
        : ['https://app-test.ovationup.com']
      if (!allowableOrigins.includes(event.origin)) {
        return
      }
      if (event.data.jwt) {
        window.localStorage.setItem('jwt', event.data.jwt)
      }

      if (event.data.initialize) {
        this.startInitialization()
      }

      if (event.data.path && event.data.path !== this.$route.fullPath) {
        const splitEventPath = event.data.path.split(/[\?\&]+/)
        const isNonRedirect = splitEventPath.includes('preventRedirect=true')
        if (!isNonRedirect) {
          this.$router.replace(event.data.path)
        }
      }

      if (event.data.initialCompanyId && event.data.userId) {
        setUserActiveCompanyId(event.data.userId, event.data.initialCompanyId)
      }

      if (event.data.companyId) {
        if (event.data.companyId == 'ALL_COMPANIES') {
          this.setAllCompanies()
        } else {
          const company = this.selectCompanies.find((c) => c._id === event.data.companyId)
          this.setActiveCompany(company)
        }
      }
    },
  },
  async mounted() {
    if (process.env.NODE_ENV !== 'headless') {
      window.addEventListener('message', this.handleIncomingMessage)
    } else {
      // can set VUE_APP_ACTIVE_COMPANY_ID and VUE_APP_TEMP_JWT in commandline to control iframe locally
      window.localStorage.setItem('jwt', process.env.VUE_APP_TEMP_JWT)
      this.startInitialization()
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleIncomingMessage)
    if (this.WSInstance) {
      this.WSInstance.close()
    }
    if (this.selectPusher) {
      this.pusherDisconnect()
    }
  },
}
</script>

<style lang="scss">
@import 'App.scss';
</style>
