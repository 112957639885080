<template>
  <div class="row">
    <LocationsSettingsDevicesOffstate :activationCode="activeLocation.activationCode" />
    <div class="col" v-if="activeLocation.devices.length">
      <h3 class="mb-0 mt-3">Active Devices</h3>
      <hr />
    </div>
    <div class="col-12">
      <LocationSettingsDevicesCard
        class="col-12"
        v-for="device in activeLocation.devices"
        v-bind:key="device._id"
        :device="device"
      />
    </div>
  </div>
</template>

<script>
import LocationSettingsBase from '../LocationSettingsBase'
import LocationSettingsDevicesCard from './LocationSettingsDevicesCard'
import LocationsSettingsDevicesOffstate from './LocationsSettingsDevicesOffstate'
export default {
  name: 'LocationSettingsDevices',
  extends: LocationSettingsBase,
  components: {
    LocationsSettingsDevicesOffstate,
    LocationSettingsDevicesCard,
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
