import TagService from '@/services/TagService'
import { cloneDeep, uniqBy } from 'lodash-es'

const MATERIALS_STATUS = {
  idle: 'idle',
  downloading: 'downloading',
  ready: 'ready',
  failed: 'failed',
}

const WS_EVENT_TYPES = {
  TagCreated: 'tag-created',
  TagMaterialsRequested: 'tag-materials-requested',
  TagMaterialsReady: 'tag-materials-ready',
  TagMaterialsFailed: 'tag-materials-failed',
}

export default {
  namespaced: true,
  state: {
    tags: [],
    currentTags: [],
    originalTags: [],
    campaignId: null,
  },
  mutations: {
    setTags(state, tags) {
      state.tags = [...tags]
    },
    setOrginalTags(state, tags) {
      state.originalTags = [...tags]
    },
    addTag(state, tag) {
      const existed = state.originalTags.find((t) => t._id === tag._id)

      if (!existed) {
        state.originalTags = [tag, ...state.originalTags]
        state.tags = cloneDeep(state.originalTags)
      }
    },
    cacheCurrentTag(state, tagIds) {
      state.currentTags = uniqBy([...state.currentTags, ...tagIds], (id) => id)
    },
    updateTag(state, tag) {
      state.tags = state.tags.map((t) => (t._id === tag._id ? tag : t))
      state.originalTags = cloneDeep(state.tags)
    },
    removeTag(state, tagId) {
      state.tags = state.tags.filter((t) => t._id !== tagId)
      state.originalTags = cloneDeep(state.tags)
    },
    setCampaignId(state, id) {
      state.campaignId = id
    },
  },
  getters: {
    externalTags(state) {
      return state.tags.filter((t) => !Boolean(t.internal))
    },
    companyTags(state, _, rootState) {
      const { activeCompany, companies } = rootState.company
      let integrationTags

      if (activeCompany._id === 'ALL_COMPANIES') {
        integrationTags = companies?.reduce((acc, cur) => [...acc, ...cur.integrations], [])
      } else {
        integrationTags = (activeCompany.integrations || []).map((i) => i.provider?.toLowerCase())
      }

      return state.tags.filter(
        (t) =>
          !Boolean(t.internal) ||
          state.currentTags.includes(t._id) ||
          integrationTags.includes(t.name.toLowerCase())
      )
    },
    tagsMap(state) {
      return state.tags.reduce((acc, cur) => ({ ...acc, [cur._id]: cur }), {})
    },
    selectCompanyTags: (state, getters) => getters.companyTags,
  },
  actions: {
    async getTags({ commit, rootState }, { archived = false } = {}) {
      const { activeCompany, companies } = rootState.company
      const payload = { archived }

      if (!activeCompany) return
      if (activeCompany._id === 'ALL_COMPANIES') {
        payload.companyIds = companies?.map((c) => c._id)
      } else {
        payload.companyId = activeCompany._id
      }

      const {
        body: { tags },
      } = await TagService.list(payload)

      commit('setTags', tags)
      commit('setOrginalTags', tags)
    },

    async addTag({ commit, rootState }, tagName) {
      const { activeCompany } = rootState.company

      if (!activeCompany) return

      const { body } = await TagService.create({
        companyId: activeCompany._id,
        name: tagName,
      })

      commit('addTag', body.tag)

      return body.tag
    },

    cacheCurrentTag({ commit }, tagIds) {
      commit('cacheCurrentTag', tagIds)
    },

    async archiveTag({ commit }, tagId) {
      await TagService.archive(tagId)
      commit('removeTag', tagId)
    },

    async updateTag({ rootState, commit }, { tagId, ...fields }) {
      const { activeCompany } = rootState.company

      if (!activeCompany) return

      const { body } = await TagService.update({ tagId, company: activeCompany._id, fields })
      commit('updateTag', body.tag)
    },

    filterTags({ state, commit }, search = '') {
      const cleanSearch = search.trim().toLowerCase()

      if (cleanSearch) {
        const filteredTags = state.tags.filter((s) => s.name.toLowerCase().includes(cleanSearch))
        commit('setTags', filteredTags)
      } else {
        commit('setTags', state.originalTags)
      }
    },

    handleWSMessage({ commit, rootState, state }, { message, notify }) {
      const { _id: activeUserId } = rootState.user.onlineUser
      const { type, detail } = message

      if (detail.userId !== activeUserId) {
        console.log('WS unmatched client', type)
        return
      }

      console.log('WS message:', { type, detail })

      switch (type) {
        case WS_EVENT_TYPES.TagMaterialsReady:
          commit('updateTag', detail.tag)
          const anchor = document.createElement('a')

          anchor.href = detail.tag.materials.url
          anchor.setAttribute('download', '')
          anchor.click()
          notify({
            title: 'Download complete',
            text: `${detail.tag.name} tag materials downloaded`,
          })
          break
        case WS_EVENT_TYPES.TagMaterialsFailed:
          notify({
            type: 'error',
            title: `Downloading ${detail.tag.name}'s materials failed! Try again`,
          })
          commit('updateTag', detail.tag)
          break
        default:
          console.log('WS unhandle message:', type)
      }
    },

    async downloadTagMaterials({ rootState, commit }, tag) {
      const { onlineUser } = rootState.user
      const { activeCompany } = rootState.company

      if (!activeCompany) return

      await TagService.downloadMaterials({
        tagId: tag._id,
        userId: onlineUser._id,
        companyId: activeCompany._id,
      })
      const updatedTag = cloneDeep(tag)
      updatedTag.materials.status = MATERIALS_STATUS.downloading

      commit('updateTag', updatedTag)
    },

    async activateTagAction({ commit }, { tagId, action, campaign }) {
      const { body } = await TagService.activateAction({
        tagId,
        action,
        campaign,
      })

      commit('updateTag', body.tag)
    },
  },
}
