<template>
  <div class="card mb-0" style="min-width: 450px">
    <div class="px-4 pt-4 text-center">
      <h4 class="text-muted" style="font-size: 14px">Mentions Over Time</h4>
    </div>
    <div id="mentionTimeChart" class="pb-3 px-4" style="height: 240px">
      <apexchart type="line" height="100%" :series="formattedMentions" :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment-timezone'
const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')

export default {
  name: 'MentionsOverTime',
  computed: {
    ...InsightsDetailsModule.mapGetters(['selectAllMentions', 'selectGranularity']),
    formattedMentions() {
      let seriesData = {
        POSITIVE: { name: 'Positive', data: [] },
        NEGATIVE: { name: 'Negative', data: [] },
      }
      Object.keys(this.selectAllMentions).forEach((date) => {
        ;['POSITIVE', 'NEGATIVE'].forEach((sentiment) => {
          seriesData[sentiment].data.push({
            x: this.formatDate(date),
            y: this.selectAllMentions[date][sentiment],
          })
        })
      })
      return [seriesData.NEGATIVE, seriesData.POSITIVE]
    },
    chartOptions() {
      return {
        chart: {
          height: 240,
          type: 'bar',
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ['#FC6D87', '#33D691'],
        grid: { show: false },
        noData: { text: 'No data yet!' },
        dataLabels: { enabled: false },
        xaxis: {
          tooltip: { enabled: false },
          tickPlacement: 'on',
          axisTicks: { show: false },
          labels: {
            style: { colors: '#95aac9' },
          },
        },
        yaxis: {
          axisBorder: { show: true },
        },
        tooltip: { 
          enabled: true,
          y: {
            formatter: (value) => {
              return value.toFixed(0)
            }
          }
        },
        stroke: { curve: 'smooth' },
        legend: { show: false },
      }
    },
  },
  methods: {
    formatDate(date) {
      const granularity = this.selectGranularity
      return granularity === 'day'
        ? moment(date).format('M/D')
        : granularity === 'week'
          ? moment(date).format('MMM D')
          : granularity === 'month'
            ? Object.keys(this.selectAllMentions).length > 12
              ? moment(date).format('MMM \'YY')
              : moment(date).format('MMM')
            : moment(date).format('YYYY')
    },
  },
}
</script>
