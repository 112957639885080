<template>
  <b-modal
    ref="createRuleModal"
    id="createRuleModal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hide="close"
  >
    <div v-if="editMode" class="card-header h2">Edit Escalation Rule</div>
    <div v-if="!editMode" class="card-header h2">Create Escalation Rule</div>

    <div class="card-body">
      <div class="mb-4">
        <div class="mb-2">Select Locations</div>
        <LocationFilter
          @update="locationUpdate"
          :locations="selectActiveLocations"
          :initialLocations="locations"
          :wideMode="true"
          :disabledOptions="disabledOptions"
          style="min-width: 150px"
        />
      </div>
      <div class="mb-4">
        <div class="mb-2">Select Users</div>
        <div class="text-muted h5">
          Users who recieve escalated feedback will no longer recieve non-escalated feedback
        </div>
        <Multiselect
          placeholder="Select users"
          selectLabel
          :close-on-select="false"
          :multiple="true"
          v-model="users"
          :searchable="true"
          :showLabels="false"
          :limitText="(count) => `+${count} more`"
          :options="selectEmployees"
          :custom-label="employeeName"
          track-by="_id"
        />
      </div>
    </div>
    <div v-if="error">
      <div class="alert alert-danger" role="alert">
        {{ error }}
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex">
        <button class="btn mr-2 ml-auto" @click="close()">Cancel</button>
        <button class="btn btn-primary" :disabled="disabled" @click="save">
          {{ selectedRule._id ? 'Update' : 'Create' }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import LocationFilter from '@/components/Modules/Filters/LocationFilter'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const RuleModule = createNamespacedHelpers('rule')

export default {
  name: 'CreateRuleModal',
  props: {
    selectedRule: {
      type: Object,
    },
    rules: {
      type: Array,
    },
  },
  components: {
    Multiselect,
    LocationFilter,
  },
  data: () => ({
    locations: [],
    users: [],
    error: null,
    loading: false,
    editMode: false,
  }),
  mounted() {
    this.fetchEmployees()
  },
  watch: {
    selectedRule: {
      immediate: true,
      handler() {
        if (Object.keys(this.selectedRule).length) {
          this.locations = this.selectedRule.locations
          this.users = this.selectedRule.users.map((id) => this.selectEmployee(id))
          this.editMode = true
        }
      },
    },
  },
  computed: {
    ...LocationModule.mapGetters(['selectActiveLocations']),
    ...CompanyModule.mapGetters(['selectEmployees', 'activeCompany', 'selectEmployee']),
    disabled() {
      return this.error || this.loading || !this.locations.length || !this.users.length
    },
    disabledOptions() {
      return this.rules
        .filter((rule) => this.selectedRule?._id?.toString() !== rule?._id?.toString())
        .reduce((acc, curr) => [...acc, ...(curr.locations || [])], [])
    },
  },
  methods: {
    ...RuleModule.mapActions(['persistRule']),
    ...CompanyModule.mapActions(['fetchEmployees']),
    locationUpdate(val) {
      this.locations = val
    },
    employeeName(user) {
      // return the employee/user full name for label
      return `${user.firstName} ${user.lastName}`
    },
    save() {
      const persist = async () => {
        try {
          await this.persistRule({
            ...(this.selectedRule?._id ? { _id: this.selectedRule._id } : {}),
            locations: this.locations,
            users: this.users.map((user) => user._id),
          })
          this.close()
        } catch (error) {
          this.error = 'Oops an error occurred, please refresh the page and try again.'
        }
        this.loading = false
      }
      this.loading = true
      persist()
    },
    close() {
      this.locations = this.users = []
      this.error = null
      this.editMode = false
      this.$refs.createRuleModal.hide()
      this.$emit('clearRule')
    },
  },
}
</script>
