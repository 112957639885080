import ReportBuilderService from '@/services/ReportBuilderService'
import moment from 'moment-timezone'
import { isEqual, pick } from 'lodash-es'
import { formatTime } from '@/lib/format'

const METRICS = [
  {
    key: 'SURVEY_COUNT',
    label: 'Surveys Completed',
    description: 'Number of surveys taken',
    class: 'text-center',
  },
  {
    key: 'AVERAGE_SURVEY_SCORE',
    label: 'Average Satisfaction',
    description: 'Average score across all surveys',
    class: 'text-center',
  },
  {
    key: 'SENT_SURVEY_INVITATIONS_COUNT',
    label: 'Sent Survey Count',
    description: 'Number of surveys sent out',
    class: 'text-center',
  },
  {
    key: 'FEEDBACK_COUNT',
    label: 'Feedback Count',
    description: 'Number of surveys with feedback',
    class: 'text-center',
  },
  {
    key: 'CUSTOMERS_SAVED_COUNT',
    label: 'Customers Saved',
    description: 'Number of customers saved',
    class: 'text-center',
  },
  {
    key: 'AVERAGE_RESPONSE_RATE',
    label: 'Response Rate',
    description: 'Percentage of surveys responded to',
    class: 'text-center',
    formatter: (value) => value + '%',
  },
  {
    key: 'AVERAGE_RESPONSE_TIME',
    label: 'Response Time',
    description: 'Average time to respond to feedback',
    class: 'text-center',
    formatter: (value) => formatTime({ seconds: value }),
  },
  {
    key: 'CLICKS_TO_REVIEW',
    label: 'Clicks to Review',
    description: 'Number of review link clicks',
    class: 'text-center',
  },
  {
    key: 'AVERAGE_ONLINE_RATING',
    label: 'Online Rating',
    description: 'Average rating across platforms',
    class: 'text-center',
    formatter: (value, key, item) => {
      if (!value) return 0
      return value.averageRating
    },
  },
  {
    key: 'BLENDED_SCORE',
    label: 'Blended Score',
    description: 'Average rating across platforms and surveys',
    class: 'text-center',
    tooltip:
      "Blended Score provides a comprehensive view of feedback data by combining average Ovation's satisfaction survey score and average online rating.",
    formatter: (value, key, item) => {
      if (!value) return 0
      return value
    },
  },
  {
    key: 'AVERAGE_ONLINE_RATING_BY_PLATFORM',
    label: 'Online Rating By Platform',
    description: 'Average rating broken down by platform',
    class: 'text-center',
  },
  {
    key: 'REVIEWS_RECEIVED_COUNT',
    label: 'Review Count',
    description: 'Number of reviews recieved',
    class: 'text-center',
  },
  {
    key: 'REVIEWS_RECEIVED_COUNT_BY_PLATFORM',
    label: 'Review Count By Platform',
    description: 'Number of reviews recieved broken down by platform',
    class: 'text-center',
  },
  {
    key: 'REVIEWS_PER_MONTH',
    label: 'Reviews Per Month',
    description: 'Number of monthly reviews',
    class: 'text-center',
    formatter: (value) => value?.avgMonthlyReviews,
  },
  {
    key: 'CUSTOMER_OPT_INS_COUNT',
    label: 'Customer Opt-ins',
    description: 'The number of customer opt-in events',
    class: 'text-center',
  },
  {
    key: 'TOTAL_CUSTOMERS_OPTED_IN_COUNT',
    label: 'Total Customers Opted-in',
    description: `
    This ignores any time frame and just counts 
    the total number of customers opted in`,
    class: 'text-center',
  },
  {
    key: 'SURVEY_PROMOTER_SCORE',
    label: 'Survey Promoter Score',
    description: 'Measure customer advocacy',
    class: 'text-center',
    formatter: (value) => Number(value).toFixed(0),
  },
]

const DEFAULT_CUSTOM_REPORT = {
  name: 'Leaderboard',
  isDefault: true,
  metrics: [
    'SURVEY_COUNT',
    'AVERAGE_SURVEY_SCORE',
    'AVERAGE_RESPONSE_RATE',
    'AVERAGE_RESPONSE_TIME',
    'AVERAGE_ONLINE_RATING',
    'BLENDED_SCORE',
  ],
  // filters: {
  //   groupBy: {},
  //   locations: null,
  //   groups: null,
  // }
}

export default {
  namespaced: true,
  state: {
    filters: {},
    metrics: [...METRICS],
    customReports: [],
    activeCustomReport: {},
    rawReportData: [],
    selectionsUpdated: false,
  },
  getters: {
    selectFilters(state) {
      return state.filters
    },
    selectMetrics(state) {
      return state.metrics
    },
    selectActiveMetrics(state) {
      return state.metrics.filter((m) => m.active)
    },
    selectActiveMetricKeys(_, getters) {
      return getters.selectActiveMetrics.map((m) => m.key)
    },
    selectIsGrouped(state) {
      return !!state.filters.groupBy?.levelName
    },
    selectRawReportData(state, getters) {
      return state.rawReportData
    },
    selectReportData(state, getters) {
      return state.rawReportData.map((item) =>
        Object.keys(item).reduce((data, next) => {
          if (next === 'AVERAGE_ONLINE_RATING_BY_PLATFORM' && item[next]?.averageRatingByNetwork) {
            getters.selectReportNetworks.forEach(
              (network) =>
                (data[`AVERAGE_ONLINE_RATING_${network.toUpperCase()}`] =
                  item[next].averageRatingByNetwork[network])
            )
          } else if (next === 'REVIEWS_RECEIVED_COUNT_BY_PLATFORM' && item[next]?.networks) {
            getters.selectReportNetworks.forEach(
              (network) =>
                (data[`REVIEWS_RECEIVED_COUNT_${network.toUpperCase()}`] =
                  item[next].networks[network])
            )
          } else {
            data[next] = item[next]
          }
          return data
        }, {})
      )
    },
    selectReportFields(state, getters, rootState, rootGetters) {
      return (getters.selectActiveMetrics || []).reduce(
        (acc, curr) => {
          if (curr.key === 'AVERAGE_ONLINE_RATING_BY_PLATFORM') {
            getters.selectReportNetworks.forEach((network) => {
              acc.push({
                key: `AVERAGE_ONLINE_RATING_${network.toUpperCase()}`,
                label: `${network[0].toUpperCase()}${network.slice(1)} Rating`,
                description: `Average rating across ${network} platforms`,
                class: 'text-center',
                sortable: true,
              })
            })
          } else if (curr.key === 'REVIEWS_RECEIVED_COUNT_BY_PLATFORM') {
            getters.selectReportNetworks.forEach((network) => {
              acc.push({
                key: `REVIEWS_RECEIVED_COUNT_${network.toUpperCase()}`,
                label: `${network[0].toUpperCase()}${network.slice(1)} Review Count`,
                description: `Number of reviews received for ${network}`,
                class: 'text-center',
                sortable: true,
              })
            })
          } else {
            curr.sortable = true
            acc.push(curr)
          }
          return acc
        },
        [
          {
            key: 'name',
            label: state.filters.groupBy?.levelname || 'Locations',
            stickyColumn: true,
            variant: 'light',
            sortable: true,
            sortByFormatted: true,
            formatter: (value) => {
              return getters.selectIsGrouped
                ? value
                : rootGetters['location/selectLocationName'](value)
            },
          },
        ]
      )
    },
    selectReportNetworks(state, getters) {
      return [
        ...(getters.selectRawReportData || []).reduce((data, next) => {
          Object.keys(
            next.AVERAGE_ONLINE_RATING_BY_PLATFORM?.averageRatingByNetwork ??
              next.REVIEWS_RECEIVED_COUNT_BY_PLATFORM?.averageRatingByNetwork ??
              {}
          ).forEach((k) => data.add(k))
          return data
        }, new Set()),
      ].sort()
    },
    selectCustomReport: (state) => (name) => {
      return state.customReports.find((r) => r.name === name)
    },
    selectCustomReports(state) {
      return state.customReports
    },
    selectActiveCustomReport(state) {
      return state.activeCustomReport
    },
    showSaveBtn(state) {
      return state.customReports.length ? state.selectionsUpdated : true
    },
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_METRICS(state, metrics) {
      state.metrics = metrics
    },
    SET_RAW_REPORT_DATA(state, rawReportData) {
      state.rawReportData = rawReportData
    },
    SET_CUSTOM_REPORTS(state, customReports) {
      state.customReports = customReports
    },
    SET_ACTIVE_CUSTOM_REPORT(state, customReport) {
      state.activeCustomReport = customReport
    },
    SET_SELECTIONS_UPDATED(state, selectionsUpdated) {
      state.selectionsUpdated = selectionsUpdated
    },
  },
  actions: {
    async applyFilters({ commit, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return
      commit('SET_FILTERS', filters)
      return true
    },

    async formatFilters({ getters, rootState, rootGetters }, forCustomReport = false) {
      const { activeCompany } = rootState.company
      const filters = getters.selectFilters
      const payloadFilters = {
        companyIds: [activeCompany._id],
        createdAtRange: filters.dateRange || [
          moment().startOf('d').subtract(30, 'd').utc().format(),
          moment().endOf('day').utc().format(),
        ],
      }
      if (filters.groupBy) {
        payloadFilters.groupBy = {
          groupId: filters.groupBy.groupId.split('-')[0],
          levelName: filters.groupBy.levelName,
        }
      }
      if (filters.groups) {
        payloadFilters.groups = Array.isArray(filters.groups) ? filters.groups : [filters.groups]
      }
      if (filters.locations) {
        payloadFilters.locationIds = Array.isArray(filters.locations)
          ? filters.locations
          : [filters.locations]
      } else if (!forCustomReport) {
        payloadFilters.locationIds = rootGetters['location/selectActiveLocations']
          .filter((l) => !l.hideFromReports)
          .map((l) => l._id)
      }

      return payloadFilters
    },

    async setMetrics({ commit }, metrics) {
      commit('SET_METRICS', metrics)
    },

    async setSelectionsUpdated({ commit }, selectionsUpdated) {
      commit('SET_SELECTIONS_UPDATED', selectionsUpdated)
    },

    async markMetricsActive({ dispatch, getters }, activeMetrics) {
      const metrics = getters.selectMetrics
      metrics.forEach((m) => (m.active = false)) // reset
      let preservedOrderMetrics = new Set()
      activeMetrics.forEach((aM) => {
        const matches = metrics.filter((m) => m.key.startsWith(aM))
        if (matches?.length) {
          matches.forEach((match) => {
            match.active = true
            preservedOrderMetrics.add(match)
          })
        }
      })
      metrics.forEach((m) => preservedOrderMetrics.add(m))
      dispatch('setMetrics', [...preservedOrderMetrics])
    },

    async fetchReportData({ commit, dispatch, getters }) {
      const filters = await dispatch('formatFilters')
      const metrics = getters.selectActiveMetrics.map((m) => m.key)
      const response = await ReportBuilderService.getReportMetrics({ filters, metrics })
      commit('SET_RAW_REPORT_DATA', response.body.data.report)
    },

    async fetchCustomReports({ commit, rootState }, setDefault = true) {
      const { activeCompany } = rootState.company
      const response = await ReportBuilderService.getCustomReports(activeCompany._id)
      const customReports = response.body.data.customReports
      commit('SET_CUSTOM_REPORTS', customReports)
      if (setDefault) {
        commit(
          'SET_ACTIVE_CUSTOM_REPORT',
          customReports.length ? customReports.find((r) => r.isDefault) : DEFAULT_CUSTOM_REPORT
        )
      }
    },

    async saveCustomReportChanges({ getters, dispatch }) {
      if (getters.selectCustomReports.length) {
        // TODO: make isDefault dynamic when we support multiple CRs
        await dispatch('updateCustomReport', { isDefault: false })
      } else {
        await dispatch('createCustomReport')
      }
    },

    async createCustomReport({ dispatch, getters, rootState, commit }) {
      const formattedFilters = await dispatch('formatFilters', true)
      const response = await ReportBuilderService.createCustomReport({
        name: 'Leaderboard',
        companyId: rootState.company.activeCompany._id,
        metrics: getters.selectActiveMetrics.map((m) => m.key),
        filters: {
          groupBy: formattedFilters.groupBy || {},
          ...(formattedFilters.groups?.length
            ? { groups: formattedFilters.groups }
            : { locations: formattedFilters.locationIds }),
        },
        // TODO: isDefault will need to be changed when we support multiple CRs
        isDefault: true,
      })
      const newCustomReport = response.body.data.customReport
      commit('SET_ACTIVE_CUSTOM_REPORT', newCustomReport)
      commit('SET_CUSTOM_REPORTS', [...getters.selectCustomReports, newCustomReport])
      dispatch('setSelectionsUpdated', false)
    },

    async updateCustomReport({ dispatch, getters, commit }, { isDefault }) {
      const formattedFilters = await dispatch('formatFilters', true)
      const payload = {
        name: getters.selectActiveCustomReport.name,
        reportId: getters.selectActiveCustomReport._id,
        metrics: getters.selectActiveMetrics.map((m) => m.key),
        filters: {
          groupBy: formattedFilters.groupBy,
          ...(formattedFilters.groups?.length
            ? { groups: formattedFilters.groups }
            : { locations: formattedFilters.locationIds }),
        },
        isDefault,
      }
      const response = await ReportBuilderService.updateCustomReport(payload)
      await dispatch('fetchCustomReports', false)
      commit(
        'SET_ACTIVE_CUSTOM_REPORT',
        getters.selectCustomReports.find((cr) => cr._id === payload.reportId)
      )
      dispatch('setSelectionsUpdated', false)
    },

    async generateReportCSV({ getters, rootGetters, dispatch }) {
      const headers = getters.selectReportFields.map((f) => f.label)
      const csvRows = getters.selectReportData.reduce((rows, item) => {
        const row = getters.selectReportFields.reduce((rowData, field) => {
          if (field.formatter) {
            rowData.push(field.formatter(item[field.key]))
          } else {
            rowData.push(item[field.key])
          }
          return rowData
        }, [])
        rows.push(row.join(','))
        return rows
      }, [])

      return [headers.join(','), ...csvRows].join('\r\n')
    },
  },
}
