<template>
  <div class="row py-3">
    <div class="col-12 col-sm-6 d-flex justify-content-center align-items-center">
      <div
        class="py-3 border rounded text-center"
        :class="statusClass"
        style="width: 150px; border-width: 2px !important"
      >
        <h1>{{ Number(reportData.overall).toFixed(1) }} / 5</h1>
        Overall Rating
      </div>
    </div>

    <div class="col-12 col-sm-6 border-left">
      <div v-for="(rating, index) of ratings" :key="index">
        <div class="row p-0 align-items-center" style="min-height: 45px">
          <div class="col-2 d-flex py-2 justify-content-center">
            <img
              :src="`/img/ratings/${rating}.png`"
              :alt="`rating ${rating} emoji`"
              class="d-block"
              contain
              height="30px"
            />
          </div>
          <div class="col-10">
            <PercentBar :percentage="reportData.ratingsBreakdown[rating]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PercentBar from './PercentBar.vue'
export default {
  name: 'RatingScaleReport',
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  components: {
    PercentBar,
  },
  data: () => ({
    ratings: [5, 4, 3, 2, 1],
  }),
  computed: {
    statusClass() {
      const overall = Number(this.reportData.overall)
      if (overall >= 4) {
        return 'border-primary'
      } else if (overall >= 3 && overall < 4) {
        return 'border-warning'
      } else if (overall < 3) {
        return 'border-danger'
      } else {
        return 'border-primary'
      }
    },
  },
}
</script>
