<template>
  <b-modal ref="gcmodal" id="GiftCardModal" hide-footer hide-header centered body-class="p-0">
    <div class="modal-card card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h2 class="m-0">Add Gift Card</h2>
        <div class="text-right">
          <button class="btn btn-link text-dark pr-0">
            <i class="fas fa-times fa-lg" @click="close"></i>
          </button>
        </div>
      </div>

      <div class="card-body text-center">
        <div class="d-flex justify-content-center align-items-center my-3">
          Gift card value:
          <span class="ml-2 mr-1" style="font-weight: bold">$</span>
          <div class="form-group d-flex align-items-center mb-0">
            <input v-model="amount" placeholder="$$.$$" class="form-control" type="text" />
          </div>
        </div>

        <div class="text-danger" v-if="!validInput">
          Amount must be a valid positive number between ${{ MIN_AMOUNT }}-${{ MAX_AMOUNT }}.
        </div>
      </div>

      <div class="card-footer text-right">
        <button class="btn mr-2" @click="reset">Clear</button>
        <button class="btn btn-primary" :disabled="!amount || !validInput" @click="save">
          Add
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'GiftCardModal',
  data: () => ({
    amount: null,
    MIN_AMOUNT: 10,
    MAX_AMOUNT: 100,
  }),
  computed: {
    validInput() {
      if (this.amount === null) return true
      let regex = /^[0-9]+[\.]?[0-9]*$/
      if (!regex.test(this.amount)) return false
      const parsedAmount = parseFloat(this.amount)
      if (
        parsedAmount !== NaN &&
        parsedAmount <= this.MAX_AMOUNT &&
        parsedAmount >= this.MIN_AMOUNT
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    save() {
      this.$emit('setAmount', this.amount)
      this.close()
    },
    reset() {
      ;(this.amount = null), this.$emit('resetAmount')
      this.close()
    },
    close() {
      this.$refs.gcmodal.hide()
    },
  },
}
</script>
