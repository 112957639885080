<template>
  <MultiSelect
    :show-labels="false"
    class="d-inline-block ml-1"
    closeOnSelect
    @input="updateCategory"
    v-model="selectedCategory"
    :options="categories"
    openDirection="below"
    label="name"
    track-by="_id"
  >
  </MultiSelect>
</template>

<script>
import MultiSelect from '@/components/Common/MultiSelect'
import { createNamespacedHelpers } from 'vuex'

const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'CategorySelection',
  props: {
    question: {
      type: Object,
    },
  },
  components: {
    MultiSelect,
  },
  data: () => ({
    selectedCategory: '',
  }),
  mounted() {
    this.selectedCategory =
      this.categories.find((category) => category._id === this.question.category) || ''
  },
  computed: {
    ...QuestionModule.mapState(['categories']),
  },
  methods: {
    ...QuestionModule.mapActions(['updateCustomQuestion']),
    async updateCategory(category) {
      console.log(category)
      try {
        await this.updateCustomQuestion({
          questionId: this.question._id,
          fields: { category: category._id },
        })
        this.$notify({ text: 'Successfully updated custom question category!' })
      } catch (error) {
        this.$notify({ text: 'Failed to update question category!', type: 'error' })
      }
    },
  },
}
</script>
