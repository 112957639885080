<template>
  <div class="message" v-if="loyalty">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-0">
        <a href="javascript:;" class="btn btn-sm btn-primary btn-rounded-circle mt-1">
          <i class="fe fe-award icon-top-adjust"></i>
        </a>
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span
              ><span>{{ action }}</span>
              <span>{{ pointTitle }}</span>
              <span>{{ how }}</span>
              <span>{{ title }}</span
              >.</span
            >
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Loyalty</span>
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">{{ created | moment('LT') }} {{ timezone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const pluralize = require('pluralize')

// Spent 10 points on

export default {
  name: 'LoyaltyEvent',
  props: ['pointLabel', 'loyalty', 'type', 'created', 'timezone'],
  computed: {
    action() {
      if (this.loyalty.source === 'gift') return 'Given '
      else if (this.type === 'earned-points') return 'Earned '
      else return 'Spent '
    },
    title() {
      if (this.loyalty.source === 'gift') return ''
      if (this.loyalty.source === 'checkin') return ` checking in`
      if (this.loyalty.source === 'survey') return ` taking a survey`
      if (this.loyalty.title) return ` ${this.loyalty.title}`
      return ` ${this.loyalty.rewardTitle}`
    },
    how() {
      if (this.loyalty.source === 'gift') return ''
      else return this.type === 'earned-points' ? ' by' : ' on'
    },
    pointTitle() {
      const points = Math.abs(this.loyalty.points)
      return `${points} ${points > 1 ? pluralize(this.pointLabel) : this.pointLabel}`
    },
  },
}
</script>
