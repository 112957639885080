<template>
  <div>
    <div class="px-3 pt-3" style="min-height: 500px">
      <div class="px-3 pt-3">
        <h2>
          Target Audience
          <span class="h5">(optional)</span>
        </h2>

        <!-- filters -->
        <div class="px-3 pt-3">
          <div>
            <!-- location -->
            <div v-if="locations.length" class="mb-4">
              <label class="font-weight-bold">
                Locations
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="locationInfo"
                ></i>
              </label>
              <GroupingFilter
                :nonGroupMembers="locations"
                nonGroupMemberLabel="Locations"
                memberType="location"
                @update="groupingUpdate"
                :init="{nonGroupMemberIds: form.filters.locationsFilter}"
                :key="locations.map((l) => l._id).join()"
                :width="180"
              />
            </div>

            <!-- ratings -->
            <div class="mb-4">
              <label class="font-weight-bold">
                Ratings
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="ratingInfo"
                ></i>
              </label>
              <RatingFilter
                placeholder="Any rating"
                :limit="5"
                @updateRatings="updateRatings"
                :selections="form.filters.ratingsFilter"
              />
            </div>

            <!-- sources -->
            <div class="mb-4">
              <label class="font-weight-bold">
                Sources
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="sourcesInfo"
                ></i>
              </label>
              <SourceFilter
                placeholder="Any source"
                @updateSources="updateSources"
                :showLimit="4"
                :selected="form.filters.sourcesFilter"
              />
            </div>

            <!-- responded -->
            <div class="mb-4">
              <label class="font-weight-bold">
                Responded
                <i
                  class="fas fa-info-circle text-muted ml-1 fa-sm"
                  v-b-popover:questionCreation.hover="respondedInfo"
                ></i>
              </label>
              <RespondedFilter
                placeholder="Any"
                @updateResponded="updateResponded"
                :selection="form.filters.respondedFilter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreationFooter :allowNext="true" />
  </div>
</template>

<script>
import MultiSelect from '@/components/Common/MultiSelect'
import CreationFooter from './CreationFooter'
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import RatingFilter from '../Filters/RatingFilter'
import RespondedFilter from '../Filters/RespondedFilter'
import SourceFilter from '../Filters/CustomQuestionSourceFilter'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'QuestionAudienceStep',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  components: {
    MultiSelect,
    CreationFooter,
    GroupingFilter,
    RatingFilter,
    RespondedFilter,
    SourceFilter,
  },
  data: () => ({
    respondedInfo: `Filters based off whether a customer has been responded to in Winback. This cannot be changed later`,
    sourcesInfo: `Filters based off the customer survey source. This cannot be changed later.`,
    ratingInfo: `Filters based off the customer's survey rating. This cannot be changed later.`,
    locationInfo: `Filters based off where the customer ordered from.`,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
  }),
  computed: {
    ...LocationModule.mapState({ locations: 'userActiveCompanyLocations' }),
  },
  methods: {
    ...GroupModule.mapActions(['selectGroupLocationIds']),
    // updateLocations(locationList) {
    //   this.form.filters.locationsFilter = locationList.map((location) => location.id)
    // },
    async groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null

      let locationsToFilter = [
        ...new Set([...(this.filteredLocations || []), ...(this.filteredGroupMembers || [])]),
      ]
      this.form.filters.locationsFilter = locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : []
    },
    updateRatings(ratingList) {
      this.form.filters.ratingsFilter = ratingList.map((rating) => rating.score)
      this.form.filters.ratingsFilter.sort()
    },
    updateSources(sourceList) {
      this.form.filters.sourcesFilter = sourceList
    },
    updateResponded(status) {
      this.form.filters.respondedFilter = status
    },
  },
}
</script>
