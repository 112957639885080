import WinbackService from '@/services/WinbackService'
import moment from 'moment-timezone'
import { dateMapper } from '@/lib/date'
import { scrollToElement, scrollToLastPosition, sortRatingBeforeFeedback } from '@/lib/utils'
import { isEqual, throttle } from 'lodash-es'
import ConversationService from '@/services/ConversationService'

const throttleTimelineEvents = throttle(
  async (dispatch) => {
    dispatch('fetchTimeline')
  },
  1000,
  { trailing: false }
)

const initialState = {
  activeConversation: null,
  activeCustomer: null,
  showTimeline: false,
  lastEventId: null,
  events: [],
  timelineEvents: [],
  loyalties: [],
  messages: [],
  orders: [],
  surveys: [],
  allConversationsLoaded: false,
  allPreviousEventsLoaded: false,
  allNextEventsLoaded: false,
  conversationSkip: 0,
  conversations: [],
  responseTimes: [],
  tags: [],
  unresolvedCount: 0,
  timelineScroll: null,
  timelineScrollDirection: null,
  limit: {
    conversations: 30,
    search: 10,
    messages: 20,
  },
  filters: {
    companies: null,
    locations: null,
    date: null,
    search: null,
  },
  isWithinCelebrationTime: false,
}

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    selectActiveConversation: (state) => state.activeConversation,
    selectActiveCustomer: (state) => state.activeCustomer,
    selectHasFilter: (state) =>
      state.filters.date ||
      state.filters.companies ||
      state.filters.locations ||
      state.filters.search
        ? true
        : false,
    selectConversationSkip: (state) => state.conversationSkip,
    selectShowTimeline: (state) => state.showTimeline,
    selectEvents: (state) => state.events,
    selectEventById: (state) => (eventId) => state.events.find((event) => eventId === event._id),
    selectFirstEventId: (state) => (state.events.length ? state.events[0]._id : null),
    selectLastEventId: (state) =>
      state.events.length ? state.events[state.events.length - 1]._id : null,
    selectSurveys: (state) => state.surveys,
    selectSurveyById: (state) => (surveyId) =>
      state.surveys.find((survey) => surveyId === survey._id),
    selectMessages: (state) => state.messages,
    selectMessageById: (state) => (messageId) =>
      state.messages.find((message) => messageId === message._id),
    selectLoyalties: (state) => state.loyalties,
    selectLoyaltyById: (state) => (loyaltyId) =>
      state.loyalties.find((loyalty) => loyaltyId === loyalty._id),
    selectOrders: (state) => state.orders,
    selectOrderById: (state) => (orderId) => state.orders.find((order) => orderId === order._id),
    selectTags: (state) => state.tags,
    selectTagById: (state) => (tagId) => state.tags.find((tag) => tagId === tag._id),
    selectTimelineEvents: (state) => state.timelineEvents,
    selectTimelineScroll: (state) => state.timelineScroll,
    selectTimelineScrollDirection: (state) => state.timelineScrollDirection,
    selectLastMessageId: (state) =>
      state.timelineScrollDirection == 'both' &&
      state.activeConversation &&
      state.activeConversation.lastMessage &&
      state.activeConversation.lastMessage.score
        ? state.activeConversation.lastMessage._id
        : null,
    selectLastSurveyId: (state) =>
      state.timelineScrollDirection == 'both' &&
      state.activeConversation &&
      state.activeConversation.lastSurvey &&
      state.activeConversation.lastSurvey.score
        ? state.activeConversation.lastSurvey._id
        : null,
    selectAllConversationsLoaded: (state) => state.allConversationsLoaded,
    selectAllPreviousEventsLoaded: (state) => state.allPreviousEventsLoaded,
    selectAllNextEventsLoaded: (state) => state.allNextEventsLoaded,
    selectConversations: (state) => state.conversations,
    selectResolvedConversations: (state) =>
      state.conversations.filter((conversation) => {
        return conversation.resolved
      }),
    selectUnresolvedConversations: (state) =>
      state.conversations.filter((conversation) => {
        return !conversation.resolved
      }),
    selectResponseTimes: (state) => state.responseTimes,
    selectResponseTimeById: (state) => (conversationId) => state.responseTimes[conversationId],
    selectLimit: (state) => state.limit,
    selectFilters: (state) => state.filters,
    selectIsWithinCelebrationTime(state) {
      return state.isWithinCelebrationTime
    },
  },
  mutations: {
    RESET_ALL(state) {
      Object.assign(state, initialState)
    },
    RESET_CONVERSATIONS(state) {
      state.conversations = []
      state.conversationSkip = 0
      state.allConversationsLoaded = false
    },
    ADD_BLOCKED_CUSTOMER(state, { companyId, customerId }) {
      if (customerId != state.activeCustomer._id) return

      state.activeCustomer.blocked = [...state.activeCustomer.blocked, companyId]
    },
    REMOVE_BLOCKED_CUSTOMER(state, { companyId, customerId }) {
      if (!state.activeCustomer || customerId != state.activeCustomer._id) return

      state.activeCustomer.blocked = [
        ...state.activeCustomer.blocked.filter((cId) => cId !== companyId),
      ]
    },
    SET_TIMELINE_SCROLL(state, scroll) {
      state.timelineScroll = { ...scroll }
    },
    SET_CONVERSATIONS(state, conversations) {
      const newConversationIds = conversations.map((conversation) => conversation._id)
      let newConversations = [
        ...state.conversations.filter(
          (conversation) => !newConversationIds.includes(conversation._id)
        ),
        ...conversations,
      ]

      if (!state.filters.search) {
        newConversations = newConversations
          .filter((conversation) => {
            if (!conversation.lastMessage && !conversation.lastSurvey) {
              return false
            }

            conversation.timestamp = new Date(
              conversation.lastMessage
                ? conversation.lastMessage.created
                : conversation.lastSurvey.updated
            ).getTime()

            return true
          })
          .sort((a, b) => {
            return b.timestamp - a.timestamp
          })
      }

      state.conversations = newConversations

      if (state.activeConversation) {
        state.activeConversation = {
          ...state.conversations.find((c) => c._id === state.activeConversation._id),
        }
      }
    },
    SET_EVENTS(state, events) {
      const newEventIds = events.map((event) => event._id)
      let rawEvents = [
        ...state.events.filter((event) => !newEventIds.includes(event._id)),
        ...events,
      ].sort((a, b) => moment(a.created) - moment(b.created))

      const sortedEvents = []
      rawEvents.forEach((event) => {
        // check if the array is empty
        if (sortedEvents.length === 0) {
          const obj = {}
          Object.assign(obj, {
            timestamp: dateMapper(event.created),
            events: [event],
          })
          sortedEvents.push(obj)
        } else {
          // check if the index item exists in the array
          const index = sortedEvents.findIndex((e) => e.timestamp === dateMapper(event.created))
          if (index > -1) {
            let item = sortedEvents[index]
            item = { ...item, events: [...item.events, event] }
            // update the item at index
            sortedEvents[index] = item
          } else {
            const obj = {}
            Object.assign(obj, {
              timestamp: dateMapper(event.created),
              events: [event],
            })
            // push the new object to array
            sortedEvents.push(obj)
          }
        }
      })
      state.events = rawEvents
      state.timelineEvents = sortRatingBeforeFeedback(sortedEvents)
    },
    SET_FILTERS(state, filters) {
      state.filters = { ...filters }
    },
    SET_CONVERSATION_SKIP(state, skip) {
      state.conversationSkip = skip
    },
    SET_LOYALTIES(state, loyalties) {
      const newLoyaltyIds = loyalties.map((loyalty) => loyalty._id)
      state.loyalties = [
        ...state.loyalties.filter((loyalty) => !newLoyaltyIds.includes(loyalty._id)),
        ...loyalties,
      ]
    },
    SET_MESSAGES(state, messages) {
      const newMessageIds = messages.map((message) => message._id)
      state.messages = [
        ...state.messages.filter((message) => !newMessageIds.includes(message._id)),
        ...messages,
      ]
    },
    SET_ORDERS(state, orders) {
      const newOrderIds = orders.map((order) => order._id)
      state.orders = [
        ...state.orders.filter((order) => !newOrderIds.includes(order._id)),
        ...orders,
      ]
    },
    SET_SURVEYS(state, surveys) {
      const newSurveyIds = surveys.map((survey) => survey._id)
      state.surveys = [
        ...state.surveys.filter((survey) => !newSurveyIds.includes(survey._id)),
        ...surveys,
      ]
    },
    SET_LAST_MESSAGE(state, { event, message }) {
      if (!state.filters.search) {
        let conversation = state.conversations.find(
          (c) => c.company === event.company && c.customer._id === event.customer
        )
        if (conversation) {
          conversation.lastMessage = message
          conversation.updated = message.created
          conversation.resolved = false
          conversation.resolvedBy = null
        }
      }
    },
    SET_LAST_SURVEY(state, { event, survey }) {
      if (!state.filters.search) {
        let conversation = state.conversations.find(
          (c) => c.company === event.company && c.customer._id === event.customer
        )
        if (conversation) {
          conversation.lastSurvey = survey
          conversation.updated = survey.created
          conversation.resolved = false
          conversation.resolvedBy = null
        }
      }
    },
    SET_ACTIVE_CONVERSATION(state, conversation) {
      if (conversation == null) {
        state.timelineScroll = null
        state.activeCustomer = null
        state.events = []
        state.loyalties = []
        state.messages = []
        state.orders = []
        state.surveys = []
        state.tags = []
        state.allNextEventsLoaded = false
        state.allPreviousEventsLoaded = false
      }
      state.activeConversation = conversation
    },
    SET_ACTIVE_CUSTOMER(state, customer) {
      state.activeCustomer = customer
    },
    SET_SHOW_TIMELINE(state, showTimeline) {
      state.showTimeline = showTimeline
    },
    SET_TIMELINE_SCROLL_DIRECTION(state, timelineScrollDirection) {
      state.timelineScrollDirection = timelineScrollDirection
    },
    SET_ALL_CONVERSATIONS_LOADED(state, allConversationsLoaded) {
      state.allConversationsLoaded = allConversationsLoaded
    },
    SET_ALL_PREVIOUS_EVENTS_LOADED(state, allPreviousEventsLoaded) {
      state.allPreviousEventsLoaded = allPreviousEventsLoaded
    },
    SET_ALL_NEXT_EVENTS_LOADED(state, allNextEventsLoaded) {
      state.allNextEventsLoaded = allNextEventsLoaded
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_RESPONSE_TIMES(state, responseTimes) {
      let newResponseTimes = { ...state.responseTimes }

      responseTimes.forEach((response) => {
        if (response) {
          newResponseTimes[response.conversationId] = response
        }
      })

      state.responseTimes = newResponseTimes
    },
    SET_UNRESOLVED_COUNT(state, count) {
      state.conversationCount = count
    },
    SET_IS_WITHIN_CELEBRATION_TIME(state, status) {
      state.isWithinCelebrationTime = status
    },
  },
  actions: {
    async winbackInit({ dispatch }) {
      const loadingUuid = await dispatch('addToLoader', { name: 'INIT_WINBACK' }, { root: true })
      await dispatch('resetAll')
      await dispatch('fetchConversations')
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async resetAll({ commit }) {
      commit('RESET_ALL')
    },
    async applyFilters({ commit, dispatch, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return

      const loadingUuid = await dispatch('addToLoader', { name: 'APPLY_FILTERS' }, { root: true })
      commit('SET_FILTERS', filters)

      if (filters.search) {
        commit('SET_TIMELINE_SCROLL_DIRECTION', 'both')
      }

      commit('RESET_CONVERSATIONS')
      await dispatch('fetchConversations')
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async fetchConversations({ commit, dispatch, rootGetters, getters }) {
      if (!getters.selectAllConversationsLoaded) {
        const loadingUuid = await dispatch(
          'addToLoader',
          { name: 'FETCH_CONVERSATIONS' },
          { root: true }
        )
        commit('SET_ALL_CONVERSATIONS_LOADED', true)
        let companies =
          rootGetters['company/activeCompany']._id === 'ALL_COMPANIES'
            ? rootGetters['company/companies']
            : [rootGetters['company/activeCompany']]

        let conversations = []
        try {
          const response = await WinbackService.fetchConversations({
            companyIds: getters.selectFilters.companies
              ? getters.selectFilters.companies
              : companies.map((company) => company._id),
            locationIds: getters.selectFilters.locations ? getters.selectFilters.locations : null,
            limit: getters.selectFilters.search
              ? getters.selectLimit.search
              : getters.selectLimit.conversations,
            date: getters.selectFilters.date,
            search: getters.selectFilters.search,
            skip: getters.selectConversationSkip,
          })

          if (response.body.success !== true) {
            await dispatch('removeFromLoader', loadingUuid, { root: true })
            throw response.body
          }

          conversations = response.body.data
        } catch (e) {
          console.log(e)
        }

        if (
          !getters.selectFilters.search &&
          conversations.length >= getters.selectLimit.conversations
        ) {
          commit('SET_ALL_CONVERSATIONS_LOADED', false)
          commit(
            'SET_CONVERSATION_SKIP',
            getters.selectLimit.conversations + getters.selectConversationSkip
          )
        }

        conversations = conversations.filter((conversation) => conversation.customer != null)

        if (conversations.length) {
          dispatch('fetchResponseTimes', conversations)
        }

        commit('SET_CONVERSATIONS', conversations)

        await dispatch('removeFromLoader', loadingUuid, { root: true })
      }
    },
    async fetchConversation({ commit, dispatch, getters, rootGetters }, conversation) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: `FETCH_CONVERSATION-${conversation._id}` },
        { root: true }
      )

      let payload = {
        companyIds: rootGetters['dataCore/selectActiveCompanyIds'],
        locationIds: rootGetters['dataCore/selectActiveLocations'].map((location) => location._id),
        limit: getters.selectLimit.conversations,
      }

      if (conversation._id) {
        payload.conversationIds = [conversation._id]
      } else {
        payload.customerIds = [conversation.customer._id]
      }

      const response = await WinbackService.fetchConversations(payload)

      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      }

      let conversations = response.body.data

      if (conversations.length) {
        dispatch('fetchResponseTimes', conversations)
      }

      commit('SET_CONVERSATIONS', conversations)

      await dispatch('removeFromLoader', loadingUuid, { root: true })

      return conversations
    },
    async setActiveConversation({ commit, dispatch, getters }, conversation) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: 'SET_ACTIVE_CONVERSATION' },
        { root: true }
      )
      const { selectFilters } = getters
      await dispatch('clearActiveConversation')
      if (!conversation._id) {
        let conversations = await dispatch('fetchConversation', conversation)
        if (conversations.length) {
          conversation = conversations.find(
            (conversation) => conversations[0]._id === conversation._id
          )
        }
      }
      commit('SET_ACTIVE_CONVERSATION', conversation)
      commit('SET_TIMELINE_SCROLL_DIRECTION', selectFilters.search ? 'both' : null)
      await dispatch('fetchTimeline')
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async clearActiveConversation({ commit }) {
      commit('SET_ACTIVE_CONVERSATION', null)
    },
    async updateTimelineScroll(
      { commit, dispatch, getters },
      { target: { scrollTop, clientHeight, scrollHeight } }
    ) {
      const { timelineScroll } = getters

      if (timelineScroll && timelineScroll.scrollTop == scrollTop) return

      commit('SET_TIMELINE_SCROLL', { scrollTop, clientHeight, scrollHeight })

      if (scrollTop + clientHeight == scrollHeight) {
        commit('SET_TIMELINE_SCROLL_DIRECTION', 'next')
        throttleTimelineEvents(dispatch)
      } else if (scrollTop == 0) {
        commit('SET_TIMELINE_SCROLL_DIRECTION', 'previous')
        throttleTimelineEvents(dispatch)
      } else {
        commit('SET_TIMELINE_SCROLL_DIRECTION', null)
      }
    },
    async fetchTimeline({ commit, dispatch, getters, rootGetters }) {
      const loadingUuid = await dispatch('addToLoader', { name: 'FETCH_TIMELINE' }, { root: true })

      const {
        selectActiveConversation,
        selectLimit,
        selectFirstEventId,
        selectAllNextEventsLoaded,
        selectTimelineScroll,
        selectAllPreviousEventsLoaded,
        selectLastEventId,
        selectTimelineScrollDirection,
        selectLastMessageId,
        selectLastSurveyId,
      } = getters

      if (!selectTimelineScrollDirection && !selectLastEventId) {
        commit('SET_ALL_NEXT_EVENTS_LOADED', true)
      } else {
        if (
          selectAllNextEventsLoaded &&
          selectActiveConversation.opened &&
          !selectActiveConversation.opened.includes(rootGetters['dataCore/selectActiveUserId'])
        ) {
          await dispatch(
            'conversation/open',
            { conversationId: selectActiveConversation._id },
            { root: true }
          )
        }

        if (
          (['next', 'both'].includes(selectTimelineScrollDirection) && selectAllNextEventsLoaded) ||
          (['previous', 'both'].includes(selectTimelineScrollDirection) &&
            selectAllPreviousEventsLoaded)
        ) {
          await dispatch('removeFromLoader', loadingUuid, { root: true })
          return
        }
      }

      let payload = {
        customerId: selectActiveConversation.customer._id,
        companyId: selectActiveConversation.company,
        limit: selectLimit.messages,
        direction: selectTimelineScrollDirection,
        lastMessageId: selectLastMessageId,
        lastEventId:
          selectTimelineScrollDirection == 'previous'
            ? selectFirstEventId
            : selectTimelineScrollDirection == 'next'
            ? selectLastEventId
            : null,
      }

      const response = await WinbackService.fetchTimeline(payload)

      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      }

      if (['next', 'both'].includes(selectTimelineScrollDirection)) {
        if (response.body.data.events.length < selectLimit.messages) {
          commit('SET_ALL_NEXT_EVENTS_LOADED', true)
        } else {
          commit('SET_ALL_NEXT_EVENTS_LOADED', false)
        }
      } else if (['previous', 'both'].includes(selectTimelineScrollDirection)) {
        if (response.body.data.events.length < selectLimit.messages) {
          commit('SET_ALL_PREVIOUS_EVENTS_LOADED', true)
        } else {
          commit('SET_ALL_PREVIOUS_EVENTS_LOADED', false)
        }
      }

      const { customer, events, orders, surveys, messages, loyalties } = response.body.data
      commit('SET_ACTIVE_CUSTOMER', customer)
      commit('SET_EVENTS', events)
      commit('SET_ORDERS', orders)
      commit('SET_SURVEYS', surveys)
      commit('SET_MESSAGES', messages)
      commit('SET_LOYALTIES', loyalties)

      dispatch('fetchTags')
      await dispatch('removeFromLoader', loadingUuid, { root: true })

      if (!selectTimelineScrollDirection) {
        scrollToElement(`timeline-${getters.selectLastEventId}`)
      } else if (selectTimelineScrollDirection == 'previous') {
        scrollToLastPosition('timelineBody', selectTimelineScroll.scrollHeight, 0)
      } else if (selectTimelineScrollDirection == 'both') {
        const event = selectLastMessageId
          ? events.find((event) => event.message == selectLastMessageId)
          : events.find((event) => event.survey == selectLastSurveyId)
        scrollToElement(`timeline-${event._id}`)
      }
    },
    async blockCustomer({ commit, dispatch }, { companyId, customerId }) {
      const loadingUuid = await dispatch('addToLoader', { name: 'BLOCK_CUSTOMER' }, { root: true })

      const response = await WinbackService.blockCustomer({ companyId, customerId })
      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      }

      commit('ADD_BLOCKED_CUSTOMER', { customerId, companyId })
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async unblockCustomer({ commit, dispatch }, { companyId, customerId }) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: 'UNBLOCK_CUSTOMER' },
        { root: true }
      )

      const response = await WinbackService.unblockCustomer({ companyId, customerId })
      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      }

      commit('REMOVE_BLOCKED_CUSTOMER', { customerId, companyId })
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async resolveConversation({ dispatch, rootGetters }, conversationId) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: `RESOLVE_CONVERSATION-${conversationId}` },
        { root: true }
      )

      const response = await WinbackService.resolveConversation({
        conversationId,
        userId: rootGetters['dataCore/selectActiveUserId'],
      })

      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      } else {
        await dispatch('conversationChange', {
          _id: conversationId,
          resolved: true,
          resolvedBy: rootGetters['dataCore/selectActiveUserId'],
        })
        await dispatch('removeFromLoader', loadingUuid, { root: true })
      }
    },
    async unresolveConversation({ dispatch, rootGetters }, conversationId) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: `UNRESOLVE_CONVERSATION-${conversationId}` },
        { root: true }
      )

      const response = await WinbackService.unresolveConversation({
        conversationId,
        userId: rootGetters['dataCore/selectActiveUserId'],
      })

      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      } else {
        await dispatch('conversationChange', {
          _id: conversationId,
          resolved: false,
          resolvedBy: null,
        })
        await dispatch('removeFromLoader', loadingUuid, { root: true })
      }
    },
    async createConversation({ dispatch, commit, getters }, { customerId, companyId, locationId }) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: 'CREATE_CONVERSATION' },
        { root: true }
      )
      const response = await ConversationService.create({
        customerId,
        locationId,
        companyId,
      })

      const { conversation } = response.body
      let newConversation = { ...getters.selectActiveConversation }
      if (newConversation && !newConversation._id) {
        newConversation._id = conversation._id
        commit('SET_ACTIVE_CONVERSATION', newConversation)
      }
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async sendReply({ dispatch, getters, rootGetters, commit }, { promoId, message }) {
      const loadingUuid = await dispatch('addToLoader', { name: 'SEND_REPLY' }, { root: true })

      const { selectActiveCustomer, selectActiveConversation } = getters
      const response = await WinbackService.sendReply({
        conversationId: selectActiveConversation._id,
        locationId: selectActiveConversation.location,
        companyId: selectActiveConversation.company,
        customerId: selectActiveCustomer._id,
        userId: rootGetters['dataCore/selectActiveUserId'],
        promoId,
        message,
      })
      const { isWithinCelebrationTime: celebrate } = response.body
      commit('SET_IS_WITHIN_CELEBRATION_TIME', celebrate)
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async fetchTags({ commit, dispatch, getters }) {
      const loadingUuid = await dispatch('addToLoader', { name: 'FETCH_TAGS' }, { root: true })
      const { selectActiveCustomer } = getters
      const tags =
        selectActiveCustomer && selectActiveCustomer.tags
          ? Object.keys(selectActiveCustomer.tags).reduce((previous, next) => {
              return [...previous, ...selectActiveCustomer.tags[next]]
            }, [])
          : []

      if (!tags.length) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        return
      }

      const response = await WinbackService.fetchTags({
        tagIds: tags,
      })

      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      }

      commit('SET_TAGS', response.body.data)
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    clearActiveTimeline({ commit }) {
      commit('SET_ACTIVE_CONVERSATION', null)
    },
    async fetchResponseTimes({ commit, dispatch }, conversations) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: 'FETCH_RESPONSE_TIMES' },
        { root: true }
      )
      const response = await WinbackService.fetchResponseTimes({
        conversations: conversations.map((conversation) => {
          return {
            customer: conversation.customer._id,
            location: conversation.location,
            _id: conversation._id,
          }
        }),
      })

      if (response.body.success !== true) {
        await dispatch('removeFromLoader', loadingUuid, { root: true })
        throw response.body
      }

      commit('SET_RESPONSE_TIMES', response.body.data)
      await dispatch('removeFromLoader', loadingUuid, { root: true })
    },
    async conversationChange({ commit, dispatch, getters, rootGetters }, conversationData) {
      let conversation = getters.selectConversations.find((c) => c._id === conversationData._id)
      if (conversation) {
        commit('SET_CONVERSATIONS', [{ ...conversation, ...conversationData }])
      } else {
        await dispatch('fetchConversation', conversationData)
      }

      await dispatch(
        'removeFromLoaderByName',
        [
          `RESOLVE_CONVERSATION-${conversationData._id}`,
          `UNRESOLVE_CONVERSATION-${conversationData._id}`,
        ],
        { root: true }
      )
    },
    async websocketMessage({ commit, dispatch, getters }, message) {
      const { selectActiveConversation, selectAllNextEventsLoaded } = getters
      switch (message.action) {
        case 'conversation-change':
          dispatch('conversationChange', message.data)
          break
        case 'new-event':
          const { event, surveys, loyalties, messages, orders } = message.data
          if (
            selectAllNextEventsLoaded &&
            selectActiveConversation &&
            event.customer === selectActiveConversation.customer._id &&
            selectActiveConversation.company === event.company
          ) {
            commit('SET_SURVEYS', surveys)
            commit('SET_LOYALTIES', loyalties)
            commit('SET_MESSAGES', messages)
            commit('SET_ORDERS', orders)
            commit('SET_EVENTS', [event])
          }

          switch (event.type) {
            case 'incoming-message':
            case 'outgoing-message':
              commit('SET_LAST_MESSAGE', { event, message: messages[0] })
              break

            case 'left-survey':
              commit('SET_LAST_SURVEY', { event, survey: surveys[0] })
              break
          }
      }
    },
    setisWithinCelebrationTime({ commit }, status) {
      commit('SET_IS_WITHIN_CELEBRATION_TIME', status)
    },
  },
}
