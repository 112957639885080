<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 150px"
          />

          <h2>Ready to collect custom data from your customers?</h2>

          <p class="text-muted mb-4">
            Create custom questions to dynamically send to your clientele based off filters!
          </p>

          <button class="btn btn-primary" @click="openQuestionCreationModal">
            <span class="fas fa-plus"></span>
            Create Question
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// @group CustomQuestions
// This Component only displays if there are no existing custom questions
export default {
  name: 'QuestionsOffstate',
  methods: {
    openQuestionCreationModal() {
      this.$root.$emit('bv::show::modal', 'questionCreationModal')
    },
  },
}
</script>

<style scoped lang="scss"></style>
