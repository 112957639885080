<template>
  <div>
    <div class="row">
      <div class="col-5">
        <!-- total calls received -->
        <TotalCallsReceived />
      </div>
      <div class="col-7">
        <!-- action breakdown -->
        <ActionBreakdown />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <!-- table list render -->
        <CallListTable />
      </div>
    </div>
  </div>
</template>

<script>
import TotalCallsReceived from '../Reports/TotalCallsReceived'
import ActionBreakdown from '../Reports/ActionBreakdown'
import CallListTable from '../Reports/CallListTable'

export default {
  name: 'CallReports',
  components: {
    TotalCallsReceived,
    ActionBreakdown,
    CallListTable,
  },
}
</script>
