<template>
  <div>
    <AddIntegrationModal
      ref="addIntegrationModal"
      :location="activeLocation"
      @connected="addIntegration($event)"
    />
    <div class="container">
      <div v-if="connectedIntegrations.length">
        <div class="col">
          <h3 class="mb-0 mt-3">Active</h3>
        </div>
        <hr class="mt-4 mb-5" />
        <div class="row">
          <div
            class="col-12 col-md-12 col-lg-6"
            v-for="integration in connectedIntegrations"
            :key="integration"
          >
            <LocationSettingsIntegrationCard
              :integration="getIntegrationDetails(integration)"
              :connectedIntegration="getConnectedIntegrationDetails(integration)"
              :connected="true"
              v-on:authenticate="authenticate($event)"
              v-on:edit-integration="editIntegration($event)"
            />
          </div>
        </div>
      </div>

      <div class="col">
        <h3 class="mb-0 mt-3">Inactive</h3>
      </div>
      <hr class="mt-4 mb-5" />
      <div class="row">
        <div
          class="col-12 col-md-12 col-lg-6"
          v-for="integration in inactiveIntegrations"
          :key="integration"
        >
          <LocationSettingsIntegrationCard
            :integration="getIntegrationDetails(integration)"
            :connectedIntegration="getConnectedIntegrationDetails(integration)"
            :connected="false"
            v-on:authenticate="authenticate($event)"
            v-on:edit-integration="editIntegration($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import LocationSettingsBase from '../LocationSettingsBase'
import LocationSettingsIntegrationCard from './LocationSettingsIntegrationCard'
import AddIntegrationModal from '@/components/Modals/AddIntegrationModal'
import { availableIntegrations, getIntegrationDetails } from '@/lib/modules/integration'
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const IntegrationProviderModule = createNamespacedHelpers('integrationProvider')
const IntegrationModule = createNamespacedHelpers('integration')

export default {
  name: 'LocationSettingsIntegrations',
  extends: LocationSettingsBase,
  components: {
    LocationSettingsIntegrationCard,
    AddIntegrationModal,
  },
  computed: {
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...IntegrationProviderModule.mapGetters([
      'selectIntegrationProviders',
      'selectIntegrationProviderById',
      'selectIntegrationProviderByName',
    ]),
    inactiveIntegrations() {
      return this.integrationOptions.filter((key) => !this.connectedIntegrations.includes(key))
    },
    integrationOptions: function () {
      let keys = Object.keys(availableIntegrations)
      // filter out bonchon integration if not enabled
      if (!this.isEnabled('BONCHON_INTEGRATION')) {
        keys = keys.filter((key) => key != 'bonchon')
      }

      keys = [
        ...keys,
        ...this.selectIntegrationProviders.map((integrationProvider) => integrationProvider.name),
      ]

      return keys?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    connectedIntegrations: function () {
      const integrations = []
      if (this.activeLocation.integrations) {
        this.activeLocation.integrations.map((integration) => {
          if (!integration.disabled) {
            let provider = integration.integrationProvider
              ? this.selectIntegrationProviderById(integration.integrationProvider)?.name
              : integration.provider
            if (provider) integrations.push(provider)
          }
        })
      }

      return integrations?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
  },
  mounted() {
    this.$root.$on('activeLocationUpdated', () => {
      this.$forceUpdate()
    })

    if (this.$route.query && ['success', 'error'].includes(this.$route.query.oauth)) {
      const provider = this.$route.query.integrationProviderName

      if (this.$route.query.oauth === 'success') {
        this.$notify({
          title: 'Authorization successful!',
          type: 'success',
        })

        const integration = this.selectIntegrationProviders.find((ip) => ip.name === provider)
        this.editIntegration(integration)
      } else {
        this.$notify({
          title: 'Sorry, the authorization was not successful.',
          type: 'error',
        })
      }

      const query = Object.assign({}, this.$route.query)
      delete query.oauth
      delete query.integrationProviderName
      this.$router.replace({ query })
    }
  },
  methods: {
    ...IntegrationModule.mapActions(['getIntegration']),
    getIntegrationDetails(provider) {
      return getIntegrationDetails(provider)
    },
    authenticate(integration) {
      this.$refs.addIntegrationModal.setIntegration(integration)
      this.$refs.addIntegrationModal.setConnectedIntegration(
        this.getConnectedIntegrationDetails(integration.provider)
      )
      this.$root.$emit('bv::show::modal', 'addIntegrationModal')
    },
    editIntegration(integration) {
      this.$refs.addIntegrationModal.setIntegration(integration)
      this.$refs.addIntegrationModal.setConnectedIntegration(
        this.getConnectedIntegrationDetails(integration.provider)
      )
      this.$root.$emit('bv::show::modal', 'addIntegrationModal')
    },
    addIntegration(provider) {
      this.connectedIntegrations.push(provider)
      this.$root.$emit('getLocationData')
    },
    getConnectedIntegrationDetails(provider) {
      const integrationProvider = this.selectIntegrationProviderByName(provider)
      if (this.activeLocation.integrations) {
        const oldIntegrationDetails = this.activeLocation.integrations.find(
          (integration) => integration.provider === provider
        )

        if (oldIntegrationDetails) return oldIntegrationDetails

        if (integrationProvider) {
          const newIntegrationDetails = this.activeLocation.integrations.find((integration) => {
            const integrationProviderToCheck = this.selectIntegrationProviderById(
              integration.integrationProvider
            )

            if (integrationProviderToCheck) {
              return integrationProviderToCheck.name === integrationProvider.name
            }
          })

          return newIntegrationDetails
        }
      }
      return null
    },
  },
}
</script>
