import Vue from 'vue'

export default {
  get(locationId) {
    return Vue.http.get(`location/${locationId}`)
  },
  getLocations(payload) {
    return Vue.http.post(`app-services/v2/locations/list`, payload)
  },
  getByCompany(companyId) {
    return Vue.http.get(`location/company/${companyId}`)
  },
  create(payload) {
    return Vue.http.post('location', payload)
  },
  addProducts(payload) {
    return Vue.http.post('location/add-products', payload)
  },
  getForUser({ companyId }) {
    return Vue.http.get(`app-services/v2/users/company/${companyId}/locations`)
  },
  update(payload) {
    return Vue.http.post('location/update', payload)
  },
  updateAddress(payload) {
    const { locationId, ...addressData } = payload
    return Vue.http.put(`app-services/v2/locations/${locationId}/address`, addressData)
  },
  getLeaderboardLocations(payload) {
    return Vue.http.post(`app-services/v2/locations/list/leaderboard`, payload)
  },
}
