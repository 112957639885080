<template>
  <transition name="slide-up">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'SlideUpTransition',
}
</script>

<style scoped lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s cubic-bezier(0.155, 0.83, 0.23, 1);
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
