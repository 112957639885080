<template>
  <p v-if="products.length" class="card-text small text-center text-muted mb-4">
    <span v-if="products.includes('tent')" class="badge badge-soft-primary">
      <i class="fas fa-qrcode"></i> Table Tents
    </span>
    &nbsp;
    <span v-if="products.includes('acrylic')" class="badge badge-soft-warning">
      <i class="fe fe-shield"></i> Acrylic
    </span>
    &nbsp;
    <span v-if="products.includes('kiosk')" class="badge badge-soft-success">
      <i class="fas fa-tablet-alt"></i> Kiosk
    </span>
  </p>
</template>

<script>
export default {
  name: 'CustomerProductBadges',
  props: ['products'],
}
</script>

<style scoped lang="scss"></style>
