<template>
  <div class="card pt-4 px-4 text-center" v-if="selectSentiment !== 'POSITIVE'">
    <h3 class="text-muted mb-0" style="font-size: 14px">Incidents</h3>
    <apexchart
      :height="heatmapHeight + 'px'"
      type="heatmap"
      :series="series"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'SingleLineHeatmap',
  props: {
    subcategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...InsightsDetailsModule.mapGetters([
      'selectFeedbackCount',
      'selectCategoryIssueCount',
      'selectSentiment',
    ]),
    ...LocationModule.mapGetters(['selectLocationName']),
    detailedSubcategories() {
      return this.subcategories
        .map((s) => {
          return { ...s, issueCount: this.selectCategoryIssueCount(s._id) }
        })
        .sort((a, b) => (a.issueCount < b.issueCount ? 1 : -1))
    },
    series() {
      return [
        {
          name: '',
          data: this.detailedSubcategories.map((subcategory) =>
            this.selectFeedbackCount
              ? Math.ceil((subcategory.issueCount / this.selectFeedbackCount) * 100)
              : 0
          ),
        },
      ]
    },
    heatmapHeight() {
      const cellHeight = 40
      const xAxisHeight = 68
      const projectedHeight = cellHeight + xAxisHeight
      return projectedHeight
    },
    chartOptions() {
      return {
        chart: {
          type: 'heatmap',
          toolbar: { show: false },
          zoom: { enabled: false },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$router.replace({
                path: '/insights/details',
                query: {
                  ...this.$route.query,
                  category: this.detailedSubcategories[config.dataPointIndex]._id,
                  preventRedirect: true,
                },
              })
              this.$emit('refreshDetails')
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#12263F'],
          },
          formatter: (val, opts) => {
            return val ? val + '%' : '0%'
          },
        },
        xaxis: {
          categories: this.detailedSubcategories,
          tooltip: { enabled: false },
          position: 'top',
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: {
            formatter: (val, opts) => {
              return val.name
            },
            style: {
              fontWeight: 'bold',
            },
            trim: true
          },
        },
        yaxis: {},
        legend: { show: false },
        tooltip: { enabled: false },
        plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: '#FFFFFF',
                },
                {
                  from: 1,
                  to: 5,
                  color: '#FED2DA',
                },
                {
                  from: 6,
                  to: 10,
                  color: '#FDA5B5',
                },
                {
                  from: 11,
                  to: 100,
                  color: '#FB4B6B',
                },
              ],
            },
          },
        },
      }
    },
  },
}
</script>

<style scoped>
#line {
  color: white;
}
.bg-light-green {
  background: #ccf7e5;
}
.bg-light-red {
  background: #ffcccb;
}
</style>
