<template>
  <div class="container pl-0 ml-0">
    <div class="row justify-content-start" v-if="initialized">
      <!-- date -->
      <div class="dropdown col-auto">
        <h6 class="header-pretitle mt-3">Date Range</h6>
        <DatePicker
          @update="dateUpdate"
          :minDate="activeCompany.created"
          :dateRange="filteredDateRange"
        />
      </div>

      <!-- location -->
      <div class="dropdown col-auto">
        <h6 class="header-pretitle mt-3">Locations</h6>
        <GroupingFilter
          :nonGroupMembers="selectActiveLocations.filter((l) => !l.hideFromReports)"
          nonGroupMemberLabel="Locations"
          memberType="location"
          @update="groupingUpdate"
          :init="{
            nonGroupMemberIds: this.filteredLocations,
            groupIds: this.filteredGroups,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import DatePicker from '@/components/Modules/Filters/DatePicker'
import { createNamespacedHelpers } from 'vuex'

const GiftCardModule = createNamespacedHelpers('giftCard')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'GiftCardFilters',
  components: {
    GroupingFilter,
    DatePicker,
  },
  data: () => ({
    initialized: false,
    filteredDateRange: null,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredDisabledLocations: null,
  }),
  mounted() {
    this.initializeData()
  },
  computed: {
    ...LocationModule.mapGetters(['selectActiveLocations', 'selectDisabledLocations']),
    ...CompanyModule.mapGetters(['activeCompany']),
  },
  methods: {
    ...GiftCardModule.mapActions(['applyFilters']),
    ...GroupModule.mapActions(['selectGroupLocationIds']),
    initializeData() {
      this.filteredDateRange = this.$route.query.dateRange
      this.filteredLocations = this.$route.query.locs
      this.filteredGroups = this.$route.query.groups
      this.filteredDisabledLocations = this.$route.query.disLocs

      if (this.filteredGroups && !Array.isArray(this.filteredGroups)) {
        this.filteredGroups = [this.filteredGroups]
      }
      if (this.filteredLocations && !Array.isArray(this.filteredLocations)) {
        this.filteredLocations = [this.filteredLocations]
      }
      if (this.filteredDisabledLocations && !Array.isArray(this.filteredDisabledLocations)) {
        this.filteredDisabledLocations = [this.filteredDisabledLocations]
      }
      this.initialized = true
    },
    groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.formatAndApplyFilters()
    },
    dateUpdate(val) {
      this.filteredDateRange = val
      this.formatAndApplyFilters()
    },
    async formatAndApplyFilters() {
      let locationsToFilter = [
        ...new Set([...(this.filteredLocations || []), ...(this.filteredGroupMembers || [])]),
      ]
      this.applyFilters({
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
        dateRange:
          this.filteredDateRange && Object.keys(this.filteredDateRange).length
            ? this.filteredDateRange
            : null,
      })
      this.$emit('filtersApplied')
      this.$router
        .replace({
          path: this.$route.path,
          query: {
            dateRange: this.filteredDateRange,
            ...(this.filteredGroups && this.filteredGroups.length
              ? { groups: this.filteredGroups }
              : {
                  ...(this.filteredLocations?.length ? { locs: this.filteredLocations } : {}),
                }),
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') throw err
        })
    },
  },
}
</script>
