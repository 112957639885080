<template>
  <div class="progress bg-light" style="min-height: 22px">
    <div
      class="progress-bar"
      role="progressbar"
      style="background-color: var(--primary)"
      :style="`width: ${percentage}%`"
    >
      <span v-if="Number(percentage)"> {{ percentage }}% </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentBar',
  props: {
    percentage: {
      type: String,
      required: true,
    },
  },
}
</script>
