import ReportService from '@/services/ReportService'
import Config from '@/config'

export default {
  namespaced: true,
  state: {
    dashboards: [],
    drafts: [],
    activeDashboard: null,
    dashboardToken: null,
  },
  getters: {
    selectDashboards: (state) => state.dashboards,
    selectDrafts: (state) => state.drafts,
    selectActiveDashboard: (state) => state.activeDashboard,
    selectDashboardToken: (state) => state.dashboardToken,
    selectBoldBiUrl: () => Config.boldBI,
    selectOBSUrl: () => Config.apiApp,
  },
  mutations: {
    SET_DASHBOARDS(state, dashboards) {
      state.dashboards = dashboards
    },
    SET_DRAFTS(state, drafts) {
      state.drafts = drafts
    },
    SET_ACTIVE_DASHBOARD(state, dashboard) {
      state.activeDashboard = dashboard
    },
    SET_DASHBOARD_TOKEN(state, token) {
      state.dashboardToken = token
    },
  },
  actions: {
    async fetchReportDashboards({ commit, getters }) {
      const response = await ReportService.fetchReportDashboards(getters.selectDashboardToken)
      const dashboards = response.data.Data || []
      commit('SET_DASHBOARDS', dashboards)
      if (dashboards.length) {
        const matchedDashboard = dashboards.find((d) => d.Id === getters.selectActiveDashboard?.Id)
        commit('SET_ACTIVE_DASHBOARD', matchedDashboard || dashboards[0])
      }
    },
    async fetchReportDashboardDrafts({ commit, getters }) {
      const response = await ReportService.fetchReportDashboardDrafts(getters.selectDashboardToken)
      const drafts = response.data || []
      commit('SET_DRAFTS', drafts)
    },
    async setActiveDashboard({ commit }, dashboard) {
      commit('SET_ACTIVE_DASHBOARD', dashboard)
    },
    async fetchDashboardToken({ commit }) {
      const response = await ReportService.fetchToken()
      commit('SET_DASHBOARD_TOKEN', response.body.data.token)
    },
    async deleteDashboard({ getters, dispatch }, dashboard) {
      const response = await ReportService.deleteDashboard(dashboard.Id)
      if (getters.selectActiveDashboard.Id === dashboard.Id) {
        await dispatch('setActiveDashboard', {})
      }
      dispatch('fetchReportDashboards')
    },
  },
}
