var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 p-0",staticStyle:{"max-width":"900px"}},_vm._l((_vm.reports),function(report,index){return _c('div',{key:index},[_c('div',{staticClass:"card shadow clickable",class:{ selectedCard: _vm.selectedCardIndex === index },on:{"click":function($event){return _vm.setSelectedCardIndex(index)}}},[_c('div',{staticClass:"px-4 pt-4 pb-0 row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"card-title pb-0 mb-3 h4"},[_vm._v(" "+_vm._s(report.question.question)+" ")]),_c('div',{staticClass:"text-capitalize card-subtitle text-muted mt-1",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.typeSubtitle(report.question.type))+" ")])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"text-right"},[_c('animated-number',{staticClass:"font-weight-bold report-count",attrs:{"value":report.answerCount,"formatValue":parseInt,"duration":500}}),_c('span',{staticClass:"report-count-text"},[_vm._v(" "+_vm._s(report.answerCount === 1 ? 'reponse' : 'responses')+" ")])],1),_c('div',{staticClass:"text-right mb-2 mt-1"},[_c('span',{staticClass:"badge text-capitalize badge-soft-primary",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getCategoryName(report.question.category))+" ")])])])]),_c('div',{staticClass:"px-5 border-top"},[(report.question.type === 'SHORT_ANSWER' && report.answerCount)?_c('ShortAnswerReport',{attrs:{"reportData":report.reportData}}):_vm._e(),(report.question.type === 'RATING_SCALE' && report.answerCount)?_c('RatingScaleReport',{attrs:{"reportData":report.reportData}}):_vm._e(),(
              (report.question.type === 'SINGLE_SELECTION' ||
                report.question.type === 'MULTIPLE_SELECTION') &&
              report.answerCount
            )?_c('SelectionReport',{attrs:{"reportData":report.reportData,"options":report.question.questionData.options}}):_vm._e()],1)]),(_vm.selectedCardIndex === index && report.childReports.length)?_c('div',{staticClass:"d-flex align-items-end flex-column"},_vm._l((report.childReports),function(childReport,index){return _c('div',{key:index,staticClass:"card shadow",staticStyle:{"width":"90%"}},[_c('div',{staticClass:"text-center border-bottom p-2 bg-light rounded-top"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Follow up for: ")]),(report.question.type === 'RATING_SCALE')?_c('span',[_c('img',{staticClass:"mt--2 ml-2",attrs:{"src":`/img/ratings/${_vm.getParentOption({ report, childReport })}.png`,"alt":`rating ${_vm.getParentOption({ report, childReport })} emoji`,"contain":"","height":"20px"}})]):_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getParentOption({ report, childReport }),85))+" ")])]),_c('div',{staticClass:"px-4 pt-3 pb-0 row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"card-title pb-0 mb-3 h4"},[_vm._v(" "+_vm._s(childReport.question.question)+" ")]),_c('div',{staticClass:"text-capitalize card-subtitle text-muted mt-1",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.typeSubtitle(childReport.question.type))+" ")])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"text-right"},[_c('animated-number',{staticClass:"font-weight-bold report-count",attrs:{"value":childReport.answerCount,"formatValue":parseInt,"duration":500}}),_c('span',{staticClass:"report-count-text"},[_vm._v(" "+_vm._s(childReport.answerCount === 1 ? 'reponse' : 'responses')+" ")])],1)])]),_c('div',{staticClass:"px-5 border-top"},[(childReport.question.type === 'SHORT_ANSWER' && childReport.answerCount)?_c('ShortAnswerReport',{attrs:{"reportData":childReport.reportData}}):_vm._e(),(childReport.question.type === 'RATING_SCALE' && childReport.answerCount)?_c('RatingScaleReport',{attrs:{"reportData":childReport.reportData}}):_vm._e(),(
                (childReport.question.type === 'SINGLE_SELECTION' ||
                  childReport.question.type === 'MULTIPLE_SELECTION') &&
                childReport.answerCount
              )?_c('SelectionReport',{attrs:{"reportData":childReport.reportData,"options":childReport.question.questionData.options}}):_vm._e()],1)])}),0):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }