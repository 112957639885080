<template>
  <div class="card p-4 mb-3">
    <div class="d-flex justify-content-between">
      <div>
        <img
          :src="`/img/ratings/${rating}.png`"
          :alt="`rating ${rating} emoji`"
          contain
          height="20"
          class="ml-2"
        />
        <small class="ml-1 text-muted"> ({{ rating }}) </small>
      </div>
      <div>
        <div :class="[status === 'Enabled' ? 'text-success' : 'text-danger']">
          {{ status }}
          <i
            class="fas fa-info-circle text-muted ml-1 fa-sm"
            v-b-popover:filters.hover.bottom="disabledReason"
            v-if="status === 'Disabled'"
          ></i>
        </div>
      </div>
    </div>

    <!-- toggle -->
    <div class="d-flex mt-4">
      <div
        class="custom-control custom-checkbox-toggle"
        v-b-popover:filters.hover.top="aiToggleText"
        :disabled="hasAuthor"
      >
        <input
          :checked="responseSettings.aiResponse"
          class="custom-control-input"
          :id="'ratingToggle' + rating"
          type="checkbox"
          @change="toggleAIResponse"
          :disabled="!hasAuthor"
        />
        <label class="custom-control-label" :for="'ratingToggle' + rating"> </label>
      </div>
      <small class="ml-2 text-muted"> AI Suggested - {{ toggleLabelText }} </small>
    </div>

    <!-- template selection -->
    <div class="mt-4" v-if="!responseSettings.aiResponse">
      <div class="mb-1">
        <span class="text-muted"> Select template </span>
        <i
          class="fas fa-info-circle text-muted ml-1 fa-sm"
          v-b-popover:filters.hover.bottom="templatePopupText"
        ></i>
      </div>
      <!-- template selector dropdown -->
      <AutoTemplatePicker
        :rating="rating"
        :disabled="responseSettings.aiResponse"
        @change="updateTemplates"
        :init="responseSettings.templates"
      />
    </div>
  </div>
</template>

<script>
import AutoTemplatePicker from './AutoTemplatePicker'

export default {
  name: 'CompanySettingsResponsesRating',
  props: {
    rating: {
      type: Number,
      required: true,
    },
    init: {
      type: Object,
    },
    hasAuthor: {
      type: Boolean,
    },
    hasDelay: {
      type: Boolean,
    },
  },
  components: {
    AutoTemplatePicker,
  },
  data: () => ({
    responseSettings: {
      aiResponse: false,
      templates: [],
    },
    templatePopupText: 'If multiple templates are selected, they will be sent to guests at random.',
    aiToggleText: 'Enabling AI requires an Author Signature.',
  }),
  beforeMount() {
    if (this.init) {
      this.responseSettings.aiResponse = this.init.aiResponse
      this.responseSettings.templates = [...this.init.templates]
    }
  },
  computed: {
    toggleLabelText() {
      return this.responseSettings.aiResponse
        ? 'AI will craft the perfect response'
        : 'let AI craft the perfect response'
    },
    status() {
      if (
        this.hasDelay &&
        ((this.responseSettings.aiResponse && this.hasAuthor) ||
          this.responseSettings.templates.length > 0)
      ) {
        return 'Enabled'
      } else {
        return 'Disabled'
      }
    },
    disabledReason() {
      let reason = ''
      if (!this.hasDelay) {
        reason += 'You must set a delay.'
      }
      if (!(this.responseSettings.aiResponse || this.responseSettings.templates.length > 0)) {
        reason.length > 0 ? (reason += ' Also, y') : (reason += 'Y')
        reason += 'ou must either select Automated Template(s) or enable AI response.'
      }
      return reason
    },
  },
  methods: {
    updateTemplates(templates) {
      this.responseSettings.templates = templates
      this.update()
    },
    toggleAIResponse() {
      this.responseSettings.aiResponse = !this.responseSettings.aiResponse
      if (this.responseSettings.aiResponse) {
        this.responseSettings.templates = []
      }
      this.update()
    },
    update() {
      this.$emit('update', this.rating, this.responseSettings)
    },
  },
}
</script>
