<template>
  <div title="Template">
    <!-- Add Template Modal -->
    <CreateTemplateModal v-if="includeModal" @added="addedTemplate" ref="createTemplateModal" />

    <!-- Button to add if no templates exist -->
    <a
      class="btn btn-white btn-sm mb-0"
      href="javascript:;"
      v-if="!filteredTemplates.length"
      @click="addTemplate"
    >
      <i class="fe fe-plus"></i> Template
    </a>

    <!-- Templates Dropdown -->
    <b-dropdown
      :variant="selectedTemplate ? 'primary' : 'link'"
      size="sm"
      v-if="filteredTemplates.length"
      class="hide-caret"
      id="template-dropdown"
    >
      <template slot="button-content">
        <span
          class="text-capitalize d-flex align-items-center"
          :class="{ 'p-1': selectedTemplate }"
        >
          <FileTextIcon
            size="1.56x"
            :class="{ 'action-icon': !selectedTemplate }"
            :style="{ color: selectedTemplate ? 'white' : '#95aac9' }"
          ></FileTextIcon>
          <span class="mb--1 ml-1">
            {{ selectedTemplate ? selectedTemplate.title : '' | truncate(16) }}
          </span>
        </span>
      </template>
      <b-dropdown-item
        v-for="template in filteredTemplates"
        :key="template._id"
        class="dropdown-item text-capitalize"
        @click="setTemplate(template)"
        >{{ template.title | truncate(50) }}</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="addTemplate" v-if="selectIsPermitted('templateReadAccess')"
        >Create Template</b-dropdown-item
      >
      <div v-if="selectedTemplate" class="dropdown-divider"></div>
      <b-dropdown-item class="text-warning" @click="reset()" v-if="selectedTemplate"
        >Reset</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import { FileTextIcon } from 'vue-feather-icons'
import CreateTemplateModal from '@/components/Modals/CreateTemplateModal'
import { createNamespacedHelpers } from 'vuex'
const TemplateModule = createNamespacedHelpers('template')
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'TemplateDropdownV2',
  props: ['includeModal', 'locationId', 'companyId'],
  components: { CreateTemplateModal, FileTextIcon },
  data: () => ({
    loadModal: false,
    selectedTemplate: null,
  }),
  computed: {
    ...TemplateModule.mapGetters(['templatesByLocation']),
    ...CampaignModule.mapGetters(['campaign']),
    ...CompanyModule.mapGetters(['selectIsPermitted']),
    filteredTemplates() {
      return this.templatesByLocation(this.locationId)
    },
  },
  methods: {
    ...TemplateModule.mapActions(['fetchTemplates']),
    addTemplate() {
      this.loadModal = true
      this.$root.$emit('add-template')
      if (this.includeModal) this.$refs.createTemplateModal.open()
    },
    setTemplate(template) {
      this.selectedTemplate = template
      this.$emit('selected', template)
    },
    reset() {
      this.selectedTemplate = null
      this.$emit('reset')
    },
    async addedTemplate(template) {
      this.fetchTemplates()
      this.setTemplate(template)
    },
  },
  async mounted() {
    await this.fetchTemplates(this.companyId)

    if (this.campaign.template) {
      this.setTemplate(
        this.filteredTemplates.find((template) => this.campaign.template === template._id)
      )
    }

    this.$root.$on('added-template', this.addedTemplate)
  },
  destroyed() {
    this.$root.$off('added-template', this.addedTemplate)
  },
}
</script>

<style scoped lang="scss">
a {
  text-transform: capitalize;
}
.dropdown-item {
  font-size: 14px;
}
.action-icon {
  color: #95aac9;
}
.action-icon:hover {
  color: #12263f !important;
}
::v-deep #template-dropdown .btn:focus {
  box-shadow: none !important;
}
::v-deep #template-dropdown .btn-primary:focus {
  box-shadow: none !important;
}
::v-deep #template-dropdown .btn-sm {
  padding: 0 0 0 0 !important;
}
</style>
