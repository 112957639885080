import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    exports: [],
  },
  getters: {
    selectExports(state) {
      return state.exports
    },
  },
  mutations: {
    SET_EXPORTS(state, exports) {
      state.exports = [state.exports, exports]
    },
  },
  actions: {
    async exportComplete({ commit }, message) {
      const { url: fileUrl } = message.data

      if (!fileUrl) {
        return
      }

      commit('SET_EXPORTS', message)

      // download the file
      let fileLink = document.createElement('a')
      fileLink.href = fileUrl
      document.body.appendChild(fileLink)
      fileLink.click()

      Vue.notify({
        type: 'success',
        text: 'Successfully Downloaded Export',
      })
    },
  },
}
