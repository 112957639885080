import moment from 'moment-timezone'
import chrono from 'chrono-node'

/**
 * Different date range names for querying customers
 */
const DATE_RANGE_TYPES = {
  ALL_TIME: {
    NAME: 'All time',
    TYPE: 'all',
  },
  IN_THE_LAST: {
    NAME: 'In the last',
    TYPE: 'ago',
  },
  DATE_RANGE: {
    NAME: 'From',
    TYPE: 'range',
  },
  SINCE_A_DATE: {
    NAME: 'Since',
    TYPE: 'since',
  },
  ON_A_DATE: {
    NAME: 'On',
    TYPE: 'on',
  },
}

/**
 * Different date range units
 */
const DATE_RANGE_UNITS = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
}

export function dateMapper(date) {
  const today = moment()
  const yesterday = today.clone().subtract(1, 'day')

  if (moment(date).isSame(today, 'day')) {
    return 'Today'
  } else if (moment(date).isSame(yesterday, 'day')) {
    return 'Yesterday'
  } else {
    return `${moment(date).format('ll')}`
  }
}

/**
 * Parses date settings for queries
 */
export function parseDateSettings(filters = {}) {
  const { type, date, unit } = filters

  switch (type) {
    case DATE_RANGE_TYPES.ALL_TIME.TYPE:
      return null

    case DATE_RANGE_TYPES.IN_THE_LAST.TYPE:
      return { $gte: moment().subtract(date, unit).startOf('day').toDate() }

    case DATE_RANGE_TYPES.SINCE_A_DATE.TYPE:
      return { $gte: parseDateTimeString(date).startOf('day') }

    case DATE_RANGE_TYPES.ON_A_DATE.TYPE:
      const parsedStartDate = parseDateTimeString(date)
      const parsedEndDate = parseDateTimeString(date + 1)
      return {
        $gt: parsedStartDate.startOf('day'),
        $lte: parsedEndDate.endOf('day'),
      }

    case DATE_RANGE_TYPES.DATE_RANGE.TYPE:
      const [startDate, endDate] = date.split(' to ')
      return {
        $gte: parseDateTimeString(startDate).startOf('day'),
        $lt: parseDateTimeString(endDate).endOf('day'),
      }

    default:
      throw 'Invalid date filter type.'
  }
}

/**
 * Parses a datetime string into a date object
 */
function parseDateTimeString(dateTimeString) {
  return moment(chrono.parseDate(dateTimeString))
}
