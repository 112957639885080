import Vue from 'vue'

export default {
  getReportMetrics({metrics, filters}) {
    return Vue.http.post('app-services/v2/report/', {metrics, filters})
  },
  getCustomReports(companyId) {
    return Vue.http.get(`app-services/v2/custom-report/${companyId}`)
  },
  createCustomReport({companyId, name, metrics, filters, isDefault}) {
    return Vue.http.post('app-services/v2/custom-report/', {companyId, name, metrics, filters, isDefault})
  },
  updateCustomReport({reportId, name, metrics, filters, isDefault}) {
    return Vue.http.put(`app-services/v2/custom-report/${reportId}`, {name, metrics, filters, isDefault})
  }
}
