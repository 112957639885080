import Vue from 'vue'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  // Initialize Sentry
  Sentry.init({
    dsn: 'https://979e08d55a7144b7a879ea90bed2b3c6@sentry.io/1399036',
    integrations: [
      new Sentry.Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  })
}

import VueResource from 'vue-resource'
import VueMask from 'v-mask'
import App from './App.vue'
import router from './router'
import store from './store'
import Config from '@/config'
import { initCharts } from './lib/chart'
import { initFirebase } from './lib/firebase'
import { interceptor } from './lib/http'
import VueNotifications from 'vue-notification'
import TextHighlight from 'vue-text-highlight'
import ReadMore from 'vue-read-more'
import VueClipboard from 'vue-clipboard2'
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

applyPolyfills().then(() => {
  defineCustomElements(window)
})

Vue.config.ignoredElements = [/amplify-\w*/]

import {
  CardPlugin,
  TablePlugin,
  FormPlugin,
  BButton,
  BCollapse,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BDropdownItem,
  BDropdownText,
  BFormTimepicker,
  BFormSpinbutton,
  BPopover,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BSpinner,
  BSkeleton,
  BSkeletonTable,
  BProgress,
  BTable,
  BTh,
  BSidebar,
  BVModalPlugin,
  VBModal,
  VBPopover,
  VBToggle,
  VBTooltip,
  FormTextareaPlugin,
} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import vSelect from 'vue-select'
import currencyFilter from './filters/currency'
import truncateFilter from './filters/truncate'
import phoneFilter from './filters/phone'
import VueIntercom from 'vue-intercom'
import VueFlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueTextareaAutosize from 'vue-textarea-autosize'
import 'vue-popperjs/dist/css/vue-popper.css'
import VueApexCharts from 'vue-apexcharts'
import vueReadMore from 'vue-read-more'
import VueFeatherIcons from 'vue-feather-icons'
import { InlineSvgPlugin } from 'vue-inline-svg'

// Initialize global Chart.js options
initCharts()

// Initialize Firebase connection
initFirebase()

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Setup Intercom
const { intercom: intercomAppId } = Config
Vue.use(VueIntercom, { appId: intercomAppId })

Vue.use(VueResource)
Vue.use(VueMask)
Vue.use(VueNotifications)
Vue.use(ReadMore)
Vue.use(VueTextareaAutosize)
Vue.use(VueMoment, { moment })
Vue.use(VueFlatPickr)
Vue.use(InlineSvgPlugin)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

// Bootstrap Vue
Vue.use(CardPlugin)
Vue.use(BVModalPlugin)
Vue.use(FormPlugin)
Vue.use(FormTextareaPlugin)
Vue.component('b-button', BButton)
Vue.component('b-collapse', BCollapse)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-dropdown-form', BDropdownForm)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-text', BDropdownText)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-timepicker', BFormTimepicker)
Vue.component('b-form-spinbutton', BFormSpinbutton)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group-prepend', BInputGroupPrepend)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-modal', BModal)
Vue.component('b-spinner', BSpinner)
Vue.component('b-progress', BProgress)
Vue.component('b-table', BTable)
Vue.component('b-th', BTh)
Vue.component('b-popover', BPopover)
Vue.component('b-sidebar', BSidebar)
Vue.component('text-highlight', TextHighlight)
Vue.component('b-skeleton', BSkeleton)
Vue.component('b-skeleton-table', BSkeletonTable)
Vue.directive('b-modal', VBModal)
Vue.directive('b-popover', VBPopover)
Vue.directive('b-toggle', VBToggle)
Vue.directive('b-tooltip', VBTooltip)
Vue.component('v-select', vSelect)

Vue.filter('currency', currencyFilter)
Vue.filter('truncate', truncateFilter)
Vue.filter('phone', phoneFilter)

Vue.config.productionTip = false

Vue.http.interceptors.push(interceptor)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
