import CustomFilterService from '@/services/CustomFilterService'
import { assignIn, omit } from 'lodash-es'
import Vue from 'vue'

const defaultCustomFilter = () => ({
  collectionName: '',
  companyFilters: [],
  locationFilters: [],
  analyticEventFilters: [],
  dateFilters: [],
  surveyFilters: [],
  tagFilters: [],
  customQuestionAnswerFilters: [],
})

const INCLUDE = 'include'
const EXCLUDE = 'exclude'
const DEFAULT_BEHAVIOR = INCLUDE
const COLLECTION_NAMES = {
  CUSTOMERS: 'customers',
}

const selectPromoId = (promos) => {
  if (Array.isArray(promos) && promos.length) {
    return { promoId: promos[0] }
  }
  return {}
}

export default {
  namespaced: true,
  state: {
    customFilter: defaultCustomFilter(),
    count: 0,
    previewCustomerList: [],
    isLoadingCounter: 0,
    skip: 0,
    limit: 3,
    isLoadingMore: false,
    isEventLimitReached: false,
  },
  getters: {
    selectCustomFilter: (state) => state.customFilter,
    selectCount: (state) => state.count || 0,
    selectIsLoading: (state) => !!state.isLoadingCounter,
    selectPreviewCustomerList: (state) => state.previewCustomerList,
    selectLimit: (state) => state.limit,
    selectSkip: (state) => state.skip,
    selectCollectionName: (state) => state.customFilter.collectionName,
    selectCompanyFilters: (state) => state.customFilter.companyFilters,
    selectLocationFilters: (state) => state.customFilter.locationFilters,
    selectAnalyticEventFilters: (state) => state.customFilter.analyticEventFilters,
    selectDateFilters: (state) => state.customFilter.dateFilters,
    selectCustomQuestionAnswerFilters: (state) => state.customFilter.customQuestionAnswerFilters,
    selectSurveyFilters: (state) => state.customFilter.surveyFilters,
    selectTagFilters: (state) => state.customFilter.tagFilters,
    selectFirstFilterCompanies: (state) =>
      state.customFilter.companyFilters?.length
        ? state.customFilter.companyFilters[0].companies
        : null,
    selectFirstFilterLocations: (state) =>
      state.customFilter.locationFilters?.length
        ? state.customFilter.locationFilters[0].locations
        : null,
    selectAllFilterActions: (state) =>
      state.customFilter.analyticEventFilters?.map((a) => {
        return {
          did: a.behavior === INCLUDE ? true : false,
          eventType: a.types[0],
          ...(a.campaigns?.length ? { campaignId: a.campaigns[0] } : {}),
          ...selectPromoId(a.promos),
        }
      }),
    selectFirstFilterDateSettings: (state) =>
      state.customFilter.dateFilters?.length ? state.customFilter.dateFilters[0] : null,
    selectFirstFilterRatingSurveyFilter: (state) =>
      state.customFilter.surveyFilters?.length
        ? state.customFilter.surveyFilters.find((sf) => sf.ratings)
        : null,
    // TODO: tagFilters and customQuestionAnswerFilters first getters
    selectFirstFilterCustomQuestionAnswer: (state) =>
      state.customFilter.customQuestionAnswerFilters?.length
        ? state.customFilter.customQuestionAnswerFilters[0]
        : null,
    selectFirstFilterTags: (state) =>
      state.customFilter.tagFilters?.length ? state.customFilter.tagFilters[0].tags : null,
    selectIsLoadingMore: (state) => state.isLoadingMore,
    selectIsEventLimitReached: (state) => state.isEventLimitReached,
  },
  mutations: {
    SET_CUSTOM_FILTER(state, customFilter) {
      state.customFilter = assignIn(defaultCustomFilter(), customFilter)
    },
    SET_COUNT(state, count) {
      state.count = count
    },
    SET_PREVIEW_CUSTOMER_LIST(state, customerList) {
      state.previewCustomerList = customerList
    },
    SET_IS_LOADING(state, status) {
      const increment = status ? 1 : -1
      state.isLoadingCounter += increment
    },
    SET_SKIP(state, skip) {
      state.skip = skip
    },
    RESET_CUSTOM_FILTER(state) {
      state.customFilter = defaultCustomFilter()
    },
    SET_IS_LOAD_MORE(state, loadMore) {
      state.isLoadingMore = loadMore
    },
    SET_EVENT_LIMIT_REACHED(state, reached) {
      state.isEventLimitReached = reached
    },
  },
  actions: {
    async resetCustomFilter({ commit }) {
      commit('RESET_CUSTOM_FILTER')
    },

    // activeCampaignNotReceived (if passed) will be the campaignId of an 'auto' campaign being edited
    async formatFilterPayload({ getters, rootGetters }, activeCampaignNotReceived) {
      const eventTypes = rootGetters['actionFilter/selectAnalyticEventTypesV2']
      // check if current campaignSetup is of type 'auto' and if it is being edited (has and _id)
      // if so then add the analyticEventFilter for 'did not receive <currentCampaign>'
      const organization = rootGetters['organization/selectOrganization']
      const activeCompany = rootGetters['company/selectActiveCompany']
      const campaignSetup = rootGetters['campaignSetups/selectCampaignSetup']
      const isSavingOrUpdating = rootGetters['campaignSetups/selectIsSavingOrUpdating']
      const isEditingAutoCampaign = campaignSetup.type === 'auto' && campaignSetup._id
      const filters = getters.selectCustomFilter
      return {
        collectionName: filters.collectionName,
        ...(organization && activeCompany._id === 'ALL_COMPANIES'
          ? { organizationId: organization._id }
          : {}),
        // always pass companyFilters
        companyFilters: filters.companyFilters.length
          ? [...filters.companyFilters]
          : [
              {
                companies: rootGetters['company/selectRelevantCompanies'].map((c) => c._id),
                behavior: DEFAULT_BEHAVIOR,
              },
            ],
        ...(filters.locationFilters.length && filters.locationFilters[0].locations.length
          ? { locationFilters: filters.locationFilters }
          : {}),
        ...(filters.dateFilters.length
          ? {
              dateFilters: filters.dateFilters.map((dF) =>
                dF.unitQuantity ? { ...dF, unitQuantity: parseInt(dF.unitQuantity) } : dF
              ),
            }
          : {}),
        ...(filters.analyticEventFilters.length
          ? {
              analyticEventFilters: [
                ...filters.analyticEventFilters,
                ...(isEditingAutoCampaign && !isSavingOrUpdating
                  ? [
                      {
                        types: [eventTypes.SENT_CAMPAIGN],
                        behavior: EXCLUDE,
                        campaigns: [campaignSetup._id],
                      },
                    ]
                  : []),
              ],
            }
          : isEditingAutoCampaign && !isSavingOrUpdating
          ? {
              analyticEventFilters: [
                {
                  types: ['sent-campaign'],
                  behavior: EXCLUDE,
                  campaigns: [campaignSetup._id],
                },
              ],
            }
          : {}),
        ...(filters.surveyFilters.length ? { surveyFilters: filters.surveyFilters } : {}),
        ...(filters.tagFilters.length ? { tagFilters: filters.tagFilters } : {}),
        ...(filters.customQuestionAnswerFilters.length
          ? { customQuestionAnswerFilters: filters.customQuestionAnswerFilters }
          : {}),
      }
    },

    async fetchAudiencePreview({ getters, commit, dispatch }, { loadMore }) {
      commit('SET_SKIP', loadMore ? getters.selectSkip + getters.selectLimit : 0)
      commit('SET_IS_LOADING', true)
      commit('SET_IS_LOAD_MORE', loadMore ? true : false)
      const formattedFilters = await dispatch('formatFilterPayload')
      try {
        await CustomFilterService.fetchPreview({
          customFilter: formattedFilters,
          skip: getters.selectSkip,
          limit: getters.selectLimit,
        })
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong. Please try again try.',
        })
        commit('SET_IS_LOADING', false)
      }
    },

    async resetCustomFilter({ commit }) {
      commit('SET_CUSTOM_FILTER', defaultCustomFilter())
    },

    setIsFetchAudiencePreview({ commit }, status) {
      commit('SET_IS_LOADING', status)
    },

    setAudiencePreviewCount({ commit }, count) {
      commit('SET_COUNT', count)
    },

    setIsEventLimitReached({ commit }, reached) {
      commit('SET_EVENT_LIMIT_REACHED', reached)
    },

    setPreviewCustomerList({ commit }, customers) {
      commit('SET_PREVIEW_CUSTOMER_LIST', customers)
    },

    async setCustomFilterFromCampaignSetup({ commit }, customFilter) {
      let newCustomFilter = omit(customFilter, ['__v', '_id', 'createdAt', 'updatedAt'])
      commit('SET_CUSTOM_FILTER', newCustomFilter)
    },

    async setCustomFilter(
      { commit, getters, rootGetters },
      {
        filterComponent,
        companyFilter,
        locationFilter,
        dateFilter,
        ratingFilter,
        actionFilter,
        tagFilter,
        customQuestionAnswerFilter,
      }
    ) {
      const customFilter = { ...getters.selectCustomFilter }
      const eventTypes = rootGetters['actionFilter/selectAnalyticEventTypesV2']

      if (filterComponent) {
        let collectionName
        switch (filterComponent) {
          case 'campaign-creation':
            collectionName = COLLECTION_NAMES.CUSTOMERS
            break
          default:
            collectionName = ''
        }
        customFilter.collectionName = collectionName
      }
      if (companyFilter) {
        customFilter.companyFilters = companyFilter.companies.length
          ? [
              {
                companies: companyFilter.companies,
                behavior: companyFilter.behavior || DEFAULT_BEHAVIOR,
              },
            ]
          : []
      }
      if (locationFilter) {
        customFilter.locationFilters = locationFilter.locations.length
          ? [
              {
                locations: locationFilter.locations,
                behavior: locationFilter.behavior || DEFAULT_BEHAVIOR,
              },
            ]
          : []
      }
      if (dateFilter) {
        customFilter.dateFilters = [
          {
            type: dateFilter.type,
            behavior: dateFilter.behavior || DEFAULT_BEHAVIOR,
            ...(dateFilter.type === 'ago'
              ? { unit: dateFilter.unit, unitQuantity: dateFilter.unitQuantity }
              : {}),
            ...(['range', 'since', 'on'].includes(dateFilter.type)
              ? { dates: dateFilter.dates }
              : {}),
          },
        ]
      }
      if (ratingFilter) {
        customFilter.surveyFilters = ratingFilter.ratings.length
          ? [{ ratings: ratingFilter.ratings, behavior: ratingFilter.behavior || DEFAULT_BEHAVIOR }]
          : []
      }
      if (actionFilter) {
        customFilter.analyticEventFilters = actionFilter.actions.map((a) => ({
          types: [a.eventType],
          behavior: a.did ? INCLUDE : EXCLUDE,
          ...(a.eventType === eventTypes.SENT_CAMPAIGN && a.campaignId
            ? { campaigns: [a.campaignId] }
            : {}),
          ...(a.eventType === eventTypes.REDEEM_PROMO && a.promoId ? { promos: [a.promoId] } : {}),
        }))
      }
      if (tagFilter) {
        customFilter.tagFilters = tagFilter.tags.length
          ? [
              {
                tags: tagFilter.tags.map((t) => t._id),
                tagNames: tagFilter.tags.map((t) => t.name),
                behavior: tagFilter.behavior || DEFAULT_BEHAVIOR,
              },
            ]
          : []
      }
      if (customQuestionAnswerFilter) {
        customFilter.customQuestionAnswerFilters = customQuestionAnswerFilter.customQuestion
          ? [
              {
                ...customQuestionAnswerFilter,
                behavior: customQuestionAnswerFilter.behavior || DEFAULT_BEHAVIOR,
              },
            ]
          : []
      }

      commit('SET_CUSTOM_FILTER', customFilter)
    },
  },
}
