function swap(a, b, arr) {
  arr[a] = arr.splice(b, 1, arr[a])[0]
  return arr
}

// takes an array of objects and a specified fieldName and sums the value from each object
// has a fallback to zero if the field doesn't exist
export function arrayOfObjectsSum(arr, objFieldName) {
  return arr.reduce((acc, curr) => acc += (curr[objFieldName] || 0), 0)
}

// takes an array and returns the sum of each value
export function arraySum(arr) {
  return arr.reduce((acc, curr) => acc += curr, 0)
}

export function sortRatingBeforeFeedback(events) {
  events.map((timestamp) => {
    timestamp.events.map((event, index, arr) => {
      const next = arr.length > index + 1 ? arr[index + 1] : arr[index]

      if (
        event.type === 'left-feedback' &&
        next.type === 'left-survey' &&
        event.survey?._id === next.survey?._id
      ) {
        const nextIndex = arr.length > index + 1 ? index + 1 : index
        // swapping the events around
        return swap(index, nextIndex, arr)
      }
      return arr
    })
  })

  return events
}

export function scrollToLastPosition(id, y, offset) {
  const element = document.getElementById(id)
  if (element) {
    element.scrollTo({ top: element.scrollHeight - (y + offset) })
  } else {
    console.log(`Element with id - ${id} not found.`)
  }
}

export async function scrollToElement(id) {
  let element = await waitForElement(id, 100, 100)
  if (element) {
    element.scrollIntoView()
  } else {
    console.log(`Element with id - ${id} not found.`)
  }
}

export function waitForElement(id, wait, max) {
  let count = 0
  return new Promise((resolve) => {
    let interval = setInterval(() => {
      if (count == max) {
        resolve()
        clearInterval(interval)
      }

      let element = document.getElementById(id)
      if (element) {
        resolve(element)
        clearInterval(interval)
      }

      count += 1
    }, wait)
  })
}
