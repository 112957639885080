<template>
  <div class="card pt-4 px-4 text-center" v-if="selectLocationMiniHeatmapSeries">
    <h3 class="text-muted mb-0" style="font-size: 14px">Incidents</h3>
    <apexchart
      :height="heatmapHeight + 'px'"
      type="heatmap"
      :series="selectLocationMiniHeatmapSeries"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'LocationMiniHeatmap',
  computed: {
    ...InsightsDetailsModule.mapGetters(['selectLocationMiniHeatmapSeries']),
    ...LocationModule.mapGetters(['selectLocationName']),
    heatmapHeight() {
      const cellHeight = 40
      const xAxisHeight = 68
      const projectedHeight = cellHeight + xAxisHeight
      return projectedHeight
    },
    chartOptions() {
      return {
        chart: {
          type: 'heatmap',
          toolbar: { show: false },
          zoom: { enabled: false },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$router.replace({
                path: '/insights/details',
                query: {
                  ...this.$route.query,
                  locs: this.selectLocationMiniHeatmapSeries[0].locationIds[config.dataPointIndex],
                  preventRedirect: true,
                },
              })
              this.$emit('refreshDetails')
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#12263F'],
          },
          formatter: (val, opts) => {
            return val ? val + '%' : '0%'
          },
        },
        xaxis: {
          categories: this.selectLocationMiniHeatmapSeries[0].locationIds,
          tooltip: { enabled: false },
          position: 'top',
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: {
            formatter: (val, opts) => {
              let label = this.selectLocationName(val)
              return label && label.length > 20 ? label.slice(0, 19).concat('...') : label
            },
            style: {
              fontWeight: 'bold',
            },
            hideOverlappingLabels: false,
            trim: true,
          },
        },
        yaxis: {},
        legend: { show: false },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const incidentCount = this.selectLocationMiniHeatmapSeries[seriesIndex].counts[dataPointIndex]
            const totalCount = this.selectLocationMiniHeatmapSeries[seriesIndex].totalCounts[dataPointIndex]
            return `
              ${incidentCount} ${incidentCount === 1 ? 'incident' : 'incidents'}
              out of ${totalCount} ${totalCount === 1 ? 'piece' : 'pieces'} of feedback
            `
          },
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: '#FFFFFF',
                },
                {
                  from: 1,
                  to: 5,
                  color: '#FED2DA',
                },
                {
                  from: 6,
                  to: 10,
                  color: '#FDA5B5',
                },
                {
                  from: 11,
                  to: 100,
                  color: '#FB4B6B',
                },
              ],
            },
          },
        },
      }
    },
  },
}
</script>

<style scoped>
#line {
  color: white;
}
.bg-light-green {
  background: #ccf7e5;
}
.bg-light-red {
  background: #ffcccb;
}
</style>
