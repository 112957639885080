<script>
import { createNamespacedHelpers } from 'vuex'
const { mapMutations, mapState, mapActions } = createNamespacedHelpers('company')

export default {
  computed: {
    ...mapState(['activeCompany']),
  },
  methods: {
    ...mapActions(['refreshCompanyList']),
    ...mapMutations(['setActiveSettingsTab']),
  },
  mounted() {
    this.setActiveSettingsTab(this.$route.name)
  },
}
</script>
