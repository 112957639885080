import CustomQuestionService from '@/services/CustomQuestionService'

const DEFAULT_DATERANGE = {
  date: '30',
  defaultValue: 90,
  name: 'In the last',
  type: 'ago',
  unit: 'days',
}
const DEFAULT_FILTERS = { status: 'ACTIVE' }
const DEFAULT_REPORTS_FILTERS = { dateSettings: DEFAULT_DATERANGE, status: 'ACTIVE' }

export default {
  namespaced: true,
  state: {
    isCreating: false,
    queryPending: false,
    refreshKey: 0,
    filterRefreshKey: 0,
    selectedCardIndex: null,
    activeCount: null,
    inactiveCount: null,
    filteredCount: null,
    questions: [],
    reportsFilters: DEFAULT_REPORTS_FILTERS,
    listFilters: DEFAULT_FILTERS,
    categories: [],
    reports: [],
    defaultOptionInputTitle: 'Click to edit title...',
    settings: null,
    creationStep: 1,
    isLoading: false,
  },
  getters: {
    selectQuestionsExist(state) {
      return state.activeCount || state.inactiveCount
    },
    getCategoryName: (state) => (id) => {
      const category = state.categories.find((category) => category._id === id)
      return category && category.name
    },
    settings(state) {
      return state.settings
    },
    getQuestions(state) {
      return state.questions
    },
    getCreationStep(state) {
      return state.creationStep
    },
    getCategories(state) {
      return state.categories
    },
    getDefaultOptionInputTitle(state) {
      return state.defaultOptionInputTitle
    },
    getSelectedCardIndex(state) {
      return state.selectedCardIndex
    },
    selectReportsFilters(state) {
      return state.reportsFilters
    },
    selectIsLoading(state) {
      return state.isLoading
    },
  },
  mutations: {
    SET_CREATION_STEP(state, step) {
      state.creationStep = step
    },
    SET_INACTIVE_COUNT(state, count) {
      state.inactiveCount = count
    },
    INCREMENT_REFRESH_KEY(state) {
      state.refreshKey += 1
    },
    SET_SELECTED_CARD_INDEX(state, index) {
      state.selectedCardIndex = index
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    toggleIsCreating(state) {
      state.isCreating = !state.isCreating
    },
    setQuestions(state, questions) {
      state.questions = questions
    },
    setListFilters(state, filters) {
      state.listFilters = filters
    },
    setReportsFilters(state, filters) {
      state.reportsFilters = filters
    },
    resetFilters(state) {
      state.filterRefreshKey += 1
      state.listFilters = DEFAULT_FILTERS
      state.reportsFilters = DEFAULT_REPORTS_FILTERS
    },
    setDefaultFilters(state) {
      state.listFilters = DEFAULT_FILTERS
      state.reportsFilters = DEFAULT_REPORTS_FILTERS
    },
    setActiveCount(state, count) {
      state.activeCount = count
    },
    setFilteredCount(state, count) {
      state.filteredCount = count
    },
    setQueryPending(state, pending) {
      state.queryPending = pending
    },
    setReports(state, reports) {
      state.reports = reports
    },
    setSettings(state, settings) {
      state.settings = settings || {}
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async getCustomQuestions({ commit, rootState, state }, includeFollowUp) {
      const { activeCompany } = rootState.company
      const response = await CustomQuestionService.list({
        companyId: activeCompany._id,
        filters: { ...state.listFilters, includeFollowUp },
      })
      const { questions, activeCount, inactiveCount } = response.data
      questions.sort((a, b) => new Date(b.created) - new Date(a.created))
      commit('setActiveCount', activeCount)
      commit('SET_INACTIVE_COUNT', inactiveCount)
      commit('setFilteredCount', questions.length)
      commit('setQuestions', questions)
    },

    async createCustomQuestion({ rootState }, { formData }) {
      const { activeCompany } = rootState.company
      const response = await CustomQuestionService.create({
        companyId: activeCompany._id,
        formData,
      })
      return response
    },

    async updateCustomQuestion({ dispatch }, { questionId, fields }) {
      const response = await CustomQuestionService.update({
        questionId,
        fields,
      })
      dispatch('getCustomQuestions')
    },

    async getReports({ state, rootState, commit }, filters) {
      const { activeCompany } = rootState.company
      const response = await CustomQuestionService.getReports({
        userId: rootState.user.onlineUser._id,
        companyId: activeCompany._id,
        filters: state.reportsFilters,
      })
      response.data.reports.sort(
        (a, b) => new Date(b.question.created) - new Date(a.question.created)
      )
      commit('setReports', response.data.reports)
    },

    async fetchCategories({ rootState, commit }) {
      const { activeCompany } = rootState.company
      const response = await CustomQuestionService.fetchCategories({ companyId: activeCompany._id })
      const { categories } = response.data
      commit('setCategories', categories)
    },

    // sends a real question list in response to a survey
    async sendCustomQuestionList({ state }, { surveyId }) {
      return CustomQuestionService.sendCustomQuestionList({ surveyId })
    },

    // sends a text to preview what a question list would look like for selected filters
    async sendCustomQuestionListPreview({ rootState, state }, { phoneNumbers }) {
      const { activeCompany } = rootState.company
      await CustomQuestionService.sendCustomQuestionListPreview({
        preview: {
          company: { _id: activeCompany._id, name: activeCompany.name, phone: activeCompany.phone },
          questionIds: state.questions.map((q) => q._id),
        },
        phoneNumbers,
      })
    },

    async saveCustomQuestionSettings({ rootState }, { fields }) {
      const { activeCompany } = rootState.company
      await CustomQuestionService.updateCustomQuestionSettings({
        companyId: activeCompany._id,
        fields,
      })
    },

    async getCustomQuestionSettings({ rootState, commit }) {
      const { activeCompany } = rootState.company
      const response = await CustomQuestionService.getCustomQuestionSettings({
        companyId: activeCompany._id,
      })
      commit('setSettings', response.data.settings)
    },

    async getMessage({ rootState, state }) {
      const fields = {
        customerName: ` ${rootState.user.onlineUser.firstName.trim()}`, // needs a space in front
        customQuestionSettings: state.settings,
      }
      const response = await CustomQuestionService.generateMessage(fields)
      return response.data.message
    },

    setCreationStep({ getters, commit }, stepSize) {
      commit('SET_CREATION_STEP', getters.getCreationStep + stepSize)
    },

    setIsLoading({ commit }, isLoading) {
      commit('SET_IS_LOADING', isLoading)
    },

    resetCreationStep({ commit }) {
      commit('SET_CREATION_STEP', 1)
    },

    incrementRefreshKey({ commit }) {
      commit('INCREMENT_REFRESH_KEY')
    },

    setSelectedCardIndex({ getters, commit }, index) {
      if (getters.getSelectedCardIndex === index) {
        commit('SET_SELECTED_CARD_INDEX', null)
      } else {
        commit('SET_SELECTED_CARD_INDEX', index)
      }
    },

    async exportCustomQuestions({ commit }, payload) {
      await CustomQuestionService.exportList(payload)
    },
  },
}
