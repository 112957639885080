<template>
  <div class="d-flex justify-content-between border-top py-4">
    <button
      class="btn btn-secondary btn-wide"
      :class="{ 'el-hide': hideBack }"
      @click="setCreationStep(-1)"
    >
      Back
    </button>
    <h4 class="d-flex align-items-center" :class="{ 'el-hide': hideStep }">Step {{ step }} of 3</h4>
    <button
      class="btn btn-primary btn-wide d-flex justify-content-center align-items-center"
      @click="save"
      v-if="showSave"
      :disabled="!allowNext || isLoading"
    >
      <i v-if="isLoading" class="mr-2 loader loader-white loader-sm" />{{
        isLoading ? 'Saving...' : 'Save'
      }}
    </button>
    <button
      class="btn btn-primary btn-wide"
      @click="setCreationStep(1)"
      v-else
      :disabled="!allowNext"
    >
      Next
    </button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'CreationFooter',
  props: {
    hideBack: {
      type: Boolean,
      default: false,
    },
    hideStep: {
      type: Boolean,
      default: false,
    },
    allowNext: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...QuestionModule.mapGetters({ step: 'getCreationStep', isLoading: 'selectIsLoading' }),
  },
  methods: {
    ...QuestionModule.mapActions(['setCreationStep']),
    save() {
      this.$emit('save')
    },
  },
}
</script>

<style scoped>
.btn-wide {
  min-width: 100px;
}
.el-hide {
  visibility: hidden;
}
</style>
