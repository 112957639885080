<template>
  <div :data-testid="integrationDetails.provider" class="card" :class="{ isConnectedIntegration }">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- Avatar -->
          <a href="#!" class="avatar avatar-sm">
            <img
              width="45"
              :src="integrationDetails.img"
              :alt="`${integrationDetails.provider} logo`"
            />
          </a>
        </div>
        <div class="col">
          <!-- Title -->
          <h4 class="card-title pt-2">
            <a href="#!">{{ integrationDetails.displayName || integrationDetails.name }}</a>
          </h4>

          <!-- Status -->
          <p class="card-text text-muted small">
            {{ integrationDetails.description }}
          </p>
        </div>

        <div class="col-auto">
          <div
            class="btn btn-sm btn-success btn-rounded-circle enabled-integration"
            v-if="connected"
          >
            <span class="fe fe-check"></span>
          </div>
          <button
            v-if="!connected"
            class="btn btn-sm btn-primary"
            v-on:click="
              isConnectedIntegration()
                ? updateEnabled(false)
                : $emit('authenticate', integrationDetails)
            "
          >
            {{ isConnectedIntegration() ? 'Enable' : 'Connect' }}
          </button>
        </div>
        <div class="col-auto" v-if="connected">
          <div class="dropdown btn btn-sm">
            <a
              href="#!"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
            >
              <i class="fe fe-more-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" v-on:click="$emit('edit-integration', integration)">
                Edit
              </a>
              <a class="dropdown-item" @click="updateEnabled(true)">
                <span class="text-danger">Disable</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
import IntegrationService from '@/services/IntegrationService'
import { getIntegrationDetails } from '@/lib/modules/integration'

export default {
  name: 'LocationSettingsIntegrationCard',
  props: ['integration', 'connected', 'connectedIntegration'],
  components: {},
  computed: {
    integrationDetails() {
      return this.integration ? getIntegrationDetails(this.integration.provider) : {}
    },
  },
  methods: {
    async updateEnabled(isDisabled) {
      await IntegrationService.update({
        integrationId: this.connectedIntegration._id,
        fields: { disabled: isDisabled },
      })

      this.$root.$emit('getLocationData')

      this.$notify({
        title: 'Integration updated',
        text: `${this.integration.name} has been ${isDisabled ? 'disabled' : 'enabled'}.`,
      })
    },
    isConnectedIntegration() {
      return this.connectedIntegration && this.connectedIntegration.disabled
    },
  },
}
</script>
