import GroupService from '@/services/GroupService'

export default {
  namespaced: true,
  state: {
    groups: [],
  },
  getters: {
    selectGroups(state) {
      return state.groups
    },
  },
  mutations: {
    SET_GROUPS(state, groups) {
      state.groups = groups
    },
  },
  actions: {
    async fetchGroups({ commit }, { companyIds, memberType }) {
      const response = await GroupService.listGroups({ companyIds, memberType })
      commit('SET_GROUPS', response.data.data.groups)
      return response
    },

    async selectGroupLocationIds({ getters, dispatch }, groupIds) {
      const selectedGroups = await dispatch('findSelectedGroups', {
        selectedGroups: new Set(),
        groupIds,
        groups: getters.selectGroups,
      })
      
      return [...(await dispatch('selectGroupMemberIds', { memberIds: new Set(), groups: [...selectedGroups] }))]
    },

    async findSelectedGroups({ dispatch }, { selectedGroups, groupIds, groups }) {
      groups.forEach((group) => {
        if (group.children && group.children.length) {
          dispatch('findSelectedGroups', { selectedGroups, groupIds, groups: group.children })
        }
        if (groupIds.includes(group._id)) {
          selectedGroups.add(group)
        }
      })

      return selectedGroups
    },

    async selectGroupMemberIds({ dispatch }, { memberIds, groups }) {
      if (!groups || !groups.length) return memberIds

      groups.forEach((group) => {
        if (group.children && group.children.length) {
          if (group.id === 'nonGroupMembers') return
          dispatch('selectGroupMemberIds', { memberIds, groups: group.children })
        } else if (group.members && group.members.length) {
          group.members.forEach((member) => {
            memberIds.add(member)
          })
        }
      })

      return memberIds
    },
  },
}
