<template>
  <div class="">
    <span v-for="rating in 5" :key="rating" class="mr-2">
      <img
        :src="`/img/ratings/${rating}.png`"
        :alt="`rating ${rating} emoji`"
        contain
        height="30px"
      />
    </span>
  </div>
</template>

<script>
// @vuese
// @group CustomQuestions
// A preview component for rating scale on the custom question page
export default {
  name: 'RatingScaleType',
}
</script>
