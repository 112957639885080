import config from '@/config'

function connect({
  onOpen = () => {
    console.log('-- websocket connected --')
  },
  onMessage = (data) => {
    console.log('-- websocket message:', data)
  },
  onClose = () => {
    console.log('-- websocket disconnected --')
  },
  onError = (error) => {
    console.error('-- websocket errror:', error)
  },
} = {}) {
  if (!config.isProduction && !config.isStaging && !config.isTest) {
    console.log('ws disabled locally')
    return [null, () => {}]
  }

  let interval = null
  const onMessageHandlers = {}
  const ws = new WebSocket(config.websocketApi)
  ws.onopen = () => {
    onOpen()
    interval = setInterval(ping, 540000) // every 9 mins
  }

  ws.onmessage = ({ data }) => {
    const message = JSON.parse(data)

    if (!message.type) {
      console.error('WS invalid:', message)
      return
    }

    switch (message.type) {
      case 'ping':
        console.log('WS:', message.detail)
        return
      case 'error':
        console.error('WS error:', message.detail)
        return
      default:
        const handlers = Object.values(onMessageHandlers)

        if (handlers.length) {
          handlers.forEach((handler) => handler(message))
        } else {
          onMessage(message)
        }
    }
  }

  ws.onclose = () => {
    onClose()
    clearInterval(interval)
  }

  ws.onerror = (error) => {
    onError(error)
    clearInterval(interval)
  }

  function ping() {
    console.log('WS pinging interval')
    if (!ws) return
    if (ws.readyState !== 1) return
    ws.send('ping')
  }

  function pushMessageHandler({ key, handler }) {
    onMessageHandlers[key] = handler
  }

  return [ws, pushMessageHandler]
}

export default {
  connect,
}
