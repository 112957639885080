import Vue from 'vue'
import AnalyticsService from '@/services/AnalyticsService'
import { cloneDeep } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    activeCustomer: {},
    activeCustomerEvents: [],
    location: undefined,
    dateRange: {},
    source: undefined,
    cacheTimestamp: undefined,
    demoMode: false,
    cache: [],
    loadingMetrics: false,
    metrics: {
      reviewSummary: [],
      roi: {},
      newReviewsCount: 0,
      reviewsIntercepted: 0,
      surveysPerMonth: 0,
      averageRating: 0,
      summary: {},
      mostPopularPlatform: 'Google',
      reviewVelocity: {},
      totalReviews: 0,
      totalCustomers: 0,
      totalSurveys: 0,
      ratingsPerLocation: {},
      reviewChart: { labels: [], data: [] },
      surveyChart: { labels: [], data: [] },
      customerChart: { labels: [], data: [] },
      satisfactionChart: [0, 0, 0, 0, 0],
      satisfactionRate: 0,
      surveySourceChart: { labels: [], data: [] },
      leaderboard: [],
      winbackCount: 0,
      totalCheckins: 0,
      totalLoyaltyMembers: 0,
      totalPointsEarned: 0,
      totalPointsSpent: 0,
      totalPointsUnspent: 0,
      checkinsChart: { labels: [], data: [] },
    },
  },
  mutations: {
    setActiveCustomer(state, customer) {
      state.activeCustomer = customer
    },
    setActiveCustomerEvents(state, events) {
      state.activeCustomerEvents = events
    },
    setLoadingMetrics(state, value) {
      state.loadingMetrics = value
    },
    setLocation(state, location) {
      state.location = cloneDeep(location)
    },
    setDateRange(state, dateRange) {
      state.dateRange = dateRange
    },
    setSource(state, source) {
      state.source = source
    },
    setDemoMode(state, mode) {
      state.demoMode = mode
    },
    updateMetricsCache(state, metrics) {
      state.cache = [...metrics, ...state.cache]
    },
    updateMetrics(state, metrics) {
      Object.keys(metrics).forEach((key) => {
        Vue.set(state.metrics, key, metrics[key])
      })
      state.cacheTimestamp = new Date()
    },
  },
  actions: {
    async updateMetrics({ state, rootState, commit }, requestedMetrics) {
      commit('setLoadingMetrics', true)
      commit('updateMetricsCache', requestedMetrics)
      if (!rootState.company.activeCompany || !requestedMetrics.length) return
      let payload = {
        metrics: requestedMetrics,
        dateRange: state.dateRange,
        source: state.source,
        companyId: rootState.company.activeCompany._id,
        locationId: state.location ? state.location._id : undefined,
      }
      const response = await AnalyticsService.get(payload)
      commit('setDemoMode', response.body.demo)
      commit('updateMetrics', response.body.data)
      commit('setLoadingMetrics', false)
    },
    async refreshMetrics({ state, dispatch }) {
      let metricsList = []
      Object.keys(state.metrics).forEach((metric) => {
        // Only request metrics that have been requested
        if (state.cache.indexOf(metric) !== -1) metricsList.push(metric)
      })
      dispatch('updateMetrics', metricsList)
    },
    async setLocation({ commit, dispatch }, location) {
      commit('setLocation', location)
      dispatch('refreshMetrics')
    },
    async setDateRange({ commit, dispatch }, dateRange) {
      commit('setDateRange', dateRange)
      dispatch('refreshMetrics')
    },
    async setSource({ commit, dispatch }, source) {
      commit('setSource', source)
      dispatch('refreshMetrics')
    },
  },
}
