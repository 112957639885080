<template>
  <multiselect
    :style="{
      width: companies.length || wideMode ? 'auto' : '120px',
    }"
    placeholder="Type to search"
    selectLabel
    :close-on-select="false"
    v-model="selectedCompanies"
    :multiple="true"
    @close="emitFilter(null)"
    @remove="emitFilter($event)"
    :searchable="true"
    :showLabels="false"
    :limitText="(count) => `+${count} more`"
    :limit="2"
    :options="companiesToShow"
    :custom-label="companyName"
    track-by="_id"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary">
        <span class="fe fe-map-pin"></span> {{ placeholder || 'All companies' }}
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span>{{ companyName(props.option) }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'CompanyFilter',
  props: ['wideMode', 'companies', 'activeCompany', 'preselectedCompanies', 'placeholder'],
  components: {
    Multiselect,
  },
  data: () => ({
    selectedCompanies: [],
  }),
  computed: {
    companiesToShow() {
      return [...this.companies].sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    },
  },
  methods: {
    emitFilter(event) {
      this.$emit('update', [
        ...this.selectedCompanies
          .map((company) => company._id)
          .filter((companyId) => (!event || companyId != event._id ? true : false)),
      ])
    },
    companyName({ name, friendlyName }) {
      return friendlyName || name
    },
  },
  mounted() {
    if (this.preselectedCompanies) {
      this.selectedCompanies = this.preselectedCompanies.map((id) =>
        this.companiesToShow.find((c) => c._id === id)
      )
    } else if (this.activeCompany && this.activeCompany._id !== 'ALL_COMPANIES') {
      this.selectedCompanies.push(this.activeCompany)
      this.$emit('update', [this.activeCompany._id])
    }
  },
}
</script>
