<template>
  <div>
    <hr class="mt-4 mb-5" />
    <div class="form-group mb-4">
      <CreatePromoCodeForm type="in-store" placeholder="TWO4ONE" />
    </div>
    <hr class="mt-4 mb-5" />
    <div class="form-group mb-4">
      <CreatePromoCodeForm type="off-premise" placeholder="BOGO324" />
    </div>
  </div>
</template>
<script>
import CreatePromoCodeForm from './CreatePromoCodeForm'

export default {
  name: 'CreatePromoCodes',
  components: {
    CreatePromoCodeForm,
  },
}
</script>
