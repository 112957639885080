<template>
  <Multiselect
    style="min-width: 168px"
    :show-labels="false"
    closeOnSelect
    :placeholder="placeholder"
    @input="updateFilters"
    v-model="selectedCategory"
    :options="categoryList"
    track-by="_id"
    :custom-label="customLabel"
  >
    <template slot="option" slot-scope="props">
      <div>
        <span v-if="props.option.path">{{ selectCategoryName(props.option.path) }}: </span>
        <span>{{ props.option.name }}</span>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')

export default {
  name: 'CategoryFilter',
  props: {
    category: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'All Categories',
    },
  },
  components: {
    Multiselect,
  },
  mounted() {
    if (this.category) {
      this.selectedCategory = this.selectNonOtherCategories.find((cat) => cat._id === this.category)
    }
  },
  computed: {
    ...InsightsModule.mapGetters(['selectNonOtherCategories', 'selectCategoryName']),
    categoryList() {
      return [
        { name: 'All Categories' },
        ...this.selectNonOtherCategories.sort((a, b) =>
          this.customLabel({name: a.name, path: a.path}) < this.customLabel({name: b.name, path: b.path})
            ? -1
            : this.customLabel({name: a.name, path: a.path}) > this.customLabel({name: b.name, path: b.path})
            ? 1
            : 0
        ),
      ]
    },
  },
  data: () => ({
    selectedCategory: '',
  }),
  methods: {
    updateFilters(selection) {
      this.$emit('update', selection?._id)
    },
    customLabel({ name, path }) {
      return path ? `${this.selectCategoryName(path)}: ${name}` : name
    },
  },
}
</script>
