import { get } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    companyNavigation: [
      {
        title: 'Company',
        route: 'company-settings-profile',
      },
      {
        title: 'Locations',
        route: 'company-settings-locations',
      },
      {
        title: 'Links',
        route: 'company-settings-links',
      },
      {
        title: 'Team',
        route: 'company-settings-team',
      },
      {
        title: 'Loyalty',
        route: 'company-settings-loyalty',
      },
      // {
      //   title: 'Integrations',
      //   route: 'company-settings-integrations'
      // },
      {
        title: 'Billing',
        route: 'company-settings-subscription',
      },
      {
        title: 'Responses',
        route: 'company-settings-responses',
      },
      // {
      //   title: 'Tags',
      //   route: 'company-settings-tags',
      // },
      {
        title: 'Templates',
        route: 'templates',
      },
      {
        title: 'Offers',
        route: 'promos',
      },
      {
        title: 'Keywords',
        route: 'keywords',
      },
    ],
    locationNavigation: [
      // {
      //   title: 'Survey',
      //   route: 'location-settings-survey'
      // },
      { title: 'Platforms', route: 'location-settings-platforms' },
      { title: 'Devices', route: 'location-settings-devices' },
      { title: 'Integrations', route: 'location-settings-integrations' },
      { title: 'Settings', route: 'location-settings-basic' },
    ],
    cttAllowedNavigation: [
      'call-reports',
      'call-settings',
      'winback',
      'company-settings-profile',
      'company-settings-locations',
      'company-settings-subscription',
      'company-settings-team',
      'promos',
      'create-promo',
      'account-profile',
    ],
  },
  getters: {
    companyNavigation(state, getters, rootState, rootGetters) {
      /*
       * When the active partner is Vendasta this filter will prevent the 'Teams'
       * section from appearing in the navigation bar.
       */
      const loyaltyEnabled = rootGetters['company/loyaltyEnabled']
      const responsesEnabled = rootGetters['company/selectAreResponsesEnabled']

      return state.companyNavigation.filter((nav) => {
        if (
          (['company-settings-profile', 'company-settings-responses'].includes(nav.route) &&
            get(rootState.user, 'onlineUser.role') !== 'admin') ||
          (nav.route === 'company-settings-team' &&
            rootGetters['company/activePartnerName'] === 'vendasta') ||
          (nav.route === 'templates' &&
            !rootGetters['company/selectIsPermitted']('templateReadAccess')) ||
          (nav.route === 'promos' &&
            !rootGetters['company/selectIsPermitted']('promosReadAccess')) ||
          (nav.route === 'keywords' &&
            !rootGetters['company/selectIsPermitted']('keywordsReadAccess')) ||
          (nav.route === 'company-settings-responses' && !responsesEnabled)
        ) {
          return false
        } else if (
          nav.route === 'company-settings-subscription' &&
          rootGetters['user/appIsWhiteLabeled']
        ) {
          return false
        } else if (!loyaltyEnabled && nav.route === 'company-settings-loyalty') {
          return false
        } else {
          return true
        }
      })
    },
    selectCTTAllowedNavigation: (state) => state.cttAllowedNavigation,
  },
}
