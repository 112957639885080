import CampaignService from '@/services/CampaignService'
import LocationService from '@/services/LocationService'
import { cloneDeep, assignIn, omit } from 'lodash-es'

const defaultCampaign = () => ({
  title: '',
  filters: {},
  messageBody: '',
  isMMS: false,
  isUnicode: false,
  isTextWithPicture: false,
  messageMedia: [],
  promo: undefined,
  type: '', // 'auto' or 'blast'
  scheduling: {
    // Auto campaigns only. Used to delay the sending of the message after a customer fits within the scope of the audience filters
    delay: false,
    // Numberic - length of the delay
    delayLength: undefined,
    // Unit of the length (minutes, days, weeks, months)
    delayUnit: 'minutes',
    // Auto campaigns only. Used to specify a date on which the campaign well automatically stop sending
    autoEnd: false,
    // Date for `autoEnd`
    autoEndDate: undefined,
    // Blast campaigns only. Is a date & time - instead of sending the blast immediately, it will be scheduled
    blastSchedule: false,
    // DateTime for blast schedule
    blastScheduleDate: undefined,
  },
  estimatedCreditUsage: 0,
})

export default {
  namespaced: true,
  state: {
    campaigns: [],
    statusFilter: 'all',
    campaign: defaultCampaign(),
    location: undefined,
    remainingCredits: 0,
  },
  getters: {
    campaign: (state) => state.campaign,
    selectRemainingCredits: (state) => state.remainingCredits,
    selectCampaign(state) {
      return state.campaign
    },
    campaignMessage: (state) => (campaignId) => {
      const campaign = state.campaigns.find(({ _id }) => _id === campaignId)
      return campaign && campaign.messageBody
    },
    messageLength(state, _, rootState) {
      const baseFooterLength = 34
      const linkCount =
        (state.campaign.messageBody.match(new RegExp('<Review Link>', 'g')) || []).length +
        (state.campaign.messageBody.match(new RegExp('<Survey Link>', 'g')) || []).length +
        (state.campaign.messageBody.match(new RegExp('<Order Link>', 'g')) || []).length

      const customerNameCount = (
        state.campaign.messageBody.match(new RegExp('<Customer First Name>', 'g')) || []
      ).length

      let formattedMessageBody = state.campaign.messageBody
        .replace(/<Review Link>/g, '')
        .replace(/<Survey Link>/g, '')
        .replace(/<Order Link>/g, '')
        .replace(/<Customer First Name>/g, '')

      const messageLength =
        formattedMessageBody.length +
        (state.campaign.promo ? 33 : 0) +
        linkCount * 23 +
        customerNameCount * 10 +
        baseFooterLength +
        rootState.company.activeCompany.name.length
      return messageLength
    },
    smsCount(state, getters) {
      if (state.campaign.isMMS) {
        if (state.campaign.isUnicode && !state.campaign.isTextWithPicture) {
          if (getters.messageLength <= 70) {
            return 1
          } else {
            return Math.ceil(getters.messageLength / 67)
          }
        } else if (!state.campaign.isUnicode && state.campaign.isTextWithPicture) {
          return Math.ceil(getters.messageLength / 1000) * 2
        } else {
          return Math.ceil(getters.messageLength / 500) * 2
        }
      } else {
        if (getters.messageLength <= 160) {
          return 1
        } else {
          return Math.ceil(getters.messageLength / 153)
        }
      }
    },
  },
  mutations: {
    setCampaigns(state, campaigns) {
      state.campaigns = campaigns
    },
    setStatusFilter(state, status) {
      state.statusFilter = status
    },
    setMessageBody(state, text) {
      state.campaign.messageBody = text
    },
    setMessageMedia(state, media) {
      state.campaign.messageMedia = [...media]
    },
    setType(state, campaignType) {
      state.campaign.type = campaignType
    },
    setTitle(state, title) {
      state.campaign.title = title
    },
    setPromo(state, promoId) {
      state.campaign.promo = promoId
    },
    setFilters(state, filters) {
      state.campaign.filters = filters
    },
    setSchedulingByKey(state, { key, value }) {
      state.campaign.scheduling[key] = value
    },
    resetCampaign(state) {
      state.campaign = defaultCampaign()
    },
    startEditingCampaign(state, campaign) {
      state.campaign = assignIn(defaultCampaign(), campaign)
    },
    setLocation(state, location) {
      state.location = location
    },
    setMessageType(state, { MMSType, unicodeType, isPicture }) {
      state.campaign.isMMS = MMSType
      state.campaign.isUnicode = unicodeType
      state.campaign.isTextWithPicture = isPicture
    },
    setCampaignToEdit(state, campaignId) {
      state.campaign = state.campaigns.find(({ _id }) => _id === campaignId)
    },
    START_DUPLICATING_CAMPAIGN(state, campaign) {
      state.campaign = assignIn(defaultCampaign(), campaign)
    },
    SET_ESTIMATED_CREDIT_USAGE(state, credit) {
      state.campaign.estimatedCreditUsage = credit
    },
    SET_REMAINING_CREDITS(state, remainingCredits) {
      state.remainingCredits = remainingCredits
    },
  },
  actions: {
    async startDuplicateCampaign({ commit }, campaign) {
      let newCampaign = omit(campaign, [
        'createdAt',
        'metrics',
        'scheduling',
        'updatedAt',
        '__v',
        '_id',
        'status',
        'history',
      ])
      newCampaign.title = 'copy of ' + newCampaign.title
      commit('START_DUPLICATING_CAMPAIGN', newCampaign)
    },

    async queryCampaigns({ rootGetters, commit }) {
      let companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]
      let responses = await Promise.all(
        companies.map((company) => {
          return CampaignService.list(company._id)
        })
      )

      let campaigns = responses.reduce(
        (previous, current) => previous.concat(current.body.campaigns),
        []
      )
      commit('setCampaigns', campaigns)
    },
    resetMessageBody({ commit }) {
      commit('setMessageBody', '')
    },
    async deleteCampaign({ dispatch }, campaign) {
      await CampaignService.delete(campaign._id)
      dispatch('queryCampaigns')
    },
    async getDefaultLocation({ rootState, commit }) {
      const response = await LocationService.getForUser({
        userId: rootState.user.onlineUser._id,
        companyId: rootState.company.activeCompany._id,
      })

      const { locations } = response.body.data

      const location = locations.length ? locations[0] : {}

      commit('setLocation', location)
    },
    async createCampaign({ rootState }, campaign) {
      const { activeCompany } = rootState.company

      if (!activeCompany) return

      const companyId = activeCompany._id

      const { body } = await CampaignService.create({
        campaign: { ...campaign, company: companyId },
      })

      return body.campaignId
    },
    async toggleCampaign({}, campaignId) {
      await CampaignService.toggle(campaignId)
    },
    async updateCampaign(_, { campaignId, fields }) {
      let response = await CampaignService.update({ campaignId, fields })
      return response
    },
    setEstimatedCreditUsage({ commit }, creditUsage) {
      commit('SET_ESTIMATED_CREDIT_USAGE', creditUsage)
    },
    async fetchRemainingCredits({ commit, rootGetters }) {
      const companyId = rootGetters['company/activeCompany']._id
      const response = await CampaignService.fetchCredits({ companyId })
      commit('SET_REMAINING_CREDITS', response.body.data.creditsRemaining)
    },
  },
}
