<template>
  <multiselect
    placeholder="Type to search"
    selectLabel
    :close-on-select="false"
    v-model="selectedSources"
    :multiple="true"
    @input="updateSources"
    :searchable="true"
    :showLabels="false"
    :limitText="(count) => `+${count} more`"
    :limit="showLimit"
    :options="sources"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary"> <span class="fas fa-inbox"></span> {{ placeholder }} </span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'

const surveyModule = createNamespacedHelpers('survey')

export default {
  name: 'SourceFilter',
  components: {
    Multiselect,
  },
  props: {
    selected: {
      type: Array,
    },
    showLimit: {
      type: Number,
      default: 2,
    },
    placeholder: {
      type: String,
      default: 'All Sources',
    },
  },
  data: () => ({
    selectedSources: [],
  }),
  mounted() {
    if (this.selected && this.selected.length) {
      this.selectedSources = this.selected
    }
  },
  computed: {
    ...surveyModule.mapState(['sources']),
  },
  methods: {
    reset(locations) {
      this.filteredLocations = []
    },
    updateSources(sources) {
      this.$emit('updateSources', sources)
    },
    sourceName({ name }) {
      return name
    },
  },
}
</script>
